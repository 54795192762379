import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import RouteNames from '../../App/RouteNames';
import PackageForm from './PackageForm';
import { makeGetPackageDetail, makeErrorMessage } from '../selectors';
import { getPackageDetail, modifyPackage, updatePackageStatus } from '../actions';
import { Breadcrumb } from '../../../components/common';
import {
  checkPermissionModifyPackage,
  checkPermissionApprovePackage,
  checkPermissionSellablePackage,
  checkPermissionNonSellablePackage,
  checkPermissionSunsetPackage,
} from '../CheckPermission';
import { makeGetPermissionsBundleManagement } from '../../App/selectors';

class PackageItemEdit extends Component {
  state = {
    isSubmitting: false,
  };

  componentDidMount() {
    this.doGetPackageDetail();
  }

  doGetPackageDetail = () => {
    const {
      match: {
        params: { id },
      },
      getPackageDetail,
    } = this.props;
    getPackageDetail(id);
  };

  onSave = (data, cb = null) => {
    const { status, components, ...dataItem } = data;
    const { modifyPackage, updatePackageStatus } = this.props;
    let newComponents = [...components];
    newComponents = newComponents.map(e => {
      const { isNew, ...rest } = e;
      return {
        ...rest,
      };
    });

    const { packageInfo } = this.props;
    const changedStatus = status !== packageInfo.status;
    dataItem.newStatus = status;
    if (!changedStatus) {
      modifyPackage({ ...dataItem, components: newComponents }, ({ success }) => {
        this.setState({ isSubmitting: false });
        if (success) this.doGetPackageDetail();
        if (cb) cb({ success });
      });
    } else {
      modifyPackage({ ...dataItem, components: newComponents }, ({ success }) => {
        if (success) {
          updatePackageStatus({ id: packageInfo.id, status }, dd => {
            this.setState({ isSubmitting: false });
            if (cb) cb({ success: dd.success });
          });
        } else {
          this.setState({ isSubmitting: false });
        }
      });
    }
  };

  render() {
    const { packageInfo, permissionsBundleManagement, t, location } = this.props;
    const { isSubmitting } = this.state;

    let modeModifyPackage = 0;
    let modeApprovePackage = 0;
    let modeSellablePackage = 0;
    let modeNonSellablePackage = 0;
    let modeSunsetPackage = 0;
    if (permissionsBundleManagement && permissionsBundleManagement.bundleModulePermissions) {
      const listPermissionBundle = permissionsBundleManagement.bundleModulePermissions;
      modeModifyPackage = checkPermissionModifyPackage({ listPermission: listPermissionBundle });
      modeApprovePackage = checkPermissionApprovePackage({ listPermission: listPermissionBundle });
      modeSellablePackage = checkPermissionSellablePackage({ listPermission: listPermissionBundle });
      modeNonSellablePackage = checkPermissionNonSellablePackage({ listPermission: listPermissionBundle });
      modeSunsetPackage = checkPermissionSunsetPackage({ listPermission: listPermissionBundle });
    }
    if (modeModifyPackage === 0) return '';

    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link
                to={{
                  pathname: RouteNames.packageItem.path,
                  state: location && location.state ? location.state : null,
                }}
                className="button x-small"
              >
                {t('label.back')}
              </Link>
            </div>

            <div className="col-sm-6">
              <Breadcrumb
                items={[
                  { name: t('navbar:pricingHub.subMain.bundleManagement'), url: RouteNames.bundleItem.path },
                  { name: t('bundlePage:sidebar.package'), url: RouteNames.packageItem.path },
                  { name: packageInfo && packageInfo.id ? packageInfo.id : 'Edit' },
                ]}
              />
            </div>
          </div>
        </div>

        <br />
        <div className="col-md-12 mb-30">
          <div className="card card-statistics h-100">
            <div className="card-body">
              <div className="d-block w-100">
                <h5 className="card-title">{t('label.editPackage')}</h5>
              </div>
              <PackageForm
                item={packageInfo}
                modeModifyPackage={modeModifyPackage}
                modeApprovePackage={modeApprovePackage}
                modeSellablePackage={modeSellablePackage}
                modeNonSellablePackage={modeNonSellablePackage}
                modeSunsetPackage={modeSunsetPackage}
                onSubmit={this.onSave}
                isSubmitting={isSubmitting}
                creatingMode={false}
                onCancel={this.doGetPackageDetail}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PackageItemEdit.propTypes = {
  permissionsBundleManagement: PropTypes.objectOf(PropTypes.any),
  match: PropTypes.objectOf(PropTypes.any),
  modifyPackage: PropTypes.func.isRequired,
  updatePackageStatus: PropTypes.func.isRequired,
  getPackageDetail: PropTypes.func.isRequired,
  packageInfo: PropTypes.objectOf(PropTypes.any),
};

PackageItemEdit.defaultProps = {
  permissionsBundleManagement: {},
  packageInfo: {},
  match: {},
};

const mapStateToProps = createStructuredSelector({
  packageInfo: makeGetPackageDetail() || {},
  errorMessage: makeErrorMessage() || '',
  permissionsBundleManagement: makeGetPermissionsBundleManagement() || {},
});

const mapDispatchToProps = dispatch => ({
  getPackageDetail: (e, cb) => dispatch(getPackageDetail(e, cb)),
  modifyPackage: (e, cb) => dispatch(modifyPackage(e, cb)),
  updatePackageStatus: (e, cb) => dispatch(updatePackageStatus(e, cb)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation(['common', 'navbar', 'bundlePage'])(compose(withConnect)(PackageItemEdit));
