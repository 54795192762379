import RouterNames from '../../../containers/App/RouteNames';

export default {
  label: {
    parentId: 'Parent ID',
    accountId: 'Account Id',
    firstName: 'First Name',
    middleName: 'Middle Name',
    lastName: 'Last Name',
    city: 'City',
    state: 'State',
    organization: 'Organization',
    status: 'Status',
    search: 'Search',
    clear: 'Clear',
    home: 'Home',
    currency: 'Currency',
    reason: 'Reason',
    action: 'Action',
    pageSize: 'Page size',
    attributeName: 'Attribute Name',
    attributeType: 'Attribute Type',
    length: 'Length',
    mandatory: 'Mandatory',
    saveConfig: 'Save config',
    createConfig: 'Create config',
    cancel: 'Cancel',
    addNewAttribute: 'Add new attribute',
    customerSegment: 'Customer Segment',
    salesChannel: 'Sales Channel',
    marketSegment: 'Market Segment',
    accountType: 'Account Type',
    accountSubType: 'Account Sub Type',
    creditLimit: 'Credit Limit',
    remove: 'Remove',
    addCreditProfile: 'add credit profile',
    sellingCompany: 'Selling Company',
    lineOfBusiness: 'Line of Business',
    legalEntity: 'Legal Entity',
    effectiveDate: 'Effective Date',
    id: 'Id',
    salutation: 'Salutation',
    position: 'Position',
    email: 'Email',
    phone: 'Phone',
    type: 'Type',
    roles: 'Roles',
    useAsBilling: 'Use As Billing',
    useAsSoldTo: 'Use As Sold To',
    useAsPayment: 'Use As Payment',
    useAsService: 'Use as service',
    addPhone: 'Add phone',
    deleteContact: 'Delete contact',
    addNewContact: 'Add new contact',
    street: 'Street',
    extraLine: 'Extra Line',
    landmark: 'Landmark',
    country: 'Country',
    postalCode: 'Postal code',
    code: 'Code',
    deleteAddress: 'Delete address',
    addNewAddress: 'Add new address',
    paymentTerm: 'Payment Term',
    paymentMethod: 'Payment Method',
    check: 'Check',
    creditCard: 'Credit card',
    billingDom: 'Billing Dom',
    billingSegment: 'Billing Segment',
    billingFrequency: 'Billing Frequency',
    invoiceType: 'Invoice Type',
    invoiceDelivery: 'Invoice Delivery',
    paymentProfileId: 'Payment Profile Id',
    creditCardToken: 'Credit Card Token',
    creditCardExpiry: 'Credit Card Expiry',
    deleteCardToken: 'Delete card token',
    creditCardCVV: 'Credit Card CVV',
    currentBalance: 'Current Balance',
    currentBillTotal: 'Current Bill Total',
    lastInvoiceDate: 'Last Invoice Date',
    lastInvoiceTotal: 'Last Invoice Total',
    lastInvoiceDueDate: 'Last Invoice Due Date',
    lastInvoiceDue: 'Last Invoice Due',
    lastPaymentDate: 'Last Payment Date',
    lastPaymentAmount: 'Last Payment Amount',
    crARSinceLastInvoice: 'Cr AR Since Last Invoice',
    drARSinceLastInvoice: 'Dr AR Since Last Invoice',
    createNewOrder: 'Create new order',
    modify: 'Modify',
    service: 'Service',
    subscription: 'Subscription',
    initialTerm: 'Initial Term',
    initialTermUnit: 'Initial Term Unit',
    renewalTerm: 'Renewal Term',
    renewalTermUnit: 'Renewal Term Unit',
    trialTerm: 'Trial Term',
    trialTermUnit: 'Trial Term Unit',
    placeOrder: 'Place Order',
    downgrade: 'Downgrade',
    upgrade: 'Upgrade',
    resume: 'Resume',
    suspend: 'Suspend',
    renew: 'Renew',
    create: 'Create',
    provisioningId: 'Provisioning Id',
    bundleId: 'Bundle Id',
    packageId: 'Package Id',
    subscriptionId: 'Subscription Id',
    assets: 'Assets',
    priceOfferId: 'Price Offer Id',
    serviceUnitId: 'Service Unit Id',
    serviceType: 'Service Type',
    priceOverride: 'Price Override',
    priceOffset: 'Price Offset',
    discountPercent: 'Discount Percent',
    startDate: 'Start Date',
    endDate: 'End Date',
    accountNumber: 'Account Number',
    balances: 'Balances',
    currencyId: 'Currency Id',
    amount: 'Amount',
    pendingBills: 'Pending Bills',
    total: 'Total',
    nonPayingTotal: 'Non-Paying Total',
    billTotal: 'Bill Total',
    openBills: 'Open Bills',
    closeBills: 'Close Bills',
    billUnitId: 'Bill Unit Id',
    dueDate: 'Due Date',
    due: 'Due',
    invoiceNumber: 'Invoice Number',
    invoiceStatus: 'Invoice Status',
    transactions: 'Transactions',
    itemId: 'Item Id',
    netAmount: 'Net Amount',
    source: 'Source',
    aRActivity: 'AR Activity',
    arTaxRule: 'AR Tax Rule',
    arCrDrType: 'AR CR Dr Type',
    invoiceUnitId: 'Invoice Unit Id',
    cRDrType: 'CR Dr Type',
    taxApplication: 'Tax Application',
    customerActivity: 'Customer Activity',
    userId: 'User Id',
    entityType: 'Entity Type',
    activityDate: 'Activity Date',
    view: 'View',
    customerHierarchy: 'Customer Hierarchy',
    submittedDate: 'Submitted Date',
    next: 'Next',
    order: 'Order',
    purchasePackage: 'Purchase package',
    purchaseBundle: 'Purchase bundle',
    purchaseAlaCarte: 'Purchase ala-carte',
    description: 'Description',
    acSubType: 'Ac Sub Type',
    selected: 'Selected',
    name: 'Name',
    pricingModel: 'Pricing Model',
    serviceAddOn: 'Service Add On',
    isPartialFulfillmentAllowed: 'Is Partial Fulfillment Allowed',
    discountOfferId: 'Discount Offer Id',
    relativeStart: 'Relative Start',
    relativeStartUnit: 'Relative Start Unit',
    relativeStartDuration: 'Relative Start Duration',
    relativeEndUnit: 'Relative End Unit',
    relativeEndDuration: 'Relative End Duration',
    priceOverrride: 'Price Overrride',
    packageName: 'Package Name',
    updateOrder: 'Update order',
    submitOrder: 'Submit order',
    approveOrder: 'Approve order',
    serviceUnits: 'Service Units',
    subscriptionReason: 'Subscription Reason',
    overrideLines: 'Override lines',
    addBundle: 'Add Bundle',
    productFamily: 'Product Family',
    productSubType: 'Product Sub Type',
    productType: 'Product Type',
    revenueType: 'Revenue Type',
    revenueRecognitionType: 'Revenue Recognition Type',
    externalId: 'External Id',
    glAccount: 'GL Account',
    externalName: 'External Name',
    parentItemId: 'Parent Item Id',
    isDiscountable: 'Is Discountable',
    isBundled: 'Is Bundled',
    editPricingItem: 'Edit Pricing Item',
    createNewItem: 'Create new item',
    createNewPackage: 'Create New Package',
    transactionType: 'Transaction Type',
    index: 'Index',
    addNewCustomerPricing: 'add new customer pricing',
    customPricing: 'Custom Pricing',
    editPricingOffer: 'Edit Pricing Offer',
    createNewPriceOffer: 'Create new price offer',
    minimumQuantity: 'Minimum Quantity',
    maximumQuantity: 'Maximum Quantity',
    validityUnit: 'Validity Unit',
    validityDuration: 'Validity Duration',
    dependency: 'Dependency',
    addNewComponent: 'Add new component',
    addNewDependencyList: 'Add new dependency list',
    dependencyList: 'Dependency List',
    applyAOneOffPayment: 'Apply A One-Off Payment',
    invoicePaymentType: 'Invoice Payment Type',
    oneOffAmount: 'One-Off Amount',
    applyTo: 'Apply to',
    routingNumber: 'Routing Number',
    checkNumber: 'Check Number',
    newAddress: 'New Address',
    bank: 'Bank',
    BANK: 'BANK',
    paymentHistory: 'Payment History',
    paymentsAfterThisDate: 'Payments After this date',
    remainingAmount: 'Remaining Amount',
    paymentAmount: 'Payment Amount',
    paymentDate: 'Payment Date',
    paymentTxnId: 'Payment Txn Id',
    autoAllocate: 'Auto allocate',
    manualAllocation: 'Manual Allocation',
    reversePayment: 'Reverse payment',
    paymentReversalReason: 'Payment Reversal Reason',
    notes: 'Notes',
    addNewAction: 'Add new action',
    offsetDays: 'Offset Days',
    addNewScheduleItem: 'Add new schedule item',
    addNewAgent: 'Add new agent',
    accountsCollection: 'Accounts Collection',
    OfInvoicesInCollection: 'of invoices in Collection',
    reAssignAgent: 'Re-assign agent',
    agentActivity: 'Agent Activity',
    numberOfAccountsInCollection: 'Number of accounts in Collection',
    averageAgeInCollection: 'Average Age in Collection',
    numberOfAccountsOutOfCollectionInLast30Days: 'Number of accounts out of Collection in last 30 days',
    ofInvoicesInCollection: '# of invoices in Collection',
    invoiceUnitsInCollection: 'Invoice Units In Collection',
    totalInCollection: 'Total in Collection',
    collectionUnitStatus: 'Collection Unit Status',
    collectionHistory: 'Collection History',
    daysInCollection: 'Days In Collection',
    collectionHistoryDetail: 'Collection History Detail',
    closeDate: 'Close Date',
    lastActionDate: 'Last Action Date',
    collectionActionsForInvoice: 'Collection Actions For Invoice',
    reasonCode: 'Reason Code',
    taxCode: 'Tax Code',
    addNewReason: 'Add new reason',
    applyAdjustment: 'Apply Adjustment',
    taxRule: 'Tax Rule',
    CRDRType: 'CRDR Type',
    arType: 'AR Type',
    settle: 'Settle',
    settleADispute: 'Settle A Dispute',
    writeOffAmount: 'Write-off Amount',
    writeOffPercent: 'Write-off Percent',
    applyDispute: 'Apply Dispute',
    percent: 'Percent',
    writeOffs: 'Write-offs',
    applyWriteOffs: 'Apply Write-offs',
    reverse: 'Reverse',
    writeOffReversal: 'Write-Off Reversal',
    applyWriteOff: 'Apply Write Off',
    unpaidBills: 'Unpaid Bills',
    invoiceDate: 'Invoice Date',
    accountWriteoff: 'Account Writeoff',
    selfCareHubModules: 'Self-Care Hub Modules',
    selfCarePermissions: 'Self-Care Permissions',
    customerHubModules: 'Customer Hub Modules',
    customerManagementPermissions: 'Customer Management Permissions',
    orderManagementPermissions: 'Order Management Permissions',
    activityManagementPermissions: 'Activity Management Permissions',
    subscriptionManagementPermissions: 'Subscription Management Permissions',
    pricingHubModules: 'Pricing Hub Modules',
    pricingManagementPermissions: 'Pricing Management Permissions',
    bundleManagementPermissions: 'Bundle Management Permissions',
    billingHubModules: 'Billing Hub Modules',
    billingModulePermissions: 'Billing Module Permissions',
    invoicingModulePermissions: 'Invoicing Module Permissions',
    ratingModulePermissions: 'Rating Module Permissions',
    arHubModules: 'AR Hub Modules',
    arOpsPermissions: 'AR Ops Permissions',
    paymentPermissions: 'Payment Permissions',
    collectionPermissions: 'Collection Permissions',
    opsHubModules: 'Ops Hub Modules',
    userManagementPermissions: 'User Management Permissions',
    jobsManagementPermissions: 'Jobs Management Permissions',
    tenantManagementPermissions: 'Tenant Management Permissions',
    revenueHubModules: 'Revenue Hub Modules',
    roleGroupName: 'Role Group Name',
    roleGroupStatus: 'Role Group Status',
    roleGroupType: 'Role Group Type',
    userPassword: 'User Password',
    userAddress: 'User Address',
    userContact: 'User Contact',
    userRoles: 'User Roles',
    userRoleGroups: 'User Role Groups',
    userCategory: 'User Category',
    userStatus: 'User Status',
    userType: 'User Type',
    editRole: 'Edit Role',
    roleName: 'Role Name',
    roleStatus: 'Role Status',
    roleType: 'Role Type',
    ACCTNo: 'ACCT No',
    noRecord: 'No records found',
    customAttributes: 'Custom Attributes',
    customerManagement: 'Customer Management',
    creditProfile: 'Credit Profile',
    creditProfileIndex: 'Credit Profile Index',
    CHECK: 'CHECK',
    CREDIT_CARD: 'CREDIT_CARD',
    NON_PAYING: 'NON_PAYING',
    field: 'Field',
    mustBe: 'must be',
    VARCHAR: 'VARCHAR',
    BOOLEAN: 'BOOLEAN',
    NUMERIC: 'NUMERIC',
    INTEGER: 'INTEGER',
    createAccount: 'Create Account',
    createNew: 'Create New',
    account: 'Account',
    contacts: 'Contacts',
    delete: 'Delete',
    addresses: 'Addresses',
    paymentProfile: 'Payment Profile',
    billingProfile: 'Billing Profile',
    ocBills: 'Open/Closed bills',
    invoiceId: 'Invoice Id',
    messageNoAccountHierarchy: 'This account is not a hierarchy account',
    detail: 'Detail',
    modifyOrder: 'Modify Order',
    newOrder: 'New Order',
    renewOrder: 'Renew Order',
    cancelOrder: 'Cancel Order',
    suspendOrder: 'Suspend Order',
    resumeOrder: 'Resume Order',
    services: 'Services',
    line: 'Lines',
    quantity: 'Quantity',
    orderId: 'Order Id',
    bundleName: 'Bundle Name',
    choosePackage: 'Choose Package',
    company: 'Company',
    productLine: 'Product Line',
    addItem: 'Add Item',
    add: 'Add',
    endUnit: 'End Unit',
    endDuration: 'End Duration',
    startUnit: 'Start Unit',
    startDuration: 'Start Duration',
    edit: 'Edit',
    flatPricing: 'Flat Pricing',
    recurringPricing: 'Recurring Pricing',
    recurringUnit: 'Recurring Unit',
    frequency: 'Frequency',
    purchaseProration: 'Purchase Proration',
    cancelProration: 'Cancel Proration',
    upgradeProration: 'Upgrade Proration',
    downgradeProration: 'Downgrade Proration',
    price: 'Price',
    addPackage: 'Add Package',
    addPriceOffer: 'Add Price Offer',
    selectPriceOffers: 'Select Price Offers',
    selectBundles: 'Select Bundles',
    editPackage: 'Edit Package',
    editDependency: 'Edit Dependency',
    addDependency: 'Add Dependency',
    detailType: 'Detail Type',
    method: 'Method',
    addPaymentMethod: 'add payment method',
    apply: 'Apply',
    invoiceDateOffset: 'Invoice Date Offset',
    days: 'Days',
    workingDay: 'Working Day',
    addPaymentTerm: '+ Add payment term',
    invoiceAmount: 'Invoice Amount',
    dueAmount: 'Due Amount',
    creditCardPayment: 'Credit Card Payment',
    newCard: 'New Card',
    last4CC: 'Last 4 of CC',
    cardToken: 'Card Token',
    cardExp: 'Card Exp',
    applyAmount: 'Apply Amount',
    suspenseReason: 'Suspense Reason',
    suspenseStatus: 'Suspense Status',
    closeSuspense: 'Close Suspense',
    applySuspense: 'Apply Suspense',
    collections: 'Collections',
    collectionActions: 'Collection Actions',
    collectionSchedule: 'Collection Schedule',
    collectionAgents: 'Collection Agents',
    accounts: 'Accounts',
    aROpsConfig: 'AR Ops Config',
    openADispute: 'Open A Dispute',
    numberOfTransactions: 'Number of Transactions',
    date: 'Date',
    settledAmount: 'Settled Amount',
    settledDate: 'Settled Date',
    recoveredDebt: 'Recovered Debt',
    recoveredDate: 'Recovered Date',
    disputeAmount: 'Dispute Amount',
    disputePercent: 'Dispute Percent',
    adjustmentDetails: 'Adjustment Details',
    disputeDetail: 'Dispute Detail',
    addRole: 'Add Role',
    save: 'Save',
    limit: 'Limit',
    permission: 'Permission',
    read: 'Read',
    readWrite: 'Read Write',
    none: 'None',
    editRoleGroup: 'Edit Role Group',
    chooseRoles: 'Choose Roles',
    addRoleToRoleGroup: 'Add Role to Role Group',
    addRoleGroup: 'Add Role Group',
    createNewRoleGroup: 'Create New Role Group',
    category: 'Category',
    createNewUser: 'Create New User',
    addUser: 'Add User',
    chooseRoleGroup: 'Choose Role Group',
    back: 'Back',
    jobType: 'Job Type',
    noJobTypes: 'No job types',
    submit: 'Submit',
    pending: 'Pending',
    automatic: 'Automatic',
    manual: 'Manual',
    createJobSchedule: 'Create Job Schedule',
    pause: 'Pause',
    process: 'Process',
    reProcess: 'Reprocess',
    noJobScheduleOn: 'No job schedule on',
    jobSchedule: 'Job Schedule',
    processAction: 'Process Action',
    retry: 'Retry',
    stats: 'Stats',
    scheduleTime: 'Schedule Time',
    completed: 'Completed',
    error: 'Error',
    jobStats: 'Job Stats',
    totalRecord: 'Total record',
    sucessfulRecords: 'Sucessful records',
    errorRecords: 'Error Records',
    jobErrors: 'Job Errors',
    errorCode: 'Error code',
    numberOfRecordsError: 'Number of Records in Error',
    jobErrorDetails: 'Job Error Details',
    entityId: 'Entity Id',
    payload: 'Payload',
    manageUserProfile: 'Manage User Profile',
    manageProfile: 'Manage Profile',
    profile: 'Profile',
    resetPassword: 'Reset Password',
    tenant: 'Tenant',
    companyProfile: 'Company Profile',
    gatewayMerchantAccounts: 'Gateway Merchant Accounts',
    platformDefaults: 'Platform Defaults',
    taxGateway: 'Tax Gateway',
    paymentGateway: 'Payment Gateway',
    financeGateway: 'Finance Gateway',
    vaultUri: 'Vault URL',
    vaultPath: 'Vault Path',
    licenseKey: 'CCP License Key',
    number: 'Number',
    enquiryEmail: 'Enquiry Email',
    enquiryPhone: 'Customer Support Contact',
    companyTaxId: 'Company Tax Id',
    companyTag: 'Company Tag',
    merchantName: 'Merchant Name',
    validityInMonths: 'Validity (in months)',
    merchantAuthType: 'Merchant Auth Type',
    clientId: 'Client Id',
    clientProfileId: 'Client Profile Id',
    username: 'Username',
    password: 'Password',
    authorizationKey: 'Authorization Key',
    merchantId: 'Merchant Id',
    privateKey: 'Private Key',
    publicKey: 'Public Key',
    companyId: 'Company Id',
    refreshToken: 'Refresh Token',
    clientSecret: 'Client Secret',
    accessToken: 'Access Token',
    defaultCurrency: 'Default Currency',
    contactRole: 'Contact Role',
    addressRole: 'Address Role',
    productCompany: 'Product Company',
    setOfBooks: 'Set of Books',
    phoneType: 'Phone Type',
    shortBillingCycle: 'Short Billing Cycle',
    paymentNotification: 'Payment Notification',
    useUsageStartTime: 'Use Usage Start Time',
    paymentAutoAllocation: 'Payment Auto Allocation',
    taxItemId: 'Taxation Item Id',
    orderStatus: 'Order Status',
    selfCareUserType: 'Self Care User Type',
    selfCareUserCategory: 'Self Care User Category',
    passwordExpiryInMonths: 'Password Expiry In Months',
    defaultGLAccount: 'Default GL Account',
    batchSizeBilling: 'Batch Size Billing',
    batchSizePayment: 'Batch Size Payment',
    batchSizeInvoice: 'Batch Size Invoice',
    batchSizeCommon: 'Batch Size Common',
    paymentFailureNotification: 'Payment Failure Notification',
    invoiceDueNotification: 'Invoice Due Notification',
    invoiceReminderThreshold: 'Invoice Reminder Threshold',
    addNewProduct: 'Add New Product',
    symbol: 'Symbol',
    roundingMethod: 'Rounding Method',
    roundingPrecision: 'Rounding Precision',
    accumulateQuantity: 'Accumulate Quantity',
    addNewCurrency: 'Add New Currency',
    grantId: 'Grant Id',
    addNewGrant: 'Add New Grant',
    accumulatorId: 'Accumulator Id',
    addNewAccumulators: 'Add New Accumulators',
    accumulator: 'Accumulator',
    addTier: 'Add Tier',
    tier: 'Tier',
    messageDelete: 'Are you sure you want to delete this Item?',
    dashboardConfig: 'Dashboard Config',
    dashboardTile: 'Dashboard Tile',
    noDashboardTypes: 'No dashboard types',
    addADashboardTile: 'Add A Dashboard Tile',
    savePreference: 'Save Preference',
    myDashboard: 'My Dashboard',
    newCustomer: 'New Customer',
    subscriptionChurn: 'Subscription Churn',
    billedRevenue: 'Billed Revenue',
    billedCustomer: 'Billed Customer',
    milestoneId: 'Milestone Id',
    accumulators: 'Accumulators',
    grants: 'Grants',
    resourceId: 'Resource Id',
    amountUsed: 'Amount Used',
    chooseCreditProfile: 'Choose Credit Profile',
    chooseAccount: 'Choose Account',
    chooseUser: 'Choose User',
    select: 'Select',
    unselect: 'Unselect',
    yes: 'Yes',
    no: 'No',
    getToken: 'Get Token',
    paymentSuccess: 'Payment Success',
    invoiceDue: 'Invoice Due',
    invoiceTotal: 'Invoice Total',
    paymentTransactionType: 'Payment Transaction Type',
    transactionId: 'Transaction Id',
    paymentId: 'Payment Id',
    thresholdAmount: 'Threshold Amount',
    paymentTxtType: 'Payment Txt Type',
    billType: 'Bill Type',
    billStatus: 'Bill Status',
    minInvoiceThreshold: 'Minimum Invoice Threshold',
    minimumBillThreshold: 'Minimum Bill Threshold',
    ageingThreshold: 'Ageing Threshold',
    '015days': '0-15 days',
    '1530days': '15-30 days',
    '030days': '0-30 days',
    '3060days': '30-60 days',
    '6090days': '60-90 days',
    over90days: '> 90 days',
    totalDue: 'Total Due',
    trialToPaidConversion: 'Trial to paid conversion',
    accountsWithFailedPayment: 'Failed Payments Customers',
    subscriptionCancellations: 'Subscription Cancellations',
    closureReason: 'Closure Reason',
    orderType: 'Order Type',
    orderLevel: 'Order Level',
    orderSubType: 'Order Sub Type',
    productGroup: 'Product Group',
    productSubGroup: 'Product Sub Group',
    itemName: 'Item Name',
    priceOfferName: 'Price Offer Name',
    jobScheduleDate: 'Job Schedule Date',
    jobScheduleId: 'Job Schedule Id',
    jobScheduleStatus: 'Job Schedule Status',
    jobId: 'Job Id',
    jobStatus: 'Job Status',
    successCount: 'Success Count',
    failureCount: 'Failure Count',
    totalCount: 'Total Count',
    referenceId: 'Reference Id',
    referenceType: 'Reference Type',
    failureReason: 'Failure Reason',
    jobDate: 'Job Date',
    expiryDays: 'Expiry Days',
    trialType: 'Trial Type',
    trialLevel: 'Trial Level',
    dueThreshold: 'Due Threshold',
    arOpsType: 'AR Ops Type',
    taxTreatment: 'Tax Treatment',
    credit_debit: 'Credit/Debit',
    arThreshold: 'AR Threshold',
    arLevel: 'AR Level',
    recordId: 'Record Id',
    transactionDate: 'Transaction Date',
    netTotal: 'Net Total',
    monthYear: 'Month Year',
    billId: 'Bill Id',
    writeOff: 'Write Off',
    payment: 'Payment',
    adjusted: 'Adjusted',
    viewOrder: 'View Order',
    hideLines: 'Hide Lines',
    signEmbrix: 'Sign into your account',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot Password',
    login: 'Login',
    embrixDetailLogin: 'Driving Innovation and Customer Experience with Embrix Order to Cash',
    copyrightText: '© Copyright 2024. EMBRIX All Rights Reserved.',
    // policyText: 'Terms & Conditions   PI Use Policy   Privacy Policy',
    termsConditions: 'Terms & Conditions',
    PIUsePolicy: 'PI Use Policy',
    privacyPolicy: 'Privacy Policy',
    createNewBundle: 'Create New Bundle',
    editBundle: 'Edit Bundle',
    components: 'Components',
    choosePriceOffer: 'Choose Price Offer',
    rateUnit: 'Rate Unit',
    timeUnit: 'Time Unit',
    zoneUnit: 'Zone Unit',
    impactUnit: 'Impact Unit',
    holidayName: 'Holiday Name',
    tierReference: 'Tier Reference',
    tierType: 'Tier Type',
    evaluationStart: 'Evaluation Start',
    evaluationEnd: 'Evaluation End',
    offsetMonths: 'Offset Months',
    pricingTerm: 'Pricing Term',
    volumeTreatment: 'Volume Treatment',
    recurringFrequency: 'Recurring Frequency',
    transactionAttributeBasedPricingModel: 'Transaction Attribute Based Pricing Model',
    recurringTieredPricingModel: 'Recurring Tiered Pricing Model',
    tieredPricingModel: 'Tiered Pricing Model',
    complexPricing: 'Complex Pricing (Usage)',
    complexPricingRecurring: 'Complex Pricing (Recurring)',
    chooseBundle: 'Choose Bundle',
    chooseGLAccount: 'Choose GL Account',
    chooseItemId: 'Choose Item ID',
    cardType: 'Card Type',
    invoiceUnits: 'Invoice Units',
    chooseItem: 'Choose Item',
    createNewRole: 'Create New Role',
    jobConfig: 'Job Config',
    schedule: 'Schedule',
    taxGatewayAttributes: 'Tax Gateway Attributes',
    endPointType: 'End Point Type',
    endPointURL: 'End-Point URL',
    httpBasicAttributes: 'Http Basic Attributes',
    paymentGatewayAttributes: 'Payment Gateway Attributes',
    jsonWebTokenAttributes: 'Json Web Token Attributes',
    financeGatewayAttributes: 'Finance Gateway Attributes',
    oauthAttributesForm: 'Oauth Attributes Form',
    taxationItemId: 'Taxation Item Id',
    selfcareUserCategory: 'Self Care User Category',
    resources: 'Resources',
    copyToCreate: 'Copy To Create',
    startRange: 'Start Range',
    endRange: 'End Range',
    isQuantityScalable: 'Is Quantity Scalable',
    unit: 'Unit',
    expression: 'Expression',
    addNewRateUnit: 'Add New Rate Unit',
    selectOperator: 'Select Operator',
    addOperator: 'Add Operator',
    addField: 'Add Field',
    selectRecord: 'Select Record',
    selectField: 'Select Field',
    timeConfig: 'Use Start Time to evaluate time configuration',
    workWeekConfiguration: 'Work Week Configuration',
    peakHoursConfiguration: 'Peak Hours Configuration',
    holidayConfiguration: 'Holiday Configuration',
    startDayOfWeek: 'Start Day of Week',
    endDayOfWeek: 'End Day of Week',
    startHourOfDay: 'Start Hour of Day',
    endHourOfDay: 'End Hour of Day',
    addNewHoliday: 'Add New Holiday',
    customRecords: 'Custom Records',
    recordNumber: 'Record Number',
    recordType: 'Record Type',
    addNewRecord: 'Add New Record',
    createCustomizedRecords: 'Create customized records',
    import: 'Import',
    export: 'Export',
    exportSpecs: 'Export Specs',
    addNewFileMapping: 'Add New File Mapping',
    copyToAdd: 'Copy to Add',
    usageFileMappingDetails: 'Usage File Mapping Details',
    recordIndicator: 'Record Indicator',
    fileType: 'File Type',
    fileDelimiter: 'File Delimiter',
    fileDescription: 'File Description',
    sourceFieldNumber: 'Source Field Number',
    sourceField: 'Source Field',
    targetField: 'Target Field',
    fieldDelimiterInFile: 'Field Delimiter in File',
    sourceFileRecord: 'Source File Record',
    targetRecord: 'Target Record',
    recordDelimiter: 'Record Delimiter',
    mapNextField: 'Map Next Field',
    mapNextRecord: 'Map Next Record',
    quickNotes: 'Quick Notes',
    usageStreamsMapping: 'Usage Streams Mapping',
    addNewStreamMapping: 'Add New Stream Mapping',
    usageStreamsDetails: 'Usage Streams Details',
    ratingType: 'Rating Type',
    active: 'Active',
    addAnotherRatingType: 'Add another Rating Type',
    addAnotherStream: 'Add another Stream',
    modules: 'Modules',
    logUsageContainer: 'Log Usage Container',
    addAnotherModule: 'Add another Module',
    stream: 'Stream',
    createNewDependency: 'CREATE NEW DEPENDENCY',
    nonPayingAccount: 'Non-Paying Account',
    parentAccount: 'Parent Account',
    logout: 'Logout',
    addSelections: 'Add Selections',
    addAnotherRowForComplexPricing: 'Add Another Row for Complex Pricing',
    ok: 'OK',
    addNewTransactionAttributePricing: 'Add New Transaction Attribute Pricing',
    addNewTier: 'Add New Tier',
    saving: 'Saving',
    reportPermissions: 'Reports Permissions',
    dashboardPermissions: 'Dashboard Permissions',
    recurringData: 'Recurring Data',
    usageData: 'Usage Data',
    taxData: 'Tax Data',
    recurringType: 'Recurring Type',
    recurringDuration: 'Recurring Duration',
    proration: 'Proration',
    scale: 'Scale',
    cycleStart: 'Cycle Start',
    cycleEnd: 'Cycle End',
    appliedStart: 'Applied Start',
    appliedEnd: 'Applied End',
    offerType: 'Offer Type',
    unitPrice: 'Unit Price',
    jurisdiction: 'Jurisdiction',
    taxableAmount: 'Taxable Amount',
    exemptAmount: 'Exempt Amount',
    taxAmount: 'Tax Amount',
    batchid: 'Batch Id',
    batchFileName: 'Batch File',
    scaledQuantity: 'Scaled Quantity',
    grantStartDate: 'Grant Start Date',
    grantEndDate: 'Grant End Date',
    balanceUnitIndex: 'Balance Unit Index',
    accumulatorStartDate: 'Accumulator Start Date',
    accumulatorEndDate: 'Accumulator End Date',
    invoice: 'Invoice',
    invoices: 'Invoices',
    recreate: 'Re-create',
    usageFileMapping: 'Usage File Mapping',
    download: 'Download',
    excel: 'Excel',
    report: 'Report',
    pdf: 'Pdf',
    addCustomAttributes: 'Add Custom Attributes',
    addUsageAttributes: 'Add Usage Attributes',
    addTransactionAttributes: 'Add Transaction Attributes',
    transactionAttributes: 'Transaction Attributes',
    usageAttributes: 'Usage Attributes',
    addTiers: 'Add Tiers',
    tiers: 'Tiers',
    AROpsType: 'AR Ops Type',
    AROpsId: 'AR Ops Id',
    AROpsData: 'AR Ops Data',
    value: 'Value',
    usageAttributeBase: 'Usage Attribute Base',
    addNewUsageAttributePricing: 'Add New Usage Attribute Pricing',
    addAnotherField: 'Add Another Field',
    customerAttributes: 'Customer Attributes',
    fieldDelimiter: 'Field Delimiter',
    embrixRecordId: 'Embrix Record Id',
    useParentUsagePriceOffer: 'Use Parent Usage Price Offer',
    triggerPendingBillNewSubsActivation: 'Trigger Pending Bill New Subs Activation',
    triggerInvoicingOnNewSubsActivation: 'Trigger Invoicing On New Subs Activation',
    triggerPendingBillForClosedSubs: 'Trigger Pending Bill For Closed Subs',
    includeSubsOnRegularInvoice: 'Include Subs On Regular Invoice',
    includeSubsOnManualInvoice: 'Include Subs On Manual Invoice',
    allowUsageProcessing: 'Allow Usage Processing',
    useShortBillingCycle: 'Use Short Billing Cycle',
    taxValidateAddress: 'Tax Validate Address',
    taxExemptionMidStartCycle: 'Tax Exemption Mid Start Cycle',
    taxExemptionEndCycle: 'Tax Exemption End Cycle',
    taxApplicable: 'Tax Applicable',
    useAutoAllocation: 'Use Auto Allocation',
    quickbooksRefreshToken: 'Quick Books Refresh Token',
    isCollectionScheduleCached: 'Is Collection Schedule Cached',
    isCustomAttributeCached: 'Is Custom Attribute Cached',
    coaExportFileName: 'Coa Export File Name',
    renewalReminderThreshold: 'Renewal Reminder Threshold',
    noOfThreads: 'No Of Threads',
    invoiceReadyNotification: 'Invoice Ready Notification',
    cancelSubscriptionNotification: 'Cancel Subscription Notification',
    renewalDueNotification: 'Renewal Due Notification',
    newSubscriptionNotification: 'New Subscription Notification',
    selfcareRole: 'Self Care Role',
    addNewMapping: 'Add new mapping',
    addNewEndPointMapping: 'Add New End-point Mapping',
    addNewPaymentMethodMapping: 'Add New Payment Method Mapping',
    paymentGatewayURLMapping: 'Payment Gateway URL mapping',
    taxGatewayURLMapping: 'Tax Gateway URL mapping',
    financeGatewayURLMapping: 'Finance Gateway URL mapping',
    providerName: 'Provider Name',
    requestResponseGatewayMapping: 'Payment Gateway End-point Request/Response Mapping',
    requestResponseTaxMapping: 'Tax Gateway End-point Request/Response Mapping',
    requestResponseFinanceMapping: 'Finance Gateway End-point Request/Response Mapping',
    requestResponseProvisionMapping: 'Provision Gateway End-point Request/Response Mapping',
    addNewAttributeMapping: 'Add New Attribute Mapping',
    gatewayName: 'Gateway Name',
    apiName: 'Api Name',
    levelOneFlag: 'Level One Flag',
    canonicalAttribute: 'Canonical Attribute',
    sourceAttribute: 'Source Attribute',
    config: 'Config',
    AROpsItemMapping: 'AR Ops Item Mapping',
    ARItemType: 'AR item Type',
    addNewItemMapping: 'Add New Item Mapping',
    ARReasonCodeDescription: 'AR Reason Code Description',
    addNewReasonCodeMapping: 'Add New Reason Code Mapping',
    itemType: 'Item Type',
    usageClass: 'Usage Class',
    addNewRateUnitMap: 'Add New Rate Unit Map',
    AROpsGL: 'AR Ops GL',
    selectInvoice: 'Select Invoice',
    chooseAccountId: 'Choose Account Id',
    organizationSetup: 'Organization Setup',
    exchangeRateType: 'Exchange Rate Type',
    newCurrencyExchangeConfiguration: 'New Currency Exchange Configuration',
    sourceCurrency: 'Source Currency',
    targetCurrency: 'Target Currency',
    exchangeRate: 'Exchange Rate',
    addNewExchangeRate: 'Add New Exchange Rate',
    offsetValue: 'Offset Value',
    milestoneDetails: 'Milestone Details',
    percentage: 'Percentage',
    manualFlag: 'Manual Flag',
    addNewMilestone: 'Add New Milestone',
    newMilestoneConfiguration: 'New Milestone Configuration',
    chooseMiletstone: 'Choose Miletstone',
    viewTransactions: 'View Transactions',
    termStart: 'Term Start',
    termEnd: 'Term End',
    addNewRequestMapping: 'Add New Request Mapping',
    addNewResponseMapping: 'Add New Response Mapping',
    addNewAPIMapping: 'Add New API Mapping',
    manualBilling: 'Manual Billing',
    billPendingCharges: 'Bill Pending charges',
    addNewManualTransaction: 'Add New Manual Transaction',
    runManualBill: 'Run Manual Bill',
    scheduleWithDailyJobs: 'Schedule with daily Jobs',
    grossAmount: 'Gross Amount',
    discountAmount: 'Discount Amount',
    totalTransactions: 'Total Transactions',
    ratedTransactions: 'Rated Transactions',
    suspendedTransactions: 'Suspended Transactions',
    erroredTransactions: 'Errored Transactions',
    skippedTransaction: 'Skipped Transactions',
    pendingTransaction: 'Pending Transactions',
    batchId: 'Batch Id',
    fileName: 'File Name',
    fileDate: 'File Date',
    quantityUnit: 'Quantity Unit',
    usageId: 'Usage Id',
    grossValue: 'Gross Value',
    netValue: 'Net Value',
    totalErrorTransactions: 'Total Error Transactions',
    totalSuspendedTransactions: 'Total Suspended Transactions',
    remainingRevenue: 'Remaining Revenue',
    totalRevenue: 'Total Revenue',
    recognizedRevenue: 'Recognized Revenue',
    billEndDate: 'Bill End Date',
    trialEndDate: 'Trial End Date',
    addNextLevel: 'Add Next Level',
    alignPendingBillToBillFrequency: 'Align Pending Bill To Bill Frequency',
    taxJurisdictionLevel: 'Tax Jurisdiction Level',
    jurisdictionLevel: 'Jurisdiction Level',
    billMonth: 'Bill-Month',
    totalTaxableAmount: 'Total Taxable Amount',
    totalExemptAmount: 'Total Exempt Amount',
    crdrNoteId: 'Cr/Dr Note Id',
    objectType: 'Object Type',
    termEndDate: 'Term End Date',
    recognitionRevenue: 'Recognition Revenue',
    totalFileRecordCount: 'Total File Record Count',
    totalFileRecordLoaded: 'Total File Record Loaded',
    totalFileRecordErrored: 'Total File Record Errored',
    ratingStatusCount: 'Rating Status Count',
    ratingErrorCount: 'Rating Error Count',
    taxDescription: 'Tax Description',
    taxCategory: 'Tax Category',
    addRevenueSplitConfiguration: 'Add Revenue Split Configuration',
    addAdditionalSplitRow: 'Add Additional Split Row',
    delayedBilling: 'Delayed Billing',
    delayInterval: 'Delay Interval',
    addDelayedBilling: 'Add Delayed Billing',
    refund: 'Refund',
    settlement: 'Settlement',
    disputed: 'Disputed',
    invoiceTransferred: 'Invoice Transferred',
    chargeBack: 'Charge Back',
    gracePeriod: 'Grace Period',
    currencyExchangeEdit: 'Edit Currency Exchange',
    dashboardMonths: 'Dashboard Months',
    additionalTax: 'Additional Tax',
    selectAccount: 'Select Account',
    selectPurchaseOptions: 'Select Purchase Options',
    setOrderData: 'Set Order Data',
    manageCreateOrder: 'Manage & Create Order',
    revenueTracking: 'Revenue Tracking',
    defaultCountry: 'Default Country',
    modifyRemoveAsset: 'Want to Modify/Remove Existing Assets',
    reclassifyTrueUpRevenue: 'Reclassify True Up Revenue',
    overrideMail: 'Override Mail',
    sendInvoice: 'Send Invoice',
    support29_30_31DayBilling: 'Support 29_30_31 Day Billing',
    moveDayForwardFor29_30_31DayBilling: 'Move Day Forward For 29_30_31 Day Billing',
    prorateShortOrLongCycle: 'Prorate Short Or Long Cycle',
    invoiceEndDateInclusive: 'Invoice End Date Inclusive',
    correspondencePermissions: 'Correspondence Permissions',
    revenueManagementPermissions: 'Revenue Management Permissions',
    optIn: 'Opt-In',
    optOut: 'Opt-Out',
    subscriptionStatus: 'Subscription Status',
    PONumber: 'PO Number',
    externalPurchaseOrder: 'External Purchase Order',
    regenerateInvoice: 'Regenerate Invoice',
    PO: 'PO',
    customMessage: 'Custom Message',
    approveInvoice: 'Approve',
    addNewExternalPO: 'Add New External PO',
    trialStatus: 'Trial Status',
    discountOfferName: 'Discount Offer Name',
    billStartDate: 'Bill Start Date',
    selectUpgradeOptions: 'Select Upgrade Options',
    upgradeOrder: 'Upgrade Order',
    downgradeOrder: 'Downgrade Order',
    upgradePackage: 'Upgrade Package',
    upgradeBundle: 'Upgrade Bundle',
    accountStatement: 'Account Statement',
    documentType: 'Document Type',
    subTotal: 'Subtotal',
    taxes: 'Taxes',
    folioDate: 'Folio Date',
    folioId: 'Folio Id',
    allocate: 'Allocate',
    paymentFinanceSync: 'Payment Finance Sync',
    realTimeFinanceSync: 'Real Time Finance Sync',
    downgradePackage: 'Downgrade Package',
    downgradeBundle: 'Downgrade Bundle',
    selectDowngradeOptions: 'Select Downgrade Options',
    addDatesToInvoiceDescription: 'Add Dates To Invoice Description',
    before: 'Before',
    after: 'After',
    addNewLocation: 'Add New Location',
    location: 'Location',
    shortName: 'Short Name',
    primaryIndustry: 'Primary Industry',
    hqCountry: 'Headquarter Country',
    enterprise: 'Enterprise',
    autoCreateLegalEntities: 'Auto Create Legal Entities',
    autoCreateBusinessUnits: 'Auto Create Business Units',
    companyLocations: 'Company Locations',
    addNewDivisions: 'Add New Divisions',
    companyDivisions: 'Company Divisions',
    fetch: 'Fetch',
    ACTIVE: 'ACTIVE',
    legalEntities: 'Legal Entities',
    autoReverseWriteoffOnPayment: 'Auto Reverse Writeoff On Payment',
    collectDebtFirst: 'Collect Debt First',
    useProviderForARTax: 'Use Provider For AR Tax',
    withTaxARRule: 'With Tax AR Rule',
    multiCurrency: 'Multi Currency',
    crossCountryPurchase: 'Cross Country Purchase',
    crossCountryPayment: 'Cross Country Payment',
    trialExpiryReminderThreshold: 'Trial Expiry Reminder Threshold',
    defaultLanguage: 'Default Language',
    futureOrderDateSystemTime: 'Future Order Date System Time',
    recurringPeriodInInvoice: 'Recurring Period In Invoice',
    ccListForInvoiceDelivery: 'CC List For Invoice Delivery',
    invoiceApprovalEmailId: 'Invoice Approval Email Id',
    invoiceApproval: 'Invoice Approval',
    generateInvoicePdf: 'Generate Invoice Pdf',
    inCompleteOrders: 'In-complete Orders',
    openingBalance: 'Opening Balance',
    closingBalance: 'Closing Balance',
    adjustmentType: 'Adjustment Type',
    disputeType: 'Dispute Type',
    writeOffType: 'WriteOff Type',
    division: 'Division',
    registrationId: 'Registration Id',
    reportingId: 'Reporting Id',
    taxId: 'Tax Id',
    alternateId: 'Alternate Id',
    addNewLegalEntity: 'Add New Legal Entity',
    financeSyncBatchSize: 'Finance Sync Batch Size',
    financeSyncBatchMode: 'Finance Sync Batch Mode',
    addNewBusinessUnits: 'Add New Business Units',
    businessUnits: 'Business Units',
    GLSegments: 'GL Segments',
    GLAccountRanges: 'GL Account Ranges',
    startingNumber: 'Starting Number',
    addNewGLSubAccountRange: 'Add New GL Sub Account Range',
    addNewGLAccountRange: 'Add New GL Account Range',
    addNewSegmentValues: 'Add New Segment Values',
    addNewSegment: 'Add New Segment',
    businessUnit: 'Business Unit',
    addNewDepartment: 'Add New Department',
    departments: 'Departments',
    department: 'Department',
    costCenter: 'Cost Center',
    costType: 'Cost Type',
    addNewCostCenter: 'Add New Cost Center',
    addNewCalendar: 'Add New Calendar',
    calendar: 'Calendar',
    adjustingPeriodCount: 'Adjusting Period',
    addNextYearPeriods: 'Add Next Year Periods',
    calendarPeriods: 'Calendar Periods',
    calendarYear: 'Calendar Year (yyyy)',
    quarter: 'Quarter',
    GLSegmentValues: 'GL Segment Values',
    leadingZeroes: 'Leading Zeroes',
    segmentValues: 'Segment Values',
    GLSubAccountRanges: 'GL Sub Account Ranges',
    GLSetup: 'GL Setup',
    prefix: 'Prefix',
    delimiter: 'Delimiter',
    subAccountRanges: 'Sub Account Ranges',
    createNewDiscountOffer: 'Create New Discount Offer',
    discountingModel: 'Discounting Model',
    recurringDiscounting: 'Recurring Discounting',
    flatDiscounting: 'Flat Discounting',
    complexDiscounting: 'Complex Discounting',
    complexDiscountingRecurring: 'Complex Discounting (Recurring)',
    recurringTieredDiscountingModel: 'Recurring Tiered Discounting Model',
    customDiscounting: 'Custom Discounting',
    editDiscountingOffer: 'Edit Discounting Offer',
    autoSendInvoice: 'Auto Send Invoice',
    noteType: 'Notes Type',
    creditNotes: 'Credit Notes',
    submitOrderAdvance: 'Submit Order in Advance (will execute the future dated order in advance today)',
    pricingType: 'Pricing Type',
    discountingType: 'Discounting Type',
    salesAgentId: 'Sales Agent Id',
    PODescription: 'PO Description',
    addPOItem: 'Add PO Item',
    partialFulfillment: 'Partial Fulfillment',
    partialProvisioningEnabled: 'Partial Provisioning Enabled',
    taxPercent: 'Tax Percent',
    addNewTaxCode: 'Add New Tax Code',
    jurisdictionList: 'Jurisdiction List',
    excludeList: 'Exclude Jurisdiction List',
    minimumThreshold: 'Minimum Threshold',
    maximumThreshold: 'Maximum Threshold',
    transactionName: 'Transaction Name',
    serviceName: 'Service Name',
    serviceId: 'Service Id',
    additionalTaxType: 'Additional Tax Type',
    additionalTaxDescription: 'Additional Tax Description',
    additionalTaxRate: 'Additional Tax Rate',
    viewTaxCode: 'View Tax Rate',
    uom: 'UOM',
    taxationModulePermissions: 'Taxation Module Permissions',
    usageProcessBatchSize: 'Usage Process Batch Size',
    mediaFile: 'Media File',
    advertisement: 'Advertisement',
    keyLinksToShare: 'Key Links To Share',
    shortTitle: 'Short Title',
    loadVideo: 'Load Video',
    loadImages: 'Load Images',
    addAnotherImage: 'Add Another Image',
    advHeader: 'Adv Header',
    advShortDescription: 'Adv Short Description',
    linkText: 'Link Text',
    URL: 'URL',
    addAnotherAdv: 'Add another Adv',
    offerName: 'Offer Name',
    addNewRegionMapping: 'Add New Region Mapping',
    regionId: 'Region Id',
    longDistanceType: 'Long Distance Type',
    addNewZeroPriceUsage: 'Add New Zero Price Usage',
    usageType: 'Usage Type',
    callingNumberPrefix: 'Calling Number Prefix',
    addSpecialPricing: 'Add Special Pricing',
    chooseDisscountOffer: 'Choose Disscount Offer',
    selectFile: 'Select File',
    upload: 'Upload',
    dropingFile: 'Drop the file here ...',
    uploadFileType: 'UPLOAD PHOTO OR VIDEO',
    pendingBillTrigger: 'Pending Bill Trigger',
    advanceBilling: 'In Advance Billing',
    addInAdvanceBilling: 'Add In Advance Billing',
    daysInAdvance: 'Days In Advance',
    recurringFeeAdvanceDom: 'Recurring Fee Advance Dom',
    URLPath: 'URL path',
    operatorId: 'Operator Id',
    operatorName: 'Operator Name',
    addNewOperatorMap: 'Add New Operator Map',
    trunkGroup: 'Trunk Group',
    site: 'Site',
    trafficType: 'Traffic Type',
    trunkType: 'Trunk Type',
    interConnectIndicator: 'Inter Connect Indicator',
    usageIndicator: 'Usage Indicator',
    carrierId: 'Carrier Id',
    interConnectType: 'Inter Connect Type',
    addNewTrunkRules: 'Add New Trunk Rules',
    paymentFileType: 'Payment File Type',
    uploadAdditionalFiles: 'Upload Additional Files',
    uploadFile: 'Upload File',
    fileDateTime: 'File Date/Time',
    totalRecords: 'Total Records',
    importedRecords: 'Imported Records',
    pendingRecords: 'Pending Records',
    processedRecords: 'Processed Records',
    suspendedRecords: 'Suspended Records',
    paymentType: 'Payment Type',
    recordDate: 'Record Date',
    paymentLimit: 'Payment Limit',
    reference1: 'Reference 1',
    reference2: 'Reference 2',
    box: 'Box',
    authorizeCode: 'Authorize Code',
    identification: 'Identification',
    suspenseAccountFlag: 'Suspense Account Flag',
    processedDate: 'Processed Date',
    recordTime: 'Record Time',
    chargeSign: 'Charge Sign',
    credit: 'Credit',
    balance: 'Balance',
    conceptBank: 'Concept Bank',
    participantName: 'Participant Name',
    beneficiaryName: 'Beneficiary Name',
    beneficiary: 'Beneficiary',
    payingAccount: 'Paying Account',
    payingName: 'Paying Name',
    refundCode: 'Refund Code',
    refundReason: 'Refund Reason',
    beneficiaryRfc: 'Beneficiary Rfc',
    originatorRfc: 'Originator Rfc',
    trackingKey: 'Tracking Key',
    bill: 'Bill',
    operationDate: 'Operation Date',
    reference: 'Reference',
    transactionCode: 'Transaction Code',
    branchOffice: 'Branch Office',
    deposit: 'Deposit',
    withdrawal: 'Withdrawal',
    movement: 'Movement',
    detailedDescription: 'Detailed Description',
    processAll: 'PROCESS-ALL',
    addNewOrderSequence: 'Add New Order Sequence',
    task: 'Task',
    addNewOrderType: 'Add New Order Type',
    apiCategory: 'Api Category',
    onErrorResubmit: 'On Error Resubmit',
    validateOnly: 'Validate Only',
    addNewOrderProvisioning: 'Add New Order Provisioning',
    numberOfDays: 'Number of Days',
    surchargeAmount: 'Surcharge Amount',
    addPaymentSurcharge: 'Add Payment Surcharge',
    concept: 'Concept',
    charge: 'Charge',
    orderManagementTaskSequence: 'Order Management Task Sequence',
    provisioningTaskSequence: 'Provisioning Task Sequence',
    addPendingBillTrigger: 'Add Pending Bill Trigger',
    dom: 'Day Of Month',
    usageFlag: 'Usage Flag',
    chooseOperatorConfig: 'Choose Operator Config',
    pacEnabled: 'Pac Enabled',
    createOrganization: 'Create Organization',
    ssoEnabled: 'SSO Enabled',
    batchPaymentFileUpload: 'Batch Payment File Upload',
    splitTaxCalculationByItem: 'Split Tax Calculation By Item',
    minimumCreditNoteThreshold: 'Minimum Credit Note Threshold',
    minimumDebitNoteThreshold: 'Minimum Debit Note Threshold',
    pricingSync: 'Pricing Sync',
    billableServiceEnabled: 'Billable Service Enabled',
    chooseInvoice: 'Choose Invoice',
    companyLogo: 'Company Logo',
    autoOverrideGrantAccumulator: 'Auto Override Grant Accumulator',
    writeOffDetail: 'Write off detail',
    monthOffset: 'Month Offset',
    '800Flag': '800 Flag',
    provisionGatewayAttributes: 'Provision Gateway Attributes',
    all: 'ALL',
    enterpriseDivisions: 'Enterprise Divisions',
    legalentities: 'Legal Entities',
    legalEntityBusinessUnits: 'Legal Entity – Business Units',
    customReasonCode: 'Custom Reason Code',
    users: 'Users',
    createNewUserGroups: 'Create New User Groups',
    addNewUserGroups: 'Add New User Groups',
    groupId: 'Group Id',
    groupName: 'Group Name',
    addUserToGroup: 'Add User To Group',
    selectUser: 'Select User',
    merchant: 'Merchant',
    storeAsPrimary: 'Store As Primary',
    mcmAccumulatorList: 'Special Validity Accumulator List',
    createNewGroupsReason: 'Create New Group Reasons',
    addNewReasonGroups: 'Add New Group Reasons',
    addReasonToGroup: 'Add Reason To Group',
    showLines: 'Show lines',
    relativeEnd: 'Relative End',
    reasons: 'Reasons',
    addNewThreshold: 'Add New Threshold Mapping',
    arAction: 'AR Action',
    arOpsLevel: 'AR Ops Level',
    maxThreshold: 'Maximum Threshold',
    miximumThreshold: 'Minimum Threshold',
    chooseReasonCode: 'Choose Reason Code',
    createNewApproval: 'Create New Approval',
    userGroupId: 'User Group Id',
    userGroupName: 'User Group Name',
    addNewApproval: 'Add New Approval',
    addAnotherApprovalLevel: 'Add Another Approval Level',
    chooseUserGroup: 'Choose User Group',
    customReason: 'Custom Reason',
    addNewUnit: 'Add New Unit',
    unitId: 'Unit Id',
    grantType: 'Grant Type',
    referenceParent: 'Reference Parent',
    comments: 'Comments',
    approve: 'Approve',
    reject: 'Reject',
    creditNoteData: 'Credit Notes Approval Data',
    dateRequested: 'Date Requested',
    dateProcessed: 'Date Processed',
    provisioningIdRequired: 'Provisioning Id Required',
    billableService: 'Billable Service',
    editUser: 'Edit User',
    applyInAdvance: 'Apply In Advance',
    serviceTypeProvisioningAttributeMapping: 'Service Type Provisioning Attribute Mapping',
    addAnotherServiceMapping: 'Add Another Service-Provisioning Mapping',
    provisioningAttributeType: 'Provisioning Attribute Type',
    attributeTag: 'Attribute Tag',
    flexAttribute: 'Flex Attribute',
    mandatoryFlag: 'Mandatory Flag',
    equipments: 'Equipments',
    locations: 'Locations',
    unitOfMeasure: 'Unit Of Measure',
    assetTag: 'Asset Tag',
    deliveryDate: 'Delivery Date',
    primaryCode: 'Primary Code',
    inventoryItemId: 'Inventory Item Id',
    commercialClasification: 'Commercial Clasification',
    parentServiceName: 'Parent Service Name',
    loadType: 'Load Type',
    pointName: 'Point Name',
    pointId: 'Point Id',
    ownerName: 'Owner Name',
    deliveryType: 'Delivery Type',
    ring: 'Ring',
    floor: 'Floor',
    colony: 'Colony',
    zip: 'Zip',
    latitude: 'Latitude',
    longitude: 'Longitude',
    queueName: 'Queue Name',
    addAnotherAttributeToMap: 'Add Another Attribute to Map',
    cost: 'Cost',
    viewProvisioningData: 'View Provisioning Data',
    serviceProvisioningData: 'Service Provisioning Data',
    provisioningType: 'Provisioning Type',
    tag1: 'Tag1',
    tag2: 'Tag2',
    tag3: 'Tag3',
    glAccountType: 'GL Account Type',
    glAccountSubType: 'GL Account Sub Type',
    addNewGLAccountToCOA: 'Add New GL Account To COA',
    coaType: 'COA Type',
    addPolicy: 'Add Policy',
    drItemId: 'Dr. Item',
    drGlAccount: 'Dr. Gl Account',
    drAccountType: 'Dr. Account Type',
    crItemId: 'Cr. Item',
    crGlAccount: 'Cr. Gl Account',
    crAccountType: 'Cr. Account Type',
    addNewGLAccount: 'Add New GL Account',
    noOfCalls: '# Of Calls',
    totalMinutes: 'Total Minutes',
    amountUsd: 'Amount USD',
    amountMxn: 'Amount MXN',
    callType: 'Call Type',
    ivaTaxItemId: 'Iva Tax Item Id',
    iepsTaxItemId: 'Ieps Tax Item Id',
    totalSeconds: 'Total Seconds',
    paymentFile: 'Payment File',
    ipAddress: 'IP Address',
    volumeUpload: 'Volume Upload',
    volumeDownload: 'Volume Download',
    countLimit: "Top 'n' by Count",
    amountLimit: "Top 'n' by Amount",
    count: 'Count',
    lineDayOfMonth: 'Line Day Of Month',
    originalNetworkId: 'Original Network Id',
    costMxn: 'Cost MXN',
    costUsd: 'Cost USD',
    barChartDashboardType: 'Bar Chart Dashboard Type',
    xaxisParameter: 'Xaxis Parameter',
    yaxisParameter: 'Yaxis Parameter',
    noOfRecordsLimit: 'No Of Records Limit',
    serviceProductCode: 'Service Product Code',
    chargeType: 'Charge Type',
    isParent: 'Is Parent',
    addNewServices: 'Add New Services',
    componentServiceId: 'Component Service Id',
    componentQuantity: 'Component Quantity',
    componentServiceName: 'Component Service Name',
    viewLocations: 'View Locations',
    locationId: 'Location Id',
    instanceId: 'Instance Id',
    statusId: 'Status Id',
    productDescription: 'Product Description',
    serviceDiscount: 'Service Discount',
    medium: 'Medium',
    stateService: 'Service State',
    releaseDate: 'Release Date',
    dischargeDate: 'Discharge Date',
    administrativeState: 'Administrative State',
    operationalState: 'Operational State',
    cancelledDate: 'Cancelled Date',
    cpId: 'CP Id',
    zipCode: 'Zip Code',
    plusCode: 'Plus Code',
    creationDate: 'Creation Date',
    fullName: 'Full Name',
    ownerId: 'Owner Id',
    validationStatus: 'Validation Status',
    cp: 'CP',
    floorSite: 'Floor Site',
    ringName: 'Ring Name',
    infrastructureEquipments: 'Infrastructure Equipments',
    totalPrice: 'Total Price',
    orderData: 'Order Data',
    capacity: 'Capacity',
    commitment: 'Commitment',
    tariff: 'Tariff',
    rental: 'Rental',
    directCommitment: 'Direct Commitment',
    directTariff: 'Direct Tariff',
    directCost: 'Direct Cost',
    commitmentLeft: 'Commitment Left',
    overageQuantity: 'Overage Quantity',
    overageCost: 'Overage Cost',
    totalCost: 'Total Cost',
    saveRevenueSplit: 'Save Revenue Split',
    splitInMultipleAccounts: 'Split In Multiple Accounts',
    addBillingAccounts: 'Add Billing Accounts',
    viewBillingAccounts: 'View Billing Accounts',
    componentServices: 'Component Services',
    locationName: 'Location Name',
    dataType: 'Data Type',
    dataUnit: 'Data Unit',
    dataValue: 'Data Value',
    discount: 'Discount',
    splitService: 'Split Service',
    processSplit: 'Process Split',
    addNewSplitLine: 'Add New Split Line',
    splitConfig: 'Split Config',
    paying: 'Paying?',
    selectParent: 'Select Parent',
    selectChild: 'Select Child',
    particalMerge: 'Partial Merge',
    moveProvisioning: 'Move Provisioning',
    desperse: 'Desperse',
    merge: 'Merge',
    addMoreAccounts: 'Add More Accounts',
    clientAccountMovementMerge: 'Client Account Movement - Merge',
    parentServiceId: 'Parent Service Id',
    circuitId: 'Circuit Id',
    clientCircuitId: 'Client Circuit Id',
    externalServiceId: 'External Service Id',
    commodityPrice: 'Commodity Price',
    externalServiceIdOp: 'External Service Id Op',
    includedLicenses: 'Included Licenses',
    priceWithTax: 'Price With Tax',
    alternateDescription: 'Alternate Description',
    invoiceLineAmount: 'Invoice Line Amount',
    percentAdjustment: 'Percent Adjustment',
    amountAdjustment: 'Amount Adjustment',
    splitOpportunityEnabled: 'Split Opportunity Enabled',
    sendAllDataToProvisioning: 'Send All Data To Provisioning',
    authorizationEnabled: 'Authorization Enabled',
    invoiceLineDetails: 'Invoice Line Details',
    batchFileTypeConfiguration: 'Batch File Type Configuration',
    addNewFileTypeMapping: 'Add New File Type Mapping',
    billingAccountMovementMerge: 'Billing Account Movement - Merge',
    paymentItemMap: 'Payment Item Map',
    billableServiceSelect: 'Billable-Service',
    level: 'Level',
    subscriptionData: 'Subscription Data',
    addNewUsageType: 'Add New Usage Type',
    addNewServiceType: 'Add New Service Type',
    serviceUsageType: 'Service Usage Type',
    orderProration: 'Order Proration',
    alignToCycle: 'Align Cycle',
    waiveCancel: 'Waive Cancel',
    orderResonCode: 'Order Reason Codes',
    addNewReasonCode: 'Add New Reason Code',
    paymentFileName: 'Payment File Name',
    contactNumber: 'Contact Number',
    rawUsageFiles: 'Raw Usage Files',
    fileId: 'File Id',
    platform: 'Platform',
    dateTime: 'Date/Time',
    totalInputRecords: 'Total Input Records',
    totalImportedRecords: 'Total Imported Records',
    totalErrorRecords: 'Total Error Records',
    switchName: 'Switch Name',
    withinOrganization: 'Within Organization',
    capacityMerge: 'Capacity Merge',
    selectCapacityMergeServiceForParent: 'Select Capacity Merge Service For Parent',
    addMoreAccountToMerge: 'Add More Account To Merge',
    selectCapacityMergeBillableService: 'Select Capacity Merge Billable Service',
    prorateOnPartialSuspend: 'Prorate On Partial Suspend',
    prorateOnPartialCancel: 'Prorate On Partial Cancel',
    destination: 'Destination',
    direction: 'Direction',
    ratingDescr: 'Rating Descr',
    accountingPeriod: 'Accounting Period (MON-yyyy)',
    drCurrency: 'Dr. Currency',
    crCurrency: 'Cr. Currency',
    drAmount: 'Dr. Amount',
    crAmount: 'Cr. Amount',
    objectId: 'Object Id',
    journalId: 'Journal Id',
    startDateWithRank: 'Start Date (>=)',
    endDateWithRank: 'End Date (<)',
    amountToReverse: 'Amount To Reverse',
    reversalReason: 'Reversal Reason',
    allocateAmount: 'Allocate Amount',
    allocatePayment: 'Allocate Payment',
    paymentAllocation: 'Payment Allocation',
    batchName: 'Batch Name',
    accountingDate: 'Accounting Date',
    extractDate: 'Extract Date',
    period: 'Period',
    totalCreditAmount: 'Total Cr. Amount',
    totalDebitAmount: 'Total Dr. Amount',
    ledgerId: 'Ledger Id',
    extractFile: 'Extract File',
    actualFlag: 'Actual Flag',
    ERPExtractsDetails: 'ERP Extracts Details',
    segment1: 'Seg 1',
    segment2: 'Seg 2',
    segment3: 'Seg 3',
    segment4: 'Seg 4',
    segment5: 'Seg 5',
    segment6: 'Seg 6',
    debit: 'Debit',
    glDate: 'GL Date',
    userGroup: 'User Group',
    defaultSubscription: 'Default Subscription',
    correspondenceTemplateConfiguration: 'Correspondence Template Configuration',
    addNewCorrespondence: 'Add New Correspondence',
    viewDetails: 'View Details',
    planId: 'Plan Id',
    planType: 'Plan Type',
    serialId: 'Serial Id',
    voiceLdiLdnPlans: 'Voice LDI/LDN Plans',
    npaId: 'NPA Id',
    region: 'Region',
    countryCode: 'Country Code',
    addNewVoicePlan: 'Add New Voice Plan',
    addNewRow: 'Add New Row',
    minimum: 'Minimum',
    maximum: 'Maximum',
    batchFolioStamping: 'Batch Folio Stamping',
    immediateNotesGeneration: 'Immediate Notes Generation',
    isChild: 'Is Child',
    xchangeCurrency: 'Xchange Currency',
    xchangeRate: 'Xchange Rate',
    payemntThreshold: 'Payment Threshold',
    xchangeSplit: 'Xchange Split (MCM Customer)',
    splitType: 'Split Type',
    addNewXchangeRate: 'Add New Xchange Rate',
    contractedExchangeRates: 'Contracted Exchange Rates',
    accountCurrency: 'Account Currency',
    reversedAmount: 'Reversed Amount',
    chooseOperator: 'Choose Operator',
    fetchParent: 'Fetch Parent',
    addProvisioning: 'Add Provisioning',
    addPricing: 'Add Pricing',
    incommingOutgoing: 'Incoming/Outgoing',
    tierMin: 'Tier Min',
    tierMax: 'Tier Max',
    contractCapacity: 'Contract Capacity',
    commitmentBase: 'Commitment Base',
    rentalAmount: 'Rental Amount',
    viewPricing: 'View Pricing',
    pricingDetails: 'Pricing Details',
    tierMaximum: 'Tier Maximum',
    tierMinimum: 'Tier Minimum',
    usageTypePricing: 'Usage Type Pricing',
    npaPricing: 'NPA Pricing',
    tierPricing: 'Tier Pricing',
    limitPricing: 'Limit Pricing',
    includedDID: 'Included DID',
    includedQuantity: 'Included Quantity',
    overagePrice: 'Overage Price',
    priceOverCommitment: 'Price Over Commitment',
    viewMore: 'View More',
    advanceFlag: 'Advance Flag',
    partMonthWaive: 'Part Month Waive',
    calendarMonth: 'Calendar Month',
    align2Cycle: 'Align To Cycle',
    pacCustomizedPdfLayout: 'Pac Customized Pdf Layout',
    viewLov: 'View LOV',
    request: 'Request',
    response: 'Response',
    externalRefId: 'External Reference Id',
    customPaymentMethod: 'Payment Method (C)',
    separator: 'Separator',
    minimumLength: 'Minimum Length',
    listOfValues: 'List Of Values',
    taxRegimeId: 'Tax Regime Id',
    externalUid: 'External Id (PAC UUID)',
    supportContactNumber: 'Support Contact Number',
    supportEmail: 'Support Email',
    tagLine: 'Tag Line',
    logoFile: 'Logo File',
    sequenceName: 'Sequence Name',
    sequenceSeed: 'Sequence Seed',
    sequenceInUse: 'Sequence In Use',
    address: 'Address',
    landMark: 'Landmark',
    selectAccountToSplit: 'Select Account To Split',
    undoPreviousMerge: 'Undo Previous Merge',
    toAccount: 'To Account',
    fromAccount: 'From Account',
    moveAllServices: 'Move All Services',
    viewBillables: 'View Billables',
    accountSplit: 'Account Split',
    split: 'Split',
    capacitySplit: 'Capacity Split',
    addMoreAccountToSplit: 'Add More Account To Split',
    triggerInvoicingWithManualBilling: 'Trigger Invoicing With Manual Billing',
    triggerInvoicingWithPendingBill: 'Trigger Invoicing With Pending Billing',
    childAccount: 'Child Account',
    nonPaying: 'Non Paying',
    provisioningLag: 'Provisioning Lag',
    disperse: 'Disperse',
    emails: 'Email(‘s)',
    addAEmail: 'Add an Email',
    crmId: 'CRM Id',
    billingAccountMovementDisperse: 'Billing Account Movement - Disperse',
    activityId: 'Activity Id',
    selectAccountToUndo: 'Select Account To Undo',
    undoPreviousDisperse: 'Undo Previous Disperse',
    sendEmail: 'Send Email',
    createSuspense: 'Create Suspense',
    reversalDate: 'Reversal Date',
    subAccount: 'Sub Account',
    interCompany: 'Inter Company',
    glOffset: 'GL Offset',
    amountRecognized: 'Amount Recognized',
    revenueActivityType: 'Revenue Activity Type',
    billed: 'Billed',
    arOpsId: 'AR Ops Id',
    itemSplitId: 'Item Split Id',
    extractBatchId: 'Extract Batch Id',
    startDateWithRankMoreThan: 'Start Date (>)',
    endDateWithRankLessThan: 'End Date (<=)',
    journals: 'Journals',
    paymentRefId: 'Payment Reference Id',
    paymentSuspenseId: 'Payment Suspense Id',
    thresholdTolerance: 'Threshold Tolerance',
    exchangeDelta: 'Exchange Delta',
    paymentCurrency: 'Payment Currency',
    sourceCurrencyAmount: 'Source Currency Amount',
    paymentSuspenseAccount: 'Payment Suspense Account',
    autoClientPaymentAllocation: 'Auto Client Payment Allocation',
    paymentSuspenseAllocation: 'Payment Suspense Allocation',
    processSuspense: 'Process Suspense',
    reGenerate: 'Re-Generate',
    addNewInstallmentPlan: 'Add New Installment Plan',
    daysOffset: 'Days Offset',
    fixedInstallments: 'Fixed Installments',
    installments: 'Installments',
    invoicePaymentInstallments: 'Invoice Payment Installments',
    installmentAmt: 'Installment Amt',
    paymentAmt: 'Payment Amt',
    monthsOffset: 'Months Offset',
    endingNumber: 'Ending Number',
    notesAccount: 'Notes Account',
    notesName: 'Notes Name',
    ratingDescription: 'Rating Description',
    sourceSystem: 'Source System',
    ratedUsageTransactions: 'Rated Usage Transactions',
    selectProvisioning: 'Select Provisioning',
    selectAll: 'Select All',
    selectSpecific: 'Select Specific',
    selectRange: 'Select Range',
    addRangeSelected: 'Add Range Selected',
    incommingRoute: 'Incoming Route',
    networkId: 'Network Id',
    ratedQuantity: 'Rated Quantity',
    perUsageQuantity: 'Per Usage Quantity',
    perUsageAmount: 'Per Usage Amount',
    origNetworkId: 'Original Network Id',
    ratingBatchId: 'Rating Batch Id',
    invoiceCancellationReason: 'Invoice Cancellation Reason',
    billUnitStatus: 'Bill Unit Status',
    invoiceUnitStatus: 'Invoice Unit Status',
    invoiceCancellation: 'Invoice Cancellation',
    fullCredit: 'Full Credit',
    invoiceExhibits: 'Invoice Exhibits',
    addInstallments: 'Add Installments',
    gatewayType: 'Gateway Type',
    approvalRequired: 'Approval Required',
    approvalCorrespondence: 'Approval Correspondence',
    rejectionCorrespondence: 'Rejection Correspondence',
    useAccumulatorBalance: 'Use Accumulator Balance',
    impactType: 'Impact Type',
    cancelPayment: 'Cancel Payment',
    remainingGrant: 'Remaining Grant',
    grantName: 'Grant Name',
    scaleUnit: 'Scale Unit',
    alpha2: 'Alpha-2',
    alpha3: 'Alpha-3',
    isdcode: 'ISD Code',
    continent: 'Continent',
    region1: 'Region-1',
    region2: 'Region-2',
    addNewCountryCodeMapping: 'Add New Country Code Mapping',
    idOperador: 'Id Operador',
    ido: 'Ido',
    ida: 'Ida',
    estatus: 'Estatus',
    claveCensal: 'Clave Censal',
    poblacion: 'Poblacion',
    municipio: 'Municipio',
    estado: 'Estado',
    central: 'Central',
    presuscripcion: 'Presuscripcion',
    asl: 'Asl',
    nir: 'Nir',
    serie: 'Serie',
    numeracionInicial: 'Numeracion Inicial',
    numeracionFinal: 'Numeracion Final',
    tipoRed: 'Tipo Red',
    modalidad: 'Modalidad',
    razonSocial: 'Razon Social',
    nombreCorto: 'Nombre Corto',
    oficioReserva: 'Oficio Reserva',
    fechaReserva: 'Fecha Reserva',
    oficioAsignacion: 'Oficio Asignacion',
    fechaAsignacion: 'Fecha Asignacion',
    fechaConsolidacion: 'Fecha Consolidacion',
    fechaMigracion: 'Fecha Migracion',
    puntoEntrega: 'Punto Entrega',
    ocupacion: 'Ocupacion',
    nirAnterior: 'Nir Anterior',
    transactionDescription: 'Transaction Description',
    billableServiceName: 'Billable Service Name',
    billableServiceId: 'Billable Service Id',
    paymentSource: 'Payment Source',
    paymentReferenceId: 'Payment Reference id',
    paymentDescription: 'Payment Description',
    itemServices: 'Item Services',
    releaseStartDate: 'Release Start Date',
    releaseEndtDate: 'Release End Date',
    deliveryStartDate: 'Delivery Start Date',
    deliveryEndDate: 'Delivery End Date',
    operativeStatus: 'Operative Status',
    adminStatus: 'Admin Status',
    cancellationDate: 'Cancellation Date',
    deliveryEndtDate: 'Delivery End Date',
    groupByType: 'Group By Type',
    '90120days': '90-120 days',
    '120150days': '120-150 days',
    '150180days': '150-180 days',
    '180270days': '180-270 days',
    '270290days': '270-290 days',
    '290365days': '290-365 days',
    '365OverDays': '>365 days',
    invoiceDueDate: 'Invoice Due Date',
    suspenseFlag: 'Suspense Flag',
    dueStartDate: 'Due Start Date',
    dueEndDate: 'Due End Date',
    invoiceSummaryTotal: 'Invoice Summary Total',
    addOnTaxAmount: 'Add On Tax Amount',
    addOnTaxRate: 'Add On Tax Rate',
    mainTaxRate: 'Main Tax Rate',
    mainTaxAmount: 'Main Tax Amount',
    orderStartDate: 'Order Start Date',
    orderEndtDate: 'Order End Date',
    cancellationStartDate: 'Cancellation Start Date',
    cancellationEndtDate: 'Cancellation End Date',
    accounttype: 'Account Type',
    delivered: 'Delivered',
    orderDate: 'Order Date',
    glSegment1: 'GL Segment 1',
    glSegment2: 'GL Segment 2',
    glSegment3: 'GL Segment 3',
    glSegment4: 'GL Segment 4',
    glSegment5: 'GL Segment 5',
    glSegment6: 'GL Segment 6',
    offsetAccount: 'Offset Account',
    groupBy: 'Group By',
    writeOffSummaryTotal: 'WriteOff Summary Total',
    groupByNote: 'Group By Note',
    summaryTotal: 'Summary Total',
    details: 'Details',
    invoiceDetails: 'Invoice Details',
    taxableRate: 'Taxable Rate',
    invoiceLines: 'Invoice Lines',
    taxLines: 'Tax Lines',
    cutOffDom: 'CutOff DOM',
    cancelAndSubstitute: 'Cancel and Substitute',
    exhibitsInvoiced: 'Exhibits Invoiced',
    projectEnabled: 'Project Enabled',
    reprocessInvoiceReason: 'Reprocess Invoice Reason',
    extractBatch: 'Extract Batch',
    extractStatus: 'Extract Status',
    objectDate: 'Object Date',
    totalSummary: 'Total Summary',
    addonTaxAmount: 'Addon Tax Amount',
    journalTotal: 'Journal Total',
    accountingLogTotal: 'Accounting Log Total',
    batchAmount: 'Batch Amount',
    objectStartDate: 'Object Start Date',
    objectEndDate: 'Object End Date',
    extractStartDate: 'Extract Start Date',
    extractEndDate: 'Extract End Date',
    percentileValue: 'Percentile Value',
    extractBatchStatus: 'Extract Batch Status',
    exhibitsInvoices: 'Exhibits Invoices',
    invoiceSubstitution: 'Invoice Substitution',
    addNewLine: 'Add New Line',
    showChildServices: 'Show Child Services',
    cutOffDOM: 'Cutoff DOM',
    inAdvanceFlag: 'In Advance Flag',
    uploadVolume: 'Upload Volume',
    downloadVolume: 'Download Volume',
    createNewInvoice: 'Create New Invoice',
    userNumber: 'User Number',
    groupNumber: 'Group Number',
    callingNumber: 'Calling Number',
    callingPresentationIndicator: 'Calling Presentation Indicator',
    calledNumber: 'Callled Number',
    startTime: 'Start Time',
    userTimeZone: 'User Time Zone',
    answerIndicator: 'Answer Indicator',
    answerTime: 'Answer Time',
    releaseTime: 'Release Time',
    terminationCause: 'Termination Cause',
    carrierIdentificationCode: 'Carrier Identification Code',
    dailedDigits: 'Dailed Digits',
    callCategory: 'Call Category',
    networkCallType: 'Network Call Type',
    networkTranslatedNumber: 'Network Translated Number',
    networkTranslatedGroup: 'Network Translated Group',
    releasingParty: 'Releasing Party',
    route: 'Route',
    networkCallId: 'Network Call Id',
    codec: 'Codec',
    accessDeviceAddress: 'Access Device Address',
    group: 'Group',
    originalCalledNumber: 'Original Called Number',
    originalCalledPresentationIndicator: 'Original Called Presentation Indicator',
    originalCalledReason: 'Original Called Reason',
    redirectingNumber: 'Redirecting Number',
    redirectingPresentationIndicator: 'Redirecting Presentation Indicator',
    redirectingReason: 'Redirecting Reason',
    chargeIndicator: 'Charge Indicator',
    typeOfNetwork: 'Type Of Network',
    voicePortalInvocationTime: 'Voice Portal Invocation Time',
    localCallId: 'Local Call Id',
    extAnswerDateTime: 'Ext Answer Date Time',
    sreserved: 'S_Reserved',
    suserid: 'Suserid',
    sotherPartyName: 'Sother Party Name',
    extDurationMinutes: 'Ext Duration Minutes',
    extDurationSeconds: 'Ext Duration Seconds',
    client: 'Client',
    aPartyNumber: 'A Party Number',
    bPartyNumber: 'B Party Number',
    durationMinutesStart: 'Duration Minutes Start',
    durationMinutesEnd: 'Duration Minutes End',
    copyChangeToAllRecurring: 'Copy Change To All Recurring',
    copyChangeToAll: 'Copy Change To All',
    viewHistory: 'View History',
    historyDetails: 'History Details',
    originOperator: 'Origin Operator',
    destinationOperator: 'Destination Operator',
    filePath: 'File Path',
    numberOfRecords: '# Records',
    billingStartDate: 'Billing Start Date',
    billingEndDate: 'Billing End Date',
    numCalls: '# Calls',
    seconds: 'Seconds',
    billedRate: 'Billed Rate',
    origin: 'Origin',
    operatorDisputeFile: 'Operator Dispute File',
    viewRecords: 'View Records',
    viewFiles: 'View Files',
    billingDate: 'Billing Date',
    cutoffDate: 'Cut Off Date',
    uploadDisputeFile: 'Upload Dispute File',
    fileCategory: 'File Category',
    operatorInvoiceFile: 'Operator Invoice File',
    uploadInvoiceFile: 'Upload Invoice File',
    vat: 'VAT',
    aPartyNumberStartRange: 'A Party Number Start Range',
    aPartyNumberEndRange: 'A Party Number End Range',
    bPartyNumberStartRange: 'B Party Number Start Range',
    bPartyNumberEndRange: 'B Party Number End Range',
    incomingRoute: 'Incoming Route',
    outgoingRoute: 'Outgoing Route',
    sourceIpAddress: 'Source Ip Address',
    destinationIpAddress: 'Destination Ip Address',
    idd: 'IDD',
    IDO: 'IDO',
    callStatus: 'Call Status',
    terminatingAccess: 'Terminating Access',
    extIddAbc: 'Ext IDD ABC',
    extIddBcd: 'Ext IDD BCD',
    messageDeliveryStatus: 'Message Delivery Status',
    submitDate: 'Submit Date',
    addressSrcDigits: 'Address Src Digits',
    srcTon: 'Src Ton',
    srcApi: 'Src Api',
    addressDestDigits: 'Address Dest Digits',
    destTon: 'Dest Ton',
    destApi: 'Dest Api',
    scheduleDeliveryTime: 'Schedule Delivery Time',
    validityPeriod: 'Validity Period',
    protocolId: 'Protocol Id',
    priority: 'Priority',
    registerDelivery: 'Register Delivery',
    replacePresent: 'Replace Present',
    dataCoding: 'Data Coding',
    messageLength: 'Message Length',
    first20Chars: 'First 20 Chars',
    optTag: 'Opt Tag',
    optLength: 'Opt Length',
    optVal: 'Opt Val',
    host: 'Host',
    esmClass: 'Esm Class',
    gsmsiei: 'Gsmsiei',
    gsmudh: 'Gsmudh',
    extIdCinta: 'Ext Id Cinta',
    messageId: 'Message Id',
    sequenceNumber: 'Sequence Number',
    commandId: 'Command Id',
    commandLength: 'Command Length',
    startDateTime: 'Start Date Time',
    processDate: 'Process Date',
    operatorRecordLogFile: 'Operator Record Log File',
    operator: 'Operator',
    duration: 'Duration',
    uploadRecordLogFile: 'Upload Record Log File',
    monthEndCloseThreshold: 'Month End Close Threshold',
    defaultCalendar: 'Default Calendar',
    poId: 'PO Id',
    month: 'Month',
    moveDate: 'Move Date',
    addExceptions: 'Add Exceptions',
    exceptions: 'Exceptions',
    includeIva: 'Include IVA',
    includeIeps: 'Include IEPS',
    complement: 'Complement',
    chooseProductCodes: 'Choose Product Codes',
    createdStart: 'Created Start (>=)',
    createdEnd: 'Created End (<)',
    createdDate: 'Created Date',
    uomName: 'UOM Name',
    requestResponseProjectMapping: 'Project Gateway End-point Request/Response Mapping',
    requestResponseCustomerSupportMapping: 'Customer Support Gateway End-point Request/Response Mapping',
    requestResponseDocumentMapping: 'Document Gateway End-point Request/Response Mapping',
    requestResponseOperationMapping: 'Operation Gateway End-point Request/Response Mapping',
    requestResponseCRMMapping: 'CRM Gateway End-point Request/Response Mapping',
    projectGatewayURLMapping: 'Project Gateway URL mapping',
    customerSupportGatewayURLMapping: 'Customer Support Gateway URL mapping',
    documentGatewayURLMapping: 'Document Gateway URL mapping',
    operationGatewayURLMapping: 'Operation Gateway URL mapping',
    crmGatewayURLMapping: 'CRM Gateway URL mapping',
    effectiveStartDate: 'Effective Start Date (>=)',
    effectiveEndDate: 'Effective End Date (<)',
    defaultCostCenter: 'Default Cost Center',
    foreignTaxId: 'Foreign Tax Id',
    failureCode: 'Failure Code',
    disconnectReason: 'Disconnect Reason',
    commercial: 'Commercial',
    callDuration: 'Call Duration',
    extIdoBcd: 'Ext IDO BCD',
    stateProvince: 'State/Province',
    tenantCalls: 'Tenant Calls',
    tenantDuration: 'Tenant Duration',
    tenantRate: 'Tenant Rate',
    opInvoiceFile: 'Op Invoice File',
    tenantDisputeFile: 'Tenant Dispute File',
    regId: 'Reg Id',
    numRegCalls: 'Num Reg Calls',
    numRegSeconds: 'Num Reg Seconds',
    recogRate: 'Recog Rate',
    disputeId: 'Dispute Id',
    disputeFileName: 'Dispute File Name',
    nim: 'NIM',
    anumber: 'A Number',
    bnumber: 'B Number',
    reconcile: 'Reconcile',
    returnPDFBase64: 'Return PDF Base64',
    max: 'Max',
    min: 'Min',
    avg: 'Avg',
    saveSplit: 'Save Split',
    commercialName: 'Commercial Name',
    defaultValue: 'Default Value',
    cutOffDate: 'CutOff Date',
    partMonthWaiveOffFlag: 'Part Month WaiveOff Flag',
    trafficFlag: 'Traffic Flag',
    opportunity: 'Opportunity',
    contractTerm: 'Contract Term',
    dispersed: 'Dispersed',
    merged: 'Merged',
    undoProcessSplit: 'Undo Process Split',
    billOfLading: 'Bill Of Lading',
    expeditionCode: 'Expedition Code',
    close: 'Close',
    gross: 'Gross',
    groupByAccount: 'Group By Account',
    location1: 'Location 1',
    location2: 'Location 2',
    taxVersion: 'Tax Version',
    propagateToBillingAccounts: 'Propagate To Billing Accounts',
    stamping: 'Stamping',
    stampingRecord: 'Stamping Record',
    modifiedDate: 'Modified Date',
    data: 'Data',
    viewUploadFileStatus: 'View Upload File Status',
    pendingCount: 'Pending Count',
    errorCount: 'Error Count',
    uploadFileStatus: 'Upload File Status',
    opportunityId: 'Opportunity Id',
    opportunityName: 'Opportunity Name',
    addReplacement: 'Add Replacement',
    moveIntoHierarchy: 'Move Into Hierarchy',
    collectionAgentId: 'Collection Agent Id',
    pauseBilling: 'Pause Billing',
    pauseInvoicing: 'Pause Invoicing',
    chooseCollectionAgent: 'Choose Collection Agent',
    collectionUserId: 'Collection User Id',
    tAndE: 'T&E',
    employee: 'Employee',
    addNewClient: 'Add New Client',
    addNewEmployee: 'Add New Employee',
    jobTitle: 'Job Title',
    clientName: 'Client Name',
    clientJobTitle: 'Client Job Title',
    firstPayrollDate: '1st Payroll Date',
    firstPayrollAmount: '1st Payroll Amount',
    secondPayrollDate: '2nd Payroll Date',
    secondSalaryAmount: '2nd Payroll Amount',
    numDaysWorked: 'Num Days Worked',
    viewUploadFileDetails: 'View Upload File Details',
    uploadEmployeePayroll: 'Upload Employee Payroll',
    clientOnboarding: 'Client Onboarding',
    addNewJobTitle: 'Add New Job Title',
    pricingUnit: 'Pricing Unit',
    workDays: 'Work Days',
    uploadClientContractPricing: 'Upload Client Contract Pricing',
    employeeName: 'Employee Name',
    year: 'Year',
    employeeId: 'Employee Id',
    grossSalaryMxn: 'Gross Salary Mxn',
    actualCostMxn: 'Actual Cost Mxn (Gross salary* 1.2865)',
    revenueUSD: 'Revenue USD',
    revenueMxn: 'Revenue Mxn',
    actIncomeMxn: 'Act Income Mxn (Rev Mxn - Act Cost Mxn)',
    salaryToBilling: 'Salary To Billing',
    customDescription: 'Custom Description',
    selective: 'Selective',
    nonUsage: 'Non-Usage',
    usage: 'Usage',
    lines: 'Lines',
    addNewTransaction: 'Add New Transaction',
    pendingManualInvoices: 'Pending Manual Invoices',
    newManualInvoice: 'New Manual Invoice',
    stampingDate: 'Stamping Date',
    seg3Name: 'Seg3Name',
    accountActions: 'Account Actions',
    pduType: 'PDU Type',
    aspName: 'ASP Name',
    messageStatus: 'Message Status',
    messageType: 'Message Type',
    downloadFile: 'Download File',
    reconcileBatch: 'Reconcile Batch',
    matchStatus: 'Match Status',
    fullyRecognized: 'Fully Recognized',
    viewRevRec: 'View Rev Rec',
    revenueRecognitionJournals: 'Revenue Recognition Journals',
    addNewRevenueJournals: 'Add New Revenue Journal',
    createOption: 'Create Option',
    gatewayMerchant: 'Gateway Merchant',
    reratePendingTransactionsOnly: 'Rerate Pending Transactions Only',
    IEPS: 'IEPS',
    IVA: 'IVA',
    validity: 'Validity',
    reratingBatch: 'Re-rating Batch',
    backoutOnly: 'Backout Only',
    usageOnly: 'Usage Only',
    primaryTaxType: 'Primary Tax Type',
    addOnTaxType: 'Add On Tax Type',
    taxRate: 'Tax Rate',
    notesOffset: 'Notes Offset',
    collectionAgentAssigned: 'Collection Agent Assigned',
    reratingBatches: 'Re-rating Batches',
    startDateMoreThanAndEqual: 'Start Date (>=)',
    endDateLessThan: 'End Date (<)',
    createNewBatch: 'Create New Batch',
    selectAccountsForRerating: 'Select Accounts for Re-rating',
    selectIfYouNeedToCreateChildAccount: 'Select If you need to create Child Account',
    allowInFlightOrders: 'Allow In Flight Orders',
    alignModifyPricingEffectiveDate: 'Align Modify Pricing Effective Date',
    exchangeRateDeltaItemId: 'Exchange Rate Delta Item Id',
    realizedGainItemId: 'Realized Gain Item Id',
    migrationBatchSize: 'Migration Batch Size',
    noOfBatchProcessThreads: 'No Of Batch Process Threads',
    realizedLossItemId: 'Realized Loss Item Id',
    queuePollDisabled: 'Queue Poll Disabled',
    selfcareUserType: 'Selfcare User Type',
    provisioningEnabled: 'Provisioning Enabled',
    advanceBillingInNoOfDays: 'Advance Billing In No Of Days',
    exchangeThreshold: 'Exchange Threshold',
    trackSubscriptionCount: 'Track Subscription Count',
    fixedTaxRuleForAROps: 'Fixed Tax Rule For AROps',
    timeAndBillingDeployment: 'Time  And Billing Deployment',
    userInMultipleGroups: 'User In Multiple Groups',
    scheduleDate: 'Schedule Date',
    savedManualBills: 'Saved Manual Bills',
    searchSavedManualBills: 'Search Saved Manual Bills',
    errorReason: 'Error Reason',
    scheduleStartDate: 'Schedule Start Date (>=)',
    scheduleEndDate: 'Schedule End Date (<)',
    selectOptions: 'Select Options',
    sourceAmount: 'Source Amount',
    savedPendingMergeRequest: 'Saved Pending Merge Request',
    savedPendingDisperseRequest: 'Saved Pending Disperse Request',
    totalOriginMessage: 'Total Origin Message',
    totalDestinationMessage: 'Total Destination Message',
    batchNumber: 'Batch Number',
    differenceInCalls: 'Difference In Calls',
    endTime: 'End Time',
    aropsSupportTicket: 'AROps Support Ticket',
    ticketNumber: 'Ticket Number',
    substituteInvoice: 'Substitute Invoice',
    substitutedInvoiceStatus: 'Substituted Invoice Status',
    notesType: 'Notes Type',
    notesId: 'Notes Id',
    notesSummary: 'Notes Summary',
    supportTicket: 'Support Ticket',
    notesSummaryLines: 'Notes Summary Lines',
    unAssignedCutOff: 'Unassigned CutOff',
    unbilledOnly: 'Unbilled Only',
    rerateReasonCode: 'Rerate Reason Code',
    calendarPeriod: 'Calendar Period',
    skipStartMonth: 'Skip Start Month',
    skipNoOfMonths: 'Skip No Of Months',
    groupApproval: 'Group Approval',
    mediationHubModules: 'Mediation Hub Modules',
    mediationManagementPermissions: 'Mediation Management Permissions',
    invoiceCurrency: 'Invoice Currency',
    invoiceCurrencyAmount: 'Invoice Currency Amount',
    exchangeRatedUsed: 'Exchange Rated Used',
    usagePlatforms: 'Usage Platforms',
    addNewPlatforms: 'Add New Platforms',
    interfaceType: 'Interface Type',
    fileUploadDate: 'File Upload Date',
    fileUploadErrors: 'File Upload Errors',
    errorMessage: 'Error Message',
    yearMonth: 'Year-Month (YYYY-MM)',
    countOfFiles: 'Count Of Files',
    startYearMonth: 'Start Year Month (YYYY-MM)',
    endYearMonth: 'End Year Month (YYYY-MM)',
    suspended: 'Suspended',
    billEndFromDate: 'Bill End From Date',
    billEndToDate: 'Bill End To Date',
    detailRecords: 'Detail Records',
    cPartyNumber: 'C Party Number',
    recordCount: 'Record Count',
    moveOrder: 'Move Order',
    suspendedFailedBatches: 'Suspended/Failed Batches',
    CDR: 'CDR',
    fetchFinalizedInvNotePymt: 'Fetch Finalized Inv Note Pymt',
    batchidFileName: 'Batch Id (File Name)',
    billingFrequencyUnit: 'Billing Frequency Unit',
    chooseServiceName: 'Choose Service Name',
    warning: 'Warning',
    newFrequencyEffectiveDate: 'New Frequency Effective Date',
    frequencyChangeAutoApplyDate: 'Frequency Change Auto Apply Date',
    invoicingReport: 'Invoicing Report',
    alignSubscriptionEndDate: 'Align Subscription End Date',
    propagateAddressChangeToBillingAccounts: 'Propagate Address Change To Billing Accounts',
    filterZeroAmount: 'Filter Zero Amount',
    systemParametersPermissions: 'System Parameters Permissions',
    batchFile: 'Batch File',
    notesReference: 'Notes Reference',
    displayExchangeRateInHomeCurrency: 'Display Exchange Rate In Home Currency',
    viewAllocation: 'View Allocation',
    allocationDate: 'Allocation Date',
    allocatedAmount: 'Allocated Amount',
    setAsideRecords: 'Set Aside Records',
    failedRecords: 'Failed Records',
    resetJobSchedule: 'Reset Job Schedule',
    advancedSearch: 'Advanced Search',
    satCode: 'SAT Code',
    invoiceExhibitsSkipStartMonth: 'Invoice Exhibits Skip Start Month',
    numberofMonthstoSkipInvoiceExhibits: 'Number of Months to Skip Invoice Exhibits',
    accountNo: 'Account No',
    commName: 'Comm Name',
    substitutionSatCode: 'Substitution SAT Code',
    cheque: 'Cheque',
    pendingDepositAccount: 'Pending Deposit Account Id',
    paymentDetails: 'Payment Details',
    groupCompany: 'Group Company',
    paymentSuspenseActivity: 'Payment Suspense Activity',
    paymentSuspenseReferenceActivity: 'Payment Suspense Reference Activity',
    paymentSuspenseAmount: 'Payment Suspense Amount',
    paymentSuspenseCurrency: 'Payment Suspense Currency',
    paymentSuspenseDate: 'Payment Suspense Date',
    unallocatedAmount: 'Unallocated Amount',
    // invoiceDueInPaymentCurrency: 'Invoice Due In Payment Currency',
    allocatedAmountInPaymentSuspenseCurrency: 'Allocated Amount </br> (In Payment Suspense Currency)',
    invoiceDueInAccountCurrency: 'Invoice Due </br> (In Account Currency)',
    invoiceDueInPaymentCurrency: 'Invoice Due </br> (In Payment Currency)',
    viewAcitvity: 'View Acitvity',
    paymentActivity: 'Payment Activity',
    paymentReferenceActivity: 'Payment Reference Activity',
    allocatedAmountInPaymentCurrency: 'Allocated Amount </br> (In Payment Currency)',
    percentWithNumberRange: 'Percent (1..100)',
    autoAllocateFullDebtOnly: 'Auto Allocate Full Debt Only',
    allocatedAmountInAccountCurrency: 'Allocated Amount </br> (In Account Currency)',
    rerate: 'Rerate',
    refresh: 'Refresh',
    commitmentDollarValue: 'Commitment$ Value',
    commitmentTerm: 'Commitment Term',
    internationalDIDCountry: 'International DID Country',
    network: 'Network',
    salesExecutive: 'Sales Executive',
    collectionAgent: 'Collection Agent',
    bulkOperations: 'Bulk Operations',
    outboundSequence: 'Outbound Sequence',
    inboundSequence: 'Inbound Sequence',
    viewFolio: 'View Folio',
    never: 'Never',
    exchangeRateForReporting: 'Exchange Rate For Reporting',
    currencyForReporting: 'Currency For Reporting',
    invoiceDueMoreThan: 'Invoice Due (>=)',
    invoiceDueLessThan: 'Invoice Due (<)',
    invoiceTotalLessThan: 'Invoice Total (<)',
    invoiceTotalMoreThan: 'Invoice Total (>=)',
    landmarkLabel: 'Landmark Label',
    extralineLabel: 'Extraline Label',
    unlimitedValidityEndDate: 'Unlimited Validity End Date',
    clientAccountId: 'Client Account Id',
    billedAmount: 'Billed Amount',
    billDue: 'Bill Due',
    exchangeCurrency: 'Exchange Currency',
    dueDateMoreThan: 'Due Date (>=)',
    dueDateLessThan: 'Due Date (<)',
    billEndMoreThan: 'Bill End (>=)',
    billEndLessThan: 'Bill End (<)',
    creditNoteId: 'Credit Note Id',
    creditNoteStatus: 'Credit Note Status',
    paymentXchangeThreshold: 'Payment Xchange Threshold',
    tooltipLabelPaymentThreshold:
      'System level configuration - If payment is an under payment within this value, the invoice will be fully paid and the shortfall will be booked against Realized Loss account.',
    tooltipLabelPaymentXchangeThreshold:
      'System level configuration - If currency conversion is involved with payment and after conversion the payment is short for an invoice but within this value, the invoice will be fully paid and the shortfall will be booked against Realized Loss account. If not specific, the payment threshold will be used.',
    payemntThresholdMoreThan: 'Payment Threshold (>=)',
    numberOfDaysSinceDueDate: 'Number Of Days Since Due Date',
    salesFunction: 'Sales Function',
    paymentFolio: 'Payment Folio',
    paymentFilesConfiguration: 'Payment Files Configuration',
    paymentFileDate: 'Payment File Date',
    npaCountry: 'NPA Country',
    totalFunctionalCurrency: 'Total </br> (Functional Currency)',
    functionalCurrency: 'Functional Currency',
    invoiceSummary: 'Invoice Summary',
    ieps: 'IEPS',
    iva: 'IVA',
    billableEmployee: 'Billable Employee',
    remainingAmountThresholdMoreThanZero: 'Remaining Amount Threshold (>0)',
    totalFunctionalCurrencyExport: 'Total (Functional Currency)',
    provisioningOnlyFlag: 'Provisioning Only Flag',
    viewInvoices: 'View Invoices',
    viewActions: 'View Actions',
    billStart: 'Bill Start',
    billEnd: 'Bill End',
    billingPeriodStart: 'Billing Period Start',
    billingPeriodEnd: 'Billing Period End',
    numberOfDaysSinceDueDateMoreThan: 'Number Of Days Since Due Date (>=)',
    numberOfDaysSinceDueDateLessThan: 'Number Of Days Since Due Date (<)',
    collectionEntryDateMoreThan: 'Collection Entry Date (>=)',
    collectionEntryDateLessThan: 'Collection Entry Date (<)',
    accountCategory: 'Account Category',
    collectionEntryDate: 'Collection Entry Date',
    folioStatus: 'Folio Status',
    roundingPrecisionForExchangeRate: 'Rounding Precision For Exchange Rate',
    folioUUID: 'Folio UUID',
    commitmentStart: 'Commitment Start',
    commitmentEnd: 'Commitment End',
    commitmentAmount: 'Commitment Amount',
    commitmentTrackerId: 'Commitment Tracker Id',
    commitmentTrackerBalance: 'Commitment Tracker Balance',
    currentExchangeRate: 'Current Exchange Rate',
    realizedLossGain: 'Realized Loss/Gain',
    paymentDateExchangeRate: 'Payment Date Exchange Rate',
    unitPriceRoundingPrecision: 'Unit Price Rounding Precision',
    unrealizedLossGain: 'Unrealized Loss/Gain',
    unrealizedLossGainInSystemCurrency: 'Unrealized Loss/Gain </br> (In System Currency)',
    unrealizedLossGainInSystemCurrencyExport: 'Unrealized Loss/Gain (In System Currency)',
    appliedExchangeRate: 'Applied Exchange Rate',
    useExchangeRate: 'Use Exchange Rate',
    numberOfDaysSincePaymentDate: 'Number Of Days Since Payment Date',
    numberOfDaysSincePaymentDateMoreThan: 'Number Of Days Since Payment Date (>=)',
    numberOfDaysSincePaymentDateLessThan: 'Number Of Days Since Payment Date (<)',
    payemntThresholdLessThan: 'Payment Threshold (<)',
    remainingAmountThresholdMoreThan: 'Remaining Amount Threshold (>=)',
    remainingAmountThresholdLessThan: 'Remaining Amount Threshold (<)',
    unallocatedAmountThresholdMoreThan: 'Unallocated Amount Threshold (>=)',
    unallocatedAmountThresholdLessThan: 'Unallocated Amount Threshold (<)',
    paymentStatus: 'Payment Status',
    viewProvisioningAttributes: 'View Provisioning Attributes',
    provisioningAttributes: 'Provisioning Attributes',
    fileNameBatchId: 'File Name (Batch Id)',
    sourcePlatform: 'Source Platform',
    processedCount: 'Processed Count',
    suspendedCount: 'Suspended Count',
    ignoredCount: 'Ignored Count',
    stage: 'Stage',
    allocatedAmountInAccountCurrencyExport: 'Allocated Amount (In Account Currency)',
    allocatedAmountInPaymentSuspenseCurrencyExport: 'Allocated Amount (In Payment Suspense Currency)',
    prorateBilledOnSuspend: 'Prorate Billed On Suspend',
    cancelTypeId: 'Cancel Type Id',
    cancelType: 'Cancel Type',
    personalEV: 'Personal EV',
    salesAgentName: 'Sales Agent Name',
    cancelDate: 'Cancel Date',
    serviceInstanceId: 'Service Instance Id',
    cancelReasonId: 'Cancel Reason Id',
    cancelReason: 'Cancel Reason',
    proratedAmount: 'Prorated Amount',
    cancelDateMoreThan: 'Cancel Date (>=)',
    cancelDateLessThan: 'Cancel Date (<)',
    selectCloseSuspenseReason: 'Select Close Suspense Reason',
    modifySuspense: 'Modify Suspense',
    closeSuspenseReason: 'Close Suspense Reason',
    careClassification: 'Care Classification',
    giro: 'Spin',
    salesAgent: 'Sales Agent',
    tipoMercado: 'Tipo Mercado',
    serviceInstance: 'Service Instance',
    invoiceTotalInUSD: 'Invoice Total In USD',
    companyName: 'Company Name',
    segmentId: 'Segment Id',
    functionEV: 'Function EV',
    familyName: 'Family  Name',
    familyId: 'Family Id',
    subFamily1Name: 'Sub Family 1 Name',
    subFamily1Id: 'Sub Family 1 Id',
    subFamily2Name: 'Sub Family 2 Name',
    subFamily2Id: 'Sub Family 2 Id',
    planStatus: 'Plan Status',
    folioDateMoreThan: 'Folio Date (>=)',
    exportConfig: 'Export Config',
    sourceFields: 'Source Fields',
    targetFields: 'Target Fields',
    crmClientId: 'Crm Client Id',
    salesConsultant: 'Sales Consultant',
    paymentReceiptsAccount: 'Payment Receipts Account',
    revenueApportionPaymentToTax: 'Revenue Apportion Payment To Tax',
    referenceBatchId: 'Reference Batch Id',
    rfc: 'RFC',
    customAccountType: 'Custom Account Type',
    outputConfig: 'Output Config',
    orderSource: 'Order Source',
    originalInvoiceRef: 'Original Invoice Ref',
    remainingCommitment: 'Remaining Commitment',
    creditNotesId: 'Credit Notes Id',
    startMonth: 'Start Month',
    monthOne: 'Month One',
    monthTwo: 'Month Two',
    monthThree: 'Month Three',
    ratingProvisioningId: 'Rating Provisioning Id',
    sServiceProvider: 'S_SERVICEPROVIDER',
    sUserNumber: 'S_USERNUMBER',
    sGroupNumber: 'S_GROUPNUMBER',
    sDirection: 'S_DIRECTION',
    sCallingNumber: 'S_CALLINGNUMBER',
    sCalledNumber: 'S_CALLEDNUMBER',
    sStartTime: 'S_STARTTIME',
    sAnswerIndicator: 'S_ANSWERINDICATOR',
    sAnswerTime: 'S_ANSWERTIME',
    sReleaseTime: 'S_RELEASETIME',
    sTerminationCause: 'S_TERMINATIONCAUSE',
    sDailedDigits: 'S_DIALEDDIGITS',
    sCallCategory: 'S_CALLCATEGORY',
    sNetworkCallType: 'S_NETWORKCALLTYPE',
    sNetworkTranslatedNumber: 'S_NETWORKTRANSLATEDNUMBER',
    sReleasingParty: 'S_RELEASINGPARTY',
    sRoute: 'S_ROUTE',
    sNetworkCallId: 'S_NETWORKCALLID',
    sCodec: 'S_CODEC',
    sAccessDeviceAddress: 'S_ACCESSDEVICEADDRESS',
    sGroup: 'S_GROUP',
    sDepartment: 'S_DEPARTMENT',
    sAuthorizationCode: 'S_AUTHORIZATIONCODE',
    sOriginalCalledNumber: 'S_ORIGINALCALLEDNUMBER',
    sOriginalCalledPresentationIndicator: 'S_ORIGINALCALLEDPRESENT_INDIC',
    sOriginalCalledReason: 'S_ORIGINALCALLEDREASON',
    sRedirectingNumber: 'S_REDIRECTINGNUMBER',
    sRedirectingPresentationIndicator: 'S_REDIRECTINGPRESENT_INDIC',
    sRedirectingReason: 'S_REDIRECTINGREASON',
    sChargeIndicator: 'S_CHARGEINDICATOR',
    sVoicePortalInvocationTime: 'S_VOICEPORTALCALLING_INVOCTIME',
    dExtAnswerDateTime: 'D_EXT_ANSWER_DATE_TIME',
    nExtDurationMinutes: 'N_EXT_DURATION_MINUTES',
    nExtDurationSeconds: 'N_EXT_DURATION_SECONDS',
    sReserved: 'S_EXT_RESERVED',
    sUserid: 'S_USERID',
    sOtherPartyName: 'S_OTHERPARTYNAME',
    partySipCallIdZero: 'Party Sip Call Id Zero',
    partySipCallIdOne: 'Party Sip Call Id One',
    serviceStatus: 'Service Status',
    nextCutoffDate: 'Next Cutoff Date',
    omsInternalId: 'Oms Internal Id',
    completedDate: 'Completed Date',
    incrementedAmount: 'Incremented Amount',
    finalAmount: 'Final Amount',
    oldAmount: 'Old Amount',
    completedStartDate: 'Completed Start Date',
    completedEndDate: 'Completed End Date',
    C601: 'C601',
    C602: 'C602',
    C003: 'C003',
    C006: 'C006',
    C101: 'C101',
    C102: 'C102',
    C103: 'C103',
    R0010501: 'R001-0501',
    R0010601: 'R001-0601',
    R0010602: 'R001-0602',
    R0010603: 'R001-0603',
    R0010604: 'R001-0604',
    R0010605: 'R001-0605',
    R0010606: 'R001-0606',
    R0010607: 'R001-0607',
    R0010608: 'R001-0608',
    R0010609: 'R001-0609',
    R0010610: 'R001-0610',
    R0010611: 'R001-0611',
    R0010612: 'R001-0612',
    R0010613: 'R001-0613',
    R0050101: 'R005-0101',
    C002: 'C002',
    C205: 'C205',
    C309: 'C309',
    R0050201: 'R005-0201',
    R0050301: 'R005-0301',
    R0050302: 'R005-0302',
    R0050303: 'R005-0303',
    R0050304: 'R005-0304',
    R0050401: 'R005-0401',
    R0050501: 'R005-0501',
    R0050502: 'R005-0502',
    R0050503: 'R005-0503',
    R0040101: 'R004-0101',
    R0040501: 'R004-0501',
    R0040502: 'R004-0502',
    R0040503: 'R004-0503',
    R0050601: 'R005-0601',
    R0050602: 'R005-0602',
    C104: 'C104',
    reserved: 'Reserved',
    text: 'Text',
    countLessThan: 'Count <',
    totalTime: 'Total Time',
    historicalFolioLink: 'Historical Folio Link',
    push: 'Push',
    defaulTrunkAccount: 'Defaul Trunk Account',
    creditNoteReadyNotification: 'Credit Note Ready Notification',
    addConfig: 'Add Config',
    addAttributes: 'Add Attributes',
    apiType: 'API Type',
    provisioningName: 'Provisioning Name',
    chooseProvisioningName: 'Choose Provisioning Name',
    attributes: 'Attributes',
    alternateName: 'Alternate Name',
    patternName: 'Pattern Name',
    patternType: 'Pattern Type',
    scheduleStart: 'Schedule Start',
    scheduleEnd: 'Schedule End',
    everyNDay: 'Every "n" Day(s)',
    everyWeekDay: 'Every Week Day',
    everyWeekEnd: 'Every Week End',
    scheduleDaily: 'Schedule Daily',
    scheduleWeekly: 'Schedule Weekly',
    scheduleMonthly: 'Schedule Monthly',
    scheduleYearly: 'Schedule Yearly',
    everyMonday: 'Every Monday',
    everyTuesday: 'Every Tuesday',
    everyWednesday: 'Every Wednesday',
    everyThursday: 'Every Thursday',
    everyFriday: 'Every Friday',
    everySaturday: 'Every Saturday',
    everySunday: 'Every Sunday',
    everyNWeek: 'Every "n" Week(s)',
    dayOfMonth: 'Day Of Month',
    everyNMonth: 'Every "n" Month(s)',
    monthOfYear: 'Month Of Year',
    everyNYear: 'Every "n" Year(s)',
    dayS: 'Day(s)',
    every: 'Every',
    weekS: 'Week(s)',
    day: 'Day',
    ofEvery: 'Of every',
    monthS: 'Month(s)',
    yearS: 'Year(s)',
    profilePicture: 'Profile Picture',
    isProvisionable: 'Is Provisionable',
    statusChangeEffectiveDate: 'Status Change Effective Date',
    changeTheDateForBackdatedOrFutureDatedStatusChange: 'Change the date for backdated or future dated status change',
    tieredDiscountingModel: 'Tiered Discounting Model',
    provisioning: 'Provisioning',
    separateStampingAPI: 'Separate Stamping API',
    connectionName: 'Connection Name',
    flexattr1: 'Flexattr 1',
    flexattr2: 'Flexattr 2',
    flexattr3: 'Flexattr 3',
    flexattr4: 'Flexattr 4',
    flexattr5: 'Flexattr 5',
    flexattr6: 'Flexattr 6',
    flexattr7: 'Flexattr 7',
    flexattr8: 'Flexattr 8',
    flexattr9: 'Flexattr 9',
    flexattr10: 'Flexattr 10',
    testUsageTool: 'Test Usage Tool',
    testUsage: 'Test Usage',
    result: 'Result',
    folioType: 'Folio Type',
    embrixTargetRecord: 'Embrix Target Record',
    provisioningNames: 'Provisioning Names',
    addProvisioningName: 'Add Provisioning Name',
    priceDiscountOverride: 'Price/Discount Override',
    provisioningOrderId: 'Provisioning Order Id',
    provisioningData: 'Provisioning Data',
    setOrderProvisioningData: 'Set Order/Provisioning Data',
    quote: 'Quote',
    submittedStartDate: 'Submitted Start Date (>=)',
    submittedEndDate: 'Submitted End Date (<)',
    quoteDetails: 'Quote Details',
    priceOffer: 'Price Offer',
    bundle: 'Bundle',
    package: 'Package',
    transaction: 'Transaction',
    discountOffer: 'Discount Offer',
    serviceSummary: 'Service Summary',
    lineSummary: 'Line Summary',
    currentCharges: 'Current Charges',
    newQuote: 'New Quote',
    createNewQuote: 'Create New Quote',
    setQuoteProvisioningData: 'Set Quote/Provisioning Data',
    manageCreateQuote: 'Manage & Create Quote',
    flatRate: 'Flat Rate',
    accumulatorAmount: 'Accumulator Amount',
    evaluationComponent: 'Evaluation Component',
    comparisonOperator: 'Comparison Operator',
    createNewDiscountTrigger: 'Create New Discount Trigger',
    editDiscountTrigger: 'Edit Discount Trigger',
    usageField: 'Usage Field',
    discountTriggerName: 'Discount Trigger Name',
    showTasks: 'Show Tasks',
    orderTask: 'Order Task',
    omsTasks: 'OMS Tasks',
    provisioningTasks: 'Provisioning Tasks',
    outbound: 'Outbound',
    inbound: 'Inbound',
    taskName: 'Task Name',
    quoteManagementPermissions: 'Quote Management Permissions',
    getQuote: 'Get Quote',
    choosePriceOffers: 'Choose Price Offers',
    convertToOrder: 'Convert To Order',
    chooseExistingAccount: 'Choose Existing Account',
    createNewAccount: 'Create New Account',
    convert: 'Convert',
    unitPriceOrDiscount: 'Unit Price/Discount',
    parentTaskId: 'Parent Task Id',
    includeChildTask: 'Include Child Task',
    idHistory: 'Id History',
    ticketId: 'Ticket Id',
    userEmail: 'User Email',
    userFirstname: 'User First Name',
    userLastName: 'User Last Name',
    createTask: 'Create Task',
    createNewTask: 'Create New Task',
    createChildTask: 'Create Child Task',
    viewChildTasks: 'View Child Tasks',
    taskDetails: 'Task Details',
    childTasks: 'Child Tasks',
    childTaskDetails: 'Child Task Details',
    taskHistory: 'Task History',
    parent: 'Parent',
    createNewChildTask: 'Create New Child Task',
    createNonPaying: 'Create Non-Paying',
    auditHistoryDetails: 'Audit History Details',
    auditHistory: 'Audit History',
    multiSubscriptionEnabled: 'Multi Subscription Enabled',
    subscriptionTopUpOfferId: 'Subscription Top Up Offer Id',
    sysAdminAccount: 'System Admin Account',
    autoTaskSync: 'Automatic Task Sync To External',
    quotePricingLevel: 'Quote Pricing Level',
    exchangeRateForProvisioning: 'Exchange Rate For Provisioning',
    addSubscription: 'Add Subscription',
    subscriptionName: 'Subscription Name',
    selectASubscriptionToProceed: 'Select A Subscription To Proceed',
    singleSubscription: 'Single Subscription',
    subscriptionTerms: 'Subscription Terms',
    overrideOptions: 'Override Options',
    purchaseOptions: 'Purchase Options',
    addDiscounts: 'Add Discounts',
    topupDetails: 'Topup Details',
    validityTerm: 'Validity Term',
    termX: 'Term (X)',
    subscriptionOrAddonPurchase: 'Subscription or Addon Purchase',
    createCancel: 'Create/Cancel',
    provisionDataSetAttributes: 'Provision data-set attributes',
    addDataSet: 'Add Data-set',
    addStep: 'Add Step',
    addAttribute: 'Add Attribute',
    dataSetType: 'Data-Set Type',
    poAmount: 'PO Amount',
    emailId: 'Email Id',
    billing: 'Billing',
    invoicing: 'Invoicing',
    lastAccountingDate: 'Last Accounting Date',
    nextAccountingDate: 'Next Accounting Date',
    futureAccountingDate: 'Future Accounting Date',
    lastBillDate: 'Last Bill Date',
    lastBillId: 'Last Bill Id',
    nextBillDate: 'Next Bill Date',
    nextBillId: 'Next Bill Id',
    futureCycleOpen: 'Future Cycle Open',
    futureBillDate: 'Future Bill Date',
    futureBillId: 'Future Bill Id',
    otherData: 'Other Data',
    manageAndOverride: 'Manage & Override',
    provisioningDataAndCreateOrder: 'Provisioning Data & Create Order',
    moveOutOfHierarchy: 'Move Out Of Hierarchy',
    limitBreachActions: 'Limit Breach Action(s)',
    addAction: 'Add Action',
    uploadBulkAdjustmentFile: 'Upload Bulk Adjustment File',
    trialToPaidNow: 'Trial To Paid Now',
    modifyTopupOfferConfig: 'Modify Topup Offer Config',
    newTopupOfferConfig: 'New Topup Offer Config',
    topupOfferId: 'Topup Offer Id',
    topupOfferName: 'Topup Offer Name',
    paymentOfferId: 'Payment Offer Id',
    paymentOfferName: 'Payment Offer Name',
    burnRateOfferId: 'Burn Rate Offer Id',
    burnRateOfferName: 'Burn Rate Offer Name',
    CHANGE: 'CHANGE',
    CANCEL: 'CANCEL',
    NEW: 'NEW',
    addNew: 'Add New',
    suspendResume: 'Suspend/Resume',
    uploadStartDate: 'Upload Start Date (>=)',
    uploadEndDate: 'Upload End Date (<)',
    uploadDate: 'Upload Date',
    downloadTemplate: 'Download Template',
    undo: 'Undo',
    orderTasks: 'Order Tasks',
    discountAmountOverride: 'Discount Amount Override',
    discountPercentOverride: 'Discount Percent Override',
    streamMapping: 'Stream Mapping',
    phones: 'Phones',
    quickCreateAccount: 'Quick Create Account',
    quickCreate: 'Quick Create',
    taxCodeTaxes: 'Tax Code Taxes',
    addTaxes: 'Add Taxes',
    taxBase: 'Tax Base',
    toAddCurrency: 'To add currency resources, click ',
    here: 'here',
    messageQuickTaxes:
      'The sequence of the rows is important. If you change the sequence of how taxes should apply, then ensure that the expression does not have the name from a row that will apply later in the sequence',
    apiMapping: 'API Mapping',
    addMerchants: 'Add Merchants',
    sendNotificationOnCollectionEntry: 'Send Notification On Collection Entry',
    webApiType: 'Web Api Type',
    provisionGatewayURLMapping: 'Provision Gateway URL mapping',
    zoneUnitEdit: 'Edit Zone Unit',
    zoneUnitAdd: 'Add Zone Unit',
    zoneType: 'Zone Type',
    originField: 'Origin Field',
    originValue: 'Origin Value',
    distanceValue: 'Distance Value',
    tenantMasterData: 'Tenant Master Data',
    tenantProfile: 'Tenant Profile',
    tenantSupport: 'Tenant Support',
    addNewZone: 'Add New Zone',
    destinationField: 'Destination Field',
    destinationValue: 'Destination Value',
    pendingBillLines: 'Pending Bill Lines',
    lineAmount: 'Line Amount',
    orderProvisioningIds: 'Order Provisioning Ids',
    serviceIdentifiers: 'Service Identifiers',
    orderAction: 'Order Action',
    taxType: 'Tax Type',
    receiver: 'Receiver',
    templateType: 'Template Type',
    addAlaCarte: 'Add Ala Carte',
    alaCarte: 'Ala Carte',
    discountOffers: 'Discount Offers',
    priceOffers: 'Price Offers',
    collectionProfile: 'Collection Profile',
    chooseCollectionProfile: 'Choose Collection Profile',
    mandateSatCodeForAdjustments: 'Mandate Sat Code For Adjustment',
    addNewSchedule: 'Add New Schedule',
    provisioningSequence: 'Provisioning Sequence',
    prerequisiteInboundSequence: 'Prerequisite Inbound Sequence',
    newPrerequisiteInboundSequence: 'New Prerequisite Inbound Sequence',
    synchronousResponse: 'Synchronous Response',
    addNewSequence: 'Add New Sequence',
    servicesTypeRemove: 'Services type remove',
    totalInvoice: 'Total Invoice',
    totalAmount: 'Total Amount',
    totalPaid: 'Total Paid',
    adjustmentRequestDetails: 'Adjustment Request Details',
    productCode: 'Product Code',
    installmentDetails: 'Installment Details',
    invoicesInInstallment: 'Invoices In Installment',
    installmentNo: 'Installment No',
    amountPaid: 'Amount Paid',
    noOfInstallments: 'No Of Installments',
    noOfInstallmentsPaid: 'No Of Installments Paid',
    addMultipleInvoicePaymentInstallment: 'Add Multiple Invoice Payment Installment',
    numberOfInstallments: 'Number Of Installments',
    monthsSOffset: 'Month(s) Offset',
    noOfInvoices: 'No Of Invoices',
    installmentPlan: 'INSTALLMENT PLAN',
    customerName: 'Customer Name',
    installmentId: 'Installment Id',
    signature: 'Signature',
    addInstallmentData: 'Add Installment Data',
    requestId: 'Request Id',
    lineReference: 'Line Reference',
    lowerLimit: 'Lower Limit',
    upperLimit: 'Upper Limit',
    productCodes: 'Product Codes',
    uploadCertificate: 'Upload Certificate',
    sharingType: 'Sharing Type',
    shareImpactOffer: 'Share Impact Offer',
    createNewGroup: 'Create New Group',
    groupDetails: 'Group Details',
    ownerOffer: 'Owner Offer',
    addSharedDiscounts: 'Add Shared Discounts',
    disputesRequestDetails: 'Disputes Request Details',
    writeOffRequestDetails: 'Write Off Request Details',
    splitTaxCalculationByServiceType: 'Split Tax Calculation By Service Type',
    actions: 'Actions',
    agents: 'Agents',
    firstInvoicePaymentActivation: 'First Invoice Payment Activation',
    writeoffId: 'Writeoff Id',
    arActivityDetails: 'AR Activity Details',
    arActivityRequestDetails: 'AR Activity Request Details',
    apiProtocol: 'API Protocol',
    newRegulatoryProductCodeConfig: 'New Regulatory Product Code Config',
    modifyRegulatoryProductCodeConfig: 'Modify Regulatory Product Code Config',
    payloadTemplate: 'Payload Template',
    embrixSmsGateway: 'Embrix Sms Gateway',
    sendProvisioningData: 'Send Provisioning Data',
    enterpriseProvisioningQueue: 'Enterprise Provisioning Queue',
    oldProvisioningSystemQueue: 'Old Provisioning System Queue',
    payloadMapOnly: 'Payload Map Only',
    revenueRecognitionInSystemCurrency: 'Revenue Recognition In System Currency',
    itemizeARRevenueExtract: 'Itemize AR Revenue Extract',
    staticValue: 'Static Value',
    tableMode: 'Table Mode',
    gridMode: 'Grid Mode',
    closed: 'Closed',
    billingOnly: 'Billing Only',
    provisioningOnly: 'Provisioning Only',
    billOrderCharges: 'Bill Order Charges',
    allowPartialProvisioning: 'Allow Partial Provisioning',
    billForOrder: 'Bill For Order',
    executeOnError: 'Execute On Error',
    errorApiName: 'Error Api Name',
    customerId: 'Customer Id',
    assigned: 'Assigned',
    arReasonCodeConfig: 'AR Reason Code Config',
    partyId: 'Party Id',
    nextActionDate: 'Next Action Date',
    nextAction: 'Next Action',
    templates: 'Templates',
    agreement: 'Agreement',
    additionalAddressInformation: 'Additional Address Information',
    municipality: 'Municipality',
    locality: 'Locality',
    neighbourhood: 'Neighbourhood',
    address1: 'Address1',
    address2: 'Address2',
    address3: 'Address3',
    district: 'District',
    paymentAllocationRules: 'Payment Allocation Rules',
    displayActiveOnly: 'Display Active Only',
    invoiceSummaryType: 'Invoice Summary Type',
    paymentAllocationRulesDetails: 'Payment Allocation Rules Details',
    summaryType: 'Summary Type',
    addSupplementaryFile: 'Add Supplementary File',
    supplementaryFiles: 'Supplementary Files',
    addResources: 'Add Resources',
    addActions: 'Add Actions',
    smsTemplate: 'SMS Template',
    uploadEmailTemplate: 'Upload Email Template',
    myActivity: 'My Activity',
    isBundle: 'Is Bundle',
    isPackage: 'Is Package',
    currencyCode: 'Currency Code',
    PRICE_OFFER: 'Price Offer',
    DISCOUNT_OFFER: 'Discount Offer',
    INACTIVE: 'Inactive',
    CLOSEDAndCANCELLED: 'Closed/Cancelled',
    subscriptionView: 'Subscription View',
    treeView: 'Tree View',
    priceUnit: 'Price Unit',
    discountUnit: 'Discount Unit',
    'ala-Carte': 'Ala-carte',
    currentAccount: 'Current Account',
    unallocatedCredits: 'Unallocated Credits',
    identity: 'Identity',
    identityDocument: 'Identity Document',
    taxByLineItem: 'Tax By Line Item',
    defaultTaxRuleForAROps: 'Default Tax Rule For AR Ops',
    lastArAction: 'Last AR Action',
    taskSequence: 'Task Sequence',
    serviceProvisioningSequence: 'Service Provisioning Sequence',
    listStatusActiveProvisioningTasks: 'COMPLETED/SKIPPED/NOT_REQUIRED',
    PENDING: 'PENDING',
    FAILED: 'FAILED',
    'PENDING/PARTIAL': 'PENDING/PARTIAL',
    'FAILED/CANCELLED': 'FAILED/CANCELLED',
    arRequestLog: 'AR Request Log',
    arOpsUnits: 'AR Ops Units',
    accountingCode: 'Accounting Code',
    itemAsTransactionDescription: 'Item As Transaction Description',
    emailSubject: 'Email Subject',
    batchFinancialExtract: 'Batch Financial Extract',
    taxExemptionOnTaxableAmount: 'Tax Exemption On Taxable Amount',
    glOffsetAccount: 'GL Offset Account',
    offsetAccountingCode: 'Offset Accounting Code',
    searchFilters: 'Search Filters',
    finalizedInvoice: 'Finalized Invoice',
    invoicesWithInstallment: 'Invoices With Installment',
    openInvoices: 'Open Invoices',
    fileUploadStats: 'File Upload Stats',
    invoiceDateWithRank: 'Invoice Date (>=)',
    useGLCombination: 'Use GL Combination',
    paymentSourceId: 'Payment Source Id',
    certificateId: 'Certificate Id',
    erpExtractGracePeriod: 'ERP Extract Grace Period',
    invoiceEnd: 'Invoice End',
    invoiceStart: 'Invoice Start',
    invoiceIssued: 'Invoice Issued',
    invoicePending: 'Invoice Pending',
    item: 'item',
    offerStart: 'Offer Start',
    offerEnd: 'Offer End',
    serviceEffectiveDate: 'Service Effective Date',
    invoicePaid: 'Invoice Paid',
    isIPTVService: 'Is IPTV Service',
    priceUnitStatus: 'Price Unit Status',
    contractApprovedDate: 'Contract Approved Date',
    contractDate: 'Contract Date',
    offerId: 'Offer Id',
    provisioningApprovedDate: 'Provisioning Approved Date',
    provisioningStartDate: 'Provisioning Start Date',
    provisioningConfirmationDate: 'Provisioning Confirmation Date',
    installmentPayments: 'Installment Payments',
    payments: 'Payments',
    dataProcessDate: 'Data Process Date',
    sendInvoicePdfAndXml: 'Send Invoice PDF and XML',
    searchPackageBundleToIncludeTax: 'Search Package/Bundle To Include Tax',
    activity: 'Activity',
    ERPExtractActivity: 'ERP Extract Activity',
    groupByPaymentSource: 'Group By Payment Source',
    selectGLAccount: 'Select GL Account',
    matchBillingEntries: 'Match Billing Entries',
    TEExpenseType: 'T&E: Expense Type',
    fixedExpenseTypes: 'Fixed Expense Types',
    variableExpenseTypes: 'Variable Expense Types',
    expenseData: 'Expense Data',
    expensePeriodUnit: 'Expense Period Unit',
    TEData: 'T&E Data',
    addTEExpenseData: 'Add T&E Data',
    PLReport: 'P&L Report',
    PLYearlyReport: 'P&L Yearly Report',
    createCustomerAttributes: 'Create Customer Attributes',
    referenceOrder: 'Reference Order',
    selectOrder: 'Select Order',
    orderProvisioningData: 'Order Provisioning Data',
    createOrder: 'Create Order',
    accountOrderWrapperAPI: 'Account Order Wrapper API',
    options: 'Options',
    or: 'Or',
    actionsList: 'Actions List',
    alignInstallmentWithBdom: 'Align Installment With BDOM',
    subject: 'Subject',
    emailContent: 'Email Content',
    filterInstallmentInvoicesForAR: 'Filter Installment Invoices For AR',
    notesOnDispute: 'Notes on Dispute',
    isGrant: 'Is Grant',
    revenueData: 'Revenue Data',
    installmentInvoice: 'Installment Invoice',
    invoiceNotes: 'Invoice Notes',
    revenueStartDate: 'Revenue Start Date',
    revenueEndDate: 'Revenue End Date',
    collectionUnitId: 'Collection Unit Id',
    employeeBonus: 'Employee Bonus',
    bonusPeriodUnit: 'Bonus Period Unit',
    addEmployeeBonus: 'Add Employee Bonus',
    moveProvisioningOrder: 'Move Provisioning',
    PLQuaterlyReport: 'P&L Quaterly Report',
    expectedBilledRevenue: 'Expected Billed Revenue',
    revenueReceived: 'Revenue Received',
    totalPayroll: 'Total Payroll',
    payrollTaxes: 'Payroll Taxes',
    legalGrossYearEndBonus: 'Legal Gross Year-end Bonus',
    actualCost: 'Actual Cost',
    grossProfitAverage: 'Gross Profit Average',
    grossMarginAverage: 'Gross Margin Average',
    fixedExpenses: 'Fixed Expenses',
    totalFixedExpenses: 'Total Fixed Expenses',
    variableExpenses: 'Variable Expenses',
    totalVariableExpenses: 'Total Variable Expenses',
    overheadExpenses: 'Overhead Expenses',
    totalRevenueUSD: 'Total Revenue USD',
    grossProfitUSD: 'Gross Profit USD',
    totalExpensesUSD: 'Total Expenses USD',
    netIncomeMXN: 'Net Income MXN',
    netIncomeUSD: 'Net Income USD',
    waitTimeForNextSequenceInSeconds: 'Wait Time for Next Sequence (In SECONDS)',
    provisioningLagInSeconds: 'Provisioning Lag (In SECONDS)',
    addFiles: 'Add Files',
    chequeNumber: 'Cheque Number',
    accumulatorBasedTaxThresholds: 'Accumulator Based Tax Thresholds',
    accumulatorMinium: 'Accumulator Minimum',
    accumulatorMaximum: 'Accumulator Maximum',
    subscriptionCategory: 'Subscription Category',
    exceptionStartTime: 'Exception Start Time (HH:MM:SS)',
    exceptionEndTime: 'Exception End Time (HH:MM:SS)',
    percentageThreshold: 'Percentage Threshold',
    valueThreshold: 'Value Threshold',
    avoidZeroAmountTaxTransaction: 'Avoid Zero Amount Tax Transaction',
    statusMessage: 'Status Message',
    templateName: 'Template Name',
    interCompanyCreditNoteItem: 'Intercompany Credit Note Item',
    interCompanyInvoiceCreditReason: 'Intercompany Invoice Credit Reason',
    billTimeTaxation: 'Bill Time-Taxation',
    periodStart: 'Period Start (YYYY-MM)',
    periodEnd: 'Period End (YYYY-MM)',
    totalKwh: 'Total KWh',
    contractAccountId: 'Account Id',
    firstNamePrepaid: 'First Name',
    lastNamePrepaid: 'Last Name',
    phoneNumberPrepaid: 'Phone Number',
    totalAmountPrepaid: 'Total Amount',
    percentageVariation: 'Percentage Variation',
    meterId: 'Meter Id',
    prev6MonthAvg: 'Prev 6 Month Avg',
    kwhPeriod: 'Kwh Period',
    kwh: 'Kwh',
    topupDate: 'Topup Date',
    idEnergyTopup: 'Id',
    subscriptionIdEnergyTopup: 'Subscription Id',
    userIdEnergyTopup: 'User Id',
    paymentSourceEnergyTopup: 'Payment Source',
    approximateKwh: 'Approximate Kwh',
    startDateWithDetails: 'Start Date (YYY-MM-DD)',
    endDateWithDetails: 'End Date (YYY-MM-DD)',
    paymentRefIdEnergyTopup: 'Payment Ref Id',
    topupAmount: 'Topup Amount',
    validityTermUnit: 'Validity Term Unit',
    applyPayment: 'Apply Payment',
    useNameAsInvoiceDescription: 'Use Name As Invoice Description',
    realTimeTaxation: 'Real Time Taxation',
    sourceItemId: 'Source Item Id',
    invoicesCount: 'Invoices Count',
    internetService: 'Internet Service',
    iptvService: 'Iptv Service',
    voipService: 'Voip Service',
    phoneNumber: 'Phone Number',
    invoiceLineView: 'Invoice Line View',
    waitTime: 'Wait Time',
    onManualProvisioningOnly: 'On Manual ProvisioningOnly',
    retryCount: 'Retry Count',
    restartAPIOnError: 'Restart API On Error',
    errorStatus: 'Error Status',
    manualProvisioningApi: 'Manual Provisioning Api',
    rateWhenInactive: 'Rate When Inactive',
    restartApiOnError: 'Restart Api On Error',
    retriedCount: 'Retried Count',
    retryCountOnError: 'Retry Count On Error',
    retryCountOnTimeOut: 'Retry Count On Time Out',
    arRequestId: 'AR Request Id',
    paymentMethods: 'Payment Methods',
    skipAuthorization: 'Skip Authorization',
    minimumThresholdTrigger: 'Minimum Threshold Trigger',
    maximumCap: 'Maximum Cap',
    key: 'Key',
    addTo: 'Add To',
    apiKeyAttributesForm: 'Api Key Attributes Form',
    exemptOnTaxAmount: 'Exempt On Tax Amount',
    taxPercentOffset: 'Tax Percent Offset',
    skipAuthorizationGatewayAPIs: 'Skip Authorization Gateway APIs',
    evaluationData: 'Evaluation Data',
    addNewEvaluationData: 'Add New Evaluation Data',
    attribute: 'Attribute',
    evaluationObjectType: 'Evaluation Object Type',
    amountOverride: 'Amount Override',
    appliedEndDate: 'Applied End Date',
    appliedStartDate: 'Applied Start Date',
    commitmentTermUnit: 'Commitment Term Unit',
    cycleEndDate: 'Cycle End Date',
    cycleStartDate: 'Cycle Start Date',
    noOfCyclesApplied: 'No Of Cycles Applied',
    offerStatus: 'Offer Status',
    percentOverride: 'Percent Override',
    branch: 'Branch',
    ifscCode: 'IFSC Code',
    swiftCode: 'Swift Code',
    bankAddress: 'Bank Address',
    actionDate: 'Action Date',
    collectionAction: 'Collection Action',
    noOfAccounts: 'No Of Accounts',
    summary: 'Summary',
    extractType: 'Extract Type',
    additionalData: 'Additional Data',
    exclude: 'Exclude',
    glAccountPattern: 'GL Account Pattern',
    assetType: 'Asset Type',
    showTopN: 'Show Top "n"',
    lastAction: 'Last Action',
  },

  message: {
    deleteAddress: 'Are you sure you want to delete this Address?',
    mandatory: 'Mandatory inputs are missing or invalid',
    selectAccount: 'You need to select account first',
    noRecord: 'No record has found!',
    noRole: 'No role has found!',
    noRoleGroup: 'No role group has found!',
    phonesNotEmpty: 'Phones can not empty',
    typePhoneRemaining: 'No unique type phone remaining',
    deleteItem: 'Are you sure you want to delete this Item',
    noModules: 'No module types',
    noAccountSubscription: 'No account subscription found for',
    messageRecordsConfig: 'Custom Record numbers to be between 100K and 200K',
    messageCountDateResetPassword: 'days to reset password',
    messageDashboardType: 'Dashboard type cannot duplicate!',
    usageMappingNoteStep1:
      'You should configure your custom records and custom attributes for your custom records prior to the Usage File Mapping.',
    usageMappingNoteStep2:
      'A Header Source File record, a Detail Source File record and a Trailer Source File record is required.',
    usageMappingNoteStep3:
      'The layout allows for multiple sub-records under a detail record, including one or more custom records.',
    usageMappingNoteStep4:
      'You can specify a file level delimiter, or a different record level delimiter used in the source file.',
    usageMappingNoteStep5:
      'For auditability reasons, if you have multiple sub-records under a Detail record, then the trailer should ideally have the number of Records and number of rows to reconcile.',
    usageMappingNoteStep6: 'Make sure you finish mapping all records before being functionally ready.',
    processingStreamsNoteStep1:
      'Determine what all type of usage rating you would need to support. The options available are:',
    processingStreamsNoteStep1a: '▪ PRERATED (rated externally and loaded in Embrix)',
    processingStreamsNoteStep1b: '▪ PREPAID (pre-pay rating in Embrix)',
    processingStreamsNoteStep1c: '▪ POSTPAID (post-pay rating in Embrix)',
    processingStreamsNoteStep2: 'For each rating type, configure your streams and modules for each stream.',
    processingStreamsNoteStep3: 'Pre-processing Stream should be the first Stream for any rating type.',
    processingStreamsNoteStep4: 'Post Processing Stream should be the last Stream for any rating type.',
    processingStreamsNoteStep5:
      'You have the option to either delete a configuration for a specific rating type or a stream in a specific rating or a module in a specific rating type. Alternatively, you can toggle them as Active=False (e.g., if you only needed postpaid usage rating, you can keep or remove the default configuration for prepaid and pre-rated rating type.',
    processingStreamsNoteStep6:
      'Other Streams can be configured/modified per requirement. For example, if you do not have revenue-sharing, you could remove the revenue sharing stream or mark it as inactive.',
    processingStreamsNoteStep7:
      'For each stream you have the flexibility to edit/modify the sequence of modules in that stream.',
    processingStreamsNoteStep8:
      'Similarly you have the flexibility to edit/modify the sequence of streams for any specific rating type.',
    processingStreamsNoteStep9:
      'Our Pre-processing and post-processing module streams require the provided set of modules to be there. Module in a stream can be toggled as Active or Inactive (instead of deleting the module).',
    processingStreamsNoteStep10:
      'Removing a module, stream or rating type Vs. marking them as Inactive does not have much difference from a scalability standpoint.',
    processingStreamsNoteStep11: 'Future roadmap would allow to add custom streams and custom modules.',
    validateEmail: 'Invalid email address.',
    validateInvoiceNumber: 'Invoice number can not be null.',
    manualBillingStep1: 'The Bill Start date and Bill End date if not provided will default to current date.',
    manualBillingStep2:
      'You can provide the transaction date as the common date for all the transactions. You can skip providing the start/end dates at each line level.',
    manualBillingStep3:
      'The description defaults to the description from the item selected. You can choose the custom description for each line.',
    manualBillingStep4:
      'The auto calculation at the line level is for your ease. You can choose to enter the Net Amount.',
    manualBillingStep5: 'The Item id and Billable Service Name are mutually exclusive.',
    messageUpDownOrder: 'Upgrade/Downgrade order is not supported for Ala-carte orders',
    messageNoUpgrade: 'No upgrade options configured',
    messageNoDowngrade: 'No downgrade options configured',
    messageTrialOrder: 'Please choose Package, Bundle or Price Offers',
    message2Selection: 'At-least 2 selections required!',
    message1Selection: 'At-least 1 selections required!',
    messageProcess: 'Are you sure you want to process all files?',
    noteThreshold1:
      'Configure the Minimum and Maximum Threshold for a specific AR Operations, AR operations level, Reason Code and Currency.',
    noteThreshold2:
      'Alternatively, set the thresholds as generic. When a attribute value is not provided, that setup applies to all possible values for that attribute.',
    noteThreshold3:
      'In terms of application, it will look for the exact match entry to determine the thresholds. If not found it will look for the absence (implies ALL Values).',
    noteThreshold4:
      'Minimum Threshold will be used to determine whether a Credit/Debit note should be generated if the values <= Minimum Threshold.',
    noteThreshold5:
      'Maximum Threshold would limit the user from performing the AR Operation for a value > Maximum Threshold value configured.',
    messageDuplicateAttribute: 'Flex Attribute can not duplicate!',
    messageLocation: 'Location split for 100% is not complete',
    messageAccountExist: 'Account Id already exists',
    mergeCompleted: 'All merge options completed?',
    dragDropTitle: 'Drag & Drop your files or <span class="filepond--label-action">Browse</span>',
    messageGroupIdExist: 'Group Id already exists',
    messageErrorSpaceOperator: 'Please add spaces after and before the characters: "(", ")"',
    messageErrorSpaceOperator2: 'Please add spaces after the character "("',
    messageErrorSpaceOperator3: 'Please add spaces before the character ")"',
    userTypeCannotBeEmpty: 'User type cannot be empty',
    userPasswordCannotBeEmpty: 'User password cannot be empty',
    userIdCannotBeEmpty: 'User id cannot be empty',
    messagePendingOrder: 'Order has Equipments, Project approval is pending',
    messageApprovalPendingOrder: 'Project approval pending',
    messageUndoProcessSplit: 'Is the order in provisioning Cancelled',
    quickNotesBillableService: `<div class="note-content">
    <div class="title-order-note">Recurring charges: (for Renta Services)</div>
    <div class="pl-3">
      <b>&#9679; &nbsp;&nbsp; Recurring Type (Forward / Arrears) - </b>
      If Forward, customer will see the transaction on 1st of month. if Arrears, the customer will see the transaction
      on the cutoff DOM. The transaction will be billed on Cutoff DOM.
    </div>
    <div class="pl-3">
      <b>&#9679; &nbsp;&nbsp; Calendar Month (TRUE/FALSE) - </b>
      The default value is TRUE. When Calendar Month is TRUE, Embrix will charge the transaction for the current month.
    </div>
    <div class="pl-3">
      <b>&#9679; &nbsp;&nbsp; Cutoff DOM - </b>
      Day-Of-Month, when the charge will be billed.
    </div>
    <div class="pl-3">
      <b>&#9679; &nbsp;&nbsp; In advance flag: (TRUE/FALSE) - </b>
      If true, the customer bill be billed on the Cutoff DOM for the next month.
    </div>
    <div class="pl-3">
      <b>&#9679; &nbsp;&nbsp; Recurring Frequency and Recurring Unit : </b>
      ‘n’ Months/Quarter/Year.
    </div>
    <div class="pl-3">
      <b>&#9679; &nbsp;&nbsp; Part month waive : (TRUE/FALSE) </b>
      If True, the 1st part month charge will be waived.
    </div>
    <div class="title-order-note mt-3">Invoice Exhibits (Venta)</div>
    <div class="pl-3">
      If invoice exhibits attribute is set to a value > 1, then the Venta Service is treated in Embrix like Renta.
    </div>
    <div class="title-order-note mt-3">Examples :</div>
    <div class="pl-3">
      <b>1. &nbsp;&nbsp;</b>
      Calendar Month true, cutoff = 5 and Recurring Type = Forward -> billed on 5th Nov for 1st Nov to 30th Nov.
    </div>
    <div class="pl-3">
      <b>2. &nbsp;&nbsp;</b>
      Calendar Month false, cutoff = 5 and Recurring Type = Forward -> billed on 5th Nov for 5th Nov to 4th Dec.
    </div>
    <div class="pl-3">
      <b>3. &nbsp;&nbsp;</b>
      Calendar Month true, cutoff = 25 and Recurring Type = Arrears -> billed on 25th Nov for 1st Nov to 30th Nov.
    </div>
    <div class="pl-3">
      <b>4. &nbsp;&nbsp;</b>
      Calendar Month false, cutoff = 25 and Recurring Type = Arrears -> billed on 25th Nov for 25th Oct to 24th Nov.
    </div>
    <div class="pl-3">
      <b>5. &nbsp;&nbsp;</b>
      Calendar Month true/false, cutoff = 17, Recurring type = Forward, In Advance = true -> billed on 17th Oct for 1st
      Nov to 30th Nov.
    </div>
  </div>`,
    quickNotesSplitOrder: `<div class="note-content pl-3 pr-3">
  <div>
    <b>1. &nbsp; Before you Split a NEW Opportunity, </b>
    Go to Customer Hub -> Customer Management -> Select the Client account based on the ‘Organization’ and update the
    Client account and Account Data (in the Navigation Pane) for the following data elements:
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Add Contacts
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Add Address
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Change Payment Profile ‘Payment Terms’
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Set Custom Attributes
  </div>
  <div class="mt-3">
    <b>2. &nbsp; </b>
    When the Client account is completely set and you perform a ‘Process Split’ by creating or adding new Billing
    Accounts.
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    The Client data is automatically inherited from the Client to the Billing Accounts.
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    This would avoid having to go back and change it later.
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Alternatively, User has the option to update/reset/override the data at the billing account level as well if
    required.
  </div>
  <div class="mt-3">
    <b>3. &nbsp; To perform a ‘Process Split’ for a NEW opportunity.</b>
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Update the Client Account first to inherit the data elements from Client to Billing accounts first.
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Move the Slider “Process Split” at the top to YES.
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Set the counter for the ‘Number of Billing Accounts’ you want to Create.
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Press the ‘CREATE’ button -> This will create the new billing accounts.
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Perform the Process Split. The new Billing accounts will be available to associate with the services.
  </div>
  <div class="mt-3">
    <b>4. &nbsp; To perform a ‘Process Split’ for any subsequent opportunity. </b>
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Move the Slider “Process Split” at the top to YES.
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    View the existing Billing accounts in the Drop Down.
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Move the Slider “Add Additional Billing Accounts” to YES, if you want to associate the new services with new
    billing accounts.
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Set the counter for the ‘Number of Billing Accounts’ you want to Create. If you set the counter to 1, it is going
    to create 1 more billing account for the Client.
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Press the ‘CREATE’ button -> This will create the new billing accounts.
  </div>
  <div class="pl-5">
    <b>&#9679; &nbsp;&nbsp; </b>
    Perform the Process Split. The new Billing accounts will be available to associate with the services.
  </div>
  <div class="mt-3">
    <b>5. </b>
    &nbsp; User can do a
    <b> ‘Save Split’ </b>
    to save the incomplete Split Request and then come back to complete it later.
  </div>
</div>`,
    errorTotalAmountSplit: 'The total amount from each account must be equal to the total amount in billable service.',
    modifyBillingProfile:
      'Modified Billing DOM  or Frequency changes will be effective from next billing cycle. Are you want to continue?',
    quickNotesConfigAllPages: `  <div class="note-content">
      <b>
        There may be related tasks that need to be performed. Please ensure that you perform the tasks to manage the
        dependencies:
      </b>
      <div class="pl-3">
        <b>1. Reason Codes: </b>
        Make sure that you have configured all your custom Reason Codes. If not, click
        <a
          href=${`${window.location.origin}${RouterNames.arOperationsConfigReasonCode.path}`}
          target="_blank"
          class="text-link"
        >
          here
        </a>
      </div>
      <div class="pl-3">
        <b>2. Users: </b>
        Make sure that you have added all your Users. If not, click
        <a
          href=${`${window.location.origin}${RouterNames.userManagementUser.path}`}
          target="_blank"
          class="text-link"
        >
          here
        </a>
      </div>
      <div class="pl-3">
        <b>3. User Groups: </b>
        Make sure that you have created/updated all your User Groups. If not, click
        <a
          href=${`${window.location.origin}${RouterNames.userManagementUserGroups.path}`}
          target="_blank"
          class="text-link"
        >
          here
        </a>
      </div>
      <div class="pl-3">
        <b>4. Group Reasons: </b>
        Make sure that you have configured the Group Reasons for the reason codes. If not, click
        <a
          href=${`${window.location.origin}${RouterNames.arOperationsConfigGetReasonGroup.path}`}
          target="_blank"
          class="text-link"
        >
          here
        </a>
      </div>
      <div class="pl-3">
        <b>5. Approvals: </b>
        Make sure that you have configured the Approval levels for your operations. If not, click
        <a
          href=${`${window.location.origin}${RouterNames.userManagementApproval.path}`}
          target="_blank"
          class="text-link"
        >
          here
        </a>
      </div>
    </div>`,
    messgaeErrorReasonCode: 'Please provide Reason Code or Custom Reason Code',
    messageCopyChangeToAllRecurring:
      'Are you sure? Copy Change to All Recurring will copy this for all Rental Services and all One-time services with Invoice exhibits > 1',
    manualInvoiceMessage: 'You have selected to create an invoice for a different billing account. Are you sure?',
    messageNoteSavedPendingAccountMovement: 'Please input a note before save.',
    messageErrorSplitOrder: 'Billing account is not selected for all Billable Services',
    messageValidTotalAmount: 'Total amount must be equals',
    messageValidTotalQuantity: 'Total quantity must be equals',
    messageInvoiceIdAleadyExist: 'Invoice id already exist',
    warningMessageSelectServiceName: 'Is it a manual bill for retrospective frequency change invoices (from past) ?',
    messageCopyChangeToAllRecurringExcludeUsage:
      'Are you sure? Copy Change to All will copy this change for all billable services except cutoffDom = USAGE',
    validUploadMessage: 'The file being uploaded is not a flat file. Accepted file types are .csv and .txt',
    messageReporcess: 'Reprocess at this level will re-process all failed/suspended record for this batch.',
    messageReporcessDetails:
      'Reprocess at this level will re-process only the records failed/suspended with this error code.',
    messageNoteAccountStatement:
      'For CLIENT account, opening and closing balance is always shown as 0 as it is not applicable to CLIENT account.',
    messageValidationUsageCutoffDom:
      'Some Billable Services have cutoff = USAGE so Rental would be billed along with Traffic. Do you want to continue ?',
    messagePaymentGoneSuspense: 'Payment has gone to Suspense!',
    messageValidNewLine: 'Text with New Line characters is not supported by PAC',
    messgaeNotePaymentSuspense: `<div>
    <h6 class="text-center pb-3">
      <b>Payment Suspense Codes</b>
    </h6>
    <div class="pb-2">
      <b>&#9679; ACCOUNT_CLOSED: </b>
      Payment is received for a Closed Account.
    </div>
    <div class="pb-2">
      <b>&#9679; ACCOUNT_NOT_FOUND: </b>
      Reference account does not exist in the Billing Platform.
    </div>
    <div class="pb-2">
      <b>&#9679; INVALID_ACCOUNT: </b>
      Reference account is an invalid account. This may not apply for your deployment.
    </div>
    <div class="pb-2">
      <b>&#9679; INVOICE_NOT_FOUND: </b>
      There are no invoices associated for the referenced account.
    </div>
    <div class="pb-2">
      <b>&#9679; NOT_A_BILLING_ACCOUNT: </b>
      Account is not a Billing account. This may not apply for your deployment.
    </div>
    <div class="pb-2">
      <b>&#9679; OVER_PAYMENT: </b>
      Payment received is an over payment. If the policy exists to auto allocate the NET DEBT of the account, then the
      payment will be allocated. Otherwise, remains in suspense.
    </div>
    <div class="pb-2">
      <b>&#9679; PREDATES_ACCOUNT: </b>
      Payment received is dated before the effective date of the account.
    </div>
    <div class="pb-2">
      <b>&#9679; PREDATES_INVOICE: </b>
      Payment received is before the invoice date.
    </div>
    <div class="pb-2">
      <b>&#9679; STATEMENT_RECORD: </b>
      Statement record received in a bank file that need not be processed.
    </div>
    <div class="pb-2">
      <b>&#9679; UNDER_PAYMENT: </b>
      Payment is an under payment. If the policy is not allocate under payment automatically, it will be moved to
      suspense.
    </div>
  </div>`,
    messgaeNoteReprocessing: `<div>
  <h6 class="text-center pb-3">
    <b>Usage Suspense Codes</b>
  </h6>
  <div class="pb-2">
    <b>&#9679; NO_ACTIVE_SERVICE_FOR_THE_PROVISIONING_ID: </b>
    The provisioning id in the CDR is not associated with any Active Service. It is likely that the cancellation date of the provisioning id is not aligned or the provisioning id was cancelled from some other account, but not re-assigned to any billing account. Re-check the cancellation date of the provisioning id or ensure that the provisioning id is associated with the right billing account before re-processing suspended CDR’s with this error code.
  </div>
  <div class="pb-2">
    <b>&#9679; NO_SERVICE_FOR_THE_PROVISIONING_ID: </b>
    The provisioning id in the CDR is not found in the billing platform. Either the provisioning system has not assigned the ID to a service or the order services do not qualify for the usage. Ensure that the order and the provisioning ID is enabled before re-processing CDR’s suspended with this error code.
  </div>
  <div class="pb-2">
    <b>&#9679; NO_MATCHING_PRICING_FOR_USAGE_RECORD: </b>
    There is no pricing found for the Usage type associated with the CDR. Please make sure that the ordering system provides the appropriate price for the usage type. Ensure the pricing is set before re-processing the CDR’s with this suspense code.
  </div>
  <div class="pb-2">
    <b>&#9679; NO_MATCHING_OPERATOR_PRICING_FOR_USAGE_RECORD: </b>
    The CDR record is associated with the operator, but no operator pricing is found. Ensure that operator pricing is enabled before re-processing the CDR’s with this suspense code.
  </div>
  <div class="pb-2">
    <b>&#9679; NO_MATCHING_BILLABLE_SVC_PRICING_FOR_USAGE_RECORD: </b>
    The Billable Service is missing the price for the CDR. Please make sure that the ordering system provides the appropriate price for the usage type. Ensure the pricing is set from the ordering system, before re-processing the CDR’s with this suspense code.
  </div>
</div>`,
    messageCloseSuspense: 'Are you sure you want to close the Payment Suspense ?',
    messgaeNoteInvoiceAction: `<div>
    <div class="pb-2">
      <b>1. RESEND_TO_VENDOR: </b>
      This allows to regenerate a print vendor Extract and send it to the Print Vendor. This action is allowed only when folio-status are "STAMPING_REQUESTED, CANCELLATION_STAMPING_REQUESTED" and invoice total > 0.
    </div>
    <div class="pb-2">
      <b>2. REGENERATE_INVOICE:</b>
      This allows to regenerate the invoice. This action is allowed only when status is not "PENDING_APPROVAL" and invoice total > 0.
    </div>
    <div class="pb-2">
      <b>3. APPROVE: </b>
      This is for invoice approval. This action is allowed only when status is "PENDING_APPROVAL" and invoice total > 0.
    </div>
    <div class="pb-2">
      <b>4. DISAPPROVE </b>
      This is for invoice disapproval. This action is allowed only when status is "PENDING_APPROVAL" and invoice total > 0.
    </div>
    <div class="pb-2">
      <b>5. EMAIL_INVOICE </b>
      This allows to email the invoice. This action is allowed only when status is not "PENDING_APPROVAL" and invoice total > 0.
    </div>
    <div class="pb-2">
      <b>6. UNDO_MANUAL_INVOICE </b>
      This is for undo manual invoice. This action is allowed only when status are not "PENDING_APPROVAL, CLOSED, COLLECTION, SENT", invoice total > 0 and bill type is MANUAL_BILL.
    </div>
    <div class="pb-2">
      <b>7. RESYNC_INVOICE </b>
      This is to resync invoice. This action is allowed only when status is "SYNC_FAILED".
    </div>
    <div class="pb-2">
      <b>8. EXTEND_DUE_DATE </b>
      This is to extend the invoice due date. This action is allowed only when due > 0.
    </div>
    <div class="pb-2">
      <b>9. UNDO_INVOICE_CANCEL </b>
      This is to undo invoice cancel. This action is allowed only when folio-status is CANCELLATION_STAMPING_REQUESTED and invoice total > 0.
    </div>
  </div>`,
    messgaeNoteCreditNoteAction: `<div>
  <div class="pb-2">
    <b>1. APPROVE: </b>
    This is for invoice approval. This action is allowed only when status is "PENDING_APPROVAL".
  </div>
  <div class="pb-2">
    <b>2. DISAPPROVE: </b>
    This is for invoice disapproval. This action is allowed only when status is "PENDING_APPROVAL".
  </div>
  <div class="pb-2">
    <b>3. RESEND_TO_VENDOR: </b>
    This allows to regenerate a print vendor Extract and send it to the Print Vendor. This action is allowed only when folio-status are "STAMPING_REQUESTED, CANCELLATION_STAMPING_REQUESTED".
  </div>
</div>`,
    messgaeNotePaymentAction: `<div>
    <div class="pb-2">
      <b>1. RESEND_TO_VENDOR: </b>
      This allows to regenerate a print vendor Extract and send it to the Print Vendor. This action is allowed only when folio-status are "STAMPING_REQUESTED, CANCELLATION_STAMPING_REQUESTED".
    </div>
    <div class="pb-2">
      <b>2. ALLOCATE_PAYMENT: </b>
      This is to auto allocate the payment. This action is allowed only when status are "OPEN, REVERSED" and Remaining Amount > 0 and all of rows selected must be same in Payment Date, Currency, Remaining Amount.
    </div>
    <div class="pb-2">
      <b>3. AUTO_ALLOCATE :</b>
      This is to auto allocate the payment. This action is allowed only when status are "OPEN, REVERSED" and Remaining Amount > 0.
    </div>
    <div class="pb-2">
      <b>3. REVERSE_PAYMENT: </b>
      This is to reverse the payment. This action is allowed only when folio-status are "STAMPING_REQUESTED, CANCELLATION_STAMPING_REQUESTED", status are not "REVERSED, REVERSED_TO_SUSPENSE" and the total value of Remaining Amount and Amount must not equal 0.
    </div>
    <div class="pb-2">
      <b>3. EMAIL_FOLIO: </b>
      This allows to email the FOLIO. This action is allowed only when folio-status is "STAMPED" and the payment has pdf file to send.
    </div>
</div>`,
    quickNotesReprocess:
      'Reprocess All will reprocess all suspended/failed records. If error code is selected, then all records with that error code will be re-processed, else all records in suspended/failed status will be re-processed (irrespective of the error code).',
    messageChooseADay: 'You must choose a day of the week!',
    messageRemoveLinePurchaseAlaCarte: 'Do you want to cancel all lines under this service?',
    messageNotesUsageTool: `<div>
    <div class="pb-2">
      <b class="mr-2">1. </b>
      Provisioning Id is the identifier of the service to be rated.
    </div>
    <div class="pb-2">
      <b class="mr-2">2. </b>
      For VOICE or TELEPHONY service start date is mandatory along with either end date or quantity (one of these
      parameters is a must). Start and end date should always be in the past compared to current system date.
    </div>
    <div class="pb-2">
      <b class="mr-2">3. </b>
      Unit is the scale for the quantity entered. It is used if end date is not specified to determine the duration to
      be added to start date (for VOICE and TELEPHONY service). For other services it is only informative.
    </div>
    <div class="pb-2">
      <b class="mr-2">4. </b>
      Upload volume and Download volume is only for DATA service.
    </div>
  </div>`,
    dashboardNoConfigMessage:
      'Dashboard config is not setup for the User. Please setup the config to view the dashboard in My Dashboard',
    msgChoosePackageBundlePO: 'Please choose Package, Bundle or Price Offers',
    contentSelectAccountInQuote:
      'Account id is not given for the quote. Do you want to select an existing account or create a new account to convert this quote to order?',
    associateAllOtherQuotes: 'Do you want to associate all other pending new quotes to this account ?',
    oldPassNotMatch: 'Old password does not match',
    confirmPasswordIncorrectly: 'Confirm password incorrectly',
    oldPassNotSame: 'Old password and new password cannot be the same',
    noTypeDashboard: 'No unique type dashboard remaining',
    useStartEndRange: 'Use the Starting and Ending Number for Select-Range',
    orderTypesAlreadyConfigured: 'All order types are already configured',
    noteBillingProfile:
      'Change in Billing Dom, Billing Frequency or Duration will be effective from next billing cycle',
    messageMultiSubsription: 'Multi Subscription disabled. Enable the property first, to add multiple subscriptions.',
    subscriptionNameNotDuplicate: 'The Subscription Name can not duplicate.',
    noTopupMessage: 'There are no PREPAID subscriptions under the account to topup',
    percentageThresholdInfo: 'Enter “|” separated values. e.g. 80|90|100 or just 100.',
    messageQuickTaxes:
      'The sequence of the rows is important. If you change the sequence of how taxes should apply, then ensure that the expression does not have the name from a row that will apply later in the sequence',
    messageNotesTaxType: `The list of names is from Tax Type Config (codes). Configure
      <a
      href=${`${window.location.origin}${RouterNames.taxTypeConfig.path}`}
      target="_self"
      class="text-link"
    >
      Tax Types
    </a> before configuring tax codes`,
    messageNotesTaxType1: 'The list of names is from Tax Type Config (codes). Configure',
    messageNotesTaxType2: 'Tax Types',
    messageNotesTaxType3: 'before configuring tax codes',
    receiverNote:
      'CUSTOMER – The end customer receives the correspondence USER – The system admin receives the correspondence',
    isPartialFulfillmentAllowedNote:
      'In case of multiservice order, each service instantiated in the system as and when the service provisioning is complete. By default, all services are instantiated at the end.',
    billingOnlyNote: 'The order requires no provisioning and only billing flow is executed.',
    provisioningOnlyNote: 'The order requires no billing and only provisioning flow is executed.',
    billOrderChargesNote: 'The charges applied for this order is billed immediately.',
    onErrorResubmitNote:
      'During order processing, if there is an error in this step and if this flag is enabled, then Embrix will stop further processing of the order. Upon resubmission, the order will resume processing from this step onwards.',
    executeOnErrorNote:
      "In case there is error in an earlier step and you require Embrix to continue processing the sequence but skip certain steps, then those steps should be marked with this flag. This is mutually exclusive to ‘On Error Resubmit'.",
    errorApiNameNote:
      'In case there is error with this step and you require Embrix to continue processing the sequence but jump to a specific step, you can select the API to jump to. Embrix will automatically add the API as the last step for you to manage. This is mutually exclusive to the other error options.',
    smsTemplateNote: `<span>This place holders in the message to be replaced with actual values at runtime should be prefixed and suffixed with --
    </br> e.g.: Hello --firstName-- , this is a welcome message from Embrix. Your account no is --accountId--. Hope you enjoy our service.</span>`,
    identityNote: 'Customer Identification number/value.',
    identityDocumentNote: 'Document proof, like Passport , License etc.',
    poDescription:
      'Value in Description field, if mentioned, will be used to set the transaction description for the rated transaction.',
    useAccumulatorBalanceInfo: 'Enabled for volume discount only.',
    groupByOrderReportInfo:
      'Select the radio button if you want one row per order. Otherwise, unselect the radio button.',
    noOrderSubscription: 'There are no completed orders and/or active dummy subscriptions.',
    accountNotHaveProvisioning:
      'Account does not have any Provisioning data associated. Please select the correct Account or check the provisioning data.',
    selectUserCollectionsInfo:
      'In order to add user as a collection agent, make sure that the user contact information is filled in. If not, the User will not be allowed to be added as a Collection Agent',
    exceptionDateInfo: 'The listed action will not be executed during the start and end time period.',
    percentageThresholdInfoExclusive:
      'Enter “|” separated values. e.g. 80|90|100 or just 100. The values must be between 0 and 100. This is mutually exclusive to the Value Threshold field.',
    valueThresholdInfoExclusive:
      'Enter “|” separated values. e.g. 80|90|100 or just 100. The values can be any numeric values. This is mutually exclusive to the Percentage Threshold field.',
    useNameAsInvoiceDescriptionTooltip:
      'If the flag is set, then the name of the price offer will be used as transaction description and in invoice presentment. Otherwise, the name of the item will be used (default).',
    processJobMessage: 'Are you sure to process/re-process job schedule?',
    waitTimeInfo:
      'Specify the Wait time in seconds. This is the time that Embrix will wait before executing the next step in the sequence.',
    oneManualProvisioningOnlyInfo:
      'In case any step fails and there is a requirement to make an outgoing call and we expect an incoming API call with some manual provisioning data provided, then the caller has to pass the “ManualProvisioning’ flag in the input request payload. That flag is an indicator for Embrix to only execute from the manual provisioning step.',
    retryCount:
      'Specify the retry count for Embrix in case this step has an error. Embrix will perform the specified number of retries before following the error sequence.',
    restartAPIonError:
      'In case this step fails and there is a requirement to make an outgoing call and we expect an incoming API call following that, then that incoming API call must include an attribute ‘restartProvisioning’ flag in the input request payment. This flag is an indicator for Embrix to restart the sequence from the API listed with this attribute.',
    errorStatusInfo: 'You can specify a specific value that you want Embrix to set and pass with the outgoing API call',
    rateWhenInactiveInfoPO:
      'When selected, the price unit instance of this price offer will be considered for rating even if the price unit instance status is INACTIVE, during billing.',
    rateWhenInactiveInfoDO:
      'When selected, the discount unit instance of this discount offer will be considered for rating even if the discount unit instance status is INACTIVE, during billing.',
    messageModifyJobSchedule: 'Are you sure you want to modify the job schedule?',
    skipAuthorizationInfo:
      'Skip Authorization when checked, indicates not to set authorization attributes when invoking the API',
    minimumThresholdTriggerInfo:
      'If the Minimum Threshold Trigger is true, the minimum threshold is used as a threshold trigger. The tax will not apply until the minimum threshold is it. But, once the minimum threshold is hit, it applies the tax to the entire amount till now. Example: If minimum threshold is 100 and flag is true and if the usage crosses the threshold to 110, then the entire 110 will be taxed. Otherwise, the flag is considered as a Minimum Limit which means that with the same example, the tax will only apply to quantity 10.',
    exemptOnTaxAmountInfo:
      'If the flag is false, the tax exemption applies to the taxable amount. The taxable amount will be reduced by the percentage and the tax will be calculated on the derived amount. If the flag is true, the tax exemption applies on the tax amount itself.',
    taxPercentOffsetInfo:
      'If the percent offset value is used instead of the percent field then the offset percentage applies to the tax rate. For example if IVA tax rate is 13%, and the offset is set as 9%, then 4% IVA will be applied. The user will have to be careful to not set the value as greater than the tax rate. Otherwise the applied tax would be 0.',
    skipGatewayAuthorizationApisInfo: 'API names need to be separated by comma.',
    evaluationObjectTypeInfo:
      'Use Evaluation Data if the sequence has to be executed conditionally based on certain account or provisioning data attribute values.',
    evaluationValueInfo:
      'All attribute value selected will be evaluated to be of exact match for the sequence to be executed.',
    glAccountPatternInfo:
      'String pattern to search the list of GL account. Only use percentage ‘%’ e.g. 4-03-%-%-000 or 2-02-%',
  },

  apiMessage: {
    // paymentAllocated: 'Payment allocated successfully!',
    createUserSuccessfully: 'Create User successfully!',
    modifyUserSuccessfully: 'Modify User successfully!',
    modifyUserFailed: 'Modify User failed',
    checkAuthorization: 'Check authorization failed!',
    sentEmailSuccessfully: 'Sent Email successfully!',
    createFileSuccessfully: 'Create File successfully!',
    modifyServiceLocationsSuccessfully: 'Modify Service Locations successfully!',
    modifyServiceSuccessfully: 'Modify Service successfully!',
    paymentReversedSuccessfully: 'Payment reversed successfully!',
    paymentAllocatedSuccessfully: 'Payment allocated successfully!',
    modifyBillableServiceSuccessfully: 'Modify Billable Service successfully!',
    reGeneratePaymentSuccessfully: 'Re-generate payment successfully!',
    updateProvisioningSuccessfully: 'Update Provisioning successfully!',
    updatePaymentStatusSuccessfully: 'Update payment status successfully!',
    updateArOpsConfigSuccessfully: 'Update ArOps Config successfully!',
    applyAdjustmentSuccessfully: 'Apply Adjustment successfully!',
    applyDisputeSuccessfully: 'Apply Dispute successfully!',
    settleADisputeSuccessfully: 'Settle a Dispute successfully!',
    reverseWriteOffSuccessfully: 'reverse Write-off successfully!',
    applyWriteOffSuccessfully: 'Apply Write off successfully!',
    modifyArOpsItemMappingSuccessfully: 'modify ArOps Item Mapping successfully!',
    modifyReasonCodeSuccessfully: 'modify Reason code successfully!',
    createReasonCodeSuccessfully: 'create Reason code successfully!',
    createGroupsReasonSuccessfully: 'Create Groups Reason successfully!',
    modifyARThresholdsSuccessfully: 'Modify AR thresholds successfully!',
    createARThresholdsSuccessfully: 'Create AR thresholds successfully!',
    modifyCurrencySuccessfully: 'Modify Currency successfully!',
    createCurrencySuccessfully: 'Create Currency successfully!',
    modifyGrantsSuccessfully: 'Modify Grants successfully!',
    createGrantsSuccessfully: 'Create Grants successfully!',
    modifyAccumulatorsSuccessfully: 'Modify Accumulators successfully!',
    createAccumulatorsSuccessfully: 'Create Accumulators successfully!',
    modifyRateUnitSuccessfully: 'Modify Rate Unit successfully!',
    createRateUnitSuccessfully: 'Create Rate Unit successfully!',
    modifyTimeUnitSuccessfully: 'Modify Time Unit successfully!',
    createTimeUnitSuccessfully: 'Create Time Unit successfully!',
    modifyCustomRecordsSuccessfully: 'Modify Custom Records successfully!',
    createCustomRecordsSuccessfully: 'Create Custom Records successfully!',
    modifyFileMappingSuccessfully: 'Modify File Mapping successfully!',
    createFileMappingSuccessfully: 'Create File Mapping successfully!',
    modifyProcessingStreamsSuccessfully: 'Modify Processing Streams successfully!',
    createProcessingStreamsSuccessfully: 'Create Processing Streams successfully!',
    modifyRateUnitMapSuccessfully: 'Modify Rate Unit Map successfully!',
    createRateUnitMapSuccessfully: 'Create Rate Unit Map successfully!',
    modifyTaxCodeSuccessfully: 'Modify Tax Code successfully!',
    createTaxCodeSuccessfully: 'Create Tax Code successfully!',
    modifyRegionMapSuccessfully: 'Modify Region Map successfully!',
    createRegionMapSuccessfully: 'Create Region Map successfully!',
    modifyZeroRateUsageSuccessfully: 'Modify Zero Rate Usage successfully!',
    createZeroRateUsageSuccessfully: 'Create Zero Rate Usage successfully!',
    modifyOperatorMapSuccessfully: 'Modify Operator Map successfully!',
    createOperatorMapSuccessfully: 'Create Operator Map successfully!',
    modifyTrunkRuleSuccessfully: 'Modify Trunk Rule successfully!',
    createTrunkRuleSuccessfully: 'Create Trunk Rule successfully!',
    modifyUnitOfMeasureSuccessfully: 'Modify Unit Of Measure successfully!',
    createUnitOfMeasureSuccessfully: 'Create Unit Of Measure successfully!',
    modifyUsageTypeSuccessfully: 'Modify Usage Type successfully!',
    createUsageTypeSuccessfully: 'Create Usage Type successfully!',
    modifyVoicePlanSuccessfully: 'Modify Voice Plan successfully!',
    createVoicePlanSuccessfully: 'Create Voice Plan successfully!',
    modifyCountryCodeSuccessfully: 'Modify Country Code successfully!',
    createCountryCodeSuccessfully: 'Create Country Code successfully!',
    modifyDelayedBillingSuccessfully: 'Modify Delayed Billing successfully!',
    createDelayedBillingSuccessfully: 'Create Delayed Billing successfully!',
    modifyAdvanceBillingSuccessfully: 'Modify Advance Billing successfully!',
    createAdvanceBillingSuccessfully: 'Create Advance Billing successfully!',
    modifyPendingBillingTriggerSuccessfully: 'Modify Pending Billing Trigger successfully!',
    createPendingBillingTriggerSuccessfully: 'Create Pending Billing Trigger successfully!',
    modifyBundleSuccessfully: 'Modify Bundle successfully!',
    createBundleSuccessfully: 'Create Bundle successfully!',
    updateBundleStatusSuccessfully: 'Update Bundle Status successfully!',
    modifyPackageSuccessfully: 'Modify Package successfully!',
    createPackageSuccessfully: 'Create Package successfully!',
    updatePackageStatusSuccessfully: 'Update Package Status successfully!',
    modifyDependencySuccessfully: 'Modify Dependency successfully!',
    createDependencySuccessfully: 'Create Dependency successfully!',
    modifyCollectionActionSuccessfully: 'Modify Collection Action successfully!',
    createCollectionActionSuccessfully: 'Create Collection Action successfully!',
    modifyCollectionAgentSuccessfully: 'Modify Collection Agent successfully!',
    createCollectionAgentSuccessfully: 'Create Collection Agent successfully!',
    modifyCollectionScheduleSuccessfully: 'Modify Collection Schedule successfully!',
    createCollectionScheduleSuccessfully: 'Create Collection Schedule successfully!',
    reassignCollectionAgentSuccessfully: 'Reassign Collection Agent successfully!',
    modifyCollectionUnitSuccessfully: 'Modify Collection Unit successfully!',
    createCorrespondenceTemplateSuccessfully: 'Create Correspondence Template successfully!',
    modifyCorrespondenceTemplateSuccessfully: 'Modify Correspondence Template successfully!',
    createAccountSuccessfully: 'Create Account successfully!',
    modifyAccountSuccessfully: 'Modify Account successfully!',
    updateAccountStatusSuccessfully: 'Update Account Status successfully',
    createCustomAttributesSuccessfully: 'Create Custom Attributes successfully!',
    modifyCustomAttributesSuccessfully: 'Modify Custom Attributes successfully!',
    createCreditProfileSuccessfully: 'Create Credit Profile successfully!',
    modifyCreditProfileSuccessfully: 'Modify Credit Profile successfully!',
    manualInvoiceSuccessfully: 'Manual Invoice successfully!',
    runPendingBillSuccessfully: 'Run Pending Bill successfully!',
    sendInoivceSuccessfully: 'Send inoivce successfully!',
    regenerateInoivceSuccessfully: 'regenerate inoivce successfully!',
    createExternalPOSuccessfully: 'Create external PO successfully!',
    modifyExternalPOSuccessfully: 'Modify external PO successfully!',
    modifyInvoiceStatusSuccessfully: 'Modify Invoice Status successfully!',
    undoManualInvoiceSuccessfully: 'Undo manual Invoice successfully!',
    createSpecialPriceSuccessfully: 'Create Special Price successfully!',
    modifySpecialPriceSuccessfully: 'Modify Special Price successfully!',
    updateOrderStatusSuccessfully: 'Update Order Status successfully!',
    updateNotesStatusSuccessfully: 'Update Notes Status successfully!',
    updateBillingAccountSuccessfully: 'Update Billing Account successfully!',
    modifyInvoiceSuccessfully: 'Modify Invoice successfully!',
    createxChangeRatesSuccessfully: 'Create xChange Rates successfully!',
    modifyxChangeRatesSuccessfully: 'Modify xChange Rates successfully!',
    invoicedAccountSuccessfully: 'Invoiced Account successfully!',
    createPaymentInstallmentSuccessfully: 'Create Payment Installment successfully!',
    modifyPaymentInstallmentSuccessfully: 'Modify Payment Installment successfully!',
    invoicedVendoSuccessfully: 'Invoiced Vendo successfully!',
    undoInvoiceCancelSuccessfully: 'Undo Invoice Cancel successfully!',
    processInvoiceSuccessfully: 'Process Invoice successfully!',
    modifyDashboardConfigSuccessfully: 'Modify dashboard config successfully!',
    createDashboardConfigSuccessfully: 'Create dashboard config successfully!',
    modifyJobConfigSuccessfully: 'Modify Job config successfully!',
    createJobConfigSuccessfully: 'Create Job config successfully!',
    modifyJobScheduleSuccessfully: 'Modify Job Schedule successfully!',
    processJobScheduleSucessfully: 'Process job schedule sucessfully!',
    retryJobScheduleSucessfully: 'Retry job schedule sucessfully!',
    reCreateJobScheduleSucessfully: 'Re-create job schedule sucessfully!',
    usernamePasswordNotCorrect: 'Username or password is not correct',
    createOrderSuccessfully: 'Create Order successfully!',
    modifyOrderSuccessfully: 'Modify Order successfully!',
    createOrderSequenceSuccessfully: 'Create Order Sequence successfully!',
    modifyOrderSequenceSuccessfully: 'Modify Order Sequence successfully!',
    createProvisioningTaskSequenceSuccessfully: 'Create Provisioning Task Sequence successfully!',
    modifyProvisioningTaskSequenceSuccessfully: 'Modify Provisioning Task Sequence successfully!',
    createProvisioningMappingSuccessfully: 'Create Provisioning Mapping successfully!',
    modifyProvisioningMappingSuccessfully: 'Modify Provisioning Mapping successfully!',
    createBillingAccountSuccessfully: 'Create Billing Account successfully!',
    splitOrderAccountSuccessfully: 'Split Order Account successfully!',
    createOrderReasonCodesSuccessfully: 'Create Order Reason Codes successfully!',
    modifyOrderReasonCodesSuccessfully: 'Modify Order Reason Codes successfully!',
    createPaymentConfigSuccessfully: 'Create Payment Config successfully!',
    modifyPaymentConfigSuccessfully: 'Modify Payment Config successfully!',
    applyPaymentSuspenseSuccessfully: 'Apply payment suspense successfully!',
    paymentHasGoneToSuspense: 'Payment has gone to Suspense',
    paymentIsSuccessful: 'Payment is successfully!',
    paymentAllocatedSuccessful: 'Payment allocated successfully!',
    modifyPaymentSuspenseSuccessfully: 'Modify Payment Suspense successfully!',
    modifyPaymentSurchargeSuccessfully: 'Modify Payment Surcharge successfully!',
    createPaymentSurchargeSuccessfully: 'Create Payment Surcharge successfully!',
    processBatchPaymentSuccessfully: 'Process Batch Payment successfully!',
    modifyBatchPaymentSuccessfully: 'Modify Batch Payment successfully!',
    createBatchPaymentSuccessfully: 'Create Batch Payment successfully!',
    modifyItemMapSuccessfully: 'Modify Item Map successfully!',
    createItemMapSuccessfully: 'Create Item Map successfully!',
    modifyItemSuccessfully: 'Modify Item successfully!',
    createItemSuccessfully: 'Create Item successfully!',
    updateItemStatusSuccessfully: "Update Item's Status successfully!",
    modifyPriceOfferSuccessfully: 'Modify Price Offer successfully!',
    createPriceOfferSuccessfully: 'Create Price Offer successfully!',
    updatePriceOfferStatusSuccessfully: "Update Price Offer's Status successfully!",
    modifyDiscountOfferSuccessfully: 'Modify Discount Offer successfully!',
    createDiscountOfferSuccessfully: 'Create Discount Offer successfully!',
    updateDiscountOfferStatusSuccessfully: "Update Discount Offer's Status successfully!",
    modifyProductFamilySuccessfully: 'Modify Product Family successfully!',
    createProductFamilySuccessfully: 'Create Product Family successfully!',
    modifyItemServiceSuccessfully: 'Modify Item Service successfully!',
    createItemServiceSuccessfully: 'Create Item Service successfully!',
    modifyMilestoneSuccessfully: 'Modify Milestone successfully!',
    createMilestoneSuccessfully: 'Create Milestone successfully!',
    modifyExchangeRateSuccessfully: 'Modify Exchange Rate successfully!',
    createExchangeRateSuccessfully: 'Create Exchange Rate successfully!',
    modifyLocationSuccessfully: 'Modify Location successfully!',
    createLocationSuccessfully: 'Create Location successfully!',
    modifyEnterpriseSuccessfully: 'Modify Enterprise successfully!',
    createEnterpriseSuccessfully: 'Create Enterprise successfully!',
    modifyDivisionSuccessfully: 'Modify Division successfully!',
    createDivisionSuccessfully: 'Create Division successfully!',
    modifyLegalEntitySuccessfully: 'Modify Legal Entity successfully!',
    createLegalEntitySuccessfully: 'Create Legal Entity successfully!',
    modifyBusinessUnitSuccessfully: 'Modify Business Unit successfully!',
    createBusinessUnitSuccessfully: 'Create Business Unit successfully!',
    modifyGLAccountSuccessfully: 'Modify GL Account successfully!',
    createGLAccountSuccessfully: 'Create GL Account successfully!',
    modifyDepartmentSuccessfully: 'Modify Department successfully!',
    createDepartmentSuccessfully: 'Create Department successfully!',
    modifyCostCenterSuccessfully: 'Modify Cost Center successfully!',
    createCostCenterSuccessfully: 'Create Cost Center successfully!',
    modifyCalendarSuccessfully: 'Modify Calendar successfully!',
    createCalendarSuccessfully: 'Create Calendar successfully!',
    modifyChartOfAccountSuccessfully: 'Modify Chart Of Account successfully!',
    createChartOfAccountSuccessfully: 'Create Chart Of Account successfully!',
    modifyAccountingPoliciesSuccessfully: 'Modify Accounting Policies successfully!',
    createAccountingPoliciesSuccessfully: 'Create Accounting Policies successfully!',
    modifyRevenueJournalSuccessfully: 'Modify Revenue Journal successfully!',
    modifyTenantSuccessfully: 'Modify Tenant successfully!',
    createTenantSuccessfully: 'Create Tenant successfully!',
    modifyPropertiesSuccessfully: 'Modify Properties successfully!',
    modifyURLMappingSuccessfully: 'Modify URL Mapping successfully!',
    modifyPaymentGatewaySuccessfully: 'Modify Payment Gateway successfully!',
    createAPIMappingSuccessfully: 'create API Mapping successfully!',
    modifyRequestResponseMappingSuccessfully: 'modify Request/Response Mapping successfully!',
    modifySelfCareConfigSuccessfully: 'Modify Self Care Config successfully!',
    createSelfCareConfigSuccessfully: 'create Self Care Config successfully!',
    modifyIdSequenceConfigSuccessfully: 'Modify Id Sequence Config successfully!',
    createIdSequenceConfigSuccessfully: 'create Id Sequence Config successfully!',
    modifyRoleSuccessfully: 'Modify Role successfully!',
    createRoleSuccessfully: 'Create Role successfully!',
    modifyRoleGroupSuccessfully: 'Modify Role Group successfully!',
    createRoleGroupSuccessfully: 'Create Role Group successfully!',
    modifyUserGroupSuccessfully: 'Modify User Group successfully!',
    createUserGroupSuccessfully: 'Create User Group successfully!',
    modifyApprovalSuccessfully: 'Modify Approval successfully!',
    createApprovalSuccessfully: 'Create Approval successfully!',
    saveCostCenterMapSuccessfully: 'Save Cost Center Map successfully!',
    reconcileDisputeSuccessfully: 'Dispute reconciliation successfully!',
    reconcileInvoiceSuccessfully: 'Invoice reconciliation successfully!',
    saveSplitOpportunity: 'Save Split successfully!',
    undoProcessSplitOrder: 'Undo Process Split successfully!',
    saveAccountNonBillablesSuccessfully: 'save Account Non Billables successfully!',
    moveAccountSuccessfully: 'move Account successfully!',
    createEmployeeSuccessfully: 'Create Employee successfully!',
    modifyClientSuccessfully: 'Modify Client Successfully',
    deletePendingManualInvoiceSuccessfully: 'Delete Pending Manual Invoice successfully!',
    saveManualInvoiceSuccessfully: 'Save Manual Invoice successfully!',
    syncProjectAppSuccessfully: 'sync Project App successfully!',
    createRevenueJournalSuccessfully: 'Create Revenue Journal successfully!',
    recognizeManualRevenueSuccessfully: 'Recognize Manual Revenue successfully!',
    resendEbsExtractSuccessfully: 'Resend Ebs Extract successfully!',
    saveReratingBatchSuccessfully: 'Save Rerating Batch successfully!',
    modifyReratingBatchSuccessfully: 'Modify Rerating Batch successfully!',
    processSavedManualInvoiceSuccessfully: 'Process Saved Manual Invoice successfully!',
    saveAccountMovementDataSuccessfully: 'Save Account Movement Data successfully!',
    deletePendingAccountMovementDataSuccessfully: 'Delete Pending Account Movement Data successfully!',
    modifyConfigUsagePlatformSuccessfully: 'Modify Config Usage Platform successfully!',
    createConfigUsagePlatformSuccessfully: 'Create Config Usage Platform successfully!',
    updateFileDetailRecordSuccessfully: 'Update File Detail Record successfully!',
    initiateUsageReprocessSuccessfully: 'Initiate Usage Reprocess successfully!',
    createTenantScheduleSucessfully: 'Create Tenant Schedule successfully!',
    resetSubsequentJobsFromErrorJobSucessfully: 'Reset Job Schedule successfully!',
    resendPaymentVendorSuccessfully: 'Resend Payment to Vendor successfully!',
    resendNotesVendorSuccessfully: 'Resend Notes to Vendor successfully!',
    deletePaymentFileSuccessfully: 'Delete Payment File successfully!',
    initiateReratingProcessSuccessfully: 'Initiate Usage Rerating successfully!',
    folioSendEmailSuccessfully: 'Folio sent to Email successfully!',
    saveOutputTemplateByTypeSuccessfully: 'Save output template successfully!',
    processSuccessfully: 'Process successfully!',
    createProvisioningAttributesConfigSuccessfully: 'Create Provisioning Attributes Config successfully!',
    modifyProvisioningAttributesConfigSuccessfully: 'Modify Provisioning Attributes Config successfully!',
    createSchedulePatternSuccessfully: 'Create Schedule Pattern successfully!',
    modifySchedulePatternSuccessfully: 'Modify Schedule Pattern successfully!',
    createAndProcessUsageRecordSuccessfully: 'Create And Process Usage Record successfully!',
    createQuoteSuccessfully: 'Create Quote successfully!',
    updateQuoteStatusSuccessfully: 'Update Quote status successfully!',
    convertQuoteSuccessfully: 'Convert Quote successfully!',
    associateQuoteToAccountSuccessfully: 'Associate Quote To Account successfully!',
    syncTaskToExternalSuccessfully: 'Sync Task To External successfully!',
    modifyTaskSuccessfully: 'Modify Task successfully!',
    createTaskSuccessfully: 'Create Task successfully!',
    subscriptionTopUpSuccessfully: 'Subscription Top Up successfully!',
    modifyPrepaidSystemOffersSuccessfully: 'Modify Topup Offers successfully!',
    createPrepaidSystemOffersSuccessfully: 'Create Topup Offers successfully!',
    createZoneUnitSuccessfully: 'Create Zone Unit successfully!',
    modifyZoneUnitSuccessfully: 'Modify Zone Unit successfully!',
    modifyTaxTypeConfigSuccessfully: 'Modify Tax Type successfully!',
    createTaxTypeConfigSuccessfully: 'Create Tax Type successfully!',
    createCollectionProfilesSuccessfully: 'Create Collection Profiles successfully!',
    modifyCollectionProfilesSuccessfully: 'Modify Collection Profiles successfully!',
    setupMultiInvoicePaymentInstallmentSuccessfully: 'setup Multi Invoice Payment Installment Successfully!',
    createSharingGroupSuccessfully: 'Create Sharing Group Successfully',
    modifySharingGroupSuccessfully: 'Modify Sharing Group Successfully',
    createRegulatoryProductCodesSuccessfully: 'Create Regulatory Product Codes successfully!',
    modifyRegulatoryProductCodesSuccessfully: 'Modify Regulatory Product Codes successfully!',
    deleteRegulatoryProductCodesSuccessfully: 'Delete Regulatory Product Codes successfully!',
    createTemplateSuccessfully: 'Create Template Successfully!',
    modifyTemplateSuccessfully: 'Modify Template Successfully!',
    modifyConfigPaymentAllocationSuccessfully: 'Modify Config Payment Allocation Successful!',
    createConfigPaymentAllocationSuccessfully: 'Create Config Payment Allocation Successful!',
    createExpensesConfigSuccessfully: 'Create Expenses Config Successfully!',
    modifyExpensesConfigSuccessfully: 'Modify Expenses Config Successfully!',
    createExpenseDataSuccessfully: 'Create Expense Data Successfully!',
    modifyExpenseDataSuccessfully: 'Modify Expense Data Successfully!',
    saveQuoteSuccessfully: 'Save Quote successfully!',
    createEmployeeBonusSuccessfully: 'Create Employee Bonus Successfully!',
    modifyEmployeeBonusSuccessfully: 'Modify Employee Bonus Successfully!',
    modifyFolioResponseSuccessfully: 'Modify Folio Response Successfully!',
    createFolioResponseSuccessfully: 'Create Folio Response Successfully!',
    removeItemSuccessfully: 'Remove Item Successfully!',
    modifyErpExtractBatchConfigSuccessfully: 'Modify ERP Extract Batch Config Successfully!',
    createErpExtractBatchConfigSuccessfully: 'Create ERP Extract Batch Config Successfully!',
  },

  dropdown: {
    status: {
      ACTIVE: 'ACTIVE',
      INACTIVE: 'INACTIVE',
      CLOSED: 'CLOSED',
    },
    variableTypes: {
      VARCHAR: 'VARCHAR',
      BOOLEAN: 'BOOLEAN',
      NUMERIC: 'NUMERIC',
      INTEGER: 'INTEGER',
      ENUM: 'ENUM',
    },

    paymentTerm: {
      NET_0: 'NET_0',
      NET_15: 'NET_15',
      NET_30: 'NET_30',
      NET_45: 'NET_45',
      NET_60: 'NET_60',
      NET_75: 'NET_75',
      NET_90: 'NET_90',
    },
    customerSegment: {
      B2B: 'B2B',
      B2C: 'B2C',
      B2B2C: 'B2B2C',
    },

    accountType: {
      DIRECT_CUSTOMER: 'DIRECT_CUSTOMER',
      PARTNER: 'PARTNER',
      PARTNER_CUSTOMER: 'PARTNER_CUSTOMER',
      RESELLER: 'RESELLER',
      RESELLER_CUSTOMER: 'RESELLER_CUSTOMER',
      INTERNAL_TEST_ACCOUNT: 'INTERNAL_TEST_ACCOUNT',
      EXTERNAL_TEST_ACCOUNT: 'EXTERNAL_TEST_ACCOUNT',
      BROKER_CHANNEL_PARTNER: 'BROKER_CHANNEL_PARTNER',
      DATA_SUPPLIER: 'DATA_SUPPLIER',
      INTERCOMPANY: 'INTERCOMPANY',
      CONNECTOR_PARTNER: 'CONNECTOR_PARTNER',
      AFFILIATE: 'AFFILIATE',
      SALES_AGENT: 'SALES_AGENT',
      SALES_CONSULTANT: 'SALES_CONSULTANT',
      CLIENT: 'CLIENT',
      PARTY: 'PARTY',
      INTERCONNECT: 'INTERCONNECT',
      NONE: 'NONE',
      DISTRIBUTOR: 'DISTRIBUTOR',
      CARRIER: 'CARRIER',
      ADMIN: 'ADMIN',
      EMPLOYEE: 'EMPLOYEE',
      RESIDENTIAL: 'RESIDENTIAL',
      ENTERPRISE: 'ENTERPRISE',
      CORPORATE: 'CORPORATE',
    },

    salesChannel: {
      WEB: 'WEB',
      POS: 'POS',
      ECOMMERCE: 'ECOMMERCE',
      ADVERTISEMENT: 'ADVERTISEMENT',
      TELE_MARKET: 'TELE_MARKET',
      AGENT_CARE: 'AGENT_CARE',
      SELFCARE: 'SELFCARE',
      NONE: 'NONE',
    },

    marketSegment: {
      INDIVIDUAL: 'INDIVIDUAL',
      SMALL_ENTERPRISE: 'SMALL_ENTERPRISE',
      SMB: 'SMB',
      LARGE_ENTERPRISE: 'LARGE_ENTERPRISE',
      NONE: 'NONE',
    },

    accountSubType: {
      SILVER: 'SILVER',
      GOLD: 'GOLD',
      PLATINUM: 'PLATINUM',
      NONE: 'NONE',
    },

    reason: {
      PAYMENT_FAILURE: 'PAYMENT_FAILURE',
      CUSTOMER_REQUEST: 'CUSTOMER_REQUEST',
      COLLECTIONS: 'COLLECTIONS',
      INTERNAL_CONTROL: 'INTERNAL_CONTROL',
      NONE: 'NONE',
      JOB_TRIGGERED_HIERARCHY_CLOSURE: 'JOB_TRIGGERED_HIERARCHY_CLOSURE',
    },

    phoneType: {
      WORK: 'WORK',
      HOME: 'HOME',
      MOBILE: 'MOBILE',
      FAX: 'FAX',
    },

    paymentMethod: {
      CHECK: 'CHECK',
      CREDIT_CARD: 'CREDIT_CARD',
      NON_PAYING: 'NON_PAYING',
      ECHECK: 'ECHECK',
      MANUAL: 'MANUAL',
    },

    paymentMethodAdmin: {
      MANUAL_PAYMENT: 'MANUAL_PAYMENT',
      CREDIT_CARD: 'CREDIT_CARD',
    },

    billingFrequency: {
      MONTHLY: 'MONTHLY',
      BI_MONTHLY: 'BI_MONTHLY',
      QUARTERLY: 'QUARTERLY',
      SEMI_ANNUAL: 'SEMI_ANNUAL',
      ANNUAL: 'ANNUAL',
      DAYS: 'DAYS',
    },

    serviceType: {
      DEFAULT: 'DEFAULT',
      VOIP: 'VOIP',
      CABLE: 'CABLE',
      UTILITIES: 'UTILITIES',
      RETAIL: 'RETAIL',
      WHOLESALE: 'WHOLESALE',
      WIRELINE: 'WIRELINE',
      TELEPHONY: 'TELEPHONY',
      IPTV: 'IPTV',
      DATA: 'DATA',
      PROFESSIONAL: 'PROFESSIONAL',
      INTERNET: 'INTERNET',
      HOMESECURITY: 'HOMESECURITY',
      VOICE: 'VOICE',
      SMS: 'SMS',
      MMS: 'MMS',
      EMAIL: 'EMAIL',
      EQUIPMENT: 'EQUIPMENT',
      DATACENTER: 'DATACENTER',
      ALL: 'ALL',
      LOGISTICS: 'LOGISTICS',
      TRANSPORT: 'TRANSPORT',
      WATER: 'WATER',
      ELECTRICITY: 'ELECTRICITY',
      SEWER: 'SEWER',
      GAS: 'GAS',
    },

    arType: {
      BILL_ADJUSTMENT: 'BILL_ADJUSTMENT',
      TRANSACTION_ADJUSTMENT: 'TRANSACTION_ADJUSTMENT',
      ACCOUNT_ADJUSTMENT: 'ACCOUNT_ADJUSTMENT',
      INVOICE_LINE_ADJUSTMENT: 'INVOICE_LINE_ADJUSTMENT',
      BILL_DISPUTE: 'BILL_DISPUTE',
      TRANSACTION_DISPUTE: 'TRANSACTION_DISPUTE',
      BILL_SETTLEMENT: 'BILL_SETTLEMENT',
      ACCOUNT_WRITEOFF: 'ACCOUNT_WRITEOFF',
      INVOICE_WRITEOFF: 'INVOICE_WRITEOFF',
      TRANSACTION_SETTLEMENT: 'TRANSACTION_SETTLEMENT',
      WRITE_OFF: 'WRITE_OFF',
      WRITE_OFF_REVERSAL: 'WRITE_OFF_REVERSAL',
      PAYMENT_REVERSAL: 'PAYMENT_REVERSAL',
      PAYMENT: 'PAYMENT',
      REFUND: 'REFUND',
      CHARGEBACK: 'CHARGEBACK',
      TAX: 'TAX',
      TAX_ADJUSTMENT: 'TAX_ADJUSTMENT',
      TAX_SETTLEMENT: 'TAX_SETTLEMENT',
      TAX_DISPUTE: 'TAX_DISPUTE',
      TAX_WRITEOFF: 'TAX_WRITEOFF',
      TAX_WRITEOFF_REVERSAL: 'TAX_WRITEOFF_REVERSAL',
      UNBILLED_LINE_ADJUSTMENT: 'UNBILLED_LINE_ADJUSTMENT',
    },

    types: {
      NEW: 'NEW',
      MODIFY: 'MODIFY',
      SUSPEND: 'SUSPEND',
      RESUME: 'RESUME',
      CANCEL: 'CANCEL',
      RENEW: 'RENEW',
      OPT_IN: 'OPT_IN',
      OPT_OUT: 'OPT_OUT',
      UPGRADE: 'UPGRADE',
      DOWNGRADE: 'DOWNGRADE',
      TRIAL: 'TRIAL',
      TRIAL_SERVICE: 'TRIAL_SERVICE',
      RELOCATION: 'RELOCATION',
    },

    statusOrder: {
      CREATED: 'CREATED',
      PENDING: 'PENDING',
      CANCELLED: 'CANCELLED',
      SUBMITTED: 'SUBMITTED',
      APPROVED: 'APPROVED',
      PROCESSING: 'PROCESSING',
      COMPLETED: 'COMPLETED',
      FAILED: 'FAILED',
      PROVISIONING_INITIATED: 'PROVISIONING_INITIATED',
      PROVISIONING_ERROR: 'PROVISIONING_ERROR',
      BILLING_ERROR: 'BILLING_ERROR',
      PROVISION_PROCESS: 'PROVISION_PROCESS',
      PROVISIONED: 'PROVISIONED',
      BILLED: 'BILLED',
    },

    initialTermUnit: {
      DAYS: 'DAYS',
      MONTHS: 'MONTHS',
      NONE: 'NONE',
      QUARTERS: 'QUARTERS',
      WEEKS: 'WEEKS',
      YEARS: 'YEARS',
    },

    recurringUnit: {
      MONTHS: 'MONTHS',
      QUARTERS: 'QUARTERS',
      YEARS: 'YEARS',
      DAYS: 'DAYS',
    },

    invoiceType: {
      SUMMARY: 'SUMMARY',
      DETAIL: 'DETAIL',
      DETAIL_SUPPRESS: 'DETAIL_SUPPRESS',
    },

    invoiceDelivery: {
      DOWNLOAD: 'DOWNLOAD',
      EMAIL: 'EMAIL',
      MAIL: 'MAIL',
    },

    timeUnit: {
      DAYS: 'DAYS',
      WEEKS: 'WEEKS',
      MONTHS: 'MONTHS',
      QUARTERS: 'QUARTERS',
      YEARS: 'YEARS',
      UNBOUND: 'UNBOUND',
    },

    statusPricing: {
      CREATE: 'CREATE',
      APPROVED: 'APPROVED',
      SELLABLE: 'SELLABLE',
      NON_SELLABLE: 'NON_SELLABLE',
      SUNSET: 'SUNSET',
    },

    paymentWorkingDay: {
      NEXT_WORKING_DAY: 'NEXT_WORKING_DAY',
      LAST_WORKING_DAY: 'LAST_WORKING_DAY',
    },

    source: {
      EXTERNAL: 'EXTERNAL',
      SELF_CARE: 'SELF_CARE',
      AGENT_CARE: 'AGENT_CARE',
      RERATING: 'RERATING',
      PAYMENTS: 'PAYMENTS',
      PAYMENT_SUSPENSE: 'PAYMENT_SUSPENSE',
      INTERNAL_BATCH_JOBS: 'INTERNAL_BATCH_JOBS',
    },

    statusPayment: {
      OPEN: 'OPEN',
      CLOSED: 'CLOSED',
      REVERSED: 'REVERSED',
      REVERSE_ALLOCATED: 'REVERSE_ALLOCATED',
      // CANCELLATION_STAMPING_REQUESTED: 'CANCELLATION_STAMPING_REQUESTED',
      // CANCELLATION_STAMPED: 'CANCELLATION_STAMPED',
      // STAMPING_REQUESTED: 'STAMPING_REQUESTED',
      // STAMPED: 'STAMPED',
      REVERSED_TO_SUSPENSE: 'REVERSED_TO_SUSPENSE',
    },

    action: {
      FAILED_PAYMENT_EMAIL: 'FAILED_PAYMENT_EMAIL',
      FIRST_REMINDER_EMAIL: 'FIRST_REMINDER_EMAIL',
      SECOND_REMINDER_EMAIL: 'SECOND_REMINDER_EMAIL',
      THIRD_REMINDER_EMAIL: 'THIRD_REMINDER_EMAIL',
      FIRST_PAYMENT_RETRY: 'FIRST_PAYMENT_RETRY',
      SECOND_PAYMENT_RETRY: 'SECOND_PAYMENT_RETRY',
      THIRD_PAYMENT_RETRY: 'THIRD_PAYMENT_RETRY',
      INACTIVATE_SUBSCRIPTION: 'INACTIVATE_SUBSCRIPTION',
      WRITEOFF_ACCOUNT: 'WRITEOFF_ACCOUNT',
      CLOSE_ACCOUNT: 'CLOSE_ACCOUNT',
    },

    statusCollections: {
      OPEN: 'OPEN',
      CLOSED: 'CLOSED',
      CUSTOMER_CONTACT_INITIATED: 'CUSTOMER_CONTACT_INITIATED',
      CUSTOMER_CONTACT_ESTABLISHED: 'CUSTOMER_CONTACT_ESTABLISHED',
      PROMISE_TO_PAY: 'PROMISE_TO_PAY',
      PROMISE_BROKEN: 'PROMISE_BROKEN',
      SUSPEND: 'SUSPEND',
      WRITEOFF: 'WRITEOFF',
      GRACE_PERIOD_REQUESTED: 'GRACE_PERIOD_REQUESTED',
    },

    reasonConfig: {
      SMALL_BALANCE_WRITE_OFF: 'SMALL_BALANCE_WRITE_OFF',
      BAD_DEBT_WRITE_OFF: 'BAD_DEBT_WRITE_OFF',
      CUSTOMER_SATISFACTION: 'CUSTOMER_SATISFACTION',
      RERATING: 'RERATING',
      QUALITY_OF_SERVICE: 'QUALITY_OF_SERVICE',
      DATA_ERROR: 'DATA_ERROR',
      SETUP_ERROR: 'SETUP_ERROR',
      PRICING_ERROR: 'PRICING_ERROR',
      PAYMENT: 'PAYMENT',
      PAYMENT_REVERSAL: 'PAYMENT_REVERSAL',
    },

    CRDRType: {
      CREDIT: 'CREDIT',
      DEBIT: 'DEBIT',
    },

    taxRule: {
      WITH_TAX: 'WITH_TAX',
      WITHOUT_TAX: 'WITHOUT_TAX',
      TAX_ONLY: 'TAX_ONLY',
    },

    disputeStatus: {
      OPEN: 'OPEN',
      CLOSED: 'CLOSED',
    },

    invoiceStatus: {
      ACTIVE: 'ACTIVE',
      CLOSED: 'CLOSED',
      COLLECTION: 'COLLECTION',
      // SENT: 'SENT',
      PENDING_APPROVAL: 'PENDING_APPROVAL',
      APPROVED: 'APPROVED',
      DISAPPROVED: 'DISAPPROVED',
      SYNC_FAILED: 'SYNC_FAILED',
      // SEND_FAILED: 'SEND_FAILED',
      // STAMPING_REQUESTED: 'STAMPING_REQUESTED',
      // STAMPED: 'STAMPED',
      // CANCELLATION_STAMPING_REQUESTED: 'CANCELLATION_STAMPING_REQUESTED',
      // CANCELLATION_STAMPED: 'CANCELLATION_STAMPED',
    },

    invoiceUnitType: {
      REGULAR: 'REGULAR',
      CORRECTIVE: 'CORRECTIVE',
      REGENERATED: 'REGENERATED',
    },

    permissionStatus: {
      PENDING: 'PENDING',
      ACTIVE: 'ACTIVE',
      SUNSET: 'SUNSET',
    },

    category: {
      AGENT: 'AGENT',
      CUSTOMER: 'CUSTOMER',
      SYSTEM: 'SYSTEM',
    },

    // jobTypes: {
    //   BILLING: 'BILLING',
    //   INVOICING: 'INVOICING',
    //   PAYMENT: 'PAYMENT',
    //   COLLECTION_CREATE: 'COLLECTION_CREATE',
    //   COLLECTION_ACTIONS: 'COLLECTION_ACTIONS',
    //   BILLING_NON_PAYING: 'BILLING_NON_PAYING',
    //   BILLING_CC: 'BILLING_CC',
    //   BILLING_CHECK: 'BILLING_CHECK',
    //   INVOICING_CC: 'INVOICING_CC',
    //   INVOICING_CHECK: 'INVOICING_CHECK',
    //   PAYMENT_CC: 'PAYMENT_CC',
    //   FUTURE_ORDERS: 'FUTURE_ORDERS',
    //   FUTURE_PURCHASE: 'FUTURE_PURCHASE',
    //   FUTURE_CANCEL: 'FUTURE_CANCEL',
    // },

    jobTypes: {
      COLLECTION_CREATE: 'COLLECTION_CREATE',
      COLLECTION_ACTIONS: 'COLLECTION_ACTIONS',
      BILL: 'BILL',
      BILL_NON_PAYING: 'BILL_NON_PAYING',
      BILL_CC: 'BILL_CC',
      BILL_CHECK: 'BILL_CHECK',
      IN_ADVANCE_BILL: 'IN_ADVANCE_BILL',
      IN_ADVANCE_BILL_NON_PAYING: 'IN_ADVANCE_BILL_NON_PAYING',
      IN_ADVANCE_BILL_CC: 'IN_ADVANCE_BILL_CC',
      IN_ADVANCE_BILL_CHECK: 'IN_ADVANCE_BILL_CHECK',
      PENDING_BILL_TRIGGER_PARENT: 'PENDING_BILL_TRIGGER_PARENT',
      PENDING_BILL_TRIGGER_CHILD: 'PENDING_BILL_TRIGGER_CHILD',
      INVOICE: 'INVOICE',
      INVOICE_NON_PAYING: 'INVOICE_NON_PAYING',
      INVOICE_CC: 'INVOICE_CC',
      INVOICE_CHECK: 'INVOICE_CHECK',
      PAYMENT_CC: 'PAYMENT_CC',
      FUTURE_ORDERS: 'FUTURE_ORDERS',
      FUTURE_PURCHASE: 'FUTURE_PURCHASE',
      FUTURE_CANCEL: 'FUTURE_CANCEL',
      CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
      CANCEL_SERVICE: 'CANCEL_SERVICE',
      TRIAL_TO_PAID_SUBSCRIPTION: 'TRIAL_TO_PAID_SUBSCRIPTION',
      TRIAL_TO_PAID_SERVICE: 'TRIAL_TO_PAID_SERVICE',
      RENEWAL_DUE_REMINDER: 'RENEWAL_DUE_REMINDER',
      CRDR_NOTES: 'CRDR_NOTES',
      INVOICE_TRANSFER: 'INVOICE_TRANSFER',
      INVOICE_DUE_REMINDER: 'INVOICE_DUE_REMINDER',
      TRIAL_EXPIRY_REMINDER: 'TRIAL_EXPIRY_REMINDER',
      RECOGNIZE_REVENUE: 'RECOGNIZE_REVENUE',
      MANUAL_BILLING_PARENT: 'MANUAL_BILLING_PARENT',
      MANUAL_BILLING_CHILD: 'MANUAL_BILLING_CHILD',
      CLOSE_HIERARCHY_PARENT: 'CLOSE_HIERARCHY_PARENT',
      CLOSE_HIERARCHY_CHILD: 'CLOSE_HIERARCHY_CHILD',
      BACKOUT_CREDIT_NOTES: 'BACKOUT_CREDIT_NOTES',
      TRIGGER_FUTURE_CYCLE: 'TRIGGER_FUTURE_CYCLE',
      SEND_INVOICE_EMAIL: 'SEND_INVOICE_EMAIL',
      FINANCE_SYNC_ACCOUNT: 'FINANCE_SYNC_ACCOUNT',
      FINANCE_SYNC_ACCOUNT_MODIFY: 'FINANCE_SYNC_ACCOUNT_MODIFY',
      FINANCE_SYNC_INVOICE: 'FINANCE_SYNC_INVOICE',
      FINANCE_SYNC_PAYMENT: 'FINANCE_SYNC_PAYMENT',
      FINANCE_SYNC_CREDIT_NOTES: 'FINANCE_SYNC_CREDIT_NOTES',
      FINANCE_SYNC_REVENUE: 'FINANCE_SYNC_REVENUE',
      FINANCE_SYNC_AROPS: 'FINANCE_SYNC_AROPS',
      MANAGE_PAC_INVOICE: 'MANAGE_PAC_INVOICE',
      MANAGE_PAC_CREDITNOTE: 'MANAGE_PAC_CREDITNOTE',
      EBS_EXTRACT: 'EBS_EXTRACT',
      AGENT_COMMISSION: 'AGENT_COMMISSION',
      MOVE_ACCOUNT: 'MOVE_ACCOUNT',
      UPDATE_BILLING_ACCOUNT: 'UPDATE_BILLING_ACCOUNT',
      FUTURE_CANCEL_DISCOUNT: 'FUTURE_CANCEL_DISCOUNT',
      RERATING: 'RERATING',
      SUSPEND_SUBSCRIPTION: 'SUSPEND_SUBSCRIPTION',
      BILL_PREPAID_AS_POSTPAID_SUBS: 'BILL_PREPAID_AS_POSTPAID_SUBS',
      PROCESS_CHARGE_SHARE: 'PROCESS_CHARGE_SHARE',
      BILL_SHARING_GROUP_OWNER: 'BILL_SHARING_GROUP_OWNER',
      SEND_GROUP_INVOICE: 'SEND_GROUP_INVOICE',
      CREDIT_LIMIT_ACTIONS: 'CREDIT_LIMIT_ACTIONS',
    },

    roundingMethod: {
      UP: 'UP',
      DOWN: 'DOWN',
      HALF_UP: 'HALF_UP',
      HALF_DOWN: 'HALF_DOWN',
      NEAREST: 'NEAREST',
    },

    selectBool: {
      TRUE: 'TRUE',
      FALSE: 'FALSE',
    },

    transactionType: {
      ONE_TIME: 'ONE_TIME',
      ONE_TIME_CANCEL: 'ONE_TIME_CANCEL',
      FORWARD_RECURRING: 'FORWARD_RECURRING',
      ARREARS_RECURRING: 'ARREARS_RECURRING',
      USAGE: 'USAGE',
      VOLUME_DISCOUNT: 'VOLUME_DISCOUNT',
      TAX: 'TAX',
      MANUAL_TRANSACTION: 'MANUAL_TRANSACTION',
      TRUE_UP: 'TRUE_UP',
      NON_TAX: 'NON_TAX',
      NONE: 'NONE',
    },

    arCrDrType: {
      DEBIT: 'DEBIT',
      CREDIT: 'CREDIT',
    },

    paymentTransactionType: {
      PAYMENT: 'PAYMENT',
      PAYMENT_REVERSAL: 'PAYMENT_REVERSAL',
      PAYMENT_SUSPENSE: 'PAYMENT_SUSPENSE',
    },

    subscriptionReason: {
      NONE: 'NONE',
      CUSTOMER_RELOCATION: 'CUSTOMER_RELOCATION',
      NOT_HAPPY: 'NOT_HAPPY',
      QOS: 'QOS',
      PRICING: 'PRICING',
      CREDIT_LIMIT_EXCEEDED: 'CREDIT_LIMIT_EXCEEDED',
    },

    jobStatus: {
      PENDING: 'PENDING',
      COMPLETED: 'COMPLETED',
      ERROR: 'ERROR',
      PROCESSING: 'PROCESSING',
      REPROCESSING: 'REPROCESSING',
      SUSPENDED: 'SUSPENDED',
      CANCELED: 'CANCELED',
      FETCH: 'FETCH',
    },

    trialLevel: {
      SUBSCRIPTION: 'SUBSCRIPTION',
      SERVICEUNIT: 'UNIDAD DE SERVICIO',
    },

    trialTypes: {
      OPT_IN_TRIAL: 'OPT_IN_TRIAL',
      OPT_OUT_TRIAL: 'OPT_OUT_TRIAL',
    },

    trialTermUnit: {
      DAYS: 'DAYS',
      WEEKS: 'WEEKS',
      MONTHS: 'MONTHS',
      QUARTERS: 'QUARTERS',
      YEARS: 'YEARS',
    },

    subscriptionStatus: {
      ACTIVE: 'ACTIVE',
      SUSPENDED: 'SUSPENDED',
      CLOSED: 'CLOSED',
    },

    suspenseReason: {
      ACCOUNT_NOT_FOUND: 'ACCOUNT_NOT_FOUND',
      ACCOUNT_CLOSED: 'ACCOUNT_CLOSED',
      INVOICE_NOT_FOUND: 'INVOICE_NOT_FOUND',
      PREDATES_ACCOUNT: 'PREDATES_ACCOUNT',
      PREDATES_INVOICE: 'PREDATES_INVOICE',
      INVALID_ACCOUNT: 'INVALID_ACCOUNT',
      NOT_A_BILLING_ACCOUNT: 'NOT_A_BILLING_ACCOUNT',
      STATEMENT_RECORD: 'STATEMENT_RECORD',
      UNDER_PAYMENT: 'UNDER_PAYMENT',
      OVER_PAYMENT: 'OVER_PAYMENT',
    },

    arLevel: {
      BILL: 'BILL',
      TRANSACTION: 'TRANSACTION',
      ACCOUNT: 'ACCOUNT',
    },

    pricingModel: {
      FLAT: 'FLAT',
      TIERED: 'TIERED',
      RECURRING: 'RECURRING',
      RECURRING_TIERED: 'RECURRING_TIERED',
      TRANSACTION_ATTRIBUTE_BASED: 'TRANSACTION_ATTRIBUTE_BASED',
      CUSTOMER_ATTRIBUTE_BASED: 'CUSTOMER_ATTRIBUTE_BASED',
      USAGE_ATTRIBUTE_BASED: 'USAGE_ATTRIBUTE_BASED',
      COMPLEX: 'COMPLEX',
      NONE: 'NONE',
    },

    streamName: {
      PRE_PROCESSING_SVC: 'PRE_PROCESSING_SVC',
      POSTPAID_RATING_SVC: 'POSTPAID_RATING_SVC',
      PREPAID_RATING_SVC: 'PREPAID_RATING_SVC',
      SHARING_MODEL_SVC: 'SHARING_MODEL_SVC',
      SETTLEMENT_SVC: 'SETTLEMENT_SVC',
      POST_PROCESSING_SVC: 'POST_PROCESSING_SVC',
    },

    revenueRecognitionType: {
      IMMEDIATE: 'IMMEDIATE',
      DEFERRED: 'DEFERRED',
      REVENUE_ON_DEFERRAL_AUTO: 'REVENUE_ON_DEFERRAL_AUTO',
      REVENUE_ON_DEFERRAL_MANUAL: 'REVENUE_ON_DEFERRAL_MANUAL',
      DAILY_STRAIGHT_LINE_AMORTIZATION: 'DAILY_STRAIGHT_LINE_AMORTIZATION',
      MONTHLY_STRAIGHT_LINE_AMORTIZATION: 'MONTHLY_STRAIGHT_LINE_AMORTIZATION',
      TRUE_UP: 'TRUE_UP',
      MILESTONE: 'MILESTONE',
    },

    revenueType: {
      TRANSACTION: 'TRANSACTION',
      SUBSCRIPTION: 'SUBSCRIPTION',
      PROFESSIONAL_SERVICES: 'PROFESSIONAL_SERVICES',
      LABOR_EXPENSE: 'LABOR_EXPENSE',
      LABOR_TIME: 'LABOR_TIME',
      LICENSE: 'LICENSE',
      // EXPENSE: 'EXPENSE',
      // LIABILITY: 'LIABILITY',
      // RECEIVABLE: 'RECEIVABLE',
      // PAYABLE: 'PAYABLE',
    },

    prorationType: {
      FULL: 'FULL',
      ZERO: 'ZERO',
      PRORATE: 'PRORATE',
    },

    timeUnitPricing: {
      WORKWEEK: 'WORKWEEK',
      WEEKEND: 'WEEKEND',
      PEAKHOURS: 'PEAKHOURS',
      OFFPEAKHOURS: 'OFFPEAKHOURS',
      HOLIDAYSPECIAL: 'HOLIDAYSPECIAL',
      ANYTIME: 'ANYTIME',
      NONE: 'NONE',
    },

    tierReference: {
      QUANTITY: 'QUANTITY',
      BALANCE: 'BALANCE',
    },

    tierType: {
      PROGRESSIVE: 'PROGRESSIVE',
      PROSPECTIVE: 'PROSPECTIVE',
    },

    volumeTreatment: {
      ACTUAL: 'ACTUAL',
      AVERAGE: 'AVERAGE',
      ANNUALIZE: 'ANNUALIZE',
    },

    dependencyType: {
      MUTUALLY_EXCLUSIVE: 'MUTUALLY_EXCLUSIVE',
      PRE_REQUISITE: 'PRE_REQUISITE',
      UPGRADE: 'UPGRADE',
      DOWNGRADE: 'DOWNGRADE',
    },

    recordExpression: {
      DETAIL: 'DETAIL',
    },

    fieldExpression: {
      startDate: 'startDate',
      endDate: 'endDate',
      processDate: 'processDate',
      quantity: 'quantity',
      upload: 'upload',
      download: 'download',
    },

    selectUnit: {
      ONE: 'ONE',
      KILOBYTE: 'KILOBYTE',
      MEGABYTE: 'MEGABYTE',
      GIGABYTE: 'GIGABYTE',
      SECOND: 'SECOND',
      MINUTE: 'MINUTE',
      HOUR: 'HOUR',
      NONE: 'NONE',
    },

    statusRateUnit: {
      ACTIVE: 'ACTIVE',
      CLOSED: 'CLOSED',
    },

    statusTenant: {
      ACTIVE: 'ACTIVE',
      INACTIVE: 'INACTIVE',
    },

    dayOfWeek: {
      SUNDAY: 'SUNDAY',
      MONDAY: 'MONDAY',
      TUESDAY: 'TUESDAY',
      WEDNESDAY: 'WEDNESDAY',
      THURSDAY: 'THURSDAY',
      FRIDAY: 'FRIDAY',
      SATURDAY: 'SATURDAY',
    },

    mandatory: {
      Y: 'Y',
      N: 'N',
    },

    recordIndicator: {
      PREPAID: 'PREPAID',
      POSTPAID: 'POSTPAID',
      PRERATED: 'PRERATED',
      MANUAL_TRANSACTION: 'MANUAL_TRANSACTION',
      PREPAID_AAA: 'PREPAID_AAA',
    },

    moduleName: {
      ACCOUNT_LOOKUP_MODULE: 'ACCOUNT_LOOKUP_MODULE',
      BALANCE_LOOKUP_MODULE: 'BALANCE_LOOKUP_MODULE',
      RATE_UNIT_MODULE: 'RATE_UNIT_MODULE',
      TIME_UNIT_MODULE: 'TIME_UNIT_MODULE',
      ZONE_UNIT_MODULE: 'ZONE_UNIT_MODULE',
      IMPACT_UNIT_MODULE: 'IMPACT_UNIT_MODULE',
      ROUTER_MODULE: 'ROUTER_MODULE',
      PO_PRICE_UNIT_ANLYS_MODULE: 'PO_PRICE_UNIT_ANLYS_MODULE',
      PO_RATING_MODULE: 'PO_RATING_MODULE',
      PO_DISCOUNT_UNIT_ANLYS_MODULE: 'PO_DISCOUNT_UNIT_ANLYS_MODULE',
      PO_DISCOUNTING_MODULE: 'PO_DISCOUNTING_MODULE',
      PP_SESSION_MGMT_MODULE: 'PP_SESSION_MGMT_MODULE',
      PP_BALANCE_MGMT_MODULE: 'PP_BALANCE_MGMT_MODULE',
      PP_PRICE_UNIT_ANLYS_MODULE: 'PP_PRICE_UNIT_ANLYS_MODULE',
      PP_RATING_MODULE: 'PP_RATING_MODULE',
      PP_DISCOUNT_UNIT_ANLYS_MODULE: 'PP_DISCOUNT_UNIT_ANLYS_MODULE',
      PP_DISCOUNTING_MODULE: 'PP_DISCOUNTING_MODULE',
      REV_SHARE_LOOKUP_MODULE: 'REV_SHARE_LOOKUP_MODULE',
      REV_SHARE_MODULE: 'REV_SHARE_MODULE',
      UPDATE_BALANCE_UNIT_MODULE: 'UPDATE_BALANCE_UNIT_MODULE',
      UPDATE_BILL_UNIT_MODULE: 'UPDATE_BILL_UNIT_MODULE',
      CREATE_TRANS_UNIT_MODULE: 'CREATE_TRANS_UNIT_MODULE',
      REVENUE_RECOGNITION_MODULE: 'REVENUE_RECOGNITION_MODULE',
      DUPLICATE_CHECK: 'DUPLICATE_CHECK',
    },

    merchantName: {
      BRAINTREE: 'BRAINTREE',
      AVALARA: 'AVALARA',
      QUICKBOOKS: 'QUICKBOOKS',
      EMBRIX: 'EMBRIX',
      SFDC: 'SFDC',
      SERVICE_NOW: 'SERVICE_NOW',
      ZENDESK: 'ZENDESK',
      DEFAULT: 'DEFAULT',
      PROJECT_APP: 'PROJECT_APP',
      COMPUTEC: 'COMPUTEC',
      JARS: 'JARS',
      NOKIA: 'NOKIA',
      NETUP: 'NETUP',
      PORTAONE: 'PORTAONE',
      NOC: 'NOC',
      COOPEWEB: 'COOPEWEB',
      HIBOX: 'HIBOX',
      MOTV: 'MOTV',
    },

    taxGetWayType: {
      BASE_URL: 'BASE_URL',
      CALCULATE_TAX: 'CALCULATE_TAX',
      CREATE_CONFIG: 'CREATE_CONFIG',
      MODIFY_CONFIG: 'MODIFY_CONFIG',
      READ_CONFIG: 'READ_CONFIG',
      ADDRESS_LOOKUP: 'ADDRESS_LOOKUP',
    },

    authType: {
      HTTP_BASIC: 'HTTP_BASIC',
      JSON_WEB_TOKEN: 'JSON_WEB_TOKEN',
      OAUTH2: 'OAUTH2',
      API_KEY: 'API_KEY',
    },

    paymentGatewayType: {
      BATCH: 'BATCH',
      AUTHORIZE: 'AUTHORIZE',
      CAPTURE: 'CAPTURE',
      CREDIT: 'CREDIT',
      VOID: 'VOID',
      BASE_URL: 'BASE_URL',
    },

    financeGatewayType: {
      BASE_URL: 'BASE_URL',
      MODIFY_COA: 'MODIFY_COA',
      MODIFY_JOURNAL: 'MODIFY_JOURNAL',
      READ_COA: 'READ_COA',
      RUN_REPORT: 'RUN_REPORT',
      CREATE_COA: 'CREATE_COA',
      CREATE_JOURNAL: 'CREATE_JOURNAL',
      CREATE_CUSTOMER: 'CREATE_CUSTOMER',
      CREATE_INVOICE: 'CREATE_INVOICE',
      CREATE_PAYMENT: 'CREATE_PAYMENT',
      MODIFY_CUSTOMER: 'MODIFY_CUSTOMER',
      CREATE_CREDIT_NOTES: 'CREATE_CREDIT_NOTES',
      CREATE_REVENUE: 'CREATE_REVENUE',
      CREATE_AROPS: 'CREATE_AROPS',
      SEND_INVOICE: 'SEND_INVOICE',
      SEND_ACCOUNTING_EXTRACT: 'SEND_ACCOUNTING_EXTRACT',
      GET_AUTHORIZATION_TOKEN: 'GET_AUTHORIZATION_TOKEN',
    },

    accountRole: {
      BILLING: 'BILLING',
      PAYMENT: 'PAYMENT',
      SERVICE: 'SERVICE',
      SOLD_TO: 'SOLD_TO',
    },

    selfCareUserType: {
      NONE: 'NONE',
      READ_ONLY: 'READ_ONLY',
      READ_WRITE: 'READ_WRITE',
    },

    dashboardType: {
      CUSTOMER_ONBOARDING: 'CUSTOMER_ONBOARDING',
      CUSTOMER_CHURN: 'CUSTOMER_CHURN',
      FAILED_ORDER: 'FAILED_ORDER',
      ACTIVE_SUBSCRIPTIONS: 'ACTIVE_SUBSCRIPTIONS',
      TRIAL_TO_PAID_CONVERSION: 'TRIAL_TO_PAID_CONVERSION',
      SUBSCRIPTION_CANCELLATIONS: 'SUBSCRIPTION_CANCELLATIONS',
      BILLED_REVENUE: 'BILLED_REVENUE',
      BILLED_CUSTOMER: 'BILLED_CUSTOMER',
      PAYMENT_SUCCESS: 'PAYMENT_SUCCESS',
      PAYMENT_SUSPENSE: 'PAYMENT_SUSPENSE',
      PAYMENT_FAILED: 'PAYMENT_FAILED',
      ACCOUNTS_WITH_FAILED_PAYMENT: 'ACCOUNTS_WITH_FAILED_PAYMENT',
      COLLECTION_0_30_DAYS: 'COLLECTION_0_30_DAYS',
      COLLECTION_30_60_DAYS: 'COLLECTION_30_60_DAYS',
      COLLECTION_60_90_DAYS: 'COLLECTION_60_90_DAYS',
      COLLECTION_OVER_90_DAYS: 'COLLECTION_OVER_90_DAYS',
      TOTAL_CREDIT_ADJUSTMENTS: 'TOTAL_CREDIT_ADJUSTMENTS',
      TOTAL_DISPUTES: 'TOTAL_DISPUTES',
      TOTAL_SETTLEMENTS: 'TOTAL_SETTLEMENTS',
      TOTAL_REVENUE: 'TOTAL_REVENUE',
      BILLS_FAILED: 'BILLS_FAILED',
      INVOICES_FAILED: 'INVOICES_FAILED',
      JOBS_FAILED: 'JOBS_FAILED',
      SUBMITTED_ORDER: 'SUBMITTED_ORDER',
      USAGE_TRANSACTION: 'USAGE_TRANSACTION',
      RATED_USAGE_TRANSACTION: 'RATED_USAGE_TRANSACTION',
      TOTAL_JOBS: 'TOTAL_JOBS',
      RECOGNIZED_REVENUE: 'RECOGNIZED_REVENUE',
      TOTAL_WRITEOFF: 'TOTAL_WRITEOFF',
      TOTAL_DEBIT_ADJUSTMENTS: 'TOTAL_DEBIT_ADJUSTMENTS',
      REVENUE_BY_PRODUCT_COMPANY: 'REVENUE_BY_PRODUCT_COMPANY',
      REVENUE_BY_REVENUE_TYPE: 'REVENUE_BY_REVENUE_TYPE',
      CUSTOMER_COUNT_BY_ACCOUNT_TYPE: 'CUSTOMER_COUNT_BY_ACCOUNT_TYPE',
      CUSTOMER_COUNT_BY_SELLING_COMPANY: 'CUSTOMER_COUNT_BY_SELLING_COMPANY',
      REVENUE_FORECAST: 'REVENUE_FORECAST',
      MONTHLY_RECURRING_REVENUE: 'MONTHLY_RECURRING_REVENUE',
      FOLIO_COUNT: 'FOLIO_COUNT',
      ASSET_SUMMARY: 'ASSET_SUMMARY',
      FOLIO_SUMMARY: 'FOLIO_SUMMARY',
    },

    entityType: {
      ORDER: 'ORDER',
      CUSTOMER: 'CUSTOMER',
      CONTACT: 'CONTACT',
      ADDRESS: 'ADDRESS',
      ITEM: 'ITEM',
      PAYMENT_PROFILE: 'PAYMENT_PROFILE',
      BILLING_PROFILE: 'BILLING_PROFILE',
      PRICE_OFFER: 'PRICE_OFFER',
      BUNDLE: 'BUNDLE',
      PACKAGE: 'PACKAGE',
      DEPENDENCY: 'DEPENDENCY',
      AROPS: 'AROPS',
      IDENTITY: 'IDENTITY',
      TAX: 'TAX',
      PAYMENT: 'PAYMENT',
      COLLECTION: 'COLLECTION',
      INTEGRATION: 'INTEGRATION',
      PAYMENT_SUSPENSE: 'PAYMENT_SUSPENSE',
      PAYMENT_REVERSAL: 'PAYMENT_REVERSAL',
      DISCOUNT_OFFER: 'DISCOUNT_OFFER',
      CUSTOM_ATTRIBUTES: 'CUSTOM_ATTRIBUTES',
      ACCOUNT_INFO: 'ACCOUNT_INFO',
      BILLING: 'BILLING',
      PROVISIONING: 'PROVISIONING',
    },

    recurringType: {
      FORWARD: 'FORWARD',
      ARREARS: 'ARREARS',
    },

    rateUnit: {
      EACH: 'EACH',
      DURATION: 'DURATION',
      VOLUME: 'VOLUME',
      QOS: 'QOS',
      DOWNLINK: 'DOWNLINK',
      UPLINK: 'UPLINK',
      SIZE: 'SIZE',
      QUANTITY: 'QUANTITY',
      VOLUMEDOWNLOAD: 'VOLUMEDOWNLOAD',
      VOLUMEUPLOAD: 'VOLUMEUPLOAD',
      NONE: 'NONE',
    },

    grantType: {
      GRANT: 'GRANT',
      CONSUME: 'CONSUME',
    },

    monthOfYear: {
      Jan: 'Jan',
      Feb: 'Feb',
      Mar: 'Mar',
      Apr: 'Apr',
      May: 'May',
      Jun: 'Jun',
      Jul: 'Jul',
      Aug: 'Aug',
      Sep: 'Sep',
      Oct: 'Oct',
      Nov: 'Nov',
      Dec: 'Dec',
    },

    attributeTypes: {
      VARCHAR: 'VARCHAR',
      INTEGER: 'INTEGER',
      BOOLEAN: 'BOOLEAN',
      DECIMAL: 'DECIMAL',
      DATE: 'DATE',
      TIMESTAMP: 'TIMESTAMP',
    },

    txnTypeTenant: {
      PAYMENT: 'PAYMENT',
      AUTHORIZE: 'AUTHORIZE',
      VOID: 'VOID',
      REFUND: 'REFUND',
      CAPTURE: 'CAPTURE',
      CREDIT: 'CREDIT',
      SETTLEMENT: 'SETTLEMENT',
      CHARGEBACK: 'CHARGEBACK',
      PAYMENT_REVERSAL: 'PAYMENT_REVERSAL',
      PAYMENT_WIDGET: 'PAYMENT_WIDGET',
      RECONCILIATION: 'RECONCILIATION',
      REPORT: 'REPORT',
    },
    gatewayName: {
      PAYMENT_GATEWAY: 'PAYMENT_GATEWAY',
      TAX_GATEWAY: 'TAX_GATEWAY',
      FINANCE_GATEWAY: 'FINANCE_GATEWAY',
      PROVISIONING_GATEWAY: 'PROVISIONING_GATEWAY',
      CUSTOMER_SUPPORT_GATEWAY: 'CUSTOMER_SUPPORT_GATEWAY',
      CRM_GATEWAY: 'CRM_GATEWAY',
      DOCUMENT_GATEWAY: 'DOCUMENT_GATEWAY',
      OPERATION_GATEWAY: 'OPERATION_GATEWAY',
      PROJECT_GATEWAY: 'PROJECT_GATEWAY',
    },

    gatewayApi: {
      CALCULATE_TAX: 'CALCULATE_TAX',
      CREATE_CONFIG: 'CREATE_CONFIG',
      MODIFY_CONFIG: 'MODIFY_CONFIG',
      READ_CONFIG: 'READ_CONFIG',
      ADDRESS_LOOKUP: 'ADDRESS_LOOKUP',
      BATCH_PAYMENT: 'BATCH_PAYMENT',
      AUTHORIZE_CREDIT_CARD: 'AUTHORIZE_CREDIT_CARD',
      CAPTURE_CREDIT_CARD: 'CAPTURE_CREDIT_CARD',
      CREDIT_CREDIT_CARD: 'CREDIT_CREDIT_CARD',
      VOID_CREDIT_CARD: 'VOID_CREDIT_CARD',
      AUTHORIZE_ECHECK: 'AUTHORIZE_ECHECK',
      CAPTURE_ECHECK: 'CAPTURE_ECHECK',
      CREDIT_ECHECK: 'CREDIT_ECHECK',
      VOID_ECHECK: 'VOID_ECHECK',
      AUTHORIZE_ACH: 'AUTHORIZE_ACH',
      CAPTURE_ACH: 'CAPTURE_ACH',
      CREDIT_ACH: 'CREDIT_ACH',
      VOID_ACH: 'VOID_ACH',
      CREATE_COA: 'CREATE_COA',
      MODIFY_COA: 'MODIFY_COA',
      READ_COA: 'READ_COA',
      CREATE_JOURNAL: 'CREATE_JOURNAL',
      MODIFY_JOURNAL: 'MODIFY_JOURNAL',
      RUN_REPORT: 'RUN_REPORT',
      CREATE_CUSTOMER: 'CREATE_CUSTOMER',
      CREATE_INVOICE: 'CREATE_INVOICE',
      CREATE_PAYMENT: 'CREATE_PAYMENT',
      MODIFY_CUSTOMER: 'MODIFY_CUSTOMER',
      CREATE_CREDIT_NOTES: 'CREATE_CREDIT_NOTES',
      CREATE_REVENUE: 'CREATE_REVENUE',
      CREATE_AROPS: 'CREATE_AROPS',
      SEND_INVOICE: 'SEND_INVOICE',
      CREATE_ORGANIZATION: 'CREATE_ORGANIZATION',
      CREATE_USER: 'CREATE_USER',
      CREATE_ITEM: 'CREATE_ITEM',
      CREATE_ERP_SERVICE: 'CREATE_ERP_SERVICE',
      CREATE_CLIENT: 'CREATE_CLIENT',
      CREATE_ACCOUNT: 'CREATE_ACCOUNT',
      CREATE_POINTS: 'CREATE_POINTS',
      CREATE_PROVISIONING_ORDER: 'CREATE_PROVISIONING_ORDER',
      CREATE_ORDER_SERVICE: 'CREATE_ORDER_SERVICE',
      REMOVE_DID: 'REMOVE_DID',
      REMOVE_DID_LIST: 'REMOVE_DID_LIST',
      UPDATE_DID: 'UPDATE_DID',
      UPDATE_DID_LIST: 'UPDATE_DID_LIST',
      REMOVE_IP_ADDRESS: 'REMOVE_IP_ADDRESS',
      UPDATE_IP_ADDRESS: 'UPDATE_IP_ADDRESS',
      REMOVE_TRUNK: 'REMOVE_TRUNK',
      UPDATE_TRUNK: 'UPDATE_TRUNK',
      UPDATE_STATUS: 'UPDATE_STATUS',
      GET_SERVICE_STATUS: 'GET_SERVICE_STATUS',
      GENERATE_MOVE_ORDER: 'GENERATE_MOVE_ORDER',
      GET_ADMIN_STATUS: 'GET_ADMIN_STATUS',
      GET_OPERATIVE_STATUS: 'GET_OPERATIVE_STATUS',
      EMBRIX_ORDER: 'EMBRIX_ORDER',
      PRICING_SYNC: 'PRICING_SYNC',
      GET_ORDER_SUMMARY_BY_ACCOUNT_ID: 'GET_ORDER_SUMMARY_BY_ACCOUNT_ID',
      CREATE_XML_INVOICE: 'CREATE_XML_INVOICE',
      CREATE_STAMP_INVOICE: 'CREATE_STAMP_INVOICE',
      GET_PDF_INVOICE: 'GET_PDF_INVOICE',
      CREATE_XML_PAYMENT: 'CREATE_XML_PAYMENT',
      CREATE_STAMP_PAYMENT: 'CREATE_STAMP_PAYMENT',
      GET_PDF_PAYMENT: 'GET_PDF_PAYMENT',
      UPDATE_OPERATIVE_STATUS: 'UPDATE_OPERATIVE_STATUS',
      UPDATE_ADMIN_STATUS: 'UPDATE_ADMIN_STATUS',
      UPDATE_OPERATIVE_ADMIN_STATUS: 'UPDATE_OPERATIVE_ADMIN_STATUS',
      APPLY_PAYMENT: 'APPLY_PAYMENT',
      AUTHORIZE: 'AUTHORIZE',
      CANCEL_ORDER: 'CANCEL_ORDER',
      CAPTURE: 'CAPTURE',
      CREATE_LADING_BILL: 'CREATE_LADING_BILL',
      CREATE_TICKET: 'CREATE_TICKET',
      CREATE_TRANSFER_DOCUMENT: 'CREATE_TRANSFER_DOCUMENT',
      CREDIT: 'CREDIT',
      GET_EXCHANGE_RATE: 'GET_EXCHANGE_RATE',
      GET_CLIENT_SERVICES: 'GET_CLIENT_SERVICES',
      GET_REASONS_CODES: 'GET_REASONS_CODES',
      GET_ITEM_SERVICES: 'GET_ITEM_SERVICES',
      GET_USAGE_ATTRIBUTES: 'GET_USAGE_ATTRIBUTES',
      GET_PROV_ATTR_BY_NAME: 'GET_PROV_ATTR_BY_NAME',
      MANUAL_BILLING: 'MANUAL_BILLING',
      ORDER_APPROVAL: 'ORDER_APPROVAL',
      UPDATE_ORGANIZATION: 'UPDATE_ORGANIZATION',
      UPDATE_PROVISIONING_REQUEST: 'UPDATE_PROVISIONING_REQUEST',
      USER_LOGIN: 'USER_LOGIN',
      VIEW_TICKET_BY_ORGANIZATION: 'VIEW_TICKET_BY_ORGANIZATION',
      VOID: 'VOID',
      CREATE_STAMP_RETENTION: 'CREATE_STAMP_RETENTION',
      GET_ACCOUNTS_INVOICES: 'GET_ACCOUNTS_INVOICES',
      SEND_SMS_NOTIFICATION: 'SEND_SMS_NOTIFICATION',
      UPDATE_WORK_ORDER: 'UPDATE_WORK_ORDER',
      UPDATE_PROVISIONING_IDENTIFIER: 'UPDATE_PROVISIONING_IDENTIFIER',
      UPDATE_ENTERPRISE_PROVISIONING_REQUEST: 'UPDATE_ENTERPRISE_PROVISIONING_REQUEST',
      REVERSE_PAYMENT: 'REVERSE_PAYMENT',
      PAYMENT_NOTIFICATION: 'PAYMENT_NOTIFICATION',
      PROCESS_ACCOUNT_AND_ORDER: 'PROCESS_ACCOUNT_AND_ORDER',
      GET_PROVISIONING_TEMPLATE: 'GET_PROVISIONING_TEMPLATE',
      GET_SPACE_DATA: 'GET_SPACE_DATA',
      GET_LOCATION_DATA: 'GET_LOCATION_DATA',
      CREATE_XML_CREDIT_NOTE: 'CREATE_XML_CREDIT_NOTE',
      CREATE_REPROVISIONING_ORDER: 'CREATE_REPROVISIONING_ORDER',
      CREATE_PROVISION_IPTV_ORDER: 'CREATE_PROVISION_IPTV_ORDER',
      CREATE_DEPROVISIONING_ORDER: 'CREATE_DEPROVISIONING_ORDER',
      SEND_ACCOUNTING_EXTRACT: 'SEND_ACCOUNTING_EXTRACT',
      CREATE_MANUAL_SUSPEND_ORDER: 'CREATE_MANUAL_SUSPEND_ORDER',
      CREATE_MANUAL_RESUME_ORDER: 'CREATE_MANUAL_RESUME_ORDER',
      CREATE_SUSPEND_RECONNECT_ORDER: 'CREATE_SUSPEND_RECONNECT_ORDER',
      GET_AUTHORIZATION_TOKEN: 'GET_AUTHORIZATION_TOKEN',
      USER_LOGOUT: 'USER_LOGOUT',
      CREATE_XML_DEBIT_NOTE: 'CREATE_XML_DEBIT_NOTE',
      PREPAID_TOP_UP: 'PREPAID_TOP_UP',
      CREATE_REPROVISION_IPTV_ORDER: 'CREATE_REPROVISION_IPTV_ORDER',
      CREATE_UTILITIES_ORDER_TRIGGER: 'CREATE_UTILITIES_ORDER_TRIGGER',
      GET_ACCOUNT_PROVISIONING_DATA: 'GET_ACCOUNT_PROVISIONING_DATA',
      GET_BALANCE: 'GET_BALANCE',
      GET_TOP_UP: 'GET_TOP_UP',
      MIGRATE_HISTORIC_BALANCES: 'MIGRATE_HISTORIC_BALANCES',
      PROCESS_FOLIO_STAMPING: 'PROCESS_FOLIO_STAMPING',
      PROCESS_ADJUSTMENT: 'PROCESS_ADJUSTMENT',
      UPDATE_OPTICAL_DATA: 'UPDATE_OPTICAL_DATA',
      UPDATE_PROVISIONING_TEMPLATE_DETAILS: 'UPDATE_PROVISIONING_TEMPLATE_DETAILS',
      SESSION_LOGIN: 'SESSION_LOGIN',
      CREATE_STB_PROFILE_ORDER: 'CREATE_STB_PROFILE_ORDER',
      CREATE_STB_PROVISIONING_ORDER: 'CREATE_STB_PROVISIONING_ORDER',
      CREATE_STB_DEPROVISIONING_ORDER: 'CREATE_STB_DEPROVISIONING_ORDER',
      UPDATE_STB_PROVISIONING_STATUS: 'UPDATE_STB_PROVISIONING_STATUS',
      CREATE_STB_ORDER_SERVICE: 'CREATE_STB_ORDER_SERVICE',
      DELETE_CUSTOMER: 'DELETE_CUSTOMER',
      GET_ACCOUNT_SERVICES: 'GET_ACCOUNT_SERVICES',
    },

    arTypeConfig: {
      PAYMENT: 'PAYMENT',
      PAYMENT_REVERSAL: 'PAYMENT_REVERSAL',
      CREDIT_ADJUSTMENT: 'CREDIT_ADJUSTMENT',
      DEBIT_ADJUSTMENT: 'DEBIT_ADJUSTMENT',
      DISPUTE: 'DISPUTE',
      SETTLEMENT: 'SETTLEMENT',
      WRITE_OFF: 'WRITE_OFF',
      WRITE_OFF_REVERSAL: 'WRITE_OFF_REVERSAL',
      REFUND: 'REFUND',
      ACCOUNT_ADJUSTMENT: 'ACCOUNT_ADJUSTMENT',
    },

    itemType: {
      REVENUE: 'REVENUE',
      EXPENSE: 'EXPENSE',
      RECEIVABLE: 'RECEIVABLE',
      LIABILITY: 'LIABILITY',
      PAYABLE: 'PAYABLE',
    },

    milestoneStatus: {
      PENDING: 'PENDING',
      ACTIVE: 'ACTIVE',
      INACTIVE: 'INACTIVE',
      SUNSET: 'SUNSET',
    },

    milestoneUnits: {
      DAYS: 'DAYS',
      MONTHS: 'MONTHS',
      BILLCYCLES: 'BILLCYCLES',
    },

    usageRecStatus: {
      PENDING: 'PENDING',
      PROCESSED: 'PROCESSED',
      SUSPENDED: 'SUSPENDED',
      FAILED: 'FAILED',
    },

    usageRecIndicator: {
      PREPAID: 'PREPAID',
      POSTPAID: 'POSTPAID',
      PRERATED: 'PRERATED',
      MANUAL_TRANSACTION: 'MANUAL_TRANSACTION',
    },

    jurisdiction: {
      FEDERAL: 'FEDERAL',
      STATE: 'STATE',
      COUNTY: 'COUNTY',
      CITY: 'CITY',
      COUNTRY: 'COUNTRY',
    },

    objectType: {
      ACCOUNT: 'ACCOUNT',
      BILLING: 'BILLING',
      BUNDLE: 'BUNDLE',
      CUSTOMER_SUPPORT: 'CUSTOMER_SUPPORT',
      DOCUMENT: 'DOCUMENT',
      FINANCE: 'FINANCE',
      INVOICE: 'INVOICE',
      ITEM: 'ITEM',
      ITEM_SERVICE: 'ITEM_SERVICE',
      OPERATION: 'OPERATION',
      ORDER: 'ORDER',
      PACKAGE: 'PACKAGE',
      PAYMENT: 'PAYMENT',
      PRICE_OFFER: 'PRICE_OFFER',
      PROJECT: 'PROJECT',
      PROVISIONING_ATTRIBUTES: 'PROVISIONING_ATTRIBUTES',
      PROVISIONING_REQUEST: 'PROVISIONING_REQUEST',
      PROVISIONING_RESPONSE: 'PROVISIONING_RESPONSE',
      REASON_CODES: 'REASON_CODES',
      SERVICE: 'SERVICE',
      TAX: 'TAX',
      USAGE: 'USAGE',
      ACCOUNT_AND_ORDER: 'ACCOUNT_AND_ORDER',
      SUBSCRIPTION: 'SUBSCRIPTION',
      RATING: 'RATING',
    },

    exchangeRateType: {
      PEGGED: 'PEGGED',
      FLOATING: 'FLOATING',
      FORWARD: 'FORWARD',
      SPOT: 'SPOT',
      DUAL: 'DUAL',
      CROSS: 'CROSS',
    },
    billUnitType: {
      REGULAR: 'REGULAR',
      ONE_TIME: 'ONE_TIME',
      REGENERATED: 'REGENERATED',
      MANUAL_BILL: 'MANUAL_BILL',
      IN_ADVANCE_BILL: 'IN_ADVANCE_BILL',
    },

    arOpsType: {
      BILL_ADJUSTMENT: 'BILL_ADJUSTMENT',
      TRANSACTION_ADJUSTMENT: 'TRANSACTION_ADJUSTMENT',
      BILL_DISPUTE: 'BILL_DISPUTE',
      TRANSACTION_DISPUTE: 'TRANSACTION_DISPUTE',
      BILL_SETTLEMENT: 'BILL_SETTLEMENT',
      ACCOUNT_WRITEOFF: 'ACCOUNT_WRITEOFF',
      INVOICE_WRITEOFF: 'INVOICE_WRITEOFF',
      TRANSACTION_SETTLEMENT: 'TRANSACTION_SETTLEMENT',
      WRITE_OFF: 'WRITE_OFF',
      WRITE_OFF_REVERSAL: 'WRITE_OFF_REVERSAL',
      PAYMENT_REVERSAL: 'PAYMENT_REVERSAL',
      PAYMENT: 'PAYMENT',
      REFUND: 'REFUND',
      CHARGEBACK: 'CHARGEBACK',
      TAX: 'TAX',
      TAX_ADJUSTMENT: 'TAX_ADJUSTMENT',
      TAX_SETTLEMENT: 'TAX_SETTLEMENT',
      TAX_DISPUTE: 'TAX_DISPUTE',
      TAX_WRITEOFF: 'TAX_WRITEOFF',
      TAX_WRITEOFF_REVERSAL: 'TAX_WRITEOFF_REVERSAL',
    },

    trialStatus: {
      OPT_IN: 'OPT_IN',
      OPT_OUT: 'OPT_OUT',
    },

    accountStatementLineType: {
      INVOICE: 'INVOICE',
      CREDIT_NOTE: 'CREDIT_NOTE',
      DEBIT_NOTE: 'DEBIT_NOTE',
      PAYMENT: 'PAYMENT',
      PAYMENT_REVERSAL: 'PAYMENT_REVERSAL',
      WRITE_OFF: 'WRITE_OFF',
      WRITE_OFF_REVERSAL: 'WRITE_OFF_REVERSAL',
      CHARGEBACK: 'CHARGEBACK',
      REFUND: 'REFUND',
      ADJUSTMENT: 'ADJUSTMENT',
      SETTLEMENT: 'SETTLEMENT',
      DISPUTE: 'DISPUTE',
      UNDO_CREDIT_NOTE: 'UNDO_CREDIT_NOTE',
      UNDO_DEBIT_NOTE: 'UNDO_DEBIT_NOTE',
    },

    revenueConfigType: {
      GL_ACCOUNT_SEGMENT: 'GL_ACCOUNT_SEGMENT',
      COMPANY_LOCATION: 'COMPANY_LOCATION',
      ENTERPRISE: 'ENTERPRISE',
      DIVISIONS: 'DIVISIONS',
      LEGAL_ENTITY: 'LEGAL_ENTITY',
      BUSINESS_UNIT: 'BUSINESS_UNIT',
      DEPARTMENT: 'DEPARTMENT',
      COST_CENTER: 'COST_CENTER',
      GENERAL_LEDGER: 'GENERAL_LEDGER',
      CHART_OF_ACCOUNT: 'CHART_OF_ACCOUNT',
    },

    billStatus: {
      PENDING: 'PENDING',
      ACTIVE: 'ACTIVE',
    },

    typeActionBill: {
      APPROVE: 'APPROVE',
      DISAPPROVE: 'DISAPPROVE',
      REGENERATE_INVOICE: 'REGENERATE_INVOICE',
      EMAIL_INVOICE: 'EMAIL_INVOICE',
      UNDO_MANUAL_INVOICE: 'UNDO_MANUAL_INVOICE',
      RESYNC_INVOICE: 'RESYNC_INVOICE',
      EXTEND_DUE_DATE: 'EXTEND_DUE_DATE',
      GENERATE_INVOICE: 'GENERATE_INVOICE',
      RESEND_TO_VENDOR: 'RESEND_TO_VENDOR',
      UNDO_INVOICE_CANCEL: 'UNDO_INVOICE_CANCEL',
    },

    adjustmentARType: {
      BILL_ADJUSTMENT: 'BILL_ADJUSTMENT',
      TRANSACTION_ADJUSTMENT: 'TRANSACTION_ADJUSTMENT',
      ACCOUNT_ADJUSTMENT: 'ACCOUNT_ADJUSTMENT',
      INVOICE_LINE_ADJUSTMENT: 'INVOICE_LINE_ADJUSTMENT',
      UNBILLED_LINE_ADJUSTMENT: 'UNBILLED_LINE_ADJUSTMENT',
    },

    disputeARType: {
      BILL_DISPUTE: 'BILL_DISPUTE',
      TRANSACTION_DISPUTE: 'TRANSACTION_DISPUTE',
    },

    writeOffARType: {
      ACCOUNT_WRITEOFF: 'ACCOUNT_WRITEOFF',
      INVOICE_WRITEOFF: 'INVOICE_WRITEOFF',
    },

    costType: {
      INCOME: 'INCOME',
      EXPENSES: 'EXPENSES',
      INCOME_AND_EXPENSES: 'INCOME_AND_EXPENSES',
    },

    calendarUnits: {
      WEEKS: 'WEEKS',
      MONTHS: 'MONTHS',
      QUARTERS: 'QUARTERS',
    },

    calendarStatus: {
      PENDING: 'PENDING',
      ACTIVE: 'ACTIVE',
      INACTIVE: 'INACTIVE',
    },

    GlAccountType: {
      ASSET: 'ASSET',
      EXPENSE: 'EXPENSE',
      LIABILITY: 'LIABILITY',
      EQUITY: 'EQUITY',
      NON_OPERATING_REVENUE: 'NON_OPERATING_REVENUE',
      NON_OPERATING_EXPENSE: 'NON_OPERATING_EXPENSE',
    },

    GlSegmentName: {
      COMPANY: 'COMPANY',
      DEPARTMENT: 'DEPARTMENT',
      DIVISION: 'DIVISION',
      COST_CENTER: 'COST_CENTER',
      ACCOUNT: 'ACCOUNT',
      SUB_ACCOUNT: 'SUB_ACCOUNT',
      INTERCOMPANY: 'INTERCOMPANY',
      LOCAL_USE: 'LOCAL_USE',
      MANAGEMENT: 'MANAGEMENT',
      FUTURE1: 'FUTURE1',
      FUTURE2: 'FUTURE2',
      FUTURE3: 'FUTURE3',
      FUTURE4: 'FUTURE4',
      FUTURE5: 'FUTURE5',
      FUTURE6: 'FUTURE6',
    },

    discountingModel: {
      FLAT: 'FLAT',
      TIERED: 'TIERED',
      RECURRING: 'RECURRING',
      RECURRING_TIERED: 'RECURRING_TIERED',
      TRANSACTION_ATTRIBUTE_BASED: 'TRANSACTION_ATTRIBUTE_BASED',
      CUSTOMER_ATTRIBUTE_BASED: 'CUSTOMER_ATTRIBUTE_BASED',
      USAGE_ATTRIBUTE_BASED: 'USAGE_ATTRIBUTE_BASED',
      COMPLEX: 'COMPLEX',
    },

    transactionTypeDiscount: {
      ONE_TIME: 'ONE_TIME',
      ONE_TIME_CANCEL: 'ONE_TIME_CANCEL',
      FORWARD_RECURRING: 'FORWARD_RECURRING',
      ARREARS_RECURRING: 'ARREARS_RECURRING',
      USAGE: 'USAGE',
      VOLUME_DISCOUNT: 'VOLUME_DISCOUNT',
      DEFAULT: 'DEFAULT',
      TAX: 'TAX',
    },

    noteStatus: {
      ACTIVE: 'ACTIVE',
      CLOSED: 'CLOSED',
      PENDING_APPROVAL: 'PENDING_APPROVAL',
      APPROVED: 'APPROVED',
      DISAPPROVED: 'DISAPPROVED',
      SENT: 'SENT',
      COLLECTION: 'COLLECTION',
      SYNC_FAILED: 'SYNC_FAILED',
      SEND_FAILED: 'SEND_FAILED',
      // STAMPING_REQUESTED: 'STAMPING_REQUESTED',
      // STAMPED: 'STAMPED',
      // CANCELLATION_STAMPING_REQUESTED: 'CANCELLATION_STAMPING_REQUESTED',
      // CANCELLATION_STAMPED: 'CANCELLATION_STAMPED',
    },

    arNotesType: {
      CREDIT_NOTE: 'CREDIT_NOTE',
      DEBIT_NOTE: 'DEBIT_NOTE',
    },

    pricingType: {
      CUSTOMER: 'CUSTOMER',
      SHARING: 'SHARING',
      NONE: 'NONE',
    },

    fileType: {
      IMAGE: 'IMAGE',
      VIDEO: 'VIDEO',
    },

    categoryUploadFile: {
      TENANT: 'TENANT',
      MIGRATION: 'MIGRATION',
      INVOICE: 'INVOICE',
      CREDITNOTE: 'CREDITNOTE',
      DEBITNOTE: 'DEBITNOTE',
      PAYMENT: 'PAYMENT',
      USAGE: 'USAGE',
    },

    filePaymentType: {
      SANTANDER: 'SANTANDER',
      BANCOMER: 'BANCOMER',
      BANAMEX: 'BANAMEX',
      BANORTE: 'BANORTE',
      // BANAMEX_STATEMENT: 'BANAMEX_STATEMENT',
      // BANCOMER_AMEX: 'BANCOMER_AMEX',
    },

    paymentStatus: {
      PENDING: 'PENDING',
      SUCCESS: 'SUCCESS',
      FAILED: 'FAILED',
      NOT_TO_BE_PROCESSED: 'NOT_TO_BE_PROCESSED',
      PROCESSED: 'PROCESSED',
      SUSPENDED: 'SUSPENDED',
    },

    omsTasks: {
      NONE: 'NONE',
      PROVISION_ORDER: 'PROVISION_ORDER',
      BILL_ORDER: 'BILL_ORDER',
      NOTIFY_ORDER: 'NOTIFY_ORDER',
      APPROVE_ORDER: 'APPROVE_ORDER',
      PROJECT_APPROVAL: 'PROJECT_APPROVAL',
      SPLIT_ORDER: 'SPLIT_ORDER',
    },

    apiCategory: {
      ORDER: 'ORDER',
      ACCOUNT: 'ACCOUNT',
      ITEM: 'ITEM',
    },

    commonStatus: {
      ACTIVE: 'ACTIVE',
      INACTIVE: 'INACTIVE',
    },

    openCloseStatus: {
      OPEN: 'OPEN',
      CLOSED: 'CLOSED',
    },

    arWriteoffStatus: {
      WRITTEN_OFF: 'WRITTEN_OFF',
      CLOSED: 'CLOSED',
    },

    provisionGatewayUrlType: {
      BASE_URL: 'BASE_URL',
      CREATE_ITEM: 'CREATE_ITEM',
      CREATE_ERP_SERVICE: 'CREATE_ERP_SERVICE',
      CREATE_CLIENT: 'CREATE_CLIENT',
      CREATE_ACCOUNT: 'CREATE_ACCOUNT',
      CREATE_POINTS: 'CREATE_POINTS',
      CREATE_PROVISIONING_ORDER: 'CREATE_PROVISIONING_ORDER',
      CREATE_ORDER_SERVICE: 'CREATE_ORDER_SERVICE',
      REMOVE_DID: 'REMOVE_DID',
      REMOVE_DID_LIST: 'REMOVE_DID_LIST',
      UPDATE_DID: 'UPDATE_DID',
      UPDATE_DID_LIST: 'UPDATE_DID_LIST',
      REMOVE_IP_ADDRESS: 'REMOVE_IP_ADDRESS',
      UPDATE_IP_ADDRESS: 'UPDATE_IP_ADDRESS',
      REMOVE_TRUNK: 'REMOVE_TRUNK',
      UPDATE_TRUNK: 'UPDATE_TRUNK',
      UPDATE_STATUS: 'UPDATE_STATUS',
      GET_SERVICE_STATUS: 'GET_SERVICE_STATUS',
      GENERATE_MOVE_ORDER: 'GENERATE_MOVE_ORDER',
      UPDATE_ADMIN_STATUS: 'UPDATE_ADMIN_STATUS',
      UPDATE_OPERATIVE_STATUS: 'UPDATE_OPERATIVE_STATUS',
      UPDATE_OPERATIVE_ADMIN_STATUS: 'UPDATE_OPERATIVE_ADMIN_STATUS',
      API_ENDPOINT_TEST: 'API_ENDPOINT_TEST',
      GET_LOCATION_DATA: 'GET_LOCATION_DATA',
      GET_SPACE_DATA: 'GET_SPACE_DATA',
      UPDATE_WORK_ORDER: 'UPDATE_WORK_ORDER',
      CREATE_PROVISION_IPTV_ORDER: 'CREATE_PROVISION_IPTV_ORDER',
      UPDATE_PROVISIONING_REQUEST: 'UPDATE_PROVISIONING_REQUEST',
      UPDATE_ENTERPRISE_PROVISIONING_REQUEST: 'UPDATE_ENTERPRISE_PROVISIONING_REQUEST',
      UPDATE_PROVISIONING_IDENTIFIER: 'UPDATE_PROVISIONING_IDENTIFIER',
      CREATE_DEPROVISIONING_ORDER: 'CREATE_DEPROVISIONING_ORDER',
      CREATE_REPROVISIONING_ORDER: 'CREATE_REPROVISIONING_ORDER',
      GET_PROVISIONING_TEMPLATE: 'GET_PROVISIONING_TEMPLATE',
      CREATE_SUSPEND_RECONNECT_ORDER: 'CREATE_SUSPEND_RECONNECT_ORDER',
      GET_AUTHORIZATION_TOKEN: 'GET_AUTHORIZATION_TOKEN',
      CREATE_CUSTOMER: 'CREATE_CUSTOMER',
      USER_LOGIN: 'USER_LOGIN',
      USER_LOGOUT: 'USER_LOGOUT',
      CREATE_MANUAL_SUSPEND_ORDER: 'CREATE_MANUAL_SUSPEND_ORDER',
      CREATE_MANUAL_RESUME_ORDER: 'CREATE_MANUAL_RESUME_ORDER',
      CREATE_UTILITIES_ORDER_TRIGGER: 'CREATE_UTILITIES_ORDER_TRIGGER',
      CREATE_REPROVISION_IPTV_ORDER: 'CREATE_REPROVISION_IPTV_ORDER',
      UPDATE_PROVISIONING_TEMPLATE_DETAILS: 'UPDATE_PROVISIONING_TEMPLATE_DETAILS',
      GET_ACCOUNT_PROVISIONING_DATA: 'GET_ACCOUNT_PROVISIONING_DATA',
      UPDATE_OPTICAL_DATA: 'UPDATE_OPTICAL_DATA',
      SESSION_LOGIN: 'SESSION_LOGIN',
      CREATE_STB_PROFILE_ORDER: 'CREATE_STB_PROFILE_ORDER',
      CREATE_STB_PROVISIONING_ORDER: 'CREATE_STB_PROVISIONING_ORDER',
      CREATE_STB_DEPROVISIONING_ORDER: 'CREATE_STB_DEPROVISIONING_ORDER',
      UPDATE_STB_PROVISIONING_STATUS: 'UPDATE_STB_PROVISIONING_STATUS',
      CREATE_STB_ORDER_SERVICE: 'CREATE_STB_ORDER_SERVICE',
      DELETE_CUSTOMER: 'DELETE_CUSTOMER',
    },

    arAction: {
      ADJUSTMENT: 'ADJUSTMENT',
      DISPUTE: 'DISPUTE',
      WRITEOFF: 'WRITEOFF',
      PAYMENT: 'PAYMENT',
      PAYMENT_REVERSAL: 'PAYMENT_REVERSAL',
      SETTLEMENT: 'SETTLEMENT',
      WRITEOFF_REVERSAL: 'WRITEOFF_REVERSAL',
    },

    objectTypeApproval: {
      ITEM: 'ITEM',
      PRICE_OFFER: 'PRICE_OFFER',
      DISCOUNT_OFFER: 'DISCOUNT_OFFER',
      BUNDLE: 'BUNDLE',
      PACKAGE: 'PACKAGE',
      INVOICE: 'INVOICE',
      CREDIT_NOTES: 'CREDIT_NOTES',
      DEBIT_NOTES: 'DEBIT_NOTES',
      NOTES: 'NOTES',
      PAYMENT: 'PAYMENT',
    },

    statusOrderLines: {
      CREATED: 'CREATED',
      EDITED: 'EDITED',
      CANCELLED: 'CANCELLED',
      SUBMITTED: 'SUBMITTED',
      PROCESSING: 'PROCESSING',
      BILLED: 'BILLED',
      PROVISIONED: 'PROVISIONED',
      COMPLETED: 'COMPLETED',
      FAILED: 'FAILED',
      BILLING_INITIATED: 'BILLING_INITIATED',
      BILLING_ERROR: 'BILLING_ERROR',
      PROVISIONING_INITIATED: 'PROVISIONING_INITIATED',
      PROVISIONING_ERROR: 'PROVISIONING_ERROR',
      PROVISION_PROCESS: 'PROVISION_PROCESS',
    },

    grantTypeConfig: {
      AMOUNT: 'AMOUNT',
      QUANTITY: 'QUANTITY',
      COUNT: 'COUNT',
    },

    creditNoteActions: {
      APPROVED: 'APPROVE',
      DISAPPROVED: 'DISAPPROVE',
      RESEND_TO_VENDOR: 'RESEND_TO_VENDOR',
    },

    objectStatus: {
      ACTIVE: 'ACTIVE',
      PENDING: 'PENDING',
      CLOSED: 'CLOSED',
    },
    flexAttribute: {
      FLEXATTR1: 'FLEXATTR1',
      FLEXATTR2: 'FLEXATTR2',
      FLEXATTR3: 'FLEXATTR3',
      FLEXATTR4: 'FLEXATTR4',
      FLEXATTR5: 'FLEXATTR5',
      FLEXATTR6: 'FLEXATTR6',
      FLEXATTR7: 'FLEXATTR7',
      FLEXATTR8: 'FLEXATTR8',
      FLEXATTR9: 'FLEXATTR9',
      FLEXATTR10: 'FLEXATTR10',
      FLEXATTR11: 'FLEXATTR11',
      FLEXATTR12: 'FLEXATTR12',
      FLEXATTR13: 'FLEXATTR13',
      FLEXATTR14: 'FLEXATTR14',
      FLEXATTR15: 'FLEXATTR15',
      FLEXATTR16: 'FLEXATTR16',
      FLEXATTR17: 'FLEXATTR17',
      FLEXATTR18: 'FLEXATTR18',
      FLEXATTR19: 'FLEXATTR19',
      FLEXATTR20: 'FLEXATTR20',
      FLEXATTR21: 'FLEXATTR21',
      FLEXATTR22: 'FLEXATTR22',
      FLEXATTR23: 'FLEXATTR23',
      FLEXATTR24: 'FLEXATTR24',
      FLEXATTR25: 'FLEXATTR25',
    },

    mandatoryFlag: {
      Y: 'Y',
      N: 'N',
    },

    orderAction: {
      ADD: 'ADD',
      MODIFY: 'MODIFY',
      CANCEL: 'CANCEL',
      SUSPEND: 'SUSPEND',
      RESUME: 'RESUME',
      ALL: 'ALL',
    },

    statusProvisioning: {
      ACTIVE: 'ACTIVE',
      CANCELLED: 'CANCELLED',
    },

    debitAccountType: {
      BILLED_REVENUE: 'BILLED_REVENUE',
      UNBILLED_REVENUE: 'UNBILLED_REVENUE',
      BILLED_EARNED_REVENUE: 'BILLED_EARNED_REVENUE',
      BILLED_UNEARNED_REVENUE: 'BILLED_UNEARNED_REVENUE',
      UNBILLED_EARNED_REVENUE: 'UNBILLED_EARNED_REVENUE',
      CASH: 'CASH',
      CASH_RESERVE: 'CASH_RESERVE',
      RESERVES: 'RESERVES',
      BAD_DEBT: 'BAD_DEBT',
      CURRENCY_EXCHANGE: 'CURRENCY_EXCHANGE',
      TAX: 'TAX',
      NON_TAX: 'NON_TAX',
      BILLED_CUSTOMER: 'BILLED_CUSTOMER',
      EXCHANGE_RATE_DELTA: 'EXCHANGE_RATE_DELTA',
      REALIZED_GAIN: 'REALIZED_GAIN',
      REALIZED_LOSS: 'REALIZED_LOSS',
      TAX_PAID: 'TAX_PAID',
      IEPS_INVOICED: 'IEPS_INVOICED',
      IVA_INVOICED: 'IVA_INVOICED',
      IEPS_PAID: 'IEPS_PAID',
      IVA_PAID: 'IVA_PAID',
    },

    accountingRulesObjectType: {
      INVOICE: 'INVOICE',
      CREDIT_NOTE: 'CREDIT_NOTE',
      DEBIT_NOTE: 'DEBIT_NOTE',
      PAYMENT: 'PAYMENT',
      ADJUSTMENTS: 'ADJUSTMENTS',
      INVOICE_RECOGNITION: 'INVOICE_RECOGNITION',
      WRITEOFF: 'WRITEOFF',
      INVOICE_LINE_ADJUSTMENT: 'INVOICE_LINE_ADJUSTMENT',
      INVOICE_LINE_ADJUSTMENT_RECOGNITION: 'INVOICE_LINE_ADJUSTMENT_RECOGNITION',
      EXCHANGE_RATE: 'EXCHANGE_RATE',
      PAYMENT_RECEIPTS: 'PAYMENT_RECEIPTS',
      PAYMENT_RECEIPTS_REVERSAL: 'PAYMENT_RECEIPTS_REVERSAL',
      PAYMENT_REVERSAL: 'PAYMENT_REVERSAL',
      DISPUTE: 'DISPUTE',
    },

    barChartDashboardType: {
      USAGE_DATA_BY_SERVICE_TYPE: 'USAGE_DATA_BY_SERVICE_TYPE',
      USAGE_DATA_BY_CALL_TYPE: 'USAGE_DATA_BY_CALL_TYPE',
    },

    xaxisParameter: {
      OPERATOR: 'OPERATOR',
      ORGANIZATION: 'ORGANIZATION',
      SERVICETYPE: 'SERVICETYPE',
      CALLTYPE: 'CALLTYPE',
    },

    yaxisParameter: {
      COUNT: 'COUNT',
      QUANTITY: 'QUANTITY',
      AMOUNT: 'AMOUNT',
    },

    chargeType: {
      R: 'R',
      U: 'U',
      M: 'M',
    },

    batchPaymentType: {
      SANTANDER: 'SANTANDER',
      BANCOMER: 'BANCOMER',
      BANORTE: 'BANORTE',
      BANAMEX: 'BANAMEX',
      BANAMEX_STATEMENT: 'BANAMEX_STATEMENT',
      BANCOMER_AMEX: 'BANCOMER_AMEX',
    },

    provisioningLevel: {
      LINE: 'LINE',
      SERVICE: 'SERVICE',
      ORDER: 'ORDER',
    },

    orderSource: {
      INTERNAL: 'INTERNAL',
      EXTERNAL: 'EXTERNAL',
      INTERNAL_BATCH_JOBS: 'INTERNAL_BATCH_JOBS',
    },

    typeProvisioning: {
      TRUNK: 'TRUNK',
      DID: 'DID',
      IP: 'IP',
      DEVICE: 'DEVICE',
    },

    switchName: {
      SMS: 'SMS',
      DATA: 'DATA',
      MET: 'MET',
      METII: 'METII',
      METITX: 'METITX',
      SSMETMX: 'SSMETMX',
      SSMET: 'SSMET',
      BROADSOFT: 'BROADSOFT',
      BROADSOFTMTY: 'BROADSOFTMTY',
      DIDWW: 'DIDWW',
      NEWSMS: 'NEWSMS',
    },

    paymentAction: {
      ALLOCATE_PAYMENT: 'ALLOCATE_PAYMENT',
      REVERSE_PAYMENT: 'REVERSE_PAYMENT',
      RE_GENERATE_FOLIO: 'RE_GENERATE_FOLIO',
      // MANUAL_CANCELLATION_UPDATE: 'MANUAL_CANCELLATION_UPDATE',
      // CANCEL_PAYMENT: 'CANCEL_PAYMENT',
      RESEND_TO_VENDOR: 'RESEND_TO_VENDOR',
    },

    paymentReversalReason: {
      INTERNAL_ERROR: 'INTERNAL_ERROR',
      WRONG_ALLOCATION: 'WRONG_ALLOCATION',
      CUSTOMER_REQUEST: 'CUSTOMER_REQUEST',
      WRONG_ACCOUNT: 'WRONG_ACCOUNT',
      WRONG_INVOICE: 'WRONG_INVOICE',
    },

    notificationType: {
      PAYMENT_FAILURE: 'PAYMENT_FAILURE',
      FIRST_PAYMENT_REMINDER: 'FIRST_PAYMENT_REMINDER',
      SECOND_PAYMENT_REMINDER: 'SECOND_PAYMENT_REMINDER',
      THIRD_PAYMENT_REMINDER: 'THIRD_PAYMENT_REMINDER',
      PAYMENT_SUCCESS: 'PAYMENT_SUCCESS',
      INVOICE_DUE_REMINDER: 'INVOICE_DUE_REMINDER',
      NEW_SUBSCRIPTION: 'NEW_SUBSCRIPTION',
      CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
      RENEWAL_DUE: 'RENEWAL_DUE',
      INVOICE_READY: 'INVOICE_READY',
      TRIAL_EXPIRY: 'TRIAL_EXPIRY',
      TRIAL_EXPIRY_OPT_IN: 'TRIAL_EXPIRY_OPT_IN',
      TRIAL_EXPIRY_OPT_OUT: 'TRIAL_EXPIRY_OPT_OUT',
      CREDIT_LIMIT_BREACH: 'CREDIT_LIMIT_BREACH',
      CREDIT_THRESHOLD_BREACH: 'CREDIT_THRESHOLD_BREACH',
      JOB_SCHEDULE_ERROR: 'JOB_SCHEDULE_ERROR',
      INVOICE_READY_FOR_APPROVAL: 'INVOICE_READY_FOR_APPROVAL',
      CREDIT_NOTE_APPROVAL: 'CREDIT_NOTE_APPROVAL',
      USAGE_PROCESS_STATUS: 'USAGE_PROCESS_STATUS',
      ADHOC: 'ADHOC',
      CREDIT_NOTE_READY: 'CREDIT_NOTE_READY',
      SUSPEND_SUBSCRIPTION: 'SUSPEND_SUBSCRIPTION',
      COLLECTION_ENTRY: 'COLLECTION_ENTRY',
      RESUME_SUBSCRIPTION: 'RESUME_SUBSCRIPTION',
      GROUP_INVOICE_READY: 'GROUP_INVOICE_READY',
      BALANCE_TOPUP: 'BALANCE_TOPUP',
      MONTH_END_BALANCE_UPDATE: 'MONTH_END_BALANCE_UPDATE',
      PREPAID_SUFFICIENT_CREDIT: 'PREPAID_SUFFICIENT_CREDIT',
      PREPAID_INSUFFICIENT_CREDIT: 'PREPAID_INSUFFICIENT_CREDIT',
      PREPAID_OVERAGE: 'PREPAID_OVERAGE',
    },

    planType: {
      LDI: 'LDI',
      LDN: 'LDN',
    },

    deltaPaymentSplitType: {
      WITH_THRESHOLD: 'WITH_THRESHOLD',
      WITHOUT_THRESHOLD: 'WITHOUT_THRESHOLD',
    },

    usageDirection: {
      IN: 'IN',
      OUT: 'OUT',
      INOUT: 'INOUT',
    },

    customDBSeqObjectType: {
      INVOICE_DB_SEQ: 'INVOICE_DB_SEQ',
      PAYMENT_DB_SEQ: 'PAYMENT_DB_SEQ',
      CREDIT_NOTE_DB_SEQ: 'CREDIT_NOTE_DB_SEQ',
      DEBIT_NOTE_DB_SEQ: 'DEBIT_NOTE_DB_SEQ',
    },

    revenueActivityType: {
      PURCHASE: 'PURCHASE',
      CANCEL: 'CANCEL',
      BILLING: 'BILLING',
      PAYMENT: 'PAYMENT',
      ADJUSTMENT: 'ADJUSTMENT',
      WRITEOFF: 'WRITEOFF',
      MANUAL: 'MANUAL',
      USAGE: 'USAGE',
      PAYMENT_RECEIPTS: 'PAYMENT_RECEIPTS',
      PAYMENT_RECEIPTS_REVERSAL: 'PAYMENT_RECEIPTS_REVERSAL',
    },

    revenueJournalStatus: {
      RECORDED: 'RECORDED',
      BILLED: 'BILLED',
      POSTED: 'POSTED',
      PENDING_APPROVAL: 'PENDING_APPROVAL',
      CANCELLED: 'CANCELLED',
      PENDING: 'PENDING',
    },

    sourceSystem: {
      SSMETMX: 'SSMETMX',
      METITX: 'METITX',
      SSMET: 'SSMET',
      METII: 'METII',
      MET: 'MET',
      BROADSOFTMTY: 'BROADSOFTMTY',
      SMS: 'SMS',
      BROADSOFT: 'BROADSOFT',
    },

    ratingDescription: {
      NPA_RATE: 'NPA_RATE',
      BNUMBERPERFIX_RATE: 'BNUMBERPERFIX_RATE',
    },

    activityAction: {
      CREATE: 'CREATE',
      MODIFY: 'MODIFY',
      DELETE: 'DELETE',
      MERGE: 'MERGE',
      DISPERSE: 'DISPERSE',
      UNDO_MERGE: 'UNDO_MERGE',
      UNDO_DISPERSE: 'UNDO_DISPERSE',
    },

    gatewayType: {
      PAYMENT_GATEWAY: 'PAYMENT_GATEWAY',
      TAX_GATEWAY: 'TAX_GATEWAY',
      FINANCE_GATEWAY: 'FINANCE_GATEWAY',
      CRM_GATEWAY: 'CRM_GATEWAY',
      PROVISIONING_GATEWAY: 'PROVISIONING_GATEWAY',
      CUSTOMER_SUPPORT_GATEWAY: 'CUSTOMER_SUPPORT_GATEWAY',
      DOCUMENT_GATEWAY: 'DOCUMENT_GATEWAY',
      SUPPORT_GATEWAY: 'SUPPORT_GATEWAY',
      SSO_GATEWAY: 'SSO_GATEWAY',
      USAGE_GATEWAY: 'USAGE_GATEWAY',
      PROJECT_GATEWAY: 'PROJECT_GATEWAY',
      OPERATION_GATEWAY: 'OPERATION_GATEWAY',
    },

    groupByType: {
      ACCOUNT: 'ACCOUNT',
      INVOICE: 'INVOICE',
      AGING_BUCKET: 'AGING_BUCKET',
    },

    paymentReportCategory: {
      PAYMENT: 'PAYMENT',
      SUSPENSE: 'SUSPENSE',
      ALL: 'ALL',
    },

    operativeStatus: {
      DELIVERED: 'DELIVERED',
      ASSIGNED: 'ASSIGNED',
      SUSPENDED: 'SUSPENDED',
      CANCELED: 'CANCELED',
      CONFIGURED: 'CONFIGURED',
      IMPLEMENTATION: 'IMPLEMENTATION',
    },

    administrativeStatus: {
      ACTIVO: 'ACTIVO',
      SUSPENDIDO: 'SUSPENDED',
      CANCELADO: 'CANCELADO',
      EN_IMPLEMENTACION: 'EN IMPLEMENTACION',
      REACTIVACION: 'REACTIVACION',
    },

    reportStatusItemService: {
      ACTIVE: 'ACTIVE',
      SUSPENDED: 'SUSPENDED',
      CANCELLED: 'CANCELLED',
      TECHNICAL_SUSPEND: 'TECHNICAL_SUSPEND',
    },

    revenueReportObjectType: {
      INVOICE: 'INVOICE',
      NOTES: 'NOTES',
      ADJUSTMENT: 'ADJUSTMENT',
      WRITEOFF: 'WRITEOFF',
      PAYMENT: 'PAYMENT',
    },

    ebsExtractStatus: {
      PENDING: 'PENDING',
      SENT: 'SENT',
      REGENERATED: 'REGENERATED',
      FAILED: 'FAILED',
      RESENT: 'RESENT',
    },

    ebsExtractType: {
      INVOICE: 'INVOICE',
      ADJUSTMENT: 'ADJUSTMENT',
      CREDIT_NOTE: 'CREDIT_NOTE',
      DEBIT_NOTE: 'DEBIT_NOTE',
      WRITE_OFF: 'WRITE_OFF',
      PAYMENT: 'PAYMENT',
      INVOICE_RECOGNITION: 'INVOICE_RECOGNITION',
      INVOICE_LINE_ADJUSTMENT: 'INVOICE_LINE_ADJUSTMENT',
      INVOICE_LINE_ADJUSTMENT_RECOGNITION: 'INVOICE_LINE_ADJUSTMENT_RECOGNITION',
      EXCHANGE_RATE: 'EXCHANGE_RATE',
      PAYMENT_REVERSAL: 'PAYMENT_REVERSAL',
      PAYMENT_RECEIPTS: 'PAYMENT_RECEIPTS',
      DISPUTE: 'DISPUTE',
      ACCOUNT_RECEIVABLE: 'ACCOUNT_RECEIVABLE',
      PAYMENT_RECEIPTS_REVERSAL: 'PAYMENT_RECEIPTS_REVERSAL',
      TOP_UP: 'TOP_UP',
    },

    activitySource: {
      EXTERNAL: 'EXTERNAL',
      SELF_CARE: 'SELF_CARE',
      AGENT_CARE: 'AGENT_CARE',
      CORE_JOBS: 'CORE_JOBS',
      PAYMENT: 'PAYMENT',
      PAYMENT_SUSPENSE: 'PAYMENT_SUSPENSE',
      INTERNAL_BATCH_JOBS: 'INTERNAL_BATCH_JOBS',
      MANUAL: 'MANUAL',
      INTERNAL: 'INTERNAL',
      EMBRIX: 'EMBRIX',
      CORE_UI: 'CORE_UI',
    },

    projectGatewayUrlType: {
      BASE_URL: 'BASE_URL',
      CREATE_CLIENT: 'CREATE_CLIENT',
      CANCEL_ORDER: 'CANCEL_ORDER',
    },

    customerSupportGatewayUrlType: {
      BASE_URL: 'BASE_URL',
      CREATE_ORGANIZATION: 'CREATE_ORGANIZATION',
      UPDATE_ORGANIZATION: 'UPDATE_ORGANIZATION',
      CREATE_USER: 'CREATE_USER',
      CREATE_TICKET: 'CREATE_TICKET',
      VIEW_TICKET_BY_ORGANIZATION: 'VIEW_TICKET_BY_ORGANIZATION',
    },

    documentGatewayUrlType: {
      BASE_URL: 'BASE_URL',
      CREATE_XML_INVOICE: 'CREATE_XML_INVOICE',
      CREATE_STAMP_INVOICE: 'CREATE_STAMP_INVOICE',
      GET_PDF_INVOICE: 'GET_PDF_INVOICE',
      CREATE_XML_PAYMENT: 'CREATE_XML_PAYMENT',
      CREATE_STAMP_PAYMENT: 'CREATE_STAMP_PAYMENT',
      GET_PDF_PAYMENT: 'GET_PDF_PAYMENT',
      CREATE_LADING_BILL: 'CREATE_LADING_BILL',
      CREATE_TRANSFER_DOCUMENT: 'CREATE_TRANSFER_DOCUMENT',
      CREATE_STAMP_RETENTION: 'CREATE_STAMP_RETENTION',
      CREATE_XML_CREDIT_NOTE: 'CREATE_XML_CREDIT_NOTE',
      CREATE_XML_DEBIT_NOTE: 'CREATE_XML_DEBIT_NOTE',
    },

    operationGatewayUrlType: {
      BASE_URL: 'BASE_URL',
      GET_EXCHANGE_RATE: 'GET_EXCHANGE_RATE',
      SEND_SMS_NOTIFICATION: 'SEND_SMS_NOTIFICATION',
      GET_AUTHORIZATION_TOKEN: 'GET_AUTHORIZATION_TOKEN',
      NOTIFY_CANCELLATION_TO_CRM: 'NOTIFY_CANCELLATION_TO_CRM',
    },

    crmGatewayUrlType: {
      EMBRIX_ORDER: 'EMBRIX_ORDER',
      CREATE_ACCOUNT: 'CREATE_ACCOUNT',
      GET_ORDER_SUMMARY_BY_ACCOUNT_ID: 'GET_ORDER_SUMMARY_BY_ACCOUNT_ID',
      ORDER_APPROVAL: 'ORDER_APPROVAL',
      APPLY_PAYMENT: 'APPLY_PAYMENT',
      MANUAL_BILLING: 'MANUAL_BILLING',
      CREATE_LADING_BILL: 'CREATE_LADING_BILL',
      GET_CLIENT_SERVICES: 'GET_CLIENT_SERVICES',
      GET_REASONS_CODES: 'GET_REASONS_CODES',
      GET_ITEM_SERVICES: 'GET_ITEM_SERVICES',
      GET_USAGE_ATTRIBUTES: 'GET_USAGE_ATTRIBUTES',
      GET_PROV_ATTR_BY_NAME: 'GET_PROV_ATTR_BY_NAME',
      USER_LOGIN: 'USER_LOGIN',
      GET_ACCOUNTS_INVOICES: 'GET_ACCOUNTS_INVOICES',
      PROCESS_ACCOUNT_AND_ORDER: 'PROCESS_ACCOUNT_AND_ORDER',
      PRICING_SYNC: 'PRICING_SYNC',
      PAYMENT_NOTIFICATION: 'PAYMENT_NOTIFICATION',
      BASE_URL: 'BASE_URL',
      REVERSE_PAYMENT: 'REVERSE_PAYMENT',
      GET_AUTHORIZATION_TOKEN: 'GET_AUTHORIZATION_TOKEN',
      PREPAID_TOP_UP: 'PREPAID_TOP_UP',
      GET_BALANCE: 'GET_BALANCE',
      GET_TOP_UP: 'GET_TOP_UP',
      MIGRATE_HISTORIC_BALANCES: 'MIGRATE_HISTORIC_BALANCES',
      PROCESS_FOLIO_STAMPING: 'PROCESS_FOLIO_STAMPING',
      PROCESS_ADJUSTMENT: 'PROCESS_ADJUSTMENT',
      GET_ACCOUNT_SERVICES: 'GET_ACCOUNT_SERVICES',
    },

    switchNameRawFileData: {
      BROADSOFT: 'BROADSOFT',
      BROADSOFTMTY: 'BROADSOFTMTY',
    },

    switchNameRawNonBroadsoft: {
      MET: 'MET',
      METII: 'METII',
      METITX: 'METITX',
      SSMETMX: 'SSMETMX',
      SSMET: 'SSMET',
      DIDWW: 'DIDWW',
      TWILIO: 'TWILIO',
    },

    operatorFileStatus: {
      PENDING: 'PENDING',
      PROCESSED: 'PROCESSED',
      RECONCILED: 'RECONCILED',
    },

    customAttributeCategory: {
      CUSTOMER: 'CUSTOMER',
      ORDER: 'ORDER',
      RATING: 'RATING',
      MEDIATION: 'MEDIATION',
      BILLING: 'BILLING',
      INVOICING: 'INVOICING',
      PAYMENTS: 'PAYMENTS',
      REPORTS: 'REPORTS',
      TAXATION: 'TAXATION',
      AR: 'AR',
      COLLECTION: 'COLLECTION',
      REVENUE: 'REVENUE',
      CORRESPONDENCE: 'CORRESPONDENCE',
      DEFAULT: 'DEFAULT',
      EMBRIX_INTERNAL: 'EMBRIX_INTERNAL',
    },

    ladingType: {
      LADING_BILL: 'LADING_BILL',
      TRANSFER: 'TRANSFER',
    },

    grantUnit: {
      DAYS: 'DAYS',
      WEEKS: 'WEEKS',
      MONTHS: 'MONTHS',
      QUARTERS: 'QUARTERS',
      YEARS: 'YEARS',
      FOREVER: 'FOREVER',
    },

    accumulatorUnit: {
      DAYS: 'DAYS',
      WEEKS: 'WEEKS',
      MONTHS: 'MONTHS',
      QUARTERS: 'QUARTERS',
      YEARS: 'YEARS',
      FOREVER: 'FOREVER',
    },

    pacRecordStatus: {
      PENDING: 'PENDING',
      SENT: 'SENT',
      COMPLETE: 'COMPLETE',
      CLOSED: 'CLOSED',
    },

    pacRecordType: {
      INVOICE: 'INVOICE',
      CREDITNOTE: 'CREDITNOTE',
      PAYMENT: 'PAYMENT',
      TRANSFER_DOCUMENT: 'TRANSFER_DOCUMENT',
      LADING_BILL: 'LADING_BILL',
    },

    pacExtractType: {
      REGULAR: 'REGULAR',
      CANCELLATION: 'CANCELLATION',
      SUBSTITUTION: 'SUBSTITUTION',
    },

    chargeTypeNoM: {
      R: 'R',
      U: 'U',
    },

    statusSaveAccountNonBillables: {
      ACTIVE: 'ACTIVE',
      CANCELLED: 'CANCELLED',
      SUSPENDED: 'SUSPENDED',
    },

    timeAndBillingObjectStatus: {
      ACTIVE: 'ACTIVE',
      ENDED: 'ENDED',
    },

    clientPricingUnit: {
      HOUR: 'HOUR',
      DAY: 'DAY',
      MONTH: 'MONTH',
      QUARTER: 'QUARTER',
      HALF_YEAR: 'HALF_YEAR',
      YEAR: 'YEAR',
    },

    quarter: {
      1: 'Q1',
      2: 'Q2',
      3: 'Q3',
      4: 'Q4',
    },

    cutOffDOM: {
      '-2': 'USAGE',
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
      8: '8',
      9: '9',
      10: '10',
      11: '11',
      12: '12',
      13: '13',
      14: '14',
      15: '15',
      16: '16',
      17: '17',
      18: '18',
      19: '19',
      20: '20',
      21: '21',
      22: '22',
      23: '23',
      24: '24',
      25: '25',
      26: '26',
      27: '27',
      28: '28',
      29: '29',
      30: '30',
      31: '31',
    },

    syncAPI: {
      SYNC_PROJECT: 'SYNC_PROJECT',
    },

    switchNameRawSmsFile: {
      SMS: 'SMS',
      NEWSMS: 'NEWSMS',
    },

    matchStatus: {
      MATCH: 'MATCH',
      NOT_MATCH: 'NOT_MATCH',
    },

    journalCreate: {
      CREATE_REV_REC: 'CREATE_REV_REC',
      COPY_TO_CREATE: 'COPY_TO_CREATE',
    },

    longDistanceType: {
      N: 'N - National',
      I: 'I - International',
    },

    userType: {
      AGENT: 'AGENT',
      ADI: 'ADI',
      AutoCuidado: 'AutoCuidado',
      'MCM Rol De Solo Lectura': 'MCM Rol De Solo Lectura',
      'Precios Leer Solamente': 'Precios Leer Solamente',
      'Administrador De Precios': 'Administrador De Precios',
      'Customer Care': 'Customer Care',
    },

    ERPExtractsAction: {
      RE_EXTRACT_RE_SEND: 'RE_EXTRACT_RE_SEND',
      RE_SEND: 'RE_SEND',
    },

    manualInvoiceStatus: {
      PENDING: 'PENDING',
      CLOSED: 'CLOSED',
      PROCESSING: 'PROCESSING',
      ERRORED: 'ERRORED',
    },

    mergeSelectOptions: {
      NEW_MERGE: 'NEW_MERGE',
      UNDO_PREVIOUS_DISPERSE: 'UNDO_PREVIOUS_DISPERSE',
      SELECT_SAVED_MOVEMENT: 'SELECT_SAVED_MOVEMENT',
    },

    reratingBatchStatus: {
      PENDING: 'PENDING',
      CANCELLED: 'CANCELLED',
      PROCESSED: 'PROCESSED',
    },

    disperseSelectOptions: {
      NEW_DISPERSE: 'NEW_DISPERSE',
      UNDO_PREVIOUS_DISPERSE: 'UNDO_PREVIOUS_DISPERSE',
      SELECT_SAVED_MOVEMENT: 'SELECT_SAVED_MOVEMENT',
    },

    invoiceUnitStatus: {
      ACTIVE: 'ACTIVE',
      CLOSED: 'CLOSED',
      COLLECTION: 'COLLECTION',
      // SENT: 'SENT',
      PENDING_APPROVAL: 'PENDING_APPROVAL',
      APPROVED: 'APPROVED',
      DISAPPROVED: 'DISAPPROVED',
      SYNC_FAILED: 'SYNC_FAILED',
      // SEND_FAILED: 'SEND_FAILED',
      // STAMPING_REQUESTED: 'STAMPING_REQUESTED',
      // STAMPED: 'STAMPED',
      // CANCELLATION_STAMPING_REQUESTED: 'CANCELLATION_STAMPING_REQUESTED',
      // CANCELLATION_STAMPED: 'CANCELLATION_STAMPED',
    },

    notesCrDrType: {
      CR: 'CR',
      DR: 'DR',
    },

    rerateReasonCodes: {
      PRICING_CHANGE: 'PRICING_CHANGE',
      WRONG_ORDER: 'WRONG_ORDER',
      CUSTOMER_REQUEST: 'CUSTOMER_REQUEST',
      PROVISIONING_ERROR: 'PROVISIONING_ERROR',
      SOURCE_USAGE_DATA_ERROR: 'SOURCE_USAGE_DATA_ERROR',
      INTERNAL_RATING_ERROR: 'INTERNAL_RATING_ERROR',
    },

    revenueObjectType: {
      INVOICE: 'INVOICE',
      CREDIT_NOTE: 'CREDIT_NOTE',
      DEBIT_NOTE: 'DEBIT_NOTE',
      ADJUSTMENT: 'ADJUSTMENT',
      WRITEOFF: 'WRITEOFF',
      PAYMENT: 'PAYMENT',
    },

    interfaceType: {
      FILE: 'FILE',
      API: 'API',
      QUEUE: 'QUEUE',
      DB_QUERY: 'DB_QUERY',
    },

    itemServiceStatus: {
      CREATE: 'CREATE',
      APPROVED: 'APPROVED',
      SELLABLE: 'SELLABLE',
      NON_SELLABLE: 'NON_SELLABLE',
      SUNSET: 'SUNSET',
    },

    documentType: {
      INVOICE: 'INVOICE',
      TRANSFER: 'TRANSFER',
      LADING_BILL: 'LADING_BILL',
      CREDIT_NOTE: 'CREDIT_NOTE',
      DEBIT_NOTE: 'DEBIT_NOTE',
      PAYMENT: 'PAYMENT',
    },

    satCode: {
      '01': '01: Comprobante Emitido Con Errores Con Relación',
      '02': '02: Comprobante Emitido Con Errores Sin Relación',
      '03': '03: No Se Llevó a Cabo La Operación',
    },

    trafficType: {
      TERMINATION: 'TERMINATION',
      TRANSIT: 'TRANSIT',
    },

    batchPaymentStatus: {
      PENDING: 'PENDING',
      SUCCESS: 'SUCCESS',
      FAILED: 'FAILED',
      NOT_TO_BE_PROCESSED: 'NOT_TO_BE_PROCESSED',
      PROCESSED: 'PROCESSED',
      SUSPENDED: 'SUSPENDED',
    },

    batchPaymentFileStatus: {
      PENDING_LOAD: 'PENDING_LOAD',
      LOADED: 'LOADED',
      PROCESSING: 'PROCESSING',
      PROCESSED: 'PROCESSED',
    },

    monthOfYearFull: {
      January: 'January',
      February: 'February',
      March: 'March',
      April: 'April',
      May: 'May',
      June: 'June',
      July: 'July',
      August: 'August',
      September: 'September',
      October: 'October',
      November: 'November',
      December: 'December',
    },

    paymentSuspenseStatus: {
      CLOSED: 'CLOSED',
      OPEN: 'OPEN',
    },

    paymentActionHistory: {
      ALLOCATE_PAYMENT: 'ALLOCATE_PAYMENT',
      AUTO_ALLOCATE: 'AUTO_ALLOCATE',
      REVERSE_PAYMENT: 'REVERSE_PAYMENT',
      EMAIL_FOLIO: 'EMAIL_FOLIO',
      RESEND_TO_VENDOR: 'RESEND_TO_VENDOR',
    },

    collectionStatus: {
      CLOSED: 'CLOSED',
      CUSTOMER_CONTACT_ESTABLISHED: 'CUSTOMER_CONTACT_ESTABLISHED',
      CUSTOMER_CONTACT_INITIATED: 'CUSTOMER_CONTACT_INITIATED',
      GRACE_PERIOD_REQUESTED: 'GRACE_PERIOD_REQUESTED',
      OPEN: 'OPEN',
      PROMISE_BROKEN: 'PROMISE_BROKEN',
      PROMISE_TO_PAY: 'PROMISE_TO_PAY',
      SUSPEND: 'SUSPEND',
      WRITEOFF: 'WRITEOFF',
    },

    folioStatus: {
      STAMPING_REQUESTED: 'STAMPING_REQUESTED',
      STAMPED: 'STAMPED',
      CANCELLATION_STAMPING_REQUESTED: 'CANCELLATION_STAMPING_REQUESTED',
      CANCELLATION_STAMPED: 'CANCELLATION_STAMPED',
      AWAITING_CANCELLATION_STAMPING: 'AWAITING_CANCELLATION_STAMPING',
    },

    commitmentTerm: {
      LATEST_TERM: 'LATEST_TERM',
      PREVIOUS_TERM: 'PREVIOUS_TERM',
    },

    installmentStatus: {
      PAID: 'PAID',
      PENDING: 'PENDING',
    },

    collectionReportGroupByType: {
      ACCOUNT: 'ACCOUNT',
      AGING_BUCKET: 'AGING_BUCKET',
      INVOICE: 'INVOICE',
      ACCOUNT_CATEGORY: 'ACCOUNT_CATEGORY',
    },

    paymentAgeingReportGroupByType: {
      ACCOUNT: 'ACCOUNT',
      AGING_BUCKET: 'AGING_BUCKET',
      PAYMENT: 'PAYMENT',
      ACCOUNT_CATEGORY: 'ACCOUNT_CATEGORY',
    },

    paymentActionSuspense: {
      PROCESS_SUSPENSE: 'PROCESS_SUSPENSE',
      CLOSE_SUSPENSE: 'CLOSE_SUSPENSE',
      MODIFY_SUSPENSE: 'MODIFY_SUSPENSE',
    },

    closeSuspenseReason: {
      'IGNORE AS INVALID PAYMENT': 'IGNORE AS INVALID PAYMENT',
      'CUSTOMER REFUND': 'CUSTOMER REFUND',
    },

    planStatus: {
      ACTIVE: 'ACTIVE',
      INACTIVE: 'INACTIVE',
    },

    errorCodeProcessService: {
      NO_SERVICE_FOR_THE_PROVISIONING_ID: 'NO_SERVICE_FOR_THE_PROVISIONING_ID',
      NO_MATCHING_PRICING_FOR_USAGE_RECORD: 'NO_MATCHING_PRICING_FOR_USAGE_RECORD',
      NO_MATCHING_OPERATOR_PRICING_FOR_USAGE_RECORD: 'NO_MATCHING_OPERATOR_PRICING_FOR_USAGE_RECORD',
      NO_MATCHING_BILLABLE_SVC_PRICING_FOR_USAGE_RECORD: 'NO_MATCHING_BILLABLE_SVC_PRICING_FOR_USAGE_RECORD',
      NO_ACTIVE_SERVICE_FOR_THE_PROVISIONING_ID: 'NO_ACTIVE_SERVICE_FOR_THE_PROVISIONING_ID',
      NO_ACTIVE_PRICE_UNIT_FOR_THE_SERVICE: 'NO_ACTIVE_PRICE_UNIT_FOR_THE_SERVICE',
      NO_MATCHING_PRICE_UNIT_FOR_THE_SERVICE: 'NO_MATCHING_PRICE_UNIT_FOR_THE_SERVICE',
      BILLING_NOT_YET_DONE: 'BILLING_NOT_YET_DONE',
      BALANCE_NOT_FOUND: 'BALANCE_NOT_FOUND',
      ITEM_NOT_FOUND: 'ITEM_NOT_FOUND',
      START_OR_END_DATE_MISSING_IN_USAGE_CONTAINER: 'START_OR_END_DATE_MISSING_IN_USAGE_CONTAINER',
      SERVICE_TYPE_MISSING_IN_USAGE_CONTAINER: 'SERVICE_TYPE_MISSING_IN_USAGE_CONTAINER',
      REC_INDICATOR_MISSING_IN_USAGE_CONTAINER: 'REC_INDICATOR_MISSING_IN_USAGE_CONTAINER',
      MISSING_MANDATORY_ACCNT_ID_OR_PROV_ID: 'MISSING_MANDATORY_ACCNT_ID_OR_PROV_ID',
      NO_PRICE_OFFER_IN_DB_FOR_GIVEN_ID: 'NO_PRICE_OFFER_IN_DB_FOR_GIVEN_ID',
      TRANSACTION_PRICING_NOT_FOUND: 'TRANSACTION_PRICING_NOT_FOUND',
      TIER_PRICING_NOT_FOUND: 'TIER_PRICING_NOT_FOUND',
      TIER_EVALUATION_FAILED: 'TIER_EVALUATION_FAILED',
      APPLICABLE_PRICE_GRANT_ACCUM_NOT_FOUND_IN_PO: 'APPLICABLE_PRICE_GRANT_ACCUM_NOT_FOUND_IN_PO',
      COMPLEX_PRICING_NOT_FOUND: 'COMPLEX_PRICING_NOT_FOUND',
      COMPLEX_TIER_PRICING_NOT_FOUND: 'COMPLEX_TIER_PRICING_NOT_FOUND',
      PLSQL_EXCEPTION: 'PLSQL_EXCEPTION',
      UNKNOWN_ERROR: 'UNKNOWN_ERROR',
    },

    usageRecStatusFailedRecord: {
      SUSPENDED: 'SUSPENDED',
      FAILED: 'FAILED',
    },

    apiType: {
      INCOMING: 'INCOMING',
      OUTGOING: 'OUTGOING',
    },

    schedulePatternType: {
      DAILY: 'DAILY',
      WEEKLY: 'WEEKLY',
      MONTHLY: 'MONTHLY',
      YEARLY: 'YEARLY',
    },

    monthOfYearWithNumber: {
      1: 'Jan',
      2: 'Feb',
      3: 'Mar',
      4: 'Apr',
      5: 'May',
      6: 'Jun',
      7: 'Jul',
      8: 'Aug',
      9: 'Sep',
      10: 'Oct',
      11: 'Nov',
      12: 'Dec',
    },

    arReasonCodeStatus: {
      ACTIVE: 'ACTIVE',
      CANCELLED: 'CANCELLED',
    },

    arReasonCodeObjectType: {
      BUNDLE: 'BUNDLE',
      CREDIT_NOTES: 'CREDIT_NOTES',
      DEBIT_NOTES: 'DEBIT_NOTES',
      DISCOUNT_OFFER: 'DISCOUNT_OFFER',
      INVOICE: 'INVOICE',
      CANCELLED: 'CANCELLED',
      ITEM: 'ITEM',
      NOTES: 'NOTES',
      PACKAGE: 'PACKAGE',
      PAYMENT: 'PAYMENT',
      PRICE_OFFER: 'PRICE_OFFER',
    },

    fileRecordType: {
      HEADER: 'HEADER',
      DETAIL: 'DETAIL',
      TRAILER: 'TRAILER',
    },

    scaleUnit: {
      GIGABYTE: 'GIGABYTE',
      HOUR: 'HOUR',
      KILOBYTE: 'KILOBYTE',
      MEGABYTE: 'MEGABYTE',
      MINUTE: 'MINUTE',
      NONE: 'NONE',
      ONE: 'ONE',
      SECOND: 'SECOND',
      MEGAWATT: 'MEGAWATT',
      KILOWATT: 'KILOWATT',
      KILOGRAM: 'KILOGRAM',
      POUND: 'POUND',
      LITRE: 'LITRE',
      GALLON: 'GALLON',
      KWH: 'KWH',
    },

    folioType: {
      PAYMENT: 'PAYMENT',
      CREDIT_NOTE: 'CREDIT_NOTE',
      INVOICE: 'INVOICE',
      DEBIT_NOTE: 'DEBIT_NOTE',
      TRANSFER: 'TRANSFER',
      LADING_BILL: 'LADING_BILL',
    },

    omsTaskType: {
      PROVISIONING_ONLY: 'PROVISIONING_ONLY',
      BILLING_ONLY: 'BILLING_ONLY',
      BOTH: 'BOTH',
    },

    evaluationComponent: {
      BALANCE_OF_ACCUMULATOR: 'BALANCE_OF_ACCUMULATOR',
      USAGE_AMOUNT: 'USAGE_AMOUNT',
      USAGE_QUANTITY: 'USAGE_QUANTITY',
      USAGE_CONTAINER_FIELD: 'USAGE_CONTAINER_FIELD',
      PROVISIONING_ATTRIBUTE_NAME: 'PROVISIONING_ATTRIBUTE_NAME',
    },

    comparisonOperator: {
      LESS_THAN: 'LESS_THAN',
      LESS_THAN_OR_EQUAL_TO: 'LESS_THAN_OR_EQUAL_TO',
      GREATER_THAN: 'GREATER_THAN',
      GREATER_THAN_OR_EQUAL_TO: 'GREATER_THAN_OR_EQUAL_TO',
      EQUAL_TO: 'EQUAL_TO',
      NOT_EQUAL_TO: 'NOT_EQUAL_TO',
    },

    transactionBalanceType: {
      ACCUMULATOR: 'ACCUMULATOR',
      ADJUSTMENT: 'ADJUSTMENT',
      DISCOUNTING: 'DISCOUNTING',
      DISPUTE: 'DISPUTE',
      GRANT: 'GRANT',
      MANUAL_TRANSACTION: 'MANUAL_TRANSACTION',
      PAYMENT: 'PAYMENT',
      PAYMENT_REVERSAL: 'PAYMENT_REVERSAL',
      RATING: 'RATING',
      SETTLEMENT: 'SETTLEMENT',
      TAXATION: 'TAXATION',
      WRITE_OFF: 'WRITE_OFF',
      WRITE_OFF_REVERSAL: 'WRITE_OFF_REVERSAL',
    },

    provisioningStatus: {
      PENDING: 'PENDING',
      PARTIAL: 'PARTIAL',
      COMPLETED: 'COMPLETED',
      CANCELLED: 'CANCELLED',
      SKIPPED: 'SKIPPED',
      FAILED: 'FAILED',
      NOT_REQUIRED: 'NOT_REQUIRED',
    },

    quoteStatus: {
      CREATED: 'CREATED',
      CANCELLED: 'CANCELLED',
      SUBMITTED: 'SUBMITTED',
      APPROVED: 'APPROVED',
      COMPLETED: 'COMPLETED',
      CONVERTED: 'CONVERTED',
      FAILED_IN_CONVERT: 'FAILED_IN_CONVERT',
    },

    quoteType: {
      NEW: 'NEW',
      TRIAL: 'TRIAL',
      MODIFY: 'MODIFY',
      RENEW: 'RENEW',
      OPT_IN: 'OPT_IN',
      OPT_OUT: 'OPT_OUT',
      UPGRADE: 'UPGRADE',
      DOWNGRADE: 'DOWNGRADE',
      TRIAL_SERVICE: 'TRIAL_SERVICE',
    },

    trialType: {
      OPT_IN_TRIAL: 'OPT_IN_TRIAL',
      OPT_OUT_TRIAL: 'OPT_OUT_TRIAL',
    },

    accountCategory: {
      PREPAID: 'PREPAID',
      POSTPAID: 'POSTPAID',
      HYBRID: 'HYBRID',
    },

    serviceUnitStatus: {
      ACTIVE: 'ACTIVE',
      CLOSED: 'CLOSED',
      SUSPENDED: 'SUSPENDED',
    },

    quotePricingLevel: {
      SIMPLE: 'SIMPLE',
      MEDIUM: 'MEDIUM',
      COMPLEX: 'COMPLEX',
    },

    subscriptionCategory: {
      PREPAID: 'PREPAID',
      POSTPAID: 'POSTPAID',
      PREPAID_AS_POSTPAID: 'PREPAID_AS_POSTPAID',
    },

    termUnit: {
      DAYS: 'DAYS',
      MONTHS: 'MONTHS',
      NONE: 'NONE',
      QUARTERS: 'QUARTERS',
      WEEKS: 'WEEKS',
      YEARS: 'YEARS',
    },

    merchantType: {
      CRM_GATEWAY: 'CRM_GATEWAY',
      CUSTOMER_SUPPORT_GATEWAY: 'CUSTOMER_SUPPORT_GATEWAY',
      DOCUMENT_GATEWAY: 'DOCUMENT_GATEWAY',
      FINANCE_GATEWAY: 'FINANCE_GATEWAY',
      OPERATION_GATEWAY: 'OPERATION_GATEWAY',
      PAYMENT_GATEWAY: 'PAYMENT_GATEWAY',
      PROJECT_GATEWAY: 'PROJECT_GATEWAY',
      PROVISIONING_GATEWAY: 'PROVISIONING_GATEWAY',
      TAX_GATEWAY: 'TAX_GATEWAY',
    },

    genericStatus: {
      ACTIVE: 'ACTIVE',
      INACTIVE: 'INACTIVE',
      CLOSED: 'CLOSED',
    },

    creditLimitBreachActions: {
      SUBSCRIPTION_SUSPENSION: 'SUBSCRIPTION_SUSPENSION',
      SUBSCRIPTION_CLOSURE: 'SUBSCRIPTION_CLOSURE',
    },

    additionalApiGetway: {
      UPDATE_PROVISIONING_REQUEST: 'UPDATE_PROVISIONING_REQUEST',
      GET_LOCATION_DATA: 'GET_LOCATION_DATA',
      GET_SPACE_DATA: 'GET_SPACE_DATA',
      UPDATE_WORK_ORDER: 'UPDATE_WORK_ORDER',
    },

    taxConfigRegular: {
      AMOUNT_TAXED: 'AMOUNT_TAXED',
    },

    webApiType: {
      REST: 'REST',
      SOAP: 'SOAP',
    },

    zoneType: {
      GEOGRAPHICAL: 'GEOGRAPHICAL',
      STANDARD: 'STANDARD',
    },

    taxType: {
      MAIN_TAX: 'MAIN_TAX',
      ADDITIONAL_TAX: 'ADDITIONAL_TAX',
    },

    messageType: {
      USER: 'USER',
      CUSTOMER: 'CUSTOMER',
    },

    notificationTemplateType: {
      SMS: 'SMS',
      EMAIL: 'EMAIL',
      BOTH: 'BOTH',
    },

    transactionSource: {
      ADJUSTMENT: 'ADJUSTMENT',
      BACKOUT: 'BACKOUT',
      BILLING: 'BILLING',
      COLLECTION: 'COLLECTION',
      JOBS: 'JOBS',
      MEDIATION: 'MEDIATION',
      ORDERS: 'ORDERS',
      RERATING: 'RERATING',
    },

    collectionAction: {
      CANCEL_SUBSCRIPTION: 'CANCEL_SUBSCRIPTION',
      CLOSE_ACCOUNT: 'CLOSE_ACCOUNT',
      FAILED_PAYMENT_EMAIL: 'FAILED_PAYMENT_EMAIL',
      FIRST_PAYMENT_RETRY: 'FIRST_PAYMENT_RETRY',
      FIRST_REMINDER_EMAIL: 'FIRST_REMINDER_EMAIL',
      INACTIVATE_SUBSCRIPTION: 'INACTIVATE_SUBSCRIPTION',
      SECOND_PAYMENT_RETRY: 'SECOND_PAYMENT_RETRY',
      SECOND_REMINDER_EMAIL: 'SECOND_REMINDER_EMAIL',
      THIRD_PAYMENT_RETRY: 'THIRD_PAYMENT_RETRY',
      THIRD_REMINDER_EMAIL: 'THIRD_REMINDER_EMAIL',
      WRITEOFF_ACCOUNT: 'WRITEOFF_ACCOUNT',
      NOTIFY_CANCELLATION_TO_CRM: 'NOTIFY_CANCELLATION_TO_CRM',
    },

    arTaxRule: {
      TAX_ONLY: 'TAX_ONLY',
      WITH_TAX: 'WITH_TAX',
      WITHOUT_TAX: 'WITHOUT_TAX',
    },

    arSource: {
      AGENT_CARE: 'AGENT_CARE',
      EXTERNAL: 'EXTERNAL',
      INTERNAL_BATCH_JOBS: 'INTERNAL_BATCH_JOBS',
      PAYMENT_SUSPENSE: 'PAYMENT_SUSPENSE',
      PAYMENTS: 'PAYMENTS',
      RERATING: 'RERATING',
      SELF_CARE: 'SELF_CARE',
    },

    exemptionLevel: {
      CITY: 'CITY',
      COUNTY: 'COUNTY',
      FEDERAL: 'FEDERAL',
      STATE: 'STATE',
    },

    sharingType: {
      CHARGE: 'CHARGE',
      DISCOUNT: 'DISCOUNT',
      REVENUE: 'REVENUE',
    },

    apiProtocol: {
      REST: 'REST',
      SOAP: 'SOAP',
      GRAPHQL: 'GRAPHQL',
    },

    paymentGatewayUrlType: {
      BASE_URL: 'BASE_URL',
      AUTHORIZE_CREDIT_CARD: 'AUTHORIZE_CREDIT_CARD',
      CAPTURE_CREDIT_CARD: 'CAPTURE_CREDIT_CARD',
      CREDIT_CREDIT_CARD: 'CREDIT_CREDIT_CARD',
      VOID_CREDIT_CARD: 'VOID_CREDIT_CARD',
      AUTHORIZE_ECHECK: 'AUTHORIZE_ECHECK',
      CAPTURE_ECHECK: 'CAPTURE_ECHECK',
      CREDIT_ECHECK: 'CREDIT_ECHECK',
      VOID_ECHECK: 'VOID_ECHECK',
    },

    collectionActionWorkingDay: {
      NEXT_WORKING_DAY: 'NEXT_WORKING_DAY',
    },

    templateType: {
      CREDIT_NOTE: 'CREDIT_NOTE',
      DEBIT_NOTE: 'DEBIT_NOTE',
      INVOICE: 'INVOICE',
      LADING_BILL: 'LADING_BILL',
      PAYMENT: 'PAYMENT',
      TRANSFER_DOCUMENT: 'TRANSFER_DOCUMENT',
      RETENTION: 'RETENTION',
      PAYMENT_AGREEMENT: 'PAYMENT_AGREEMENT',
      PAYMENT_INSTALLMENT_REPORT: 'PAYMENT_INSTALLMENT_REPORT',
      USAGE_CONSUMPTION_STATEMENT: 'USAGE_CONSUMPTION_STATEMENT',
    },

    templateFileType: {
      HTML: 'HTML',
      XSLT: 'XSLT',
    },

    templateFileStatus: {
      ACTIVE: 'ACTIVE',
      INACTIVE: 'INACTIVE',
    },

    invoiceSummaryType: {
      RECURRING: 'RECURRING',
      NON_RECURRING: 'NON_RECURRING',
      USAGE: 'USAGE',
      TAX: 'TAX',
    },

    billUnitStatus: {
      ACTIVE: 'ACTIVE',
      PENDING: 'PENDING',
    },

    activityType: {
      ACCOUNT_INFO: 'ACCOUNT_INFO',
      ADDRESS: 'ADDRESS',
      AROPS: 'AROPS',
      BILLING: 'BILLING',
      BILLING_PROFILE: 'BILLING_PROFILE',
      BUNDLE: 'BUNDLE',
      COLLECTION: 'COLLECTION',
      CONTACT: 'CONTACT',
      CUSTOM_ATTRIBUTES: 'CUSTOM_ATTRIBUTES',
      CUSTOMER: 'CUSTOMER',
      DEPENDENCY: 'DEPENDENCY',
      DISCOUNT_OFFER: 'DISCOUNT_OFFER',
      IDENTITY: 'IDENTITY',
      INTEGRATION: 'INTEGRATION',
      ITEM: 'ITEM',
      ORDER: 'ORDER',
      PACKAGE: 'PACKAGE',
      PAYMENT: 'PAYMENT',
      PAYMENT_PROFILE: 'PAYMENT_PROFILE',
      PAYMENT_REVERSAL: 'PAYMENT_REVERSAL',
      PAYMENT_SUSPENSE: 'PAYMENT_SUSPENSE',
      PRICE_OFFER: 'PRICE_OFFER',
      PROVISIONING: 'PROVISIONING',
      QUOTE: 'QUOTE',
      TAX: 'TAX',
    },

    expensePeriodUnit: {
      MONTH: 'MONTH',
      QUARTER: 'QUARTER',
      YEAR: 'YEAR',
    },

    moveProvisioningTypeActions: {
      MOVE_PROVISIONING: 'MOVE_PROVISIONING',
      CHANGE_PROVISIONING: 'CHANGE_PROVISIONING',
      MOVE_TO_STD_PROVISIONING: 'MOVE_TO_STD_PROVISIONING',
    },

    quoteSource: {
      EXTERNAL: 'EXTERNAL',
      INTERNAL: 'INTERNAL',
    },

    bonusPeriodUnit: {
      MONTH: 'MONTH',
      QUARTER: 'QUARTER',
      YEAR: 'YEAR',
    },

    objectFileType: {
      CSV: 'CSV',
      HTML: 'HTML',
      PDF: 'PDF',
      TXT: 'TXT',
      XML: 'XML',
      XSLT: 'XSLT',
    },

    scheduleFrequency: {
      DAILY: 'DAILY',
      SCHEDULED: 'SCHEDULED',
    },

    apiKeyAddTo: {
      HEADER: 'HEADER',
      QUERY_PARAMS: 'QUERY_PARAMS',
    },

    provSeqObjectType: {
      ACCOUNT: 'ACCOUNT',
      PROVISIONING_DATA: 'PROVISIONING_DATA',
    },

    priceUnitStatus: {
      ACTIVE: 'ACTIVE',
      CLOSED: 'CLOSED',
      INACTIVE: 'INACTIVE',
    },

    assetSummaryType: {
      SERVICE_TYPE: 'SERVICE_TYPE',
      PAID_VS_TRIAL_SUBSCRIPTION: 'PAID_VS_TRIAL_SUBSCRIPTION',
      SUBSCRIPTION_TYPE: 'SUBSCRIPTION_TYPE',
      PACKAGE: 'PACKAGE',
      BUNDLE: 'BUNDLE',
      PRICE_OFFER: 'PRICE_OFFER',
      DISCOUNT_OFFER: 'DISCOUNT_OFFER',
    },
  },
};
