// import React from 'react';
import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';
import writeOffNS from './constants/writeOffNS';
import { getUserId } from '../../localStorages';

export default data => {
  const newData = data;
  newData.userId = getUserId() || newData.userId || null;
  const dataCreate = parseToMutationRequest(newData, writeOffNS);
  const processWriteoff = `processWriteoff(input: ${dataCreate})`;
  return gql`
  mutation {
    ${processWriteoff} {
      id
    }
  }
`;
};
