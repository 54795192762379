import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import RouteNames from '../App/RouteNames';
import Footer from '../../components/Footer';
import { CurrencyIndex, CreateCurrency, ModifyCurrency } from './CurrencyExchange';
import { MilestoneIndex, CreateMilestone, ModifyMilestone } from './Milestone';
import Location from './Location';
import Enterprise from './Enterprise';
import Divisions from './Divisions';
import LegalEntity from './LegalEntity';
import BusinessUnits from './BusinessUnits';
import GLSetup from './GLSetup';
import Department from './Department';
import CostCenter from './CostCenter';
import Calendar from './Calendar';
import GLChartOfAccount from './GLChartOfAccount';
import AccountingPolicies from './AccountingPolicies';
import GLAccounts from './GLAccounts';
import CostCenterMapping from './CostCenterMapping';
import ERPExtractBatch from './ERPExtractBatch';
import { makeGetPermissionsRevenue } from '../App/selectors';
import Sidebar from './Sidebar';
import { checkPermissionForAllPages } from './CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class RevenueManagement extends PureComponent {
  render() {
    const { permissionsRevenue } = this.props;
    let permissions = {};
    if (!isHasPermissionWithMenu({ permissions: permissionsRevenue, listKeys: permissionList.revenue })) return null;

    if (permissionsRevenue && permissionsRevenue.revenueModulePermissions) {
      const listPermission = permissionsRevenue.revenueModulePermissions;
      permissions = checkPermissionForAllPages({ listPermission });
    }
    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar />
          <div className="content-wrapper">
            <Switch>
              <Route
                exact
                path={RouteNames.revenueConfigurationCurrencyExchange.path}
                component={() => <CurrencyIndex permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.revenueConfigurationCurrencyExchangeCreate.path}
                component={() => <CreateCurrency permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.revenueConfigurationCurrencyExchangeModify.path}
                component={() => <ModifyCurrency permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.revenueConfigurationMilestone.path}
                component={() => <MilestoneIndex permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.revenueConfigurationMilestoneCreate.path}
                component={() => <CreateMilestone permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.revenueConfigurationMilestoneModify.path}
                component={() => <ModifyMilestone permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.revenueConfigurationLocation.path}
                component={() => <Location permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.revenueConfigurationEnterprise.path}
                component={() => <Enterprise permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.revenueConfigurationDivisions.path}
                component={() => <Divisions permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.revenueConfigurationLegalEntity.path}
                component={() => <LegalEntity permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.revenueConfigurationBusinessUnit.path}
                component={() => <BusinessUnits permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.revenueConfigurationGLSetup.path}
                component={() => <GLSetup permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.revenueConfigurationDepartment.path}
                component={() => <Department permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.revenueConfigurationCostCenter.path}
                component={() => <CostCenter permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.revenueConfigurationCalendar.path}
                component={() => <Calendar permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.revenueConfigurationChartOfAccount.path}
                component={() => <GLChartOfAccount permissions={permissions} />}
              />

              <Route
                exact
                path={RouteNames.revenueAccountingPolicies.path}
                component={() => <AccountingPolicies permissions={permissions} />}
              />

              <Route
                exact
                path={RouteNames.revenueConfigurationGLAccounts.path}
                component={() => <GLAccounts permissions={permissions} />}
              />

              <Route
                exact
                path={RouteNames.costCenterMapping.path}
                component={() => <CostCenterMapping permissions={permissions} />}
              />
              <Route
                exact
                path={RouteNames.erpExtractBatch.path}
                component={() => <ERPExtractBatch permissions={permissions} />}
              />
            </Switch>
            <br />
            <Footer className="footer-bottom" />
          </div>
        </div>
      </div>
    );
  }
}

const withReducer = injectReducer({ key: 'revenueConfigReducer', reducer });
const withSaga = injectSaga({ key: 'revenueConfigSaga', saga });

const mapStateToProps = createStructuredSelector({
  permissionsRevenue: makeGetPermissionsRevenue() || {},
});

const withConnect = connect(mapStateToProps, null);

export default compose(withReducer, withSaga, withConnect)(RevenueManagement);
