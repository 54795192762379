export default {
  sidebar: {
    config: 'Config',
    companySetup: 'Company Setup',
    currencyExchange: 'Currency Exchange',
    milestone: 'Milestone',
    GLAccounts: 'GL Accounts',
    revenue: 'Revenue',
    location: 'Location',
    enterprise: 'Enterprise',
    divisions: 'Divisions',
    legalEntity: 'Legal Entity',
    businessUnit: 'Business Unit',
    department: 'Department',
    costCenter: 'Cost Center',
    calendar: 'Calendar',
    GL: 'GL',
    GLSetup: 'GL Setup',
    chartOfAccount: 'Chart of Account',
    ledger: 'Ledger',
    primary: 'Primary',
    secondary: 'Secondary',
    peporting: 'Reporting',
    others: 'Others',
    accounting: 'Accounting',
    accountingPolicies: 'Accounting Policies',
    accountingLog: 'Accounting Log',
    journals: 'Journals',
    erpExtracts: 'ERP Extracts',
    costCenterMapping: 'Cost Center Mapping',
    multiOrgSetup: 'Multi Org Setup...',
    baseConfigurations: 'Base Configurations',
    '4cs': "4 C's",
    accountingConvention: 'Accounting Convention',
    revenueMilestones: 'Revenue Milestones',
    erpExtractBatch: 'ERP Extract Batch',
  },
};
