import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['serviceType', 'status'])}` : '';
  const getAssetReport = `getAssetReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getAssetReport} {
        id
        offerId
        offerType
        offerStatus
        accountId
        subscriptionId
        serviceUnitId
        serviceType
        packageId
        packageName
        bundleId
        bundleName
        startDate
        endDate
        appliedStartDate
        appliedEndDate
        cycleStartDate
        cycleEndDate
        noofcyclesapplied
        reason
        instanceId
        omsInternalId
        amountOverride
        percentOverride
        priceOffset
        discountPercent
        discPercentOverride
        quantity
        commitmentTermUnit
        commitmentTerm
        offerName
      }
    }
  `;
};
