import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';
import discountNS from './constants/discountOfferNS';
import { getUserId } from '../../localStorages';

export default data => {
  let newPriceOfferNS = discountNS;
  if (data && data.discountingModel === 'COMPLEX') {
    newPriceOfferNS = newPriceOfferNS.filter(item => item !== 'timeUnit');
  }
  const newData = data;
  newData.userId = getUserId() || newData.userId || null;
  const dataModify = parseToMutationRequest(newData, newPriceOfferNS);
  const modifyDiscountOffer = `modifyDiscountOffer(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyDiscountOffer} {
      id
    }
  }
`;
};
