import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';
import { withTranslation } from 'react-i18next';
import { countryList } from '../../../constantsApp/countryList';
import listCurrency from '../../../constantsApp/currency.json';
import { GenericInput } from '../../../components/common';
import RouteNames from '../../App/RouteNames';
import PackageComponent from './PackageComponent';
import { getLastIndex } from '../../../utils/utils';
import { getUserId } from '../../../localStorages';

const currencyOptions = listCurrency.currencies.map(item => ({
  value: item.code,
  label: `${item.name} (${item.code})`,
}));

class PackageForm extends Component {
  state = {
    isSubmitting: false,
    wasValidated: false,
    formValid: true,
    components: [],
  };

  formRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    if (state.item === props.item) return null;
    return {
      ...props.item,
      components: props.item.components || [],
      item: props.item,
    };
  }

  validate = (out = false) => {
    const { components } = this.state;
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if ((!components || components.length === 0 || !formValid) && out) {
      toast.error(t('message.mandatory'));
    }
    return components && components.length > 0 && formValid;
  };

  onCancelSelector = () => {
    this.setState({ isSelectingParent: false });
  };

  handleInputChange = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  onAddNewComponent = e => {
    e.preventDefault();
    let { components } = this.state;
    const { creatingMode } = this.props;
    components = components || [];
    let index = 1;
    if (!creatingMode) {
      index = getLastIndex({ data: components });
    } else {
      index = components.length + 1;
    }
    this.setState({ components: [...components, { index, discountOfferId: null, isNew: true }] });
  };

  handleChangeComponents = components => {
    this.setState({ components });
  };

  handleSubmit = e => {
    this.setState({ wasValidated: true });
    e.preventDefault();
    if (!this.validate(true)) {
      return false;
    }
    const { onSubmit } = this.props;
    const {
      id,
      name,
      description,
      minimumQuantity,
      maximumQuantity,
      salesChannel,
      marketSegment,
      accountType,
      accountSubType,
      country,
      currency,
      startDate,
      endDate,
      status,
      components,
    } = this.state;
    const newComponents =
      components && components.length
        ? components.map(val => {
            const { bundleName, discountOfferName, ...rest } = val;
            return rest;
          })
        : null;
    const data = {
      id: id || null,
      name: name || null,
      description: description || null,
      minimumQuantity: minimumQuantity || null,
      maximumQuantity: maximumQuantity || null,
      salesChannel,
      marketSegment,
      accountType,
      accountSubType,
      country,
      currency,
      startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
      endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
      status,
      components: newComponents,
    };
    if (onSubmit) {
      onSubmit(data, ({ success }) => {
        this.setState({ wasValidated: !success });
      });
    }
  };

  onCancelData = () => {
    this.setState({
      id: null,
      name: null,
      description: null,
      minimumQuantity: null,
      maximumQuantity: null,
      salesChannel: null,
      marketSegment: null,
      accountType: null,
      accountSubType: null,
      country: null,
      currency: null,
      startDate: null,
      endDate: null,
      status: null,
      components: [],
    });
  };

  render() {
    const {
      creatingMode,
      modeModifyPackage,
      modeApprovePackage,
      modeSellablePackage,
      modeNonSellablePackage,
      modeSunsetPackage,
      t,
      onCancel,
    } = this.props;
    const { wasValidated, components, item } = this.state;

    const dataCopy2Create = cloneDeep(item);
    if (dataCopy2Create.id) delete dataCopy2Create.id;
    if (dataCopy2Create.name) delete dataCopy2Create.name;
    if (dataCopy2Create.description) delete dataCopy2Create.description;

    let newStatusOptionsWithPermission = cloneDeep(t('selections:statusPricing')());
    if (modeApprovePackage === 0)
      newStatusOptionsWithPermission = newStatusOptionsWithPermission.filter(item => item.value !== 'APPROVED');
    if (modeSellablePackage === 0)
      newStatusOptionsWithPermission = newStatusOptionsWithPermission.filter(item => item.value !== 'SELLABLE');
    if (modeNonSellablePackage === 0)
      newStatusOptionsWithPermission = newStatusOptionsWithPermission.filter(item => item.value !== 'NON_SELLABLE');
    if (modeSunsetPackage === 0)
      newStatusOptionsWithPermission = newStatusOptionsWithPermission.filter(item => item.value !== 'SUNSET');

    if (modeApprovePackage === 1)
      newStatusOptionsWithPermission[
        newStatusOptionsWithPermission.findIndex(item => item.value === 'APPROVED')
      ].isDisabled = true;
    if (modeSellablePackage === 1)
      newStatusOptionsWithPermission[
        newStatusOptionsWithPermission.findIndex(item => item.value === 'SELLABLE')
      ].isDisabled = true;
    if (modeNonSellablePackage === 1)
      newStatusOptionsWithPermission[
        newStatusOptionsWithPermission.findIndex(item => item.value === 'NON_SELLABLE')
      ].isDisabled = true;
    if (modeSunsetPackage === 1)
      newStatusOptionsWithPermission[
        newStatusOptionsWithPermission.findIndex(item => item.value === 'SUNSET')
      ].isDisabled = true;

    const listInputs = [
      {
        label: 'common:label.id',
        wrapperClass: 'col-md-4',
        name: 'id',
      },
      {
        label: 'common:label.name',
        wrapperClass: 'col-md-4',
        name: 'name',
        required: true,
      },
      {
        label: 'common:label.description',
        wrapperClass: 'col-md-4',
        name: 'description',
        required: true,
      },
      {
        label: 'common:label.country',
        wrapperClass: 'col-md-4',
        name: 'country',
        type: 'select',
        options: countryList,
      },
      {
        label: 'common:label.currency',
        wrapperClass: 'col-md-4',
        name: 'currency',
        type: 'select',
        options: currencyOptions,
      },
      {
        label: 'common:label.minimumQuantity',
        wrapperClass: 'col-md-4',
        name: 'minimumQuantity',
      },
      {
        label: 'common:label.maximumQuantity',
        wrapperClass: 'col-md-4',
        name: 'maximumQuantity',
      },
      {
        name: 'salesChannel',
        label: 'common:label.salesChannel',
        wrapperClass: 'col-md-4',
        tOptions: 'selections:salesChannel',
        type: 'select',
        isClearable: true,
      },
      {
        name: 'marketSegment',
        label: 'common:label.marketSegment',
        wrapperClass: 'col-md-4',
        tOptions: 'selections:marketSegment',
        type: 'select',
        isClearable: true,
      },
      {
        name: 'accountType',
        label: 'common:label.accountType',
        wrapperClass: 'col-md-4',
        tOptions: 'selections:accountType',
        type: 'select',
        isClearable: true,
      },
      {
        name: 'accountSubType',
        label: 'label.accountSubType',
        wrapperClass: 'col-md-4',
        tOptions: 'selections:accountSubType',
        type: 'select',
        isClearable: true,
      },
      {
        name: 'status',
        label: 'common:label.status',
        wrapperClass: 'col-md-4',
        options: newStatusOptionsWithPermission,
        type: 'select',
        isClearable: true,
      },
      {
        name: 'startDate',
        label: 'common:label.startDate',
        wrapperClass: 'col-md-4',
        type: 'date',
      },
      {
        name: 'endDate',
        label: 'common:label.endDate',
        wrapperClass: 'col-md-4',
        type: 'date',
      },
      {
        name: 'userId',
        label: 'common:label.userId',
        wrapperClass: 'col-md-4',
        readOnly: true,
      },
    ];

    return (
      <form
        onSubmit={this.handleSubmit}
        className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
        noValidate
        ref={this.formRef}
      >
        <div className="form-row">
          {listInputs.map(input => {
            if (input.name === 'userId') {
              return (
                <GenericInput
                  {...input}
                  key={input.name}
                  value={creatingMode ? this.state[input.name] || getUserId() || null : this.state[input.name] || null}
                  onChange={this.handleInputChange}
                />
              );
            }
            return (
              <GenericInput
                {...input}
                key={input.name}
                value={this.state[input.name]}
                onChange={this.handleInputChange}
                disabled={!creatingMode && input.name === 'id'}
              />
            );
          })}
        </div>
        <div className="form-row">
          <div className="col-md-12">
            <PackageComponent
              components={components}
              onChangeComponents={this.handleChangeComponents}
              creatingMode={creatingMode}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12 action-buttons">
            <label htmlFor="validationCustom01" />
            <br />
            <button
              type="button"
              className={`btn-new-component  button button-border x-small float-left ${
                wasValidated && (!components || components.length === 0) ? 'btn btn-danger' : ''
              }`}
              onClick={this.onAddNewComponent}
            >
              {t('label.addNewComponent')}
            </button>
            {item && item.id && modeModifyPackage === 2 && (
              <Link
                to={{ pathname: RouteNames.packageItemAdd.path, state: dataCopy2Create }}
                className="button button-border gray x-small float-right"
              >
                {t('label.copyToCreate')}
              </Link>
            )}
            {item && item.id && modeModifyPackage === 2 && (
              <button type="submit" className="button button-border x-small float-right">
                {t('label.modify')}
              </button>
            )}
            {!(item && item.id) && (
              <button type="submit" className="button button-border x-small float-right">
                {t('label.create')}
              </button>
            )}

            {onCancel ? (
              <button onClick={onCancel} type="button" className="button button-border black x-small  float-right">
                {t('label.cancel')}
              </button>
            ) : (
              <button
                onClick={this.onCancelData}
                type="button"
                className="button button-border black x-small  float-right"
              >
                {t('label.cancel')}
              </button>
            )}
          </div>
        </div>
      </form>
    );
  }
}

PackageForm.propTypes = {
  onSubmit: PropTypes.func,
  item: PropTypes.objectOf(PropTypes.any),
  isSubmitting: PropTypes.bool,
  creatingMode: PropTypes.bool,
  modeModifyPackage: PropTypes.number,
  modeApprovePackage: PropTypes.number,
  modeSellablePackage: PropTypes.number,
  modeNonSellablePackage: PropTypes.number,
  modeSunsetPackage: PropTypes.number,
};

PackageForm.defaultProps = {
  item: {},
  onSubmit: () => {},
  isSubmitting: false,
  creatingMode: true,
  modeModifyPackage: 0,
  modeApprovePackage: 2,
  modeSellablePackage: 2,
  modeNonSellablePackage: 2,
  modeSunsetPackage: 2,
};
export default withTranslation('common')(PackageForm);
