import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SideBar } from '../../components/common';
import RouteNames from '../App/RouteNames';
import { checkPermissionForAllPages } from './CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class Sidebar extends Component {
  state = {};

  render() {
    const { isExpand = true, permissionsRevenue } = this.props;
    const childConfig = [];
    const othersConfig = [];
    const baseConfigChild = [];
    let permissions = {};
    if (!isHasPermissionWithMenu({ permissions: permissionsRevenue, listKeys: permissionList.revenue })) return null;
    if (permissionsRevenue && permissionsRevenue.revenueModulePermissions) {
      const listPermission = permissionsRevenue.revenueModulePermissions;
      permissions = checkPermissionForAllPages({ listPermission });
    }

    const {
      modeGetLocationConfig,
      modeGetCurrencyExchangeConfig,
      modeGetMilestoneConfig,
      modeGetGLAccountConfig,
      modeGetEnterpriseConfig,
      modeGetDivisionsConfig,
      modeGetLegalEntityConfig,
      modeGetBusinessUnitConfig,
      modeGetDepartmentConfig,
      modeGetCostCenterConfig,
      modeGetCalendarConfig,
      modeGetCostCenterMap,
      modeGetErpExtractBatchConfig,
    } = permissions;
    if (modeGetLocationConfig) {
      childConfig.push({
        icon: 'ti-location-pin',
        label: 'revenuePage:sidebar.location',
        href: RouteNames.revenueConfigurationLocation.path,
      });
    }
    if (modeGetEnterpriseConfig) {
      childConfig.push({
        icon: 'fa fa-building',
        label: 'revenuePage:sidebar.enterprise',
        href: RouteNames.revenueConfigurationEnterprise.path,
      });
    }
    if (modeGetDivisionsConfig) {
      childConfig.push({
        icon: 'ti-Italic',
        label: 'revenuePage:sidebar.divisions',
        href: RouteNames.revenueConfigurationDivisions.path,
      });
    }
    if (modeGetLegalEntityConfig) {
      childConfig.push({
        icon: 'fa fa-book',
        label: 'revenuePage:sidebar.legalEntity',
        href: RouteNames.revenueConfigurationLegalEntity.path,
      });
    }
    if (modeGetBusinessUnitConfig) {
      childConfig.push({
        icon: 'fa fa-building-o',
        label: 'revenuePage:sidebar.businessUnit',
        href: RouteNames.revenueConfigurationBusinessUnit.path,
      });
    }
    if (modeGetDepartmentConfig) {
      childConfig.push({
        icon: 'ti-layout-column2',
        label: 'revenuePage:sidebar.department',
        href: RouteNames.revenueConfigurationDepartment.path,
      });
    }
    if (modeGetCostCenterConfig) {
      childConfig.push({
        icon: 'ti-credit-card',
        label: 'revenuePage:sidebar.costCenter',
        href: RouteNames.revenueConfigurationCostCenter.path,
      });
    }

    if (modeGetGLAccountConfig) {
      baseConfigChild.push({
        icon: 'ti-book',
        label: 'revenuePage:sidebar.GLAccounts',
        href: RouteNames.revenueConfigurationGLAccounts.path,
      });
    }

    if (modeGetCostCenterMap) {
      baseConfigChild.push({
        icon: 'ti-image',
        label: 'revenuePage:sidebar.costCenterMapping',
        href: RouteNames.costCenterMapping.path,
      });
    }

    if (modeGetMilestoneConfig) {
      baseConfigChild.push({
        icon: 'ti-export',
        label: 'revenuePage:sidebar.revenueMilestones',
        href: RouteNames.revenueConfigurationMilestone.path,
      });
    }
    if (modeGetErpExtractBatchConfig) {
      baseConfigChild.push({
        icon: 'ti-files',
        label: 'revenuePage:sidebar.erpExtractBatch',
        href: RouteNames.erpExtractBatch.path,
      });
    }
    if (modeGetCalendarConfig) {

      othersConfig.push({
        icon: 'fa fa-calendar',
        label: 'revenuePage:sidebar.calendar',
        href: RouteNames.revenueConfigurationCalendar.path,
      });
    }
    if (modeGetCurrencyExchangeConfig) {
      othersConfig.push({
        icon: 'ti-bar-chart',
        label: 'revenuePage:sidebar.currencyExchange',
        href: RouteNames.revenueConfigurationCurrencyExchange.path,
      });
    }

    const listItems = [
      {
        isHaveExpand: true,
        mainTilte: 'revenuePage:sidebar.multiOrgSetup',
        isNoExpand: true,
        href: '#',
        isExpandChild: true,
        childs: [...childConfig],
      },
    ];

    const sideProps = {
      isExpand,
      items: [
        ...listItems,
        {
          isHaveExpand: true,
          isExpandChild: true,
          mainTilte: 'revenuePage:sidebar.baseConfigurations',
          disable: true,
          href: '',
          childs: [
            {
              icon: 'ti-receipt',
              label: 'revenuePage:sidebar.GLSetup',
              href: RouteNames.revenueConfigurationGLSetup.path,
            },
            ...baseConfigChild,
          ],
        },

        {
          isHaveExpand: true,
          isExpandChild: true,
          mainTilte: 'revenuePage:sidebar.4cs',
          disable: true,
          childs: [
            {
              icon: 'ti-id-badge',
              label: 'revenuePage:sidebar.chartOfAccount',
              href: RouteNames.revenueConfigurationChartOfAccount.path,
            },
            ...othersConfig,
            {
              icon: 'ti-user',
              label: 'revenuePage:sidebar.accountingConvention',
              href: RouteNames.revenueAccountingPolicies.path,
            },
          ],
          href: '',
        },
        {
          isHaveExpand: true,
          isExpandChild: true,
          mainTilte: 'revenuePage:sidebar.ledger',
          disable: true,
          childs: [
            {
              icon: 'ti-direction',
              label: 'revenuePage:sidebar.primary',
              href: RouteNames.revenueConfigurationPrimary.path,
            },
            {
              icon: 'ti-direction-alt',
              label: 'revenuePage:sidebar.secondary',
              href: RouteNames.revenueConfigurationSecondary.path,
            },
            {
              icon: 'ti-target',
              label: 'revenuePage:sidebar.peporting',
              href: RouteNames.revenueConfigurationPeporting.path,
            },
          ],
          href: '',
        },
      ],
    };
    return <SideBar {...sideProps} isMaximizeOne />;
  }
}

const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const permissionsRevenue = store.get('auth').toJS().permissionsApp.revenueHubModules;

  return {
    isExpand: sidebarExpanded,
    permissionsRevenue: permissionsRevenue.length ? permissionsRevenue.filter(a => a.moduleName === 'REVENUE')[0] : {},
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

Sidebar.propTypes = {
  isExpand: PropTypes.bool,
  permissionsPayment: PropTypes.objectOf(PropTypes.any),
};

Sidebar.defaultProps = {
  isExpand: false,
  permissionsPayment: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
