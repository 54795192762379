import React, { Component } from 'react';
import { isEmpty, size as sizeLodash } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { invoiceInCollectionSort } from '../../../../constantsApp';
import { getPageTotalCount, formatMoneyValue } from '../../../../utils/utils';
import { FormInvoiceUnit } from '../../../../components/CollectionsHub';
import { DataTable, TablePagination, ModalWithItem } from '../../../../components/common';
import CheckGroup from '../../../../components/common/CheckGroup';
import { tableColumnsInvoiceUnits } from '../../constant';
import { makeGetPermissionsCollectionManagement } from '../../../App/selectors';
import { searchInvoiceInCollection, setParamsInvoiceInCollection, modifyCollectionUnit } from '../../actions';
import { makeGetListInvoiceUnits, makeInvoiceUnitsParams, errorMessageInvoiceUnits } from '../../selectors';
import { checkPermissionSearchInvoiceUnitsInCollection } from '../../CheckPermission';
import RouteNames from '../../../App/RouteNames';
import { getObjectFileById } from '../../../App/actions';

class BillUnits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      size: 20,
      isSearching: false,
      totalCount: null,
      rowSelected: {},
      newUnitStatus: {},
      filter: {
        // userId: 'UserX001',
        accountId: props?.match?.params?.id || props?.accountId || '',
      },
      unitActionList: [],
      isOpenMdalDetails: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      totalCount: getPageTotalCount({
        ...state,
        items: !isEmpty(props.invoiceUnits) ? props.invoiceUnits[0].invoicesInCollection : [],
      }),
    };
  }

  componentDidMount() {
    this.doSearchInvoices();
  }

  doSearchInvoices = () => {
    const { filter, page, size, sorted } = this.state;
    const { searchInvoiceInCollection } = this.props;
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? invoiceInCollectionSort[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isSearching: true });
    searchInvoiceInCollection(payload, () => this.setState({ isSearching: false }));
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchInvoices());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchInvoices());
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchInvoices());
  };

  handleSelectedRow = item => {
    let { rowSelected } = this.state;
    if (item.index === rowSelected.index) {
      rowSelected = {};
    } else {
      rowSelected = item;
    }
    this.setState({ rowSelected });
  };

  handleApplyBtn = () => {
    const { newUnitStatus, rowSelected } = this.state;
    const { modifyCollectionUnit } = this.props;
    modifyCollectionUnit(
      {
        id: rowSelected.id,
        status: newUnitStatus.status,
        notes: newUnitStatus.notes,
        gracePeriodDays: newUnitStatus.gracePeriodDays,
      },
      ({ success }) => {
        if (success) {
          this.setState({
            newUnitStatus: {},
            rowSelected: {},
          });

          this.doSearchInvoices();
        }
      }
    );
  };

  onChangeNewUnit = ({ name, value }) => {
    const { newUnitStatus } = this.state;
    newUnitStatus[name] = value;
    this.setState({ ...newUnitStatus });
  };

  doGetObjectFileById = invoiceId => {
    const {
      history,
      getObjectFileById,
      id,
      filter,
      page,
      size,
      totalCount,
      sorted,
      isOpenModalInvoiceUnits,
      itemSelected,
    } = this.props;
    getObjectFileById(invoiceId, data => {
      if (data && data.length) {
        history.push({
          pathname: RouteNames.prettifyPath(RouteNames.invoicesInfo.path, invoiceId),
          state: {
            fromPath: RouteNames.collectionsSearchAccount.path,
            fileData: data,
            filter,
            page,
            size,
            totalCount,
            sorted,
            isOpenModalInvoiceUnits,
            itemSelected,
          },
        });
      }
    });
  };

  doGetCollectionUnitsById = ({ item }) => {
    const { getCollectionUnitsById } = this.props;
    getCollectionUnitsById(item.id, ({ success, data }) => {
      this.setState({
        unitActionList: success && data?.unitActionList ? data?.unitActionList : [],
        isOpenMdalDetails: true,
      });
    });
  };

  onToggleModalDetails = () => {
    this.setState({
      unitActionList: [],
      isOpenMdalDetails: false,
    });
  };

  render() {
    const {
      invoiceUnits,
      match: {
        params: { id },
      },
      permissionsCollections,
      t,
      // isPacEnabled,
    } = this.props;
    const {
      page,
      size,
      totalCount,
      sorted,
      rowSelected,
      newUnitStatus,
      isSearching,
      isOpenMdalDetails,
      unitActionList,
    } = this.state;
    const dataInvoice = !isEmpty(invoiceUnits) ? invoiceUnits[0].invoicesInCollection : [];
    const totalCollection = !isEmpty(invoiceUnits) ? invoiceUnits[0].totalInCollection : 0;

    let modeSearchInvoiceUnitsInCollection = 0;

    if (permissionsCollections && permissionsCollections.collectionModulePermissions) {
      const listPermission = permissionsCollections.collectionModulePermissions;
      modeSearchInvoiceUnitsInCollection = checkPermissionSearchInvoiceUnitsInCollection({ listPermission });
    }

    if (modeSearchInvoiceUnitsInCollection === 0) return '';

    const tableConfig = {
      columns: [
        {
          name: 'Selected',
          label: 'common:label.selected',
          render: (colName, item) => {
            return (
              <CheckGroup
                type="checkbox"
                name={item.index}
                checked={rowSelected.index === item.index}
                onChange={() => this.handleSelectedRow(item)}
                disabled={!item?.due || (item && item.due <= 0)}
              />
            );
          },
        },
        ...tableColumnsInvoiceUnits,
        {
          name: 'viewActions',
          label: 'common:label.viewActions',
          render: (colName, item) => (
            <button
              className="button x-small ml-3"
              type="button"
              onClick={() => this.doGetCollectionUnitsById({ item })}
            >
              {t('label.view')}
            </button>
          ),
        },
        {
          name: 'invoiceId',
          label: 'common:label.invoiceNumber',
          render: (colName, item) => (
            <button
              className="text-success no-border"
              type="button"
              onClick={() => this.doGetObjectFileById(item.invoiceId)}
            >
              {item.invoiceId}
            </button>
          ),
        },
        {
          name: 'status',
          label: 'common:label.invoiceStatus',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t ? t('selections:invoiceUnitStatus')().find(val => val.value === item.status) : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
        {
          name: 'invoiceType',
          label: 'common:label.invoiceType',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t ? t('selections:invoiceUnitType')().find(val => val.value === item.invoiceType) : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
        {
          name: 'lastActionDate',
          label: 'common:label.lastActionDate',
        },
        {
          name: 'nextActionDate',
          label: 'common:label.nextActionDate',
        },
        {
          name: 'nextAction',
          label: 'common:label.nextAction',
          isRenderT: true,
          render: (colName, item, t) => {
            const slt = t ? t('selections:collectionAction')().find(val => val.value === item.nextAction) : '';
            return <span>{slt ? slt.label : ''}</span>;
          },
        },
        {
          name: 'createdDate',
          label: 'common:label.collectionEntryDate',
        },
      ],
      sorted,
      onSort: this.onSortColumn,
      data: dataInvoice,
      isLoading: isSearching,
    };

    const columnsActionsList = [
      {
        name: 'index',
        label: 'common:label.index',
      },
      {
        name: 'description',
        label: 'common:label.description',
      },
      {
        name: 'action',
        label: 'common:label.action',
        isRenderT: true,
        render: (colName, item, t) => {
          const slt = t ? t('selections:collectionAction')().find(val => val.value === item.action) : '';
          return <span>{slt ? slt.label : ''}</span>;
        },
      },
      {
        name: 'date',
        label: 'common:label.date',
      },
    ];

    return (
      <div className="col-md-12 mb-30">
        <DataTable {...tableConfig} />
        <div className="col-md-12 p-3">
          <span>
            {`${t('label.totalInCollection')}:`}
            <span className="ml-2">{formatMoneyValue(totalCollection)}</span>
          </span>
        </div>
        <FormInvoiceUnit
          isEnableBtn={rowSelected.id && sizeLodash(newUnitStatus) >= 2}
          data={newUnitStatus}
          onChange={this.onChangeNewUnit}
          onHandleSubmit={this.handleApplyBtn}
        />
        <br />
        <TablePagination
          pageNumber={page}
          pageSize={size}
          totalCount={totalCount}
          onPageChange={this.onPageChange}
          onSizeChange={this.onSizeChange}
        />
        <ModalWithItem
          modalTitle={t('label.actionsList')}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          isOpen={isOpenMdalDetails}
          onToggle={this.onToggleModalDetails}
        >
          <DataTable columns={columnsActionsList} data={unitActionList || []} />
          <button type="button" onClick={this.onToggleModalDetails} className="button x-small float-right">
            {t('label.back')}
          </button>
        </ModalWithItem>
      </div>
    );
  }
}

BillUnits.propTypes = {
  searchInvoiceInCollection: PropTypes.func.isRequired,
  match: PropTypes.objectOf(PropTypes.any),
  invoiceUnits: PropTypes.arrayOf(PropTypes.any),
  modifyCollectionUnit: PropTypes.func.isRequired,
  permissionsCollections: PropTypes.objectOf(PropTypes.any),
};

BillUnits.defaultProps = {
  match: {},
  invoiceUnits: [],
  permissionsCollections: {},
};

const mapStateToProps = createStructuredSelector({
  invoiceUnits: makeGetListInvoiceUnits() || [],
  params: makeInvoiceUnitsParams() || {},
  errorMessage: errorMessageInvoiceUnits() || '',
  permissionsCollections: makeGetPermissionsCollectionManagement() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    searchInvoiceInCollection,
    setParamsInvoiceInCollection,
    modifyCollectionUnit,
    getObjectFileById,
  })(withRouter(BillUnits))
);
