import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import SearchComponent from '../../PricingHub/SearchComponent';

class CollectionActionsSearchForm extends SearchComponent {
  searchInputs = [
    {
      label: 'label.actionDate',
      name: 'actionDate',
      type: 'date',
      defaultValue: moment()
      .startOf('month')
      .format('YYYY-MM-DD'),
    },
  ];

  btnSpan = 'col-md-9';
}

CollectionActionsSearchForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

CollectionActionsSearchForm.defaultProps = {
  isSubmitting: false,
};

export default withTranslation('common')(CollectionActionsSearchForm);
