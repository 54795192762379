import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';
import itemNS from './constants/exchangeRateNS';
import { getUserId } from '../../localStorages';

export default data => {
  const newData = data;
  newData.userId = getUserId() || newData.userId || null;
  const dataCreate = parseToMutationRequest(newData, itemNS);
  const createExchangeRate = `createExchangeRate(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createExchangeRate} {
      id
    }
  }
`;
};

