// import React from 'react';
import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';
import { getUserId } from '../../localStorages';

export default data => {
  const newData = data;
  newData.userId = getUserId() || newData.userId || null;
  const dataModify = parseToMutationRequest(newData, ['status', 'gracePeriodDays']);
  const modifyCollectionUnit = `modifyCollectionUnit(input: ${dataModify})`;
  return gql`
  mutation {
    ${modifyCollectionUnit} {
      id
      userId
      accountId
      invoiceId
      invoiceDate
      dueDate
      lastActionDate
      notes
      closeDate
      daysInCollection
      status
    }
  }
`;
};
