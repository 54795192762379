import React, { useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { map, groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { month2Translation, getMaxMinData } from '../../../utils/utils';

const colorsList = [
  '#28a745',
  '#3366ff',
  '#d4526e',
  '#13d8aa',
  '#2b908f',
  '#f9a3a4',
  '#90ee7e',
  '#f48024',
  '#69d2e7',
  '#33b2df',
  '#ff66ff',
  '#996633',
  '#993333',
  '#cccc00',
  '#9900ff',
  '#800000',
  '#b35900',
  '#660066',
  '#004d1a',
  '#00e600',
  '#33cccc',
  '#666699',
  '#cc3300',
  '#bbff33',
];

const convertLabelCategories = ({ value, isNeedSupportYear, t }) => {
  return isNeedSupportYear ? `${month2Translation(value, t)}-${moment().format('YYYY')}` : month2Translation(value, t);
};

const convertCategories = ({ data, keyCategories, isNeedSupportYear, t }) => {
  return data && data.length > 0
    ? map(data, item => convertLabelCategories({ value: item[keyCategories], t, isNeedSupportYear }))
    : [];
};

const LineChartsGroup = ({
  title,
  wrapperClass,
  data,
  keySeries,
  keyCategories,
  style,
  isNeedSupportYear,
  isToolbar,
  subClass,
  isDescription,
  minWidthYAxis,
  keyData,
  keyType,
  topN,
  isSupportZero,
}) => {
  const { t } = useTranslation('common');
  const listLabels = [];
  let newData = [];
  let maxValue = 0;

  const categories = convertCategories({ data, keyCategories, isNeedSupportYear, t });

  if (data && data.length) {
    let dataConvert = [];

    data.forEach(val => {
      if (val[keyData || 'folioTypeData'] && val[keyData || 'folioTypeData'].length)
        dataConvert = [...dataConvert, ...val[keyData || 'folioTypeData'].map(dt => ({ ...dt, mon: val.mon }))];
    });

    dataConvert = groupBy(dataConvert, item => {
      return item[keyType || 'folioType'];
    });

    map(dataConvert, (value, key) => {
      if (categories && categories.length) {
        const dataItem = categories.map(val => {
          const monItem =
            value && value.find(it => convertLabelCategories({ value: it.mon, isNeedSupportYear, t }) === val);
          if (!maxValue || (monItem && monItem.value > maxValue)) {
            maxValue = monItem.value;
          }
          if (isSupportZero) return monItem && monItem.value ? monItem.value : 0;
          return monItem && monItem.value ? monItem.value : null;
        });
        newData = [...newData, { data: dataItem, name: key }];
      }
      listLabels.push(key);
    });
  }

  if (newData && newData.length && topN) {
    newData = newData.splice(0, topN);
  }

  const options = {
    xaxis: {
      categories,
      labels: {
        show: categories && categories.length,
      },
    },
    yaxis: {
      labels: {
        minWidth: minWidthYAxis || 50,
        show: categories && categories.length,
        offsetY: 0,
      },
      min: 0,
      max: maxValue ? maxValue + (maxValue * 10) / 100 : undefined,
    },
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: !!isToolbar,
        export: {
          csv: {
            filename: `${title.replace(/ /g, '')}_${moment(new Date()).format('YYYY_MM_DD')}`,
            columnDelimiter: ',',
            headerCategory: keyCategories || 'category',
            headerValue: keySeries || 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: `${title.replace(/ /g, '')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          },
          png: {
            filename: `${title.replace(/ /g, '')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          },
        },
      },
    },
    markers: {
      size: 8,
    },
    tooltip: {
      enabled: true,
      theme: 'dark',
    },
    colors: listLabels && listLabels.length === 1 ? colorsList : undefined,
    stroke: {
      curve: 'smooth',
    },

    dataLabels: {
      enabled: true,
      offsetY: 1,
      background: {
        borderRadius: 6,
      },
    },

    noData: {
      text: 'No data to present',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '13px',
        fontFamily: "'Poppins', sans-serif",
        fontStyle: 'italic',
      },
    },

    responsive: [
      {
        breakpoint: 700,
        options: {
          legend: {
            position: 'right',
            horizontalAlign: 'right',
            show: true,
          },
        },
      },
    ],
  };

  useEffect(() => {
    const dom = document.getElementsByTagName('tspan');
    if (dom) {
      for (let i = 0; i < dom.length; i++) {
        if (dom && dom[i] && dom[i].textContent === 'NaN') dom[i].textContent = '';
      }
    }
  }, []);
  const dataInfo = [];

  if (data && data.length && isDescription) {
    data.forEach(val => {
      const { folioTypeData } = val;
      if (folioTypeData && folioTypeData.length) {
        let count = 0;
        folioTypeData.forEach(fl => {
          count += fl.value || 0;
        });
        dataInfo.push(count || 0);
      }
    });
  }

  const { max, min, avg } = getMaxMinData(dataInfo || []);

  return (
    <div className={wrapperClass || 'col-md-4'} style={style}>
      <div className="mixed-chart responsive-chart">
        <h5 className={`p-2 text-center card-title no-border ${subClass || ''}`}>{title}</h5>
        {listLabels && listLabels.length === 1 && (
          <div className="chart-value bar-chart-value chart-line-value">
            {listLabels.map((label, index) => (
              <div className={`d-flex ${subClass || ''}`} key={`key-${index}`}>
                <div className="label-details" style={{ backgroundColor: colorsList[index] }} />
                <div className="title-number bar-title-number">{label}</div>
              </div>
            ))}
          </div>
        )}
        {isDescription && max + min + avg !== 0 && (
          <div className="bar-chart-value-min-max">
            <div className={`d-flex ${subClass || ''}`}>
              <div className="bar-number-chart-details max-number">{max}</div>
              <div className="bar-title-number-min-max">{t('label.max')}</div>
            </div>
            <div className={`d-flex ${subClass || ''}`}>
              <div className="bar-number-chart-details min-number">{min}</div>
              <div className="bar-title-number-min-max">{t('label.min')}</div>
            </div>
            <div className={`d-flex ${subClass || ''}`}>
              <div className="bar-number-chart-details avg-number">{avg}</div>
              <div className="bar-title-number-min-max">{t('label.avg')}</div>
            </div>
          </div>
        )}
        <ReactApexChart
          className={listLabels && listLabels.length === 1 ? 'chart-line-item-grouped' : ''}
          options={options}
          series={newData || []}
          width="100%"
          type="line"
        />
      </div>
    </div>
  );
};

LineChartsGroup.propTypes = {
  title: PropTypes.string,
  wrapperClass: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any),
  keySeries: PropTypes.string,
  keyCategories: PropTypes.string,
  dataType: PropTypes.string,
};

LineChartsGroup.defaultProps = {
  title: '',
  wrapperClass: '',
  keySeries: '',
  keyCategories: '',
  data: [],
  dataType: 'COUNT',
};

export default LineChartsGroup;
