import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { queryRequest, mutationRequest } from 'utils/request';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import {
  getARReasonCodeConfig,
  modifyARReasonCodeConfig,
  createARReasonCodeConfig,
  searchRevenueMilestone,
  getRevenueMilestoneById,
  modifyRevenueMilestone,
  createRevenueMilestone,
  searchExchangeRate,
  getExchangeRate,
  createExchangeRate,
  modifyExchangeRate,
  exportExchangeRate,
  getLocations,
  modifyLocation,
  createLocation,
  getEnterprise,
  modifyEnterprise,
  createEnterprise,
  getDivision,
  modifyDivision,
  createDivision,
  createLegalEntity,
  getLegalEntity,
  modifyLegalEntity,
  getBusinessUnit,
  modifyBusinessUnit,
  createBusinessUnit,
  getGlAccount,
  createGLAccountConfig,
  modifyGLAccountConfig,
  getDepartment,
  modifyDepartment,
  createDepartment,
  getCostCenter,
  createCostCenter,
  modifyCostCenter,
  createCalendarConfig,
  modifyCalendarConfig,
  getCalendar,
  getCOA,
  createCOA,
  modifyCOA,
  getConfigAccountingRules,
  modifyConfigAccountingRules,
  createConfigAccountingRules,
  getCostCenterMap,
  saveCostCenterMap,
  modifyChartOfAccount,
  createChartOfAccount,
  searchChartOfAccount,
  searchConfigAccountingRules,
  getErpExtractBatchConfig,
  modifyErpExtractBatchConfig,
  createErpExtractBatchConfig,
} from 'api';
import { isConnecting, isEndConnected } from '../Loader/actions';
import * as types from './types';
import * as actions from './actions';
import i18n from '../../i18n';

export function* getARReasonCodeConfigRevenueSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getARReasonCodeConfig(payload));
    if (response.getARReasonCodeConfig) {
      if (cb) cb({ success: true, data: response.getARReasonCodeConfig });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* modifyARReasonCodeConfigRevenueSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyARReasonCodeConfig(payload));
    if (response.modifyARReasonCodeConfig) {
      toast.success(i18n.t('common:apiMessage.modifyReasonCodeSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyARReasonCodeConfig });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createARReasonCodeConfigRevenueSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createARReasonCodeConfig(payload));
    if (response.createARReasonCodeConfig) {
      toast.success(i18n.t('common:apiMessage.createReasonCodeSuccessfully'));
      if (cb) cb({ success: true, data: response.createARReasonCodeConfig });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* searchRevenueMilestoneSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchRevenueMilestone(payload));
    if (response.searchRevenueMilestone) {
      yield put(actions.searchRevenueMilestoneSuccess(response.searchRevenueMilestone));
      if (cb) cb({ success: true, data: response.searchRevenueMilestone });
    } else {
      yield put(actions.searchRevenueMilestoneSuccess([]));
      if (cb) cb({ success: true, data: null });
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(actions.searchRevenueMilestoneSuccess([]));
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* getRevenueMilestoneByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getRevenueMilestoneById(payload));
    if (response.getRevenueMilestoneById) {
      yield put(actions.getRevenueMilestoneByIdSuccess(response.getRevenueMilestoneById));
      if (cb) cb({ success: true, data: response.getRevenueMilestoneById });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* modifyRevenueMilestoneSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyRevenueMilestone(payload));
    if (response.modifyRevenueMilestone) {
      if (cb) cb({ success: true, data: response.modifyRevenueMilestone });
      toast.success(i18n.t('common:apiMessage.modifyMilestoneSuccessfully'));
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createRevenueMilestoneSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createRevenueMilestone(payload));
    if (response.createRevenueMilestone) {
      toast.success(i18n.t('common:apiMessage.createMilestoneSuccessfully'));
      yield put(push(`/revenue-config/milestone/modify/${response.createRevenueMilestone.id}`));
      if (cb) cb({ success: true, data: response.createRevenueMilestone });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* searchExchangeRateSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchExchangeRate(payload));
    if (response.searchExchangeRate) {
      yield put(actions.searchExchangeRateSuccess(response.searchExchangeRate));
      if (cb) cb({ success: true, data: response.searchExchangeRate });
    } else if (cb) {
      cb({ success: true, data: null });
      yield put(actions.searchExchangeRateSuccess([]));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(actions.searchExchangeRateSuccess([]));
    yield put(isEndConnected());
  }
}

export function* getExchangeRateByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getExchangeRate(payload));
    if (response.getExchangeRate) {
      yield put(actions.getExchangeRateByIdSuccess(response.getExchangeRate));
      if (cb) cb({ success: true, data: response.getExchangeRate });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* modifyExchangeRateSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyExchangeRate(payload));
    if (response.modifyExchangeRate) {
      toast.success(i18n.t('common:apiMessage.modifyExchangeRateSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyExchangeRate });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createExchangeRateSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createExchangeRate(payload));
    if (response.createExchangeRate) {
      toast.success(i18n.t('common:apiMessage.createExchangeRateSuccessfully'));
      yield put(push(`/revenue-configuration/currency-exchange/modify/${response.createExchangeRate.id}`));
      if (cb) cb({ success: true, data: response.createExchangeRate });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* exportExchangeRateSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, exportExchangeRate(payload));
    if (response.exportExchangeRate) {
      yield put(actions.exportExchangeRateSuccess(response.exportExchangeRate));
      if (cb) cb({ success: true, data: response.exportExchangeRate });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

export function* getLocationsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getLocations(payload));
    if (response.getLocations) {
      if (cb) cb({ success: true, data: response.getLocations });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* modifyLocationSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyLocation(payload));
    if (response.modifyLocation) {
      toast.success(i18n.t('common:apiMessage.modifyLocationSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyLocation });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createLocationSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createLocation(payload));
    if (response.createLocation) {
      toast.success(i18n.t('common:apiMessage.createLocationSuccessfully'));
      if (cb) cb({ success: true, data: response.createLocation });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* getEnterpriseSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getEnterprise(payload));
    if (response.getEnterprise) {
      if (cb) cb({ success: true, data: response.getEnterprise });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* modifyEnterpriseSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyEnterprise(payload));
    if (response.modifyEnterprise) {
      toast.success(i18n.t('common:apiMessage.modifyEnterpriseSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyEnterprise });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createEnterpriseSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createEnterprise(payload));
    if (response.createEnterprise) {
      toast.success(i18n.t('common:apiMessage.createEnterpriseSuccessfully'));
      if (cb) cb({ success: true, data: response.createEnterprise });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* getDivisionSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getDivision(payload));
    if (response.getDivision) {
      if (cb) cb({ success: true, data: response.getDivision });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* modifyDivisionSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyDivision(payload));
    if (response.modifyDivision) {
      toast.success(i18n.t('common:apiMessage.modifyDivisionSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyDivision });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createDivisionSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createDivision(payload));
    if (response.createDivision) {
      toast.success(i18n.t('common:apiMessage.createDivisionSuccessfully'));
      if (cb) cb({ success: true, data: response.createDivision });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* getLegalEntitySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getLegalEntity(payload));
    if (response.getLegalEntity) {
      if (cb) cb({ success: true, data: response.getLegalEntity });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* modifyLegalEntitySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyLegalEntity(payload));
    if (response.modifyLegalEntity) {
      toast.success(i18n.t('common:apiMessage.modifyLegalEntitySuccessfully'));
      if (cb) cb({ success: true, data: response.modifyLegalEntity });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createLegalEntitySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createLegalEntity(payload));
    if (response.createLegalEntity) {
      toast.success(i18n.t('common:apiMessage.createLegalEntitySuccessfully'));
      if (cb) cb({ success: true, data: response.createLegalEntity });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* getBusinessUnitsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBusinessUnit(payload));
    if (response.getBusinessUnit) {
      if (cb) cb({ success: true, data: response.getBusinessUnit });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* modifyBusinessUnitsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyBusinessUnit(payload));
    if (response.modifyBusinessUnit) {
      toast.success(i18n.t('common:apiMessage.modifyBusinessUnitSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyBusinessUnit });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createBusinessUnitsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createBusinessUnit(payload));
    if (response.createBusinessUnit) {
      toast.success(i18n.t('common:apiMessage.createBusinessUnitSuccessfully'));
      if (cb) cb({ success: true, data: response.createBusinessUnit });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* getGLSetupSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getGlAccount(payload));
    if (response.getGlAccount) {
      if (cb) cb({ success: true, data: response.getGlAccount });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* modifyGLSetupSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyGLAccountConfig(payload));
    if (response.modifyGlAccountConfig) {
      toast.success(i18n.t('common:apiMessage.modifyGLAccountSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyGlAccountConfig });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createGLSetupSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createGLAccountConfig(payload));
    if (response.createGlAccountConfig) {
      toast.success(i18n.t('common:apiMessage.createGLAccountSuccessfully'));
      if (cb) cb({ success: true, data: response.createGlAccountConfig });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* getDepartmentSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getDepartment(payload));
    if (response.getDepartment) {
      if (cb) cb({ success: true, data: response.getDepartment });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* modifyDepartmentSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyDepartment(payload));
    if (response.modifyDepartment) {
      toast.success(i18n.t('common:apiMessage.modifyDepartmentSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyDepartment });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createDepartmentSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createDepartment(payload));
    if (response.createDepartment) {
      toast.success(i18n.t('common:apiMessage.createDepartmentSuccessfully'));
      if (cb) cb({ success: true, data: response.createDepartment });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* getCostCenterSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCostCenter(payload));
    if (response.getCostCenter) {
      if (cb) cb({ success: true, data: response.getCostCenter });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* modifyCostCenterSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyCostCenter(payload));
    if (response.modifyCostCenter) {
      toast.success(i18n.t('common:apiMessage.modifyCostCenterSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyCostCenter });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createCostCenterSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createCostCenter(payload));
    if (response.createCostCenter) {
      toast.success(i18n.t('common:apiMessage.createCostCenterSuccessfully'));
      if (cb) cb({ success: true, data: response.createCostCenter });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* getCalendarSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCalendar(payload));
    if (response.getCalendar) {
      if (cb) cb({ success: true, data: response.getCalendar });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* modifyCalendarSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyCalendarConfig(payload));
    if (response.modifyCalendarConfig) {
      toast.success(i18n.t('common:apiMessage.modifyCalendarSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyCalendarConfig });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createCalendarSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createCalendarConfig(payload));
    if (response.createCalendarConfig) {
      toast.success(i18n.t('common:apiMessage.createCalendarSuccessfully'));
      if (cb) cb({ success: true, data: response.createCalendarConfig });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* getCOASaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCOA(payload));
    if (response.getCOA) {
      if (cb) cb({ success: true, data: response.getCOA });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* modifyCOASaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyCOA(payload));
    if (response.modifyCOA) {
      toast.success(i18n.t('common:apiMessage.modifyChartOfAccountSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyCOA });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createCOASaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createCOA(payload));
    if (response.createCOA) {
      toast.success(i18n.t('common:apiMessage.createChartOfAccountSuccessfully'));
      if (cb) cb({ success: true, data: response.createCOA });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* getConfigAccountingRulesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getConfigAccountingRules(payload));
    if (response.getConfigAccountingRules) {
      if (cb) cb({ success: true, data: response.getConfigAccountingRules });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* modifyConfigAccountingRulesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyConfigAccountingRules(payload));
    if (response.modifyConfigAccountingRules) {
      toast.success(i18n.t('common:apiMessage.modifyAccountingPoliciesSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyConfigAccountingRules });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createConfigAccountingRulesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createConfigAccountingRules(payload));
    if (response.createConfigAccountingRules) {
      toast.success(i18n.t('common:apiMessage.createAccountingPoliciesSuccessfully'));
      if (cb) cb({ success: true, data: response.createConfigAccountingRules });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* getChartOfAccountSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchChartOfAccount(payload));
    if (response.searchChartOfAccount) {
      if (cb) cb({ success: true, data: response.searchChartOfAccount });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* modifyChartOfAccountSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyChartOfAccount(payload));
    if (response.modifyChartOfAccount) {
      toast.success(i18n.t('common:apiMessage.modifyChartOfAccountSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyChartOfAccount });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createChartOfAccountSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createChartOfAccount(payload));
    if (response.createChartOfAccount) {
      toast.success(i18n.t('common:apiMessage.createChartOfAccountSuccessfully'));
      if (cb) cb({ success: true, data: response.createChartOfAccount });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* getCostCenterMapSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getCostCenterMap(payload));
    if (response.getCostCenterMap) {
      if (cb) cb({ success: true, data: response.getCostCenterMap });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* saveCostCenterMapSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, saveCostCenterMap(payload));
    if (response.saveCostCenterMap) {
      toast.success(i18n.t('common:apiMessage.saveCostCenterMapSuccessfully'));
      if (cb) cb({ success: true, data: response.saveCostCenterMap });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* searchAllGLAccountsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchChartOfAccount(payload));
    if (response.searchChartOfAccount) {
      yield put(actions.searchAllGLAccountsSuccess(response.searchChartOfAccount));
      if (cb) cb({ success: true, data: response.searchChartOfAccount });
    } else {
      yield put(actions.searchAllGLAccountsSuccess([]));
      if (cb) cb({ success: true, data: null });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(actions.searchAllGLAccountsSuccess([]));
    yield put(isEndConnected());
  }
}

export function* searchConfigAccountingRulesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchConfigAccountingRules(payload));
    if (response.searchConfigAccountingRules) {
      if (cb) cb({ success: true, data: response.searchConfigAccountingRules });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* getErpExtractBatchConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getErpExtractBatchConfig(payload));
    if (response.getErpExtractBatchConfig) {
      if (cb) cb({ success: true, data: response.getErpExtractBatchConfig });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* modifyErpExtractBatchConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { isDelete, ...rest } = payload;
    const response = yield call(mutationRequest, modifyErpExtractBatchConfig(rest));
    if (isDelete) toast.success(i18n.t('common:apiMessage.removeItemSuccessfully'));
    if (response.modifyErpExtractBatchConfig) {
      toast.success(i18n.t('common:apiMessage.modifyErpExtractBatchConfigSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyErpExtractBatchConfig });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createErpExtractBatchConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createErpExtractBatchConfig(payload));
    if (response.createErpExtractBatchConfig) {
      toast.success(i18n.t('common:apiMessage.createErpExtractBatchConfigSuccessfully'));
      if (cb) cb({ success: true, data: response.createErpExtractBatchConfig });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* paymentSaga() {
  yield takeLatest(types.GET_AR_REASON_CODE_REVENUE, getARReasonCodeConfigRevenueSaga);
  yield takeLatest(types.MODIFY_AR_REASON_CODE_REVENUE, modifyARReasonCodeConfigRevenueSaga);
  yield takeLatest(types.CREATE_AR_REASON_CODE_REVENUE, createARReasonCodeConfigRevenueSaga);
  yield takeLatest(types.SEARCH_REVENUE_MILESTONE, searchRevenueMilestoneSaga);
  yield takeLatest(types.GET_REVENUE_MILESTONE_BY_ID, getRevenueMilestoneByIdSaga);
  yield takeLatest(types.MODIFY_REVENUE_MILESTONE, modifyRevenueMilestoneSaga);
  yield takeLatest(types.CREATE_REVENUE_MILESTONE, createRevenueMilestoneSaga);
  yield takeLatest(types.SEARCH_EXCHANGE_RATE, searchExchangeRateSaga);
  yield takeLatest(types.MODIFY_EXCHANGE_RATE, modifyExchangeRateSaga);
  yield takeLatest(types.CREATE_EXCHANGE_RATE, createExchangeRateSaga);
  yield takeLatest(types.GET_EXCHANGE_RATE_BY_ID, getExchangeRateByIdSaga);
  yield takeLatest(types.EXPORT_EXCHANGE_RATE_BY_ID, exportExchangeRateSaga);
  yield takeLatest(types.GET_LOCATION, getLocationsSaga);
  yield takeLatest(types.CREATE_LOCATION, createLocationSaga);
  yield takeLatest(types.MODIFY_LOCATION, modifyLocationSaga);
  yield takeLatest(types.GET_ENTERPRISE, getEnterpriseSaga);
  yield takeLatest(types.CREATE_ENTERPRISE, createEnterpriseSaga);
  yield takeLatest(types.MODIFY_ENTERPRISE, modifyEnterpriseSaga);
  yield takeEvery(types.GET_DIVISION, getDivisionSaga);
  yield takeLatest(types.CREATE_DIVISION, createDivisionSaga);
  yield takeLatest(types.MODIFY_DIVISION, modifyDivisionSaga);
  yield takeEvery(types.GET_LEGAL_ENTITY, getLegalEntitySaga);
  yield takeLatest(types.CREATE_LEGAL_ENTITY, createLegalEntitySaga);
  yield takeLatest(types.MODIFY_LEGAL_ENTITY, modifyLegalEntitySaga);
  yield takeEvery(types.GET_BUSINESS_UNITS, getBusinessUnitsSaga);
  yield takeLatest(types.CREATE_BUSINESS_UNITS, createBusinessUnitsSaga);
  yield takeLatest(types.MODIFY_BUSINESS_UNITS, modifyBusinessUnitsSaga);
  yield takeLatest(types.GET_GL_SETUP, getGLSetupSaga);
  yield takeLatest(types.CREATE_GL_SETUP, createGLSetupSaga);
  yield takeLatest(types.MODIFY_GL_SETUP, modifyGLSetupSaga);
  yield takeEvery(types.GET_DEPARTMENT, getDepartmentSaga);
  yield takeLatest(types.CREATE_DEPARTMENT, createDepartmentSaga);
  yield takeLatest(types.MODIFY_DEPARTMENT, modifyDepartmentSaga);
  yield takeEvery(types.GET_COST_CENTER, getCostCenterSaga);
  yield takeLatest(types.CREATE_COST_CENTER, createCostCenterSaga);
  yield takeLatest(types.MODIFY_COST_CENTER, modifyCostCenterSaga);
  yield takeEvery(types.GET_CALENDAR, getCalendarSaga);
  yield takeLatest(types.CREATE_CALENDAR, createCalendarSaga);
  yield takeLatest(types.MODIFY_CALENDAR, modifyCalendarSaga);
  yield takeEvery(types.GET_COA, getCOASaga);
  yield takeLatest(types.CREATE_COA, createCOASaga);
  yield takeLatest(types.MODIFY_COA, modifyCOASaga);
  yield takeEvery(types.GET_CONFIG_ACCOUNTING_RULES, getConfigAccountingRulesSaga);
  yield takeLatest(types.CREATE_CONFIG_ACCOUNTING_RULES, createConfigAccountingRulesSaga);
  yield takeLatest(types.MODIFY_CONFIG_ACCOUNTING_RULES, modifyConfigAccountingRulesSaga);
  yield takeEvery(types.GET_CHART_OF_ACCOUNT_REVENUE, getChartOfAccountSaga);
  yield takeLatest(types.CREATE_CHART_OF_ACCOUNT_REVENUE, createChartOfAccountSaga);
  yield takeLatest(types.MODIFY_CHART_OF_ACCOUNT_REVENUE, modifyChartOfAccountSaga);
  yield takeLatest(types.GET_COST_CENTER_MAP, getCostCenterMapSaga);
  yield takeLatest(types.SAVE_COST_CENTER_MAP, saveCostCenterMapSaga);
  yield takeLatest(types.SEARCH_ALL_GL_ACCOUNTS, searchAllGLAccountsSaga);
  yield takeLatest(types.SEARCH_CONFIG_ACCOUNTING_RULES, searchConfigAccountingRulesSaga);
  yield takeLatest(types.GET_ERP_EXTRACT_BATCH_CONFIG, getErpExtractBatchConfigSaga);
  yield takeLatest(types.MODIFY_ERP_EXTRACT_BATCH_CONFIG, modifyErpExtractBatchConfigSaga);
  yield takeLatest(types.CREATE_ERP_EXTRACT_BATCH_CONFIG, createErpExtractBatchConfigSaga);
}
