import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import {
  doLogout,
  getTenantDefaultConfig,
  readFileFromS3,
  getAwsS3Credential,
  getCurrencyConfigApp,
} from '../../App/actions';
import logoIconDark from '../../../Assets/images/logo-icon-dark.png';
import avatarUrl from '../../../Assets/images/profile-avatar.jpg';
import RouteNames from '../../App/RouteNames';
import {
  getUserId,
  getEmail,
  getSSOLogin,
  setRounding,
  setLogoBase64,
  clearAllLocalStorage,
  getAvatarBase64,
} from '../../../localStorages';
import { makeGetS3Config, makeGetCurrencyConfig, makeGetAvatarProfile } from '../../App/selectors';
import { itemLangSelected, listLangNotSelect } from '../../../utils/listLangue';
import { getBase64, checkPermissionWithRoutes, isHasPermissionWithModule } from '../../../utils/utils';
import { navLinkItems, permissionList } from '../../../utils/constants';

class Navbar extends Component {
  constructor(props) {
    super(props);
    if (!localStorage.getItem('i18nextLng') || !props?.i18n?.language) {
      localStorage.setItem('i18nextLng', 'en-US');
    }
    this.state = {
      lang: localStorage.getItem('i18nextLng') || props?.i18n?.language || 'en-US',
      base64Logo: null,
      pathFile: null,
      isFirstGetTheLogo: false,
    };
  }

  componentDidMount() {
    const { getTenantDefaultConfig, getAwsS3Credential, s3Config, getCurrencyConfigApp, currencyConfig } = this.props;
    getCurrencyConfigApp('', ({ success, data }) => {
      if (success && data && data.currencyListConfig && data?.currencyListConfig[0]?.roundingPrecision) {
        setRounding(data.currencyListConfig[0].roundingPrecision);
      }
    });
    getTenantDefaultConfig(({ success, data }) => {
      if (success && data && data.tenantProfile && data.tenantProfile.logoPath) {
        this.setState({ pathFile: data.tenantProfile.logoPath });
      }
    });
  }

  componentDidUpdate() {
    const { s3Config } = this.props;
    const { isFirstGetTheLogo, pathFile } = this.state;
    if (!isFirstGetTheLogo && pathFile && (s3Config || s3Config.accessKey)) {
      this.doReadFileFromConfig(s3Config, pathFile);
    }
  }

  doReadFileFromConfig = (config, pathFile) => {
    const { readFileFromS3 } = this.props;
    this.setState({ isFirstGetTheLogo: true });
    readFileFromS3({ ...config, url: pathFile }, ({ success, data }) => {
      if (success) {
        getBase64(data, result => {
          setLogoBase64(result);
          this.setState({ base64Logo: result });
        });
      }
    });
  };

  onToggleSidebar = e => {
    e.preventDefault();
    const { onToggleSidebar } = this.props;
    if (onToggleSidebar) {
      onToggleSidebar();
    }
  };

  toggleFullScreen = event => {
    let element = document.body;
    if (event instanceof HTMLElement) {
      element = event;
    }
    const isFullscreen = document.webkitIsFullScreen || document.mozFullScreen || false;
    element.requestFullScreen =
      element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen;
    document.cancelFullScreen =
      document.cancelFullScreen || document.webkitCancelFullScreen || document.mozCancelFullScreen;
    return isFullscreen ? document.cancelFullScreen() : element.requestFullScreen();
  };

  isCurrentTab = hubpath => {
    const {
      location: { pathname },
    } = this.props;
    return pathname.match(new RegExp(hubpath, 'i')) ? 'active' : '';
  };

  renderCustomerHub = t => {
    const { permissions } = this.props;
    return (
      <li
        className={`nav-item animate__animated animate__slideInUp ${this.isCurrentTab(
          `${RouteNames.customers.path}|${RouteNames.orders.path}|${RouteNames.quotePage.path}`
        )}`}
      >
        <div className="dropdown dropdown-menu-group">
          <span
            className="button button-border gray icon x-small dropdown-toggle"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t('customerHub.main')}
          </span>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            {checkPermissionWithRoutes({ links: navLinkItems.customerHubModules.links, permissions }).map(i => (
              <Link className="dropdown-item" to={i.path} key={i.path}>
                {t(i.label)}
              </Link>
            ))}
          </div>
        </div>
      </li>
    );
  };

  renderBillingHub = t => {
    const { permissions } = this.props;
    return (
      <li
        className={`nav-item animate__animated animate__slideInUp ${this.isCurrentTab(
          `${RouteNames.billingConfig.path}|${RouteNames.taxation.path}|${RouteNames.usage.path}`
        )}`}
      >
        <div className="dropdown dropdown-menu-group">
          <span
            className="button button-border gray icon x-small dropdown-toggle"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t('billingHub.main')}
          </span>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            {checkPermissionWithRoutes({ links: navLinkItems.billingHubModules.links, permissions }).map(i => (
              <Link className="dropdown-item" to={i.path} key={i.path}>
                {t(i.label)}
              </Link>
            ))}
          </div>
        </div>
      </li>
    );
  };

  renderPricingHub = t => {
    const { permissions } = this.props;
    return (
      <li
        className={`nav-item animate__animated animate__slideInUp ${this.isCurrentTab(
          `${RouteNames.pricingItem.path}|${RouteNames.pricingPriceOffer.path}|${RouteNames.pricingDiscountOffer.path}|${RouteNames.bundleItem.path}|${RouteNames.packageItem.path}|${RouteNames.dependencyItem.path}|${RouteNames.baseConfigurations.path}|${RouteNames.productFamilyPricing.path}|${RouteNames.itemServices.path}|${RouteNames.topupOffers.path}`
        )}`}
      >
        <div className="dropdown dropdown-menu-group">
          <span
            className="button button-border gray icon x-small dropdown-toggle"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t('pricingHub.main')}
          </span>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            {checkPermissionWithRoutes({ links: navLinkItems.pricingHubModules.links, permissions }).map(i => (
              <Link className="dropdown-item" to={i.path} key={i.path}>
                {t(i.label)}
              </Link>
            ))}
          </div>
        </div>
      </li>
    );
  };

  renderArHubModules = t => {
    const { permissions } = this.props;
    return (
      <li
        className={`nav-item animate__animated animate__slideInUp ${this.isCurrentTab(
          `${RouteNames.paymentConfiguration.path}|
    |${RouteNames.paymentSurcharge.path}|
    |${RouteNames.paymentOneOff.path}|
    |${RouteNames.paymentActivity.path}|
    |${RouteNames.paymentSuspense.path}|
    |${RouteNames.uploadPayment.path}|
    |${RouteNames.processPayment.path}|
    |${RouteNames.arOperationsConfigItemMap.path}|
    |${RouteNames.arOperationsConfigReasonCode.path}|
    |${RouteNames.arOperationsAdjustments.path}|
    |${RouteNames.arOperationsDisputes.path}|
    |${RouteNames.arOperationsConfig.path}|
    |${RouteNames.collections.path}|
    |${RouteNames.arOperationsWriteOffs.path}|
    |${RouteNames.arOperationsConfigGetReasonGroup.path}|
    |${RouteNames.paymentItemMap.path}|
    |${RouteNames.batchPaymentFile.path}|
    |${RouteNames.arCreditNotesReport.path}|
    |${RouteNames.arOperationsARThresholds.path}|
    |${RouteNames.arOperationsBulkAdjustments.path}|
    |${RouteNames.allocationRules.path}`
        )}`}
      >
        <div className="dropdown dropdown-menu-group">
          <span
            className="button button-border gray icon x-small dropdown-toggle"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t('arHub.main')}
          </span>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            {checkPermissionWithRoutes({ links: navLinkItems.arHubModules.links, permissions }).map(i => (
              <Link className="dropdown-item" to={i.path} key={i.path}>
                {t(i.label)}
              </Link>
            ))}
          </div>
        </div>
      </li>
    );
  };

  renderRevenueHubModules = t => {
    const { permissions } = this.props;
    return (
      <li
        className={`nav-item animate__animated animate__slideInUp ${this.isCurrentTab(
          `${RouteNames.revenueConfig.path}|${RouteNames.accountingLog.path}`
        )}`}
      >
        <div className="dropdown dropdown-menu-group">
          <span
            className="button button-border gray icon x-small dropdown-toggle"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t('revenueHub.main')}
          </span>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            {checkPermissionWithRoutes({ links: navLinkItems.revenueHubModules.links, permissions }).map(i => (
              <Link className="dropdown-item" to={i.path} key={i.path}>
                {t(i.label)}
              </Link>
            ))}
          </div>
        </div>
      </li>
    );
  };

  renderOpsHubModules = t => {
    const { permissions } = this.props;
    return (
      <li
        className={`nav-item animate__animated animate__slideInUp ${this.isCurrentTab(
          `${RouteNames.userManagement.path}|${RouteNames.jobsManagment.path}|${RouteNames.correspondence.path}|${RouteNames.report.path}|${RouteNames.dashboard.path}|${RouteNames.tenantManagement.path}|${RouteNames.taskManagement.path}`
        )}`}
      >
        <div className="dropdown dropdown-menu-group">
          <span
            className="button button-border gray icon x-small dropdown-toggle"
            role="button"
            id="dropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t('operationsHub.main')}
          </span>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            {checkPermissionWithRoutes({ links: navLinkItems.opsHubModules.links, permissions }).map(i => (
              <Link className="dropdown-item" to={i.path} key={i.path}>
                {t(i.label)}
              </Link>
            ))}
          </div>
        </div>
      </li>
    );
  };

  renderControlProfile = t => {
    const { lang } = this.state;
    const { avatarProfile } = this.props;

    return (
      <ul className="nav navbar-nav ml-auto">
        <li className="nav-item dropdown ">
          <button
            className="nav-link no-border"
            data-toggle="dropdown"
            type="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="title-language">
              {itemLangSelected(lang || 'en-US') ? itemLangSelected(lang || 'en-US').name : 'English'}
            </span>
            <img src={itemLangSelected(lang || 'en-US').flag} alt="flag" className="flag-icon" />
          </button>
          <div className="dropdown-menu dropdown-menu-right dropdown-language">
            {listLangNotSelect(lang || 'en-US').map(item => (
              <button
                key={item.key}
                type="button"
                className="dropdown-item"
                onClick={() => this.onChangeLang(item.key)}
              >
                <span className="title-language">{item.name}</span>
                <span className="float-right">
                  <img src={item.flag} alt="flag" className="flag-icon-item" />
                </span>
              </button>
            ))}
          </div>
        </li>
        <li className="nav-item fullscreen">
          <a id="btnFullscreen" href="#" onClick={this.toggleFullScreen} className="nav-link">
            <i className="ti-fullscreen" />
          </a>
        </li>
        <li className="nav-item dropdown mr-15">
          <a
            className="nav-link nav-pill user-avatar"
            data-toggle="dropdown"
            href="#"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img src={avatarProfile || getAvatarBase64() || avatarUrl} alt="avatar" />
          </a>
          <div className="dropdown-menu dropdown-menu-right drd-profile">
            <div className="dropdown-header">
              <div className="media">
                <div className="media-body">
                  <h5 className="mt-0 mb-0">{getUserId()}</h5>
                  <span>{getEmail()}</span>
                </div>
              </div>
            </div>
            <div className="dropdown-divider" />
            <Link className="dropdown-item" to={RouteNames.manageProfile.path}>
              <i className="text-warning ti-user" />
              {t('common:label.profile')}
            </Link>
            {!getSSOLogin() && (
              <Link className="dropdown-item" to="/login" onClick={e => this.props.doLogout()}>
                <i className="text-danger ti-unlock" />
                {t('common:label.logout')}
              </Link>
            )}
            {getSSOLogin() && (
              <button
                className="dropdown-item"
                type="button"
                onClick={e => {
                  clearAllLocalStorage();
                  window.open(
                    `https://service-sso.develop.embrix.org/api/saml/logout?redirect=${window.location.href}`,
                    '_self'
                  );
                }}
              >
                <i className="text-danger ti-unlock" />
                {t('common:label.logout')}
              </button>
            )}
          </div>
        </li>
      </ul>
    );
  };

  onChangeLang = lang => {
    const { i18n } = this.props;
    this.setState({ lang });
    i18n.changeLanguage(lang);
  };

  render() {
    const { lang, base64Logo } = this.state;
    const { permissions, t, currencyConfig, i18n, avatarProfile } = this.props;
    return (
      <>
        <nav
          className={`admin-header navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row nav-main-screen ${
            i18n?.language === 'es' ? 'es-lang' : 'us-lang'
          }`}
        >
          <div className="text-left navbar-brand-wrapper">
            <Link className="navbar-brand brand-logo" to="/">
              <img src={base64Logo} alt="" className="base64-logo" />
              {/* <img src={logoPng} alt="" /> */}
            </Link>
            <Link className="navbar-brand brand-logo-mini" to="/">
              <img src={logoIconDark} alt="" className="" />
            </Link>
          </div>
          <ul className="nav navbar-nav mr-auto">
            <li className="nav-item btn-toggle-group">
              <button
                id="button-toggle"
                type="button"
                className="button-toggle-nav inline-block ml-20 pull-left no-border mb-mobile-btn-toggle"
                onClick={this.onToggleSidebar}
              >
                <i className="zmdi zmdi-menu ti-align-right ico-toggle" />
              </button>
            </li>
            {permissions.customerHubModules &&
              permissions.customerHubModules.length > 0 &&
              isHasPermissionWithModule({
                permissions: permissions.customerHubModules,
                listKeys: [
                  permissionList.customerManagement.subPermissionKey,
                  permissionList.quoteManagement.subPermissionKey,
                  permissionList.orderManagement.subPermissionKey,
                ],
              }) &&
              this.renderCustomerHub(t)}
            {permissions.billingHubModules &&
              permissions.billingHubModules.length > 0 &&
              isHasPermissionWithModule({
                permissions: permissions.billingHubModules,
                listKeys: [
                  permissionList.billing.subPermissionKey,
                  permissionList.taxation.subPermissionKey,
                  permissionList.usage.subPermissionKey,
                ],
              }) &&
              this.renderBillingHub(t)}
            {permissions.pricingHubModules &&
              permissions.pricingHubModules.length > 0 &&
              isHasPermissionWithModule({
                permissions: permissions.pricingHubModules,
                listKeys: [
                  permissionList.baseConfigurations.subPermissionKey,
                  permissionList.pricingManagement.subPermissionKey,
                  permissionList.bundleManagement.subPermissionKey,
                ],
              }) &&
              this.renderPricingHub(t)}

            {permissions.arHubModules &&
              permissions.arHubModules.length > 0 &&
              isHasPermissionWithModule({
                permissions: permissions.arHubModules,
                listKeys: [
                  permissionList.payments.subPermissionKey,
                  permissionList.collections.subPermissionKey,
                  permissionList.AROperations.subPermissionKey,
                ],
              }) &&
              this.renderArHubModules(t)}

            {permissions.revenueHubModules &&
              permissions.revenueHubModules.length > 0 &&
              isHasPermissionWithModule({
                permissions: permissions.revenueHubModules,
                listKeys: [permissionList.revenue.subPermissionKey],
              }) &&
              this.renderRevenueHubModules(t)}
            {permissions.opsHubModules &&
              permissions.opsHubModules.length > 0 &&
              (isHasPermissionWithModule({
                permissions: permissions.opsHubModules,
                listKeys: [
                  permissionList.userManagement.subPermissionKey,
                  permissionList.jobsManagement.subPermissionKey,
                  permissionList.correspondence.subPermissionKey,
                  permissionList.reports.subPermissionKey,
                  permissionList.dashboard.subPermissionKey,
                  permissionList.tenantManagement.subPermissionKey,
                ],
              }) ||
                isHasPermissionWithModule({
                  permissions: permissions.customerHubModules,
                  listKeys: [permissionList.taskManagement.subPermissionKey],
                })) &&
              this.renderOpsHubModules(t)}
          </ul>
          <ul className="nav navbar-nav ml-auto">{this.renderControlProfile(t)}</ul>
        </nav>
        <nav
          className={`admin-header navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row nav-mb-screen ${
            i18n?.language === 'es' ? 'es-lang' : 'us-lang'
          }`}
        >
          <div className="text-left navbar-brand-wrapper">
            <Link className="navbar-brand brand-logo" to="/">
              <img src={base64Logo} alt="" className="base64-logo" />
              {/* <img src={logoPng} alt="" /> */}
            </Link>
            <Link className="navbar-brand brand-logo-mini" to="/">
              <img src={logoIconDark} alt="" className="" />
            </Link>
          </div>
          <ul className="nav navbar-nav mr-auto">
            <li className="nav-item btn-toggle-group">
              <button
                id="button-toggle"
                type="button"
                className="button-toggle-nav inline-block ml-20 pull-left no-border mb-mobile-btn-toggle"
                onClick={this.onToggleSidebar}
              >
                <i className="zmdi zmdi-menu ti-align-right ico-toggle" />
              </button>
            </li>
            {permissions.customerHubModules &&
              permissions.customerHubModules.length > 0 &&
              isHasPermissionWithModule({
                permissions: permissions.customerHubModules,
                listKeys: [
                  permissionList.customerManagement.subPermissionKey,
                  permissionList.quoteManagement.subPermissionKey,
                  permissionList.orderManagement.subPermissionKey,
                ],
              }) &&
              this.renderCustomerHub(t)}
            {permissions.billingHubModules &&
              permissions.billingHubModules.length > 0 &&
              isHasPermissionWithModule({
                permissions: permissions.billingHubModules,
                listKeys: [
                  permissionList.billing.subPermissionKey,
                  permissionList.taxation.subPermissionKey,
                  permissionList.usage.subPermissionKey,
                ],
              }) &&
              this.renderBillingHub(t)}
            {permissions.pricingHubModules &&
              permissions.pricingHubModules.length > 0 &&
              isHasPermissionWithModule({
                permissions: permissions.pricingHubModules,
                listKeys: [
                  permissionList.baseConfigurations.subPermissionKey,
                  permissionList.pricingManagement.subPermissionKey,
                  permissionList.bundleManagement.subPermissionKey,
                ],
              }) &&
              this.renderPricingHub(t)}

            {permissions.arHubModules &&
              permissions.arHubModules.length > 0 &&
              isHasPermissionWithModule({
                permissions: permissions.arHubModules,
                listKeys: [
                  permissionList.payments.subPermissionKey,
                  permissionList.collections.subPermissionKey,
                  permissionList.AROperations.subPermissionKey,
                ],
              }) &&
              this.renderArHubModules(t)}

            {permissions.revenueHubModules &&
              permissions.revenueHubModules.length > 0 &&
              isHasPermissionWithModule({
                permissions: permissions.revenueHubModules,
                listKeys: [permissionList.revenue.subPermissionKey],
              }) &&
              this.renderRevenueHubModules(t)}
            {permissions.opsHubModules &&
              permissions.opsHubModules.length > 0 &&
              (isHasPermissionWithModule({
                permissions: permissions.opsHubModules,
                listKeys: [
                  permissionList.userManagement.subPermissionKey,
                  permissionList.jobsManagement.subPermissionKey,
                  permissionList.correspondence.subPermissionKey,
                  permissionList.reports.subPermissionKey,
                  permissionList.dashboard.subPermissionKey,
                  permissionList.tenantManagement.subPermissionKey,
                  // permissionList.taskManagement.subPermissionKey,
                ],
              }) ||
                isHasPermissionWithModule({
                  permissions: permissions.customerHubModules,
                  listKeys: [permissionList.taskManagement.subPermissionKey],
                })) &&
              this.renderOpsHubModules(t)}
            {this.renderControlProfile(t)}
          </ul>
        </nav>
      </>
    );
  }
}

Navbar.propTypes = {
  onToggleSidebar: PropTypes.func,
  location: PropTypes.objectOf(PropTypes.any),
  permissions: PropTypes.objectOf(PropTypes.any),
};

Navbar.defaultProps = {
  onToggleSidebar: () => {},
  location: {},
  permissions: {},
};

const mapStateToProps = createStructuredSelector({
  s3Config: makeGetS3Config() || {},
  currencyConfig: makeGetCurrencyConfig() || {},
  avatarProfile: makeGetAvatarProfile() || {},
});

const newNavbar = connect(mapStateToProps, {
  doLogout,
  getTenantDefaultConfig,
  getAwsS3Credential,
  readFileFromS3,
  getCurrencyConfigApp,
})(withRouter(Navbar));

export default withTranslation('navbar')(newNavbar);
