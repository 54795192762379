export default {
  sidebar: {
    report: 'Report',
    config: 'Config',
    styleSheet: 'Style-sheet',
    templates: 'Templates',
    template: 'Template',
    Reports: 'Reports',
    customerReports: 'Customer Reports',
    newCustomer: 'New Customer',
    accountClosure: 'Account Closure',
    subscriptionReports: 'Subscription Reports',
    subscriptionExpiry: 'Subscription Expiry',
    trialExpiry: 'Trial Expiry',
    trialSubscription: 'Trial Subscription',
    orderReports: 'Order Reports',
    order: 'Order',
    billingReports: 'Billing Reports',
    billing: 'Billing',
    productSummary: 'Product Summary',
    invoicing: 'Invoicing',
    transactionSummary: 'Transaction Summary',
    paymentReports: 'Payment Reports',
    failedPayments: 'Failed Payments',
    payment: 'Payment',
    paymentSuspense: 'Payment Suspense',
    collectionReports: 'Collection Reports',
    '030days': '0-30 days',
    '3060days': '30-60 days',
    '6090days': '60-90 days',
    over90days: '> 90 days',
    ageingSummary: 'Ageing Summary',
    AROpsReports: 'AR Ops Reports',
    AROpsDetails: 'AR Ops Details',
    openDispute: 'Open Dispute',
    openWriteOff: 'Open Write-off',
    revenueReports: 'Revenue Reports',
    revenueSummary: 'Revenue Summary',
    jobReports: 'Job Reports',
    scheduleSummary: 'Schedule Summary',
    jobFailureActivity: 'Job Failure Activity',
    reconciliationReports: 'Reconciliation Reports',
    unbilledTransactions: 'Unbilled Transactions',
    billingInvoicing: 'Billing-Invoicing',
    billingAR: 'Invoicing - AR',
    usageSummary: 'Usage Summary',
    usageDetail: 'Usage Detail',
    usageReconciliationSummary: 'Usage Reconciliation Summary',
    jobsDetailReport: 'Jobs Detail',
    usage: 'Usage Report',
    revenueRecognition: 'Revenue Recognition',
    activeSubscriptions: 'Active Subscriptions',
    taxSummary: 'Tax Summary',
    taxDetail: 'Tax Detail',
    subscriptionContractExpiry: 'Subscription Contract Expiry',
    trialToPaidSubscription: 'Trial To Paid Subscription',
    revenueForecast: 'Revenue Forecast',
    MRRForecast: 'MRR Forecast',
    voiceCustomerCallTypeSummary: 'Voice Customer Call Type',
    voiceOperatorCallTypeSummary: 'Voice Operator Call Type',
    voiceExcessTrafficReport: 'Voice Excess Traffic',
    voiceTrafficByLdDistination: 'Voice LDI Destination',
    onDemand: 'Data Customer',
    newSmsReportData: 'Sms Customer',
    interconnectVoiceReport: 'Raw ITX Voice',
    interconnectSmsReport: 'Sms Operator',
    creditNotes: 'Credit Notes',
    voiceCallTypeSummaryReport: 'Voice Call Type Summary',
    internetOperatorDataCost: 'Data Operator Cost',
    telcoSummary: 'Telco Summary',
    accountReport: 'Accounts',
    rawFiles: 'Raw Files Master',
    subscriptionBillableServices: 'Billable Services',
    accountingReconciliationReport: 'Accounting Reconciliation',
    rawCdrData: 'Raw Files Data',
    rawSMSFiles: 'Raw SMS Files',
    rawNonBroadsoft: 'Raw Non-Broadsoft',
    servicesForcecast: 'Services Forcecast',
    timeAndExpensePL: 'Time and Expense P&L',
    billableServicesForecast: 'Billable Services Forecast',
    objectFilesSummary: 'Object Files Summary',
    provisioningData: 'Provisioning Data',
    timeAndExpense: 'Time and Expense',
    billingTracker: 'Billing Tracker',
    timeAndExpenseYearlyPL: 'Time and Expense Yearly P&L',
    timeAndExpenseYearly: 'Time and Expense Yearly',
    commitmentData: 'Commitment Data',
    ageingInvoice: 'Ageing Invoice',
    ageingPayment: 'Ageing Payment',
    CDRReconciliationSummary: 'CDR Reconciliation Summary',
    extracts: 'Extracts',
    commissionsCancellations: 'Commissions Cancellations',
    KPI: 'KPI',
    rawBroadsoft: 'Raw Broadsoft',
    rawNonBroadsoftZeroDurationCDR: 'Raw Non-Broadsoft Zero Duration CDR',
    rawBroadsoftZeroDurationCDR: 'Raw Broadsoft Zero Duration CDR',
    suspendedFailedRecords: 'Suspended Failed Records',
    CDRReports: 'CDR Reports',
    extractBI: 'Extract BI',
    metaswitch: 'Metaswitch',
    metaswitchZero: 'Metaswitch Zero',
    regulatoryReports: 'Regulatory Reports',
    R00105: 'R001-05',
    R00106: 'R001-06',
    R00401: 'R004-01',
    R00405: 'R004-05',
    R00501: 'R005-01',
    R00502: 'R005-02',
    R00503: 'R005-03',
    R00504: 'R005-04',
    R00505: 'R005-05',
    R00506: 'R005-06',
    creditBureau: 'Credit Bureau',
    taxExemption: 'Tax Exemption',
    fonatelReport: 'Fonatel Report',
    PHCReport: 'PHC Report',
    contractServiceReconciliation: 'Contract Service Reconciliation',
    folioCount: 'Folio Count',
    prepaidConsumption: 'Prepaid Consumption',
    prepaidAverageConsumption: 'Prepaid Average Consumption',
    energyTopup: 'Energy Topup',
    topUp: 'Top Up',
    orderDetail: 'Order Detail',
    serviceInvoiceSummary: 'Service Invoice Summary',
    itemSummary: 'Item Summary',
    asset: 'Asset',
    collectionActions: 'Collection Actions',
  },
};
