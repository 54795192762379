import React from 'react';
import { compose } from 'redux';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import RouteNames from '../App/RouteNames';
import reducer from './reducer';
import reportReducer from '../ReportManagement/reducer';
import saga from './saga';
import reportSaga from '../ReportManagement/saga';
import Footer from '../../components/Footer';
import Sidebar from './Sidebar';
import UserPreferences from './UserPreferences';
import MyDashboard from './MyDashboard';
import NewCustomer from './NewCustomer';
import BilledCustomer from './BilledCustomer';
import PaymentSuccess from './PaymentSuccess';
import Clollection030 from './Clollection030';
import Clollection3060 from './Clollection3060';
import Clollection6090 from './Clollection6090';
import ClollectionOver90 from './ClollectionOver90';
import TotalRevenue from './TotalRevenue';
import TotalWriteOff from './TotalWriteOff';
import RevenueType from './RevenueType';
import ProductCompany from './ProductCompany';
import FailedOrder from './FailedOrder';
import SubmittedOrder from './SubmittedOrder';
import BillFailed from './BillFailed';
import InvoicesFailed from './InvoicesFailed';
import JobsFailed from './JobsFailed';
import TotalJobs from './TotalJobs';
import TotalCreditAdjustments from './TotalCreditAdjustments';
import CustomerChurn from './CustomerChurn';
import AccountType from './AccountType';
import SellingCompany from './SellingCompany';
import ActiveSubscriptions from './ActiveSubscriptions';
import TrialToPaid from './TrialToPaid';
import SubscriptionCancellations from './SubscriptionCancellations';
import BilledRevenue from './BilledRevenue';
import PaymentSuspense from './PaymentSuspense';
import PaymentFailed from './PaymentFailed';
import FailedPaymentsCustomers from './FailedPaymentsCustomers';
import TotalDisputes from './TotalDisputes';
import TotalSettlements from './TotalSettlements';
import DebitAdjustments from './DebitAdjustments';
import UsageTransactionsReceived from './UsageTransactionsReceived';
import RatedUsageTransaction from './RatedUsageTransaction';
import RecongnizedRevenue from './RecongnizedRevenue';
import RevenueForecast from './RevenueForecast';
import MonthlyRecurringRevenue from './MonthlyRecurringRevenue';
import CreditNotes from './CreditNotes';
import FolioSummary from './FolioSummary';
import FolioCount from './FolioCount';
import AssetSummary from './AssetSummary';
import { makeGetPermissionsDashboard } from '../App/selectors';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

const Dashboard = ({ permissionsDashboard }) => {
  if (!isHasPermissionWithMenu({ permissions: permissionsDashboard, listKeys: permissionList.dashboard })) return null;

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar />
        <div className="content-wrapper">
          <Switch>
            <Route exact path={`${RouteNames.userPreferences.path}`} component={UserPreferences} />
            <Route exact path={`${RouteNames.myDashboard.path}`} component={MyDashboard} />
            {/* customer */}
            <Route exact path={`${RouteNames.dashboardCustomerChurn.path}`} component={CustomerChurn} />
            <Route exact path={`${RouteNames.dashboardNewCustomer.path}`} component={NewCustomer} />
            <Route exact path={`${RouteNames.dashboardAccountType.path}`} component={AccountType} />
            <Route exact path={`${RouteNames.dashboardSellingCompany.path}`} component={SellingCompany} />
            {/* Subscription */}
            <Route exact path={`${RouteNames.acitveSubscriptions.path}`} component={ActiveSubscriptions} />
            <Route exact path={`${RouteNames.trialToPaidConversion.path}`} component={TrialToPaid} />
            <Route exact path={`${RouteNames.subscriptionCancellations.path}`} component={SubscriptionCancellations} />
            {/* Billing */}
            <Route exact path={`${RouteNames.billedRevenue.path}`} component={BilledRevenue} />
            <Route exact path={`${RouteNames.billedCustomer.path}`} component={BilledCustomer} />
            {/* Payments */}
            <Route exact path={`${RouteNames.paymentSuccess.path}`} component={PaymentSuccess} />
            <Route exact path={`${RouteNames.paymentSuspenseDashboard.path}`} component={PaymentSuspense} />
            <Route exact path={`${RouteNames.paymentFailed.path}`} component={PaymentFailed} />
            <Route exact path={`${RouteNames.accountsWithFailedPayment.path}`} component={FailedPaymentsCustomers} />
            {/* Collection */}
            <Route exact path={`${RouteNames.collection030Days.path}`} component={Clollection030} />
            <Route exact path={`${RouteNames.collection3060Days.path}`} component={Clollection3060} />
            <Route exact path={`${RouteNames.collection6090Days.path}`} component={Clollection6090} />
            <Route exact path={`${RouteNames.collectionOver90Days.path}`} component={ClollectionOver90} />
            {/* Revenue */}
            <Route exact path={`${RouteNames.totalRevenue.path}`} component={TotalRevenue} />
            <Route exact path={`${RouteNames.recognizedRevenue.path}`} component={RecongnizedRevenue} />
            <Route exact path={`${RouteNames.totalWriteOff.path}`} component={TotalWriteOff} />
            <Route exact path={`${RouteNames.revenueType.path}`} component={RevenueType} />
            <Route exact path={`${RouteNames.productCompany.path}`} component={ProductCompany} />
            <Route exact path={`${RouteNames.revenueForecastDashboard.path}`} component={RevenueForecast} />
            <Route exact path={`${RouteNames.monthlyRecurringRevenue.path}`} component={MonthlyRecurringRevenue} />
            {/* Orders */}
            <Route exact path={`${RouteNames.failedOrder.path}`} component={FailedOrder} />
            <Route exact path={`${RouteNames.submittedOrder.path}`} component={SubmittedOrder} />
            {/* Jobs */}
            <Route exact path={`${RouteNames.billsFailed.path}`} component={BillFailed} />
            <Route exact path={`${RouteNames.invoicesFailed.path}`} component={InvoicesFailed} />
            <Route exact path={`${RouteNames.jobsFailed.path}`} component={JobsFailed} />
            <Route exact path={`${RouteNames.totalJobs.path}`} component={TotalJobs} />
            {/* AR Ops */}
            <Route exact path={`${RouteNames.totalCreditAdjustments.path}`} component={TotalCreditAdjustments} />
            <Route exact path={`${RouteNames.totalDisputes.path}`} component={TotalDisputes} />
            <Route exact path={`${RouteNames.totalSettlements.path}`} component={TotalSettlements} />
            <Route exact path={`${RouteNames.debitAdjustments.path}`} component={DebitAdjustments} />

            {/* Orders */}
            <Route exact path={`${RouteNames.usageTransaction.path}`} component={UsageTransactionsReceived} />
            <Route exact path={`${RouteNames.ratedUsageTransaction.path}`} component={RatedUsageTransaction} />
            <Route exact path={`${RouteNames.creditNoteDashboard.path}`} component={CreditNotes} />
            <Route exact path={`${RouteNames.folioSummary.path}`} component={FolioSummary} />
            <Route exact path={`${RouteNames.folioCountSummary.path}`} component={FolioCount} />
            <Route exact path={`${RouteNames.assetSummarySubs.path}`} component={AssetSummary} />
            <Route
              exact
              path={`${RouteNames.assetSummaryOffer.path}`}
              component={props => <AssetSummary {...props} isOffer />}
            />
          </Switch>
          <Footer className="footer-bottom" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  permissionsDashboard: makeGetPermissionsDashboard() || {},
});

const withConnect = connect(mapStateToProps, null);

const withReducer = injectReducer({ key: 'dashboardReducer', reducer });
const withSaga = injectSaga({ key: 'dashboardSaga', saga });

const withReducerReport = injectReducer({ key: 'reportReducer', reducer: reportReducer });
const withSagaReport = injectSaga({ key: 'reportSaga', saga: reportSaga });

export default compose(withReducer, withSaga, withReducerReport, withSagaReport, withConnect)(Dashboard);
