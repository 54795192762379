export default {
  sidebar: {
    config: 'Configuración',
    companySetup: 'Configuración de la Compañía',
    currencyExchange: 'Cambio de Divisas',
    milestone: 'Hito',
    GLAccounts: 'Cuentas del Libro Mayor',
    // GLAccounts: 'GL Accounts',
    revenue: 'Ingresos',
    location: 'Ubicación',
    enterprise: 'Empresa',
    divisions: 'Divisiones',
    legalEntity: 'Entidad Legal',
    businessUnit: 'Unidades de Negocios',
    department: 'Departamento',
    costCenter: 'Centro de Costos',
    calendar: 'Calendario',
    GL: 'GL',
    GLSetup: 'Configuración Libro mayor',
    chartOfAccount: 'Diagrama de Cuentas',
    ledger: 'Libro Mayor',
    primary: 'Primario',
    secondary: 'Secundario',
    peporting: 'Informes',
    others: 'Otros',
    accounting: 'Contabilidad',
    accountingPolicies: 'Políticas Contables',
    accountingLog: 'Registro Contable',
    journals: 'Journals',
    erpExtracts: 'Extractos ERP',
    costCenterMapping: 'Mapeo de Centros de Costos',
    multiOrgSetup: 'Configuración de Múltiples Organizaciones...',
    baseConfigurations: 'Configuraciones Básicas',
    '4cs': "4 C's",
    accountingConvention: 'Convención Contable',
    revenueMilestones: 'Hitos de Ingresos',
    erpExtractBatch: 'ERP Extract Batch',
  },
};
