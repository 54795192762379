import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  getUserPreferencesByUserId,
  modifyUserPreferences,
  createUserPreferences,
  getUserDashboards,
  getDashBoardByType,
  getPieChartDashBoardByType,
  getBarchartDashboardByType,
  getFolioSummaryDashboard,
  getAssetSummaryDashboard,
} from '../../api';
import { isConnecting, isEndConnected } from '../Loader/actions';
import { queryRequest, mutationRequest } from '../../utils/request';
import * as types from './types';
import * as actions from './actions';
import i18n from '../../i18n';

export function* getUserPreferencesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getUserPreferencesByUserId());
    if (response.getUserPreferencesByUserId) {
      yield put(actions.getUserPreferencesSuccess(response.getUserPreferencesByUserId.dashboards || []));
      if (!response?.getUserPreferencesByUserId?.dashboards || !response?.getUserPreferencesByUserId?.dashboards.length)
        // toast.info(i18n.t('common:message.dashboardNoConfigMessage'));
      if (cb) cb({ success: true, data: response.getUserPreferencesByUserId.dashboards });
    } else if (cb) {
      cb({
        success: false,
        isCreate: !(response.getUserPreferencesByUserId && response.getUserPreferencesByUserId.id),
      });
    }

    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

// ------ modify user preferences
export function* modifyUserPreferencesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyUserPreferences(payload));
    if (response.modifyUserPreferences && response.modifyUserPreferences) {
      if (cb) cb({ success: true, data: response.modifyUserPreferences });
      yield put(actions.getUserPreferences());
      toast.success(i18n.t('common:apiMessage.modifyDashboardConfigSuccessfully'));
    }
    if (cb) {
      cb({ success: true });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

// ------ create user preferences
export function* createUserPreferencesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createUserPreferences(payload));
    if (response.createUserPreferences && response.createUserPreferences) {
      if (cb) cb({ success: true, data: response.createUserPreferences });
      toast.success(i18n.t('common:apiMessage.createDashboardConfigSuccessfully'));
    }
    if (cb) {
      cb({ success: true });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getUserDashboardsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getUserDashboards());
    if (response.getUserDashboards && response.getUserDashboards.dashboards) {
      yield put(actions.getUserDashboardsSuccess(response.getUserDashboards.dashboards));
      if (cb) cb({ success: true, data: response.getUserDashboards.dashboards });
    } else if (cb) cb({ success: true, data: [] });

    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getDashboardByTypeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getDashBoardByType(payload));
    if (response.getDashBoardByType && response.getDashBoardByType.dashboards) {
      if (cb) cb({ success: true, data: response.getDashBoardByType.dashboards });
    } else if (cb) cb({ success: true, data: [] });

    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getPieChartDashBoardByTypeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getPieChartDashBoardByType(payload));
    if (response.getPieChartDashBoardByType && response.getPieChartDashBoardByType.dashboards) {
      if (cb) cb({ success: true, data: response.getPieChartDashBoardByType.dashboards });
    } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getBarchartDashboardByTypeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getBarchartDashboardByType(payload));
    if (response.getBarchartDashboardByType && response.getBarchartDashboardByType.barChartDashboardData) {
      if (cb) cb({ success: true, data: response.getBarchartDashboardByType.barChartDashboardData });
    } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getFolioSummaryDashboardSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getFolioSummaryDashboard(payload));
    if (response.getFolioSummaryDashboard && response.getFolioSummaryDashboard) {
      if (cb) cb({ success: true, data: response.getFolioSummaryDashboard });
    } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export function* getAssetSummaryDashboardSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAssetSummaryDashboard(payload));
    if (response.getAssetSummaryDashboard && response.getAssetSummaryDashboard) {
      if (cb) cb({ success: true, data: response.getAssetSummaryDashboard });
    } else if (cb) cb({ success: true, data: [] });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) {
      cb({ success: false });
    }
    yield put(isEndConnected());
  }
}

export default function* collectionsSaga() {
  yield takeLatest(types.GET_USER_PREFERENCES, getUserPreferencesSaga);
  yield takeLatest(types.MODIFY_USER_PREFERENCES, modifyUserPreferencesSaga);
  yield takeLatest(types.CREATE_USER_PREFERENCES, createUserPreferencesSaga);
  yield takeLatest(types.GET_USER_DASHBOARDS, getUserDashboardsSaga);
  yield takeLatest(types.GET_DASHBOARD_BY_TYPE, getDashboardByTypeSaga);
  yield takeLatest(types.GET_PIE_CHART_DASHBOARD_BY_TYPE, getPieChartDashBoardByTypeSaga);
  yield takeLatest(types.GET_BAR_CHART_DASHBOARD_BY_TYPE, getBarchartDashboardByTypeSaga);
  yield takeLatest(types.GET_FOLIO_SUMMARY_DASHBOARD, getFolioSummaryDashboardSaga);
  yield takeLatest(types.GET_ASSET_SUMMARY_DASHBOARD, getAssetSummaryDashboardSaga);
}
