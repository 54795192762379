import gql from 'graphql-tag';
import { convertFilterRequestUrl } from 'utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter
    ? `, filter: ${convertFilterRequestUrl(filter, [])}`
    : '';
  const searchCollectionSchedules = `searchCollectionSchedules(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${searchCollectionSchedules} {
        id
        collectionProfile
        userId
        collectionSchedule {
          index
          offsetdays
          description
          action
          actionWorkingDay
        }
      }
    }
  `;
};
