import gql from 'graphql-tag';
import { convertFilterRequestUrl } from '../../utils/utils';

export default ({ page, size, filter, sort }) => {
  const sortStr = sort ? `, sort: ${sort}` : '';
  const filterStr = filter ? `, filter: ${convertFilterRequestUrl(filter, ['accountType', 'dueThreshold'])}` : '';
  const getCollectionMoreThan90DaysReport = `getCollectionMoreThan90DaysReport(page: ${page}, size: ${size}${sortStr}${filterStr})`;
  return gql`
    query {
      ${getCollectionMoreThan90DaysReport} {
        accountId
        company
        firstName
        lastName
        accountType
        dueDate
        invoiceDate
        lastActionDate
        invoiceId
        paymentMethod
        due
        daysInCollection
        paymentTerm
        groupCompany
        nextActionDate
        nextAction
        lastAction
      }
    }
  `;
};
