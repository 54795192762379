import { groupNamePermissions } from './constants';
import { getTypePermission } from '../../utils/utils';

const checkPermissionSaveUserPreferences = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.saveUserPreferences });
};

const checkPermissionGetUserPreferences = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.getUserPreferences });
};

const checkPermissionCustomerOnboarding = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.customerOnboarding });
};

const checkPermissionCustomerChunk = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.customerChunk });
};

const checkPermissionTrialToPaidConversion = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.trialToPaidConversion });
};

const checkPermissionSubscriptionCancellations = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.subscriptionCancellations });
};

const checkPermissionBilledRevenue = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.billedRevenue });
};

const checkPermissionBilledCustomer = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.billedCustomer });
};

const checkPermissionPaymentSuccess = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.paymentSuccess });
};

const checkPermissionPaymentSuspense = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.paymentSuspense });
};

const checkPermissionPaymentFailed = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.paymentFailed });
};

const checkPermissionAccountWithFailedPayment = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.accountWithFailedPayment });
};

const checkPermissionCollection030days = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.collection030days });
};

const checkPermissionCollection3060days = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.collection3060days });
};

const checkPermissionCollection6090days = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.collection6090days });
};

const checkPermissionCollectionOver90days = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.collectionOver90days });
};

const checkPermissionTotalRevenue = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.totalRevenue });
};

const checkPermissionFailedOrder = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.failedOrder });
};

const checkPermissionSubmittedOrder = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.submittedOrder });
};

const checkPermissionBillsFailed = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.billsFailed });
};

const checkPermissionInvoicesFailed = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.invoicesFailed });
};

const checkPermissionTotalCreditAdjustments = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.totalCreditAdjustments });
};

const checkPermissionTotalDisputes = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.totalDisputes });
};

const checkPermissionTotalSettlements = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.totalSettlements });
};

const checkPermissionJobFailed = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.jobFailed });
};

const checkPermissionRatedUsageTransaction = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.ratedUsageTransaction });
};

const checkPermissionUsageTransactionsReceived = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.usageTransaction });
};

const checkPermissionTotalJobs = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.totalJobs });
};

const checkPermissionRecognizedRevenue = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.recognizedRevenue });
};

const checkPermissionTotalWriteOff = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.totalWriteOff });
};

const checkPermissionActiveSubscriptions = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.activeSubscriptions });
};

const checkPermissionRevenueByRevenueType = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.revenueByRevenueType });
};

const checkPermissionRevenueByProductCompany = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.revenueByProductCompany });
};

const checkPermissionRevenueByAccountType = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.revenueByAccountType });
};

const checkPermissionRevenueBySellingCompany = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.revenueBySellingCompany });
};

const checkPermissionTotalDebitAdjustments = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.totalDebitAdjustments });
};

const checkPermissionRevenueForecast = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.revenueForecast });
};

const checkPermissionMonthlyRecurringRevenue = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.monthlyRecurringRevenue });
};

const checkPermissionUsageDataByServiceType = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.usageDataByServiceType });
};

const checkPermissionUsageDataByCallType = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.usageDataByCallType });
};

const checkPermissionFolioSummary = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.folioSummary });
};

const checkPermissionAssetSummary = ({ listPermission = [] }) => {
  return getTypePermission({ listPermission, type: groupNamePermissions.assetSummary });
};

const checkPermissionForAllPages = ({ listPermission = [], isShowMode }) => {
  const listKeyShowing = [];
  let modeJobFailed = 0;
  let modeBilledCustomer = 0;
  let modeBilledRevenue = 0;
  let modeBillsFailed = 0;
  let modeCollection030days = 0;
  let modeCollection3060days = 0;
  let modeCollection6090days = 0;
  let modeCollectionOver90days = 0;
  let modeCustomerChunk = 0;
  let modeCustomerOnboarding = 0;
  let modePaymentFailed = 0;
  let modePaymentSuccess = 0;
  let modePaymentSuspense = 0;
  let modeTotalRevenue = 0;
  let modeSubscriptionCancellations = 0;
  let modeTotalCreditAdjustments = 0;
  let modeTotalDisputes = 0;
  let modeTotalSettlements = 0;
  let modeTrialToPaidConversion = 0;
  let modeFailedOrder = 0;
  let modeSubmittedOrder = 0;
  let modeAccountWithFailedPayment = 0;
  let modeInvoicesFailed = 0;
  let modeRatedUsageTransaction = 0;
  let modeUsageTransactionsReceived = 0;
  let modeTotalJobs = 0;
  let modeRecognizedRevenue = 0;
  let modeTotalWriteOff = 0;
  let modeActiveSubscriptions = 0;
  let modeRevenueByRevenueType = 0;
  let modeRevenueByProductCompany = 0;
  let modeRevenueByAccountType = 0;
  let modeRevenueBySellingCompany = 0;
  let modeTotalDebitAdjustments = 0;
  let modeRevenueForecast = 0;
  let modeMonthlyRecurringRevenue = 0;
  let modeUsageDataByServiceType = 0;
  let modeUsageDataByCallType = 0;
  let modeFolioSummary = 0;
  let modeAssetSummary = 0;

  modeJobFailed = checkPermissionJobFailed({ listPermission });
  modeBilledCustomer = checkPermissionBilledCustomer({ listPermission });
  modeBilledRevenue = checkPermissionBilledRevenue({ listPermission });
  modeBillsFailed = checkPermissionBillsFailed({ listPermission });
  modeCollection030days = checkPermissionCollection030days({ listPermission });
  modeCollection3060days = checkPermissionCollection3060days({ listPermission });
  modeCollection6090days = checkPermissionCollection6090days({ listPermission });
  modeCollectionOver90days = checkPermissionCollectionOver90days({ listPermission });
  modeCustomerChunk = checkPermissionCustomerChunk({ listPermission });
  modeCustomerOnboarding = checkPermissionCustomerOnboarding({ listPermission });
  modePaymentFailed = checkPermissionPaymentFailed({ listPermission });
  modePaymentSuccess = checkPermissionPaymentSuccess({ listPermission });
  modePaymentSuspense = checkPermissionPaymentSuspense({ listPermission });
  modeTotalRevenue = checkPermissionTotalRevenue({ listPermission });
  modeSubscriptionCancellations = checkPermissionSubscriptionCancellations({ listPermission });
  modeTotalCreditAdjustments = checkPermissionTotalCreditAdjustments({ listPermission });
  modeTotalDisputes = checkPermissionTotalDisputes({ listPermission });
  modeTotalSettlements = checkPermissionTotalSettlements({ listPermission });
  modeTrialToPaidConversion = checkPermissionTrialToPaidConversion({ listPermission });
  modeFailedOrder = checkPermissionFailedOrder({ listPermission });
  modeSubmittedOrder = checkPermissionSubmittedOrder({ listPermission });
  modeAccountWithFailedPayment = checkPermissionAccountWithFailedPayment({ listPermission });
  modeInvoicesFailed = checkPermissionInvoicesFailed({ listPermission });
  modeRatedUsageTransaction = checkPermissionRatedUsageTransaction({ listPermission });
  modeRatedUsageTransaction = checkPermissionUsageTransactionsReceived({ listPermission });
  modeUsageTransactionsReceived = checkPermissionUsageTransactionsReceived({ listPermission });
  modeTotalJobs = checkPermissionTotalJobs({ listPermission });
  modeRecognizedRevenue = checkPermissionRecognizedRevenue({ listPermission });
  modeTotalWriteOff = checkPermissionTotalWriteOff({ listPermission });
  modeActiveSubscriptions = checkPermissionActiveSubscriptions({ listPermission });
  modeRevenueByRevenueType = checkPermissionRevenueByRevenueType({ listPermission });
  modeRevenueByProductCompany = checkPermissionRevenueByProductCompany({ listPermission });
  modeRevenueByAccountType = checkPermissionRevenueByAccountType({ listPermission });
  modeRevenueBySellingCompany = checkPermissionRevenueBySellingCompany({ listPermission });
  modeTotalDebitAdjustments = checkPermissionTotalDebitAdjustments({ listPermission });
  modeRevenueForecast = checkPermissionRevenueForecast({ listPermission });
  modeMonthlyRecurringRevenue = checkPermissionMonthlyRecurringRevenue({ listPermission });
  modeUsageDataByServiceType = checkPermissionUsageDataByServiceType({ listPermission });
  modeUsageDataByCallType = checkPermissionUsageDataByCallType({ listPermission });
  modeFolioSummary = checkPermissionFolioSummary({ listPermission });
  modeAssetSummary = checkPermissionAssetSummary({ listPermission });

  if (modeJobFailed !== 0) listKeyShowing.push('JOBS_FAILED');
  if (modeBilledCustomer !== 0) listKeyShowing.push('BILLED_CUSTOMER');
  if (modeBilledRevenue !== 0) listKeyShowing.push('BILLED_REVENUE');
  if (modeBillsFailed !== 0) listKeyShowing.push('BILLS_FAILED');
  if (modeCollection030days !== 0) listKeyShowing.push('COLLECTION_0_30_DAYS');
  if (modeCollection3060days !== 0) listKeyShowing.push('COLLECTION_30_60_DAYS');
  if (modeCollection6090days !== 0) listKeyShowing.push('COLLECTION_60_90_DAYS');
  if (modeCollectionOver90days !== 0) listKeyShowing.push('COLLECTION_OVER_90_DAYS');
  if (modeCustomerChunk !== 0) listKeyShowing.push('CUSTOMER_CHURN');
  if (modeCustomerOnboarding !== 0) listKeyShowing.push('CUSTOMER_ONBOARDING');
  if (modePaymentFailed !== 0) listKeyShowing.push('PAYMENT_FAILED');
  if (modePaymentSuccess !== 0) listKeyShowing.push('PAYMENT_SUCCESS');
  if (modePaymentSuspense !== 0) listKeyShowing.push('PAYMENT_SUSPENSE');
  if (modeTotalRevenue !== 0) listKeyShowing.push('TOTAL_REVENUE');
  if (modeSubscriptionCancellations !== 0) listKeyShowing.push('SUBSCRIPTION_CANCELLATIONS');
  if (modeTotalCreditAdjustments !== 0) listKeyShowing.push('TOTAL_CREDIT_ADJUSTMENTS');
  if (modeTotalDisputes !== 0) listKeyShowing.push('TOTAL_DISPUTES');
  if (modeTotalSettlements !== 0) listKeyShowing.push('TOTAL_SETTLEMENTS');
  if (modeTrialToPaidConversion !== 0) listKeyShowing.push('TRIAL_TO_PAID_CONVERSION');
  if (modeFailedOrder !== 0) listKeyShowing.push('FAILED_ORDER');
  if (modeSubmittedOrder !== 0) listKeyShowing.push('SUBMITTED_ORDER');
  if (modeAccountWithFailedPayment !== 0) listKeyShowing.push('ACCOUNTS_WITH_FAILED_PAYMENT');
  if (modeInvoicesFailed !== 0) listKeyShowing.push('INVOICES_FAILED');
  if (modeRatedUsageTransaction !== 0) listKeyShowing.push('RATED_USAGE_TRANSACTION');
  if (modeUsageTransactionsReceived !== 0) listKeyShowing.push('USAGE_TRANSACTION');
  if (modeTotalJobs !== 0) listKeyShowing.push('TOTAL_JOBS');
  if (modeRecognizedRevenue !== 0) listKeyShowing.push('RECOGNIZED_REVENUE');
  if (modeTotalWriteOff !== 0) listKeyShowing.push('TOTAL_WRITEOFF');
  if (modeActiveSubscriptions !== 0) listKeyShowing.push('ACTIVE_SUBSCRIPTIONS');
  if (modeRevenueByRevenueType !== 0) listKeyShowing.push('REVENUE_BY_REVENUE_TYPE');
  if (modeRevenueByProductCompany !== 0) listKeyShowing.push('REVENUE_BY_PRODUCT_COMPANY');
  if (modeRevenueByAccountType !== 0) listKeyShowing.push('CUSTOMER_COUNT_BY_ACCOUNT_TYPE');
  if (modeRevenueBySellingCompany !== 0) listKeyShowing.push('CUSTOMER_COUNT_BY_SELLING_COMPANY');
  if (modeTotalDebitAdjustments !== 0) listKeyShowing.push('TOTAL_DEBIT_ADJUSTMENTS');
  if (modeRevenueForecast !== 0) listKeyShowing.push('REVENUE_FORECAST');
  if (modeMonthlyRecurringRevenue !== 0) listKeyShowing.push('MONTHLY_RECURRING_REVENUE');
  if (modeFolioSummary !== 0) listKeyShowing.push('FOLIO_SUMMARY');
  if (modeAssetSummary !== 0) {
    listKeyShowing.push('ASSET_SUMMARY');
    listKeyShowing.push('ASSET_SUMMARY_OFFER');
  }
  // Hard for viewer
  if (modeMonthlyRecurringRevenue !== 0) listKeyShowing.push('CREDIT_NOTES');

  return isShowMode
    ? {
        modeJobFailed,
        modeBilledCustomer,
        modeBilledRevenue,
        modeBillsFailed,
        modeCollection030days,
        modeCollection3060days,
        modeCollection6090days,
        modeCollectionOver90days,
        modeCustomerChunk,
        modeCustomerOnboarding,
        modePaymentFailed,
        modePaymentSuccess,
        modePaymentSuspense,
        modeTotalRevenue,
        modeSubscriptionCancellations,
        modeTotalCreditAdjustments,
        modeTotalDisputes,
        modeTotalSettlements,
        modeTrialToPaidConversion,
        modeFailedOrder,
        modeAccountWithFailedPayment,
        modeInvoicesFailed,
        modeSubmittedOrder,
        modeRatedUsageTransaction,
        modeUsageTransactionsReceived,
        modeTotalJobs,
        modeRecognizedRevenue,
        modeTotalWriteOff,
        modeActiveSubscriptions,
        modeRevenueByRevenueType,
        modeRevenueByProductCompany,
        modeRevenueByAccountType,
        modeRevenueBySellingCompany,
        modeRevenueForecast,
        modeMonthlyRecurringRevenue,
        modeUsageDataByServiceType,
        modeUsageDataByCallType,
        modeFolioSummary,
        modeAssetSummary,
      }
    : listKeyShowing;
};

export {
  checkPermissionSaveUserPreferences,
  checkPermissionGetUserPreferences,
  checkPermissionCustomerOnboarding,
  checkPermissionCustomerChunk,
  checkPermissionTrialToPaidConversion,
  checkPermissionSubscriptionCancellations,
  checkPermissionBilledRevenue,
  checkPermissionBilledCustomer,
  checkPermissionPaymentSuccess,
  checkPermissionPaymentSuspense,
  checkPermissionPaymentFailed,
  checkPermissionAccountWithFailedPayment,
  checkPermissionCollection030days,
  checkPermissionCollection3060days,
  checkPermissionCollection6090days,
  checkPermissionCollectionOver90days,
  checkPermissionTotalRevenue,
  checkPermissionFailedOrder,
  checkPermissionBillsFailed,
  checkPermissionInvoicesFailed,
  checkPermissionTotalCreditAdjustments,
  checkPermissionTotalDisputes,
  checkPermissionTotalSettlements,
  checkPermissionJobFailed,
  checkPermissionSubmittedOrder,
  checkPermissionRatedUsageTransaction,
  checkPermissionUsageTransactionsReceived,
  checkPermissionTotalJobs,
  checkPermissionRecognizedRevenue,
  checkPermissionForAllPages,
  checkPermissionTotalWriteOff,
  checkPermissionActiveSubscriptions,
  checkPermissionRevenueByRevenueType,
  checkPermissionRevenueByProductCompany,
  checkPermissionRevenueByAccountType,
  checkPermissionRevenueBySellingCompany,
  checkPermissionTotalDebitAdjustments,
  checkPermissionRevenueForecast,
  checkPermissionMonthlyRecurringRevenue,
  checkPermissionUsageDataByServiceType,
  checkPermissionUsageDataByCallType,
  checkPermissionFolioSummary,
  checkPermissionAssetSummary,
};
