import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';
import { getUserId } from '../../localStorages';

export default data => {
  const newData = data;
  newData.userId = getUserId() || newData.userId || null;
  const dataCreate = parseToMutationRequest(data, ['index', 'type', 'revenueActivityType', 'exclude']);
  const createErpExtractBatchConfig = `createErpExtractBatchConfig(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createErpExtractBatchConfig} {
      id
    }
  }
`;
};
