import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment';
import PageTitle from '../../../../components/PageTitle';
import RouteNames from '../../../App/RouteNames';
import { uploadMultiPartFiles } from '../../../App/actions';
import TitleFrom from '../../../../components/common/TitleFrom';
import { CollectionActionsSearchForm } from '../../../../components/ReportHub';
import {
  SwitchExport,
  DataTable,
  ExcelExport,
  TablePagination,
  ButtonExport,
  EmailGroup,
} from '../../../../components/common';
import { tabelColumnExportCollectionActions, sortCollectionActions } from '../../constants';
import { getCollectionNextActionReport, getAllCollectionNextActionReport } from '../../actions';
import { makeGetListCollectionNextActionReport, makeGetListAllCollectionNextActionReport } from '../../selectors';
import {
  getPageTotalCount,
  parseToMutationRequestPostMethod,
  blobToFile,
  validateEmail,
  blobToCSVFile,
} from '../../../../utils/utils';
import convertJson2Sheet from '../../../../utils/ExcelHelper/exportExcelFile';
import convertJson2Pdf from '../../../../utils/PdfHelper/exportPdfFile';
import { PAGE_SIZE_DOWNLOAD } from '../../../../utils/constants';

let tableColumnsOutput = null;

class CollectionActions extends Component {
  buttonRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      isActivePdf: false,
      page: 0,
      size: 20,
      filter: {
        actionDate: moment()
          .startOf('month')
          .format('YYYY-MM-DD'),
      },
      sort: '',
      sorted: {},
      totalCount: null,
      isSearching: false,
      listCollectionActions: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      listCollectionActions: props.listCollectionActions,
      totalCount: getPageTotalCount({ ...state, items: props.listCollectionActions }),
    };
  }

  componentDidMount() {
    const {
      permissions: { modeGetCollectionNextActionReport },
    } = this.props;
    if (modeGetCollectionNextActionReport) {
      this.doGetCollectionActions();
    }
  }

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => {
      this.doGetCollectionActions();
    });
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doGetCollectionActions());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doGetCollectionActions());
  };

  onHandleSubmitSearch = filter => {
    this.setState({ filter: { ...filter }, page: 0 }, () => {
      this.doGetCollectionActions();
    });
  };

  doGetCollectionActions = () => {
    const { filter, page, size, sorted } = this.state;
    const { getCollectionNextActionReport } = this.props;
    const newFilter = cloneDeep(filter);

    const payload = {
      page: page + 1,
      size,
      filter: { ...newFilter },
      sort:
        !isEmpty(sorted) && sortCollectionActions[sorted.sortCol]
          ? sortCollectionActions[sorted.sortCol][sorted.sortDir]
          : null,
    };
    this.setState({ isSearching: true });
    getCollectionNextActionReport(payload, () => {
      this.setState({ isSearching: false });
    });
  };

  doGetAlllistCollectionActions = cb => {
    const { filter, sorted } = this.state;
    const { getAllCollectionNextActionReport } = this.props;
    const newFilter = cloneDeep(filter);

    const payload = {
      page: 1,
      size: PAGE_SIZE_DOWNLOAD,
      filter: { ...newFilter },
      sort: !isEmpty(sorted) ? sortCollectionActions[sorted.sortCol][sorted.sortDir] : null,
    };
    getAllCollectionNextActionReport(payload, () => {
      if (cb) cb();
    });
  };

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onExport = () => {
    this.doGetAlllistCollectionActions(() => {
      const { isActivePdf } = this.state;
      if (isActivePdf) {
        const { listAllCollectionActions, t } = this.props;
        convertJson2Pdf({
          data: listAllCollectionActions,
          t,
          title: t('reportPage:sidebar.collectionActions').toLocaleUpperCase(),
          fileName: `${t('reportPage:sidebar.collectionActions')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          columnsTable: tableColumnsOutput,
          fontSize: 7,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  sendEmail = ({ subject, emailBody }) => {
    this.doGetAlllistCollectionActions(() => {
      const { listAllCollectionActions, t, uploadMultiPartFiles } = this.props;
      const { emails, isActivePdf } = this.state;

      const query = `{"query": "mutation{sendReport(input: ${parseToMutationRequestPostMethod(
        {
          emailAddress: emails.join(', '),
          type: 'GET_COLLECTION_NEXT_ACTION_REPORT',
          subject: subject || `Embrix Report ${t('reportPage:sidebar.collectionActions')}`,
          emailBody,
        },
        ['type']
      )})}"}`;
      const formData = new FormData();

      if (!isActivePdf) {
        formData.append(
          'file',
          blobToCSVFile({
            fileName: `${t('reportPage:sidebar.collectionActions')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}.csv`,
            data: listAllCollectionActions,
            t,
            columns: tableColumnsOutput,
          })
        );
      } else {
        const pdfFile = convertJson2Pdf({
          data: listAllCollectionActions,
          t,
          title: t('reportPage:sidebar.collectionActions').toLocaleUpperCase(),
          fileName: `${t('reportPage:sidebar.collectionActions')
            .toLocaleLowerCase()
            .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`,
          isFile: true,
          columnsTable: tableColumnsOutput,
          fontSize: 7,
        });
        formData.append(
          'file',
          blobToFile(
            pdfFile,
            `${t('reportPage:sidebar.collectionActions')
              .toLocaleLowerCase()
              .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`
          )
        );
      }

      formData.append('graphql', query);
      uploadMultiPartFiles(
        formData,
        res => {
          if (res && res.success) {
            if (res.success) this.setState({ emails: [] });
          }
        },
        true
      );
    });
  };

  onChangeEmail = tags => {
    let isEmail = true;
    if (tags && tags.length) {
      tags.forEach(email => {
        if (!validateEmail(email)) {
          isEmail = false;
        }
      });
    }
    if (!isEmail) return '';

    return this.setState({ emails: tags });
  };

  render() {
    const {
      isSearching,
      isActivePdf,
      sorted,
      page,
      size,
      totalCount,
      listCollectionActions,
      emails,
      filter,
    } = this.state;
    const {
      listAllCollectionActions,
      t,
      permissions: { modeGetCollectionNextActionReport, modeDownloadReport, modeGetMailReport },
    } = this.props;
    if (!modeGetCollectionNextActionReport) return '';
    tableColumnsOutput = tabelColumnExportCollectionActions;
    return (
      <div className="col-md-12">
        <>
          <PageTitle
            linkTo={RouteNames.report.path}
            titleBtn="back"
            items={[
              { name: t('label.report'), url: RouteNames.report.path },
              { name: t('reportPage:sidebar.collectionActions') },
            ]}
          />
          <br />
        </>

        <div className="card card-statistics mb-30">
          <TitleFrom title={t('reportPage:sidebar.collectionActions')} />
          <div className="col-md-12 mb-30">
            <div className="card-body">
              <div className="pl-2">
                <CollectionActionsSearchForm onSubmit={this.onHandleSubmitSearch} defaultFilter={filter} />
              </div>
              <div>
                <div className="mt-3 col-md-12 row mb-4 pt-3 pb-3">
                  {!!modeDownloadReport && (
                    <div className="ml-auto mt-2 mb-auto mr-3">
                      <SwitchExport
                        onChange={this.onChangeSwitch}
                        wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                        title={t('label.excel')}
                        checked={isActivePdf}
                        rightTitle={t('label.pdf')}
                      />
                    </div>
                  )}
                  {!!modeDownloadReport && (
                    <>
                      <ButtonExport onExport={this.onExport} />
                      <ExcelExport
                        element={<button type="button" className="display-none" ref={this.buttonRef} />}
                        nameSheet={t('reportPage:sidebar.collectionActions').toLocaleUpperCase()}
                        multiDataSet={convertJson2Sheet({
                          data: listAllCollectionActions,
                          t,
                          title: t('reportPage:sidebar.collectionActions').toLocaleUpperCase(),
                          columnsTable: tableColumnsOutput,
                        })}
                        fileName={`${t('reportPage:sidebar.collectionActions')
                          .toLocaleLowerCase()
                          .replace(/ /g, '_')}_${moment(new Date()).format('YYYY_MM_DD')}`}
                      />
                    </>
                  )}
                  {modeGetMailReport !== 0 && (
                    <EmailGroup
                      emails={emails}
                      onChangeEmail={this.onChangeEmail}
                      sendEmail={this.sendEmail}
                      data={listCollectionActions}
                    />
                  )}
                </div>
                <DataTable
                  columns={tableColumnsOutput}
                  data={listCollectionActions && listCollectionActions.length ? listCollectionActions : []}
                  onSort={this.onSortColumn}
                  sorted={sorted}
                  isLoading={isSearching}
                  isFixedHeaderTable
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-30">
          <TablePagination
            pageNumber={page}
            pageSize={size}
            totalCount={totalCount}
            onPageChange={this.onPageChange}
            onSizeChange={this.onSizeChange}
          />
        </div>
      </div>
    );
  }
}

CollectionActions.propTypes = {
  getCollectionNextActionReport: PropTypes.func,
  getAllCollectionNextActionReport: PropTypes.func,
  listAllCollectionActions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
};

CollectionActions.defaultProps = {
  getCollectionNextActionReport: () => {},
  getAllCollectionNextActionReport: () => {},
  listAllCollectionActions: [],
};

const mapStateToProps = createStructuredSelector({
  listCollectionActions: makeGetListCollectionNextActionReport() || [],
  listAllCollectionActions: makeGetListAllCollectionNextActionReport() || [],
});
export default withTranslation('common')(
  connect(mapStateToProps, {
    getCollectionNextActionReport,
    getAllCollectionNextActionReport,
    uploadMultiPartFiles,
  })(CollectionActions)
);
