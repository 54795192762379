import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';
import packageNS from './constants/packageNS';
import { getUserId } from '../../localStorages';

export default data => {
  const newData = data;
  newData.userId = getUserId() || newData.userId || null;
  const dataCreate = parseToMutationRequest(newData, packageNS);
  const createPackage = `createPackage(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createPackage} {
      id
    }
  }
`;
};
