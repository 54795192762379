import React from 'react';

const tableColumnsAccount = [
  {
    name: 'accountId',
    label: 'common:label.ACCTNo',
  },
  {
    name: 'firstName',
    label: 'common:label.firstName',
    sortable: true,
  },
  {
    name: 'lastName',
    label: 'common:label.lastName',
    sortable: true,
  },
  {
    name: 'organization',
    label: 'common:label.organization',
  },
  {
    name: 'email',
    label: 'common:label.email',
  },
  {
    name: 'city',
    label: 'common:label.city',
  },
  {
    name: 'state',
    label: 'common:label.state',
  },
  {
    name: 'currency',
    label: 'common:label.currency',
  },
  {
    name: 'status',
    label: 'common:label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:statusCollections')().find(val => val.value === item.status) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'userId',
    label: 'common:label.userId',
  },
  {
    name: 'numberInvoicesInCollection',
    label: 'common:label.ofInvoicesInCollection',
    sortable: true,
  },
];

const tableColumnsCollectionAgent = [
  {
    name: 'userId',
    label: 'common:label.userId',
  },
  {
    name: 'firstName',
    label: 'common:label.firstName',
  },
  {
    name: 'lastName',
    label: 'common:label.lastName',
  },
  {
    name: 'countOfAccounts',
    label: 'common:label.numberOfAccountsInCollection',
  },
  {
    name: 'averageAgeInCollection',
    label: 'common:label.averageAgeInCollection',
  },
  {
    name: 'countOfAccountsResolved',
    label: 'common:label.numberOfAccountsOutOfCollectionInLast30Days',
  },
];

const tableColumnsSerachAccount = [
  {
    name: 'accountId',
    label: 'common:label.accountId',
    // render: (colName, item) => (
    //   <Link
    //     className="text-success"
    //     to={RouteNames.prettifyPath(RouteNames.collectionsInvoiceUnitsDetails.path, item.accountId)}
    //   >
    //     {item.accountId}
    //   </Link>
    // ),
  },
  {
    name: 'firstName',
    label: 'common:label.firstName',
    sortable: true,
  },
  {
    name: 'lastName',
    label: 'common:label.lastName',
    sortable: true,
  },
  {
    name: 'organization',
    label: 'common:label.organization',
  },
  {
    name: 'commercialName',
    label: 'common:label.commercialName',
  },
  { name: 'clientId', label: 'common:label.clientId' },

  {
    name: 'currency',
    label: 'common:label.currency',
  },
  {
    name: 'numberInvoicesInCollection',
    label: 'common:label.ofInvoicesInCollection',
    sortable: true,
  },
  {
    name: 'accountCategory',
    label: 'common:label.accountCategory',
  },
  // {
  //   name: 'createdDate',
  //   label: 'common:label.collectionEntryDate',
  // },
  {
    name: 'email',
    label: 'common:label.email',
  },
  {
    name: 'city',
    label: 'common:label.city',
  },
  {
    name: 'state',
    label: 'common:label.state',
  },
  // {
  //   name: 'status',
  //   label: 'common:label.status',
  //   isRenderT: true,
  //   render: (colName, item, t) => {
  //     const slt = t ? t('selections:statusCollections')().find(val => val.value === item.status) : '';
  //     return <span>{slt ? slt.label : ''}</span>;
  //   },
  // },
];

const tableColumnsInvoiceUnits = [
  {
    name: 'invoiceDate',
    label: 'common:label.invoiceDate',
    sortable: true,
  },
  {
    name: 'amount',
    label: 'common:label.total',
  },
  {
    name: 'collectionStatus',
    label: 'common:label.status',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:collectionStatus')().find(val => val.value === item.collectionStatus) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'due',
    label: 'common:label.due',
    sortable: true,
  },
  {
    name: 'dueDate',
    label: 'common:label.dueDate',
    sortable: true,
  },
];

const collectionHistoryFieldDetails = [
  {
    label: 'common:label.invoiceDate',
    name: 'invoiceDate',
  },
  {
    label: 'common:label.userId',
    name: 'userId',
  },
  {
    label: 'common:label.dueDate',
    name: 'dueDate',
  },
  {
    label: 'common:label.lastActionDate',
    name: 'lastActionDate',
  },
  {
    label: 'common:label.status',
    name: 'status',
  },
  {
    label: 'common:label.closeDate',
    name: 'closeDate',
  },
  {
    label: 'common:label.daysInCollection',
    name: 'daysInCollection',
  },
  {
    label: 'common:label.notes',
    name: 'notes',
    type: 'textarea',
    wrapperClass: 'col-md-12',
  },
];

const tableColumnsCollectionHistoryDetails = [
  {
    name: 'index',
    label: 'common:label.index',
  },
  {
    name: 'action',
    label: 'common:label.action',
    isRenderT: true,
    render: (colName, item, t) => {
      const slt = t ? t('selections:action')().find(val => val.value === item.action) : '';
      return <span>{slt ? slt.label : ''}</span>;
    },
  },
  {
    name: 'description',
    label: 'common:label.description',
  },
  {
    name: 'date',
    label: 'common:label.date',
  },
];

const groupNamePermissions = {
  createCollectionAgentConfig: 'CREATE_COLLECTION_AGENT_CONFIG',
  modifyCollectionAgentConfig: 'MODIFY_COLLECTION_AGENT_CONFIG',
  getCollectionAgentConfig: 'GET_COLLECTION_AGENT_CONFIG',
  createCollectionActionConfig: 'CREATE_COLLECTION_ACTION_CONFIG',
  modifyCollectionActionConfig: 'MODIFY_COLLECTION_ACTION_CONFIG',
  getCollectionActionConfig: 'GET_COLLECTION_ACTION_CONFIG',
  createCollectionSheduleConfig: 'CREATE_COLLECTION_SCHEDULE_CONFIG',
  modifyCollectionSheduleConfig: 'MODIFY_COLLECTION_SCHEDULE_CONFIG',
  getCollectionSheduleConfig: 'GET_COLLECTION_SCHEDULE_CONFIG',
  searchCollectionAgentActivity: 'SEARCH_COLLECTION_AGENT_ACTIVITY',
  searchAccountsInCollection: 'SEARCH_ACCOUNTS_IN_COLLECTION',
  reassignCollectionAgent: 'REASSIGN_COLLECTION_AGENT',
  searchInvoiceUnitsInCollection: 'SEARCH_INVOICE_UNITS_IN_COLLECTION',
  searchCollectionUnit: 'SEARCH_COLLECTION_UNITS',
  CREATE_COLLECTION_PROFILE: 'CREATE_COLLECTION_PROFILE',
  MODIFY_COLLECTION_PROFILE: 'MODIFY_COLLECTION_PROFILE',
  SEARCH_COLLECTION_PROFILE: 'SEARCH_COLLECTION_PROFILE',
};

export {
  tableColumnsAccount,
  tableColumnsCollectionAgent,
  tableColumnsSerachAccount,
  tableColumnsInvoiceUnits,
  collectionHistoryFieldDetails,
  tableColumnsCollectionHistoryDetails,
  groupNamePermissions,
};
