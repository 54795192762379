import listCurrency from '../../constantsApp/currency.json';
import { countryList } from '../../constantsApp/countryList';

const currencyOptions = listCurrency.currencies.map(item => ({
  value: item.code,
  label: `${item.name} (${item.code})`,
}));

const sortRevenue = {
  id: {
    asc: 'id_ASC',
    desc: 'id_DESC',
  },
  name: {
    asc: 'name_ASC',
    desc: 'name_DESC',
  },
  productFamily: {
    asc: 'productFamily_ASC',
    desc: 'productFamily_DESC',
  },
  productLine: {
    asc: 'productLine_ASC',
    desc: 'productLine_DESC',
  },
  productType: {
    asc: 'productType_ASC',
    desc: 'productType_DESC',
  },
  productSubType: {
    asc: 'productSubType_ASC',
    desc: 'productSubType_DESC',
  },
  currency: {
    asc: 'currency_ASC',
    desc: 'currency_DESC',
  },
  country: {
    asc: 'country_ASC',
    desc: 'country_DESC',
  },
  serviceType: {
    asc: 'serviceType_ASC',
    desc: 'serviceType_DESC',
  },
  status: {
    asc: 'status_ASC',
    desc: 'status_DESC',
  },
};

const listMilestoneFields = [
  {
    label: 'common:label.name',
    name: 'name',
    required: true,
  },
  {
    label: 'common:label.description',
    name: 'description',
    required: true,
  },
  {
    label: 'common:label.productFamily',
    name: 'productFamily',
    options: [],
    type: 'select',
  },
  {
    label: 'common:label.productLine',
    name: 'productLine',
    options: [],
    type: 'select',
  },
  {
    label: 'common:label.productType',
    name: 'productType',
    options: [],
    type: 'select',
  },
  {
    label: 'common:label.productSubType',
    name: 'productSubType',
    options: [],
    type: 'select',
  },
  {
    label: 'common:label.currency',
    name: 'currency',
    type: 'select',
    options: currencyOptions,
  },
  {
    label: 'common:label.country',
    name: 'country',
    type: 'select',
    options: countryList,
  },
  {
    label: 'common:label.status',
    name: 'status',
    type: 'select',
    tOptions: 'selections:milestoneStatus',
  },
  {
    label: 'common:label.unit',
    name: 'unit',
    type: 'select',
    tOptions: 'selections:milestoneUnits',
    required: true,
  },
  {
    label: 'common:label.serviceType',
    name: 'type',
    type: 'select',
    tOptions: 'selections:serviceType',
  },
  {
    label: 'common:label.offsetValue',
    name: 'offsetval',
    type: 'number',
    required: true,
  },
];

const sortCurency = {
  effectiveDate: {
    asc: 'effectiveDate_ASC',
    desc: 'effectiveDate_DESC',
  },
  type: {
    asc: 'type_ASC',
    desc: 'type_DESC',
  },
};

const groupNamePermissions = {
  CREATE_LOCATION_CONFIG: 'CREATE_LOCATION_CONFIG',
  GET_LOCATION_CONFIG: 'GET_LOCATION_CONFIG',
  MODIFY_LOCATION_CONFIG: 'MODIFY_LOCATION_CONFIG',
  CREATE_CURRENCY_EXCHANGE_CONFIG: 'CREATE_CURRENCY_EXCHANGE_CONFIG',
  GET_CURRENCY_EXCHANGE_CONFIG: 'GET_CURRENCY_EXCHANGE_CONFIG',
  MODIFY_CURRENCY_EXCHANGE_CONFIG: 'MODIFY_CURRENCY_EXCHANGE_CONFIG',
  CREATE_MILESTONE_CONFIG: 'CREATE_MILESTONE_CONFIG',
  GET_MILESTONE_CONFIG: 'GET_MILESTONE_CONFIG',
  MODIFY_MILETSTONE_CONFIG: 'MODIFY_MILETSTONE_CONFIG',
  CREATE_GL_ACCOUNTS_CONFIG: 'CREATE_GL_ACCOUNTS_CONFIG',
  GET_GL_ACCOUNTS_CONFIG: 'GET_GL_ACCOUNTS_CONFIG',
  MODIFY_GL_ACCOUNTS_CONFIG: 'MODIFY_GL_ACCOUNTS_CONFIG',
  CREATE_LEDGER_MAP: 'CREATE_LEDGER_MAP',
  GET_LEDGER_MAP: 'GET_LEDGER_MAP',
  MODIFY_LEDGER_MAP: 'MODIFY_LEDGER_MAP',
  CREATE_SUB_LEDGER_MAP: 'CREATE_SUB_LEDGER_MAP',
  GET_SUB_LEDGER_MAP: 'GET_SUB_LEDGER_MAP',
  MODIFY_SUB_LEDGER_MAP: 'MODIFY_SUB_LEDGER_MAP',
  CREATE_MANUAL_RECOGNITION: 'CREATE_MANUAL_RECOGNITION',
  VIEW_LEDGER: 'VIEW_LEDGER',
  VIEW_SUB_LEDGER: 'VIEW_SUB_LEDGER',
  VIEW_BALANCE_SHEET: 'VIEW_BALANCE_SHEET',
  VIEW_P_AND_L: 'VIEW_P_AND_L',
  CREATE_ITEM_SPLIT_CONFIG: 'CREATE_ITEM_SPLIT_CONFIG',
  MODIFY_ITEM_SPLIT_CONFIG: 'MODIFY_ITEM_SPLIT_CONFIG',
  GET_ITEM_SPLIT_CONFIG: 'GET_ITEM_SPLIT_CONFIG',
  CREATE_CALENDAR_CONFIG: 'CREATE_CALENDAR_CONFIG',
  GET_CALENDAR_CONFIG: 'GET_CALENDAR_CONFIG',
  MODIFY_CALENDAR_CONFIG: 'MODIFY_CALENDAR_CONFIG',
  CREATE_ENTERPRISE_CONFIG: 'CREATE_ENTERPRISE_CONFIG',
  GET_ENTERPRISE_CONFIG: 'GET_ENTERPRISE_CONFIG',
  MODIFY_ENTERPRISE_CONFIG: 'MODIFY_ENTERPRISE_CONFIG',
  CREATE_DIVISIONS_CONFIG: 'CREATE_DIVISIONS_CONFIG',
  GET_DIVISIONS_CONFIG: 'GET_DIVISIONS_CONFIG',
  MODIFY_DIVISIONS_CONFIG: 'MODIFY_DIVISIONS_CONFIG',
  CREATE_LEGAL_ENTITY_CONFIG: 'CREATE_LEGAL_ENTITY_CONFIG',
  GET_LEGAL_ENTITY_CONFIG: 'GET_LEGAL_ENTITY_CONFIG',
  MODIFY_LEGAL_ENTITY_CONFIG: 'MODIFY_LEGAL_ENTITY_CONFIG',
  CREATE_BUSINESS_UNIT_CONFIG: 'CREATE_BUSINESS_UNIT_CONFIG',
  GET_BUSINESS_UNIT_CONFIG: 'GET_BUSINESS_UNIT_CONFIG',
  MODIFY_BUSINESS_UNIT_CONFIG: 'MODIFY_BUSINESS_UNIT_CONFIG',
  CREATE_DEPARTMENT_CONFIG: 'CREATE_DEPARTMENT_CONFIG',
  GET_DEPARTMENT_CONFIG: 'GET_DEPARTMENT_CONFIG',
  MODIFY_DEPARTMENT_CONFIG: 'MODIFY_DEPARTMENT_CONFIG',
  CREATE_COST_CENTER_CONFIG: 'CREATE_COST_CENTER_CONFIG',
  GET_COST_CENTER_CONFIG: 'GET_COST_CENTER_CONFIG',
  MODIFY_COST_CENTER_CONFIG: 'MODIFY_COST_CENTER_CONFIG',
  CREATE_COA_CONFIG: 'CREATE_COA_CONFIG',
  GET_COA_CONFIG: 'GET_COA_CONFIG',
  MODIFY_COA_CONFIG: 'MODIFY_COA_CONFIG',
  CREATE_CONFIG_ACCOUNTING_RULES: 'CREATE_CONFIG_ACCOUNTING_RULES',
  GET_CONFIG_ACCOUNTING_RULES: 'GET_CONFIG_ACCOUNTING_RULES',
  MODIFY_CONFIG_ACCOUNTING_RULES: 'MODIFY_CONFIG_ACCOUNTING_RULES',
  SAVE_COST_CENTER_MAP_CONFIG: 'SAVE_COST_CENTER_MAP_CONFIG',
  GET_COST_CENTER_MAP_CONFIG: 'GET_COST_CENTER_MAP_CONFIG',
  GET_ERP_EXTRACT_BATCH_CONFIG: 'GET_ERP_EXTRACT_BATCH_CONFIG',
  MODIFY_ERP_EXTRACT_BATCH_CONFIG: 'MODIFY_ERP_EXTRACT_BATCH_CONFIG',
  CREATE_ERP_EXTRACT_BATCH_CONFIG: 'CREATE_ERP_EXTRACT_BATCH_CONFIG',
};

const listFieldsEnterprise = [
  {
    label: 'label.name',
    name: 'name',
    required: true,
  },
  {
    label: 'label.description',
    name: 'description',
    required: true,
  },
  {
    label: 'label.shortName',
    name: 'shortName',
    required: true,
  },
  {
    label: 'label.primaryIndustry',
    name: 'primaryIndustry',
  },
  {
    label: 'label.hqCountry',
    name: 'hqCountry',
    required: true,
  },
  {
    label: 'label.location',
    name: 'locationIndex',
    fa: 'fa fa-external-link',
  },
  {
    label: 'label.autoCreateLegalEntities',
    name: 'autoCreateLegalEntities',
    tOptions: 'selections:selectBool',
    isBoolean: true,
  },
  {
    label: 'label.autoCreateBusinessUnits',
    name: 'autoCreateBusinessUnits',
    tOptions: 'selections:selectBool',
    isBoolean: true,
  },
];

const listFieldsDivisions = [
  {
    label: 'label.name',
    name: 'name',
    required: true,
  },
  {
    label: 'label.description',
    name: 'description',
    required: true,
  },
  {
    label: 'label.shortName',
    name: 'shortName',
    required: true,
  },
  {
    label: 'label.code',
    name: 'code',
    required: true,
  },
  {
    label: 'label.enterprise',
    name: 'enterprise',
    fa: 'fa fa-external-link',
    required: true,
  },
  {
    label: 'label.location',
    name: 'locationIndex',
    fa: 'fa fa-external-link',
    required: true,
  },
  {
    label: 'label.autoCreateLegalEntities',
    name: 'autoCreateLegalEntities',
    tOptions: 'selections:selectBool',
    isBoolean: true,
  },
  {
    label: 'label.autoCreateBusinessUnits',
    name: 'autoCreateBusinessUnits',
    tOptions: 'selections:selectBool',
    isBoolean: true,
  },
  {
    label: 'label.status',
    name: 'status',
    tOptions: 'selections:status',
  },
];

const listFieldsLegalEntity = [
  {
    label: 'label.name',
    name: 'name',
    required: true,
  },
  {
    label: 'label.code',
    name: 'code',
    required: true,
  },
  {
    label: 'label.shortName',
    name: 'shortName',
    required: true,
  },
  {
    label: 'label.description',
    name: 'description',
    required: true,
  },
  {
    label: 'label.enterprise',
    name: 'enterprise',
    fa: 'fa fa-external-link',
    required: true,
  },
  {
    label: 'label.division',
    name: 'division',
    fa: 'fa fa-external-link',
    required: true,
  },
  {
    label: 'label.registrationId',
    name: 'registrationId',
    required: true,
  },
  {
    label: 'label.reportingId',
    name: 'reportingId',
    required: true,
  },
  {
    label: 'label.taxId',
    name: 'taxId',
    required: true,
  },
  {
    label: 'label.alternateId',
    name: 'altId',
    // required: true,
  },
  {
    label: 'label.location',
    name: 'locationIndex',
    fa: 'fa fa-external-link',
    required: true,
  },
  {
    label: 'label.status',
    name: 'status',
    tOptions: 'selections:status',
  },
];

const listFieldsBusinessUnits = [
  {
    label: 'label.name',
    name: 'name',
    required: true,
  },
  {
    label: 'label.code',
    name: 'code',
    required: true,
  },
  {
    label: 'label.shortName',
    name: 'shortName',
    required: true,
  },
  {
    label: 'label.description',
    name: 'description',
    required: true,
  },
  {
    label: 'label.enterprise',
    name: 'enterprise',
    fa: 'fa fa-external-link',
    required: true,
  },
  {
    label: 'label.division',
    name: 'division',
    fa: 'fa fa-external-link',
    required: true,
  },
  {
    label: 'label.legalEntity',
    name: 'legalEntity',
    fa: 'fa fa-external-link',
    required: true,
  },
  {
    label: 'label.location',
    name: 'locationIndex',
    fa: 'fa fa-external-link',
    required: true,
  },
  {
    label: 'label.status',
    name: 'status',
    tOptions: 'selections:status',
  },
];

const listFieldsGLSetup = [
  {
    label: 'label.name',
    name: 'name',
    required: true,
  },
  {
    label: 'label.code',
    name: 'code',
    required: true,
  },
  {
    label: 'label.shortName',
    name: 'shortName',
    required: true,
  },
  {
    label: 'label.description',
    name: 'description',
    required: true,
  },
  {
    label: 'label.enterprise',
    name: 'enterprise',
    fa: 'fa fa-external-link',
    required: true,
  },
  {
    label: 'label.division',
    name: 'division',
    fa: 'fa fa-external-link',
    required: true,
  },
  {
    label: 'label.legalEntity',
    name: 'legalEntity',
    fa: 'fa fa-external-link',
    required: true,
  },
  {
    label: 'label.location',
    name: 'locationIndex',
    fa: 'fa fa-external-link',
    required: true,
  },
  {
    label: 'label.status',
    name: 'status',
    tOptions: 'selections:status',
  },
];

const listFieldsCalendar = [
  {
    label: 'label.name',
    name: 'name',
    required: true,
  },
  {
    label: 'label.description',
    name: 'description',
  },
  {
    label: 'label.startDate',
    name: 'startDate',
    type: 'date',
    required: true,
  },
  {
    label: 'label.prefix',
    name: 'prefix',
    required: true,
  },
  {
    label: 'label.delimiter',
    name: 'delimiter',
    required: true,
  },
  {
    label: 'label.unit',
    name: 'unit',
    tOptions: 'selections:calendarUnits',
    required: true,
  },
  {
    label: 'label.frequency',
    name: 'frequency',
    type: 'number',
    required: true,
  },
  {
    label: 'label.adjustingPeriodCount',
    name: 'adjustingPeriodCount',
    type: 'number',
    required: true,
  },
  {
    label: 'label.status',
    name: 'status',
    tOptions: 'selections:calendarStatus',
  },
];

export {
  sortRevenue,
  listMilestoneFields,
  sortCurency,
  groupNamePermissions,
  listFieldsEnterprise,
  listFieldsDivisions,
  listFieldsLegalEntity,
  listFieldsBusinessUnits,
  listFieldsGLSetup,
  listFieldsCalendar,
};
