// import React from 'react';
import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';
import mutationSchedule from './constants/mutationSchedule';
import { getUserId } from '../../localStorages';

export default data => {
  const newData = data;
  newData.userId = getUserId() || newData.userId || null;
  const dataCreate = parseToMutationRequest(newData, mutationSchedule);
  const createCollectionSchedule = `createCollectionSchedule(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createCollectionSchedule} {
      id
      collectionProfile
      collectionSchedule {
        index
        offsetdays
        description
        action
        actionWorkingDay
      }
    }
  }
`;
};
