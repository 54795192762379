import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { DataTable, GenericInput, TitleFrom, CollapsibleTable } from '../../../components/common';
import PageTitle from '../../../components/PageTitle';
import { getErpExtractBatchConfig, createErpExtractBatchConfig, modifyErpExtractBatchConfig } from '../actions';
import RouteNames from '../../App/RouteNames';
import { validate, supportRemoveIndexWithSize, compareArrayIndexValueChange } from '../../../utils/utils';
import ModalGLAccount from '../../../components/common/ModalGLAccounts';
import { getUserId } from '../../../localStorages';

const ERPExtractBatch = ({
  getErpExtractBatchConfig,
  createErpExtractBatchConfig,
  modifyErpExtractBatchConfig,
  permissions,
}) => {
  const { modeCreateErpExtractBatchConfig, modeModifyErpExtractBatchConfig, modeGetErpExtractBatchConfig } =
    permissions || {};
  const formRef = useRef();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [wasValidated, setValidate] = useState(false);
  const [activeTabTable, setActiveTabTable] = useState(-1);
  const [defaultData, setDefaultData] = useState(null);
  const [itemSelect, setItemSelect] = useState(null);
  const [indexSelect, setIndexSelect] = useState(null);

  const { t } = useTranslation('common');

  const onChangeData = ({ name, value, index }) => {
    try {
      const newData = cloneDeep(data);
      newData[index][name] = value;
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const addNewItem = () => {
    try {
      let newData = cloneDeep(data);
      let lastIndex = 0;
      if (!newData) newData = [];
      if (newData)
        newData.forEach(val => {
          if (val.index > lastIndex) lastIndex = val.index;
        });
      const payload = {
        type: null,
        description: null,
        userId: getUserId() || null,
        isNew: true,
      };
      newData = [payload, ...newData];
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onToggleTableTab = (indexItem, newData) => {
    if (activeTabTable === indexItem) return setActiveTabTable(-1);
    if (activeTabTable !== indexItem) {
      setDefaultData(cloneDeep(newData ? newData[indexItem] : data[indexItem]));
      return setActiveTabTable(indexItem);
    }
    return setActiveTabTable(-1);
  };

  const dogetErpExtractBatchConfig = useCallback(
    item => {
      if (modeGetErpExtractBatchConfig) {
        setLoading(true);
        setValidate(false);
        getErpExtractBatchConfig({}, ({ success, data }) => {
          setLoading(false);
          if (success && data) {
            setData(data);
            if (item?.id) {
              const indexItem = data.findIndex(dt => dt.id === item.id);
              onToggleTableTab(indexItem, data);
            }
          }
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getErpExtractBatchConfig, modeGetErpExtractBatchConfig]
  );

  const onSave = ({ item }) => {
    setValidate(true);
    if (!validate(true, formRef, t)) {
      return false;
    }
    const payload = cloneDeep(item);
    const { type, description, id, userId, data } = payload;
    if (payload && payload.id) {
      return modifyErpExtractBatchConfig(
        {
          id: id || null,
          description: description || null,
          type: type || null,
          userId: userId || null,
          data: compareArrayIndexValueChange({
            defaultData: defaultData ? defaultData.data : null,
            newData: supportRemoveIndexWithSize({ data }),
          }),
        },
        ({ success, data }) => {
          if (success) {
            setDefaultData(null);
            dogetErpExtractBatchConfig(data);
          }
        }
      );
    }
    return createErpExtractBatchConfig(
      {
        description: description || null,
        type: type || null,
        userId: userId || null,
        data: data
          ? data.map(val => {
              const { index, isNew, ...rest } = val;
              return rest;
            })
          : null,
      },
      ({ success, data }) => {
        if (success) {
          setDefaultData(null);
          dogetErpExtractBatchConfig(data);
        }
      }
    );
  };

  const onSelectGLAccount = id => {
    try {
      const newData = cloneDeep(data);
      newData[activeTabTable].data[indexSelect].glAccount = id;
      setData(newData);
      setItemSelect(null);
      setIndexSelect(null);
    } catch (error) {
      console.log(error);
    }
  };

  const onCancelGlAccount = () => {
    setItemSelect(null);
    setIndexSelect(null);
  };

  const addNewSubItem = () => {
    try {
      const newData = cloneDeep(data);
      if (!newData[activeTabTable].data) newData[activeTabTable].data = [];
      const payload = {
        glAccount: null,
        revenueActivityType: null,
        exclude: false,
        glAccountPattern: null,
        isNew: true,
      };
      newData[activeTabTable].data = [payload, ...newData[activeTabTable].data];
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeDataSubTable = ({ name, value, index }) => {
    try {
      const newData = cloneDeep(data);
      newData[activeTabTable].data[index][name] = value;
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const onClearData = () => {
    const newData = cloneDeep(data);
    newData[activeTabTable] = defaultData;
    setData(newData);
  };

  const onDeleteItem = ({ index, item }) => {
    const newData = cloneDeep(data);
    try {
      if (item.isNew) {
        newData.splice(index, 1);
        setData(newData);
        setActiveTabTable(-1);
      } else {
        modifyErpExtractBatchConfig(
          {
            id: item.id,
            isDelete: true,
          },
          () => {
            dogetErpExtractBatchConfig();
            setActiveTabTable(-1);
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onRemoveItem = ({ index, item }) => {
    const newData = cloneDeep(data);
    try {
      if (item.isNew) {
        newData[activeTabTable].data.splice(index, 1);
      } else {
        newData[activeTabTable].data[index] = {
          index: newData[activeTabTable].data[index].index,
        };
      }
      setData(newData);
    } catch (error) {
      console.log(error);
    }
  };

  const saveConfigColumn =
    modeModifyErpExtractBatchConfig === 2
      ? [
          {
            name: 'saveConfig',
            label: 'label.saveConfig',
            render: (colName, item, idx) => (
              <button type="button" className="button x-small" onClick={() => onSave({ item, index: idx })}>
                {t('label.apply')}
              </button>
            ),
          },
          {
            name: 'remove',
            label: 'label.remove',
            render: (colName, item, idx) => {
              return (
                <div className="form-group col-md-12">
                  <button type="button" className="btn-phone" onClick={() => onDeleteItem({ item, index: idx })}>
                    <i className="fa fa-trash" />
                  </button>
                </div>
              );
            },
          },
        ]
      : [];

  const tableERPExtractBatch = [
    {
      name: 'type',
      label: 'label.extractType',
      required: true,
      style: { minWidth: '200px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.type}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeData({ name, value, index: idx })}
          name="type"
          type="select"
          tOptions="selections:ebsExtractType"
          menuPortalTarget
          required
          isSupportDefaultValue
        />
      ),
    },
    {
      name: 'description',
      label: 'label.description',
      style: { minWidth: '200px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.description}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeData({ name, value, index: idx })}
          name="description"
          type="textarea"
        />
      ),
    },
    {
      name: 'userId',
      label: 'label.userId',
      style: { minWidth: '200px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.userId}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeData({ name, value, index: idx })}
          name="userId"
          readOnly
          disabled
        />
      ),
    },
    {
      name: 'additionalData',
      label: 'label.additionalData',
      render: (colName, item, idx, indexParent, activeTab) => (
        <button type="button" className="btn-expand-table mr-3" onClick={() => onToggleTableTab(idx)}>
          <i className={`fa ${activeTab ? 'fa-minus' : 'fa-plus'}`} />
        </button>
      ),
    },
    ...saveConfigColumn,
  ];

  const saveConfigSubColumn =
    modeModifyErpExtractBatchConfig === 2
      ? [
          {
            name: 'remove',
            label: 'label.remove',
            render: (colName, item, idx) => {
              return (
                <div className="form-group col-md-12">
                  <button type="button" className="btn-phone" onClick={() => onRemoveItem({ item, index: idx })}>
                    <i className="fa fa-trash" />
                  </button>
                </div>
              );
            },
          },
        ]
      : [];

  const subTableERPExtractBatch = [
    {
      name: 'exclude',
      label: 'label.exclude',
      render: (colName, item, idx) => (
        <GenericInput
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeDataSubTable({ name, value, index: idx })}
          name="exclude"
          value={item.exclude}
          type="checkbox"
        />
      ),
    },
    {
      name: 'glAccountPattern',
      label: 'label.glAccountPattern',
      tooltipLabel: 'message.glAccountPatternInfo',
      render: (colName, item, idx) => (
        <GenericInput
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeDataSubTable({ name, value, index: idx })}
          name="glAccountPattern"
          value={item.glAccountPattern}
          disabled={!!item.glAccount}
        />
      ),
    },
    {
      name: 'glAccount',
      label: 'label.glAccount',
      style: { minWidth: '200px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.glAccount}
          wrapperClass="col-md-12"
          onChange={() => {}}
          name="glAccount"
          fa="fa-external-link"
          onClick={() => {
            setItemSelect(item);
            setIndexSelect(idx);
          }}
          disabled={!!item.glAccountPattern}
        />
      ),
    },
    {
      name: 'revenueActivityType',
      label: 'label.revenueActivityType',
      style: { minWidth: '200px' },
      render: (colName, item, idx) => (
        <GenericInput
          value={item.revenueActivityType}
          wrapperClass="col-md-12"
          onChange={({ name, value }) => onChangeDataSubTable({ name, value, index: idx })}
          name="revenueActivityType"
          type="select"
          tOptions="selections:revenueActivityType"
          menuPortalTarget
        />
      ),
    },
    ...saveConfigSubColumn,
  ];

  useEffect(() => {
    return dogetErpExtractBatchConfig();
  }, [dogetErpExtractBatchConfig]);

  if (!modeGetErpExtractBatchConfig) return '';

  return (
    <div className="col-md-12">
      <PageTitle
        linkTo={RouteNames.invoiceUsage.path}
        titleBtn={t('label.back')}
        items={[{ name: t('revenuePage:sidebar.erpExtractBatch') }]}
        isNoDefaultButton
      />
      <br />
      <div className="card card-statistics h-100 mr-3">
        <TitleFrom title={t('revenuePage:sidebar.erpExtractBatch')} />
        <br />
        <div>
          <div className="form-group col-md-12 buttons-attibute">
            {modeCreateErpExtractBatchConfig === 2 && (
              <button type="button" onClick={addNewItem} className="button x-small">
                +
                {t('label.add')}
              </button>
            )}
            <button
              type="button"
              onClick={dogetErpExtractBatchConfig}
              className="button mr-2 button-border black x-small float-right"
            >
              {t('label.cancel')}
            </button>
          </div>
          <form ref={formRef} noValidate className={`${wasValidated ? 'was-validated' : ''}`}>
            <div className="group-collapsible">
              <CollapsibleTable
                columns={tableERPExtractBatch}
                data={data || []}
                isSupportRemoveIndex
                indexViewer={activeTabTable}
                isLoading={isLoading}
              >
                <div className="col-md-12 pt-2 pb-3 mb-4">
                  {modeCreateErpExtractBatchConfig === 2 && (
                    <button type="button" onClick={addNewSubItem} className="button x-small mb-3 ml-2">
                      +
                      {t('label.add')}
                    </button>
                  )}
                  <DataTable
                    columns={subTableERPExtractBatch}
                    data={
                      activeTabTable !== -1 && data[activeTabTable] && data[activeTabTable].data
                        ? data[activeTabTable].data
                        : []
                    }
                    isSupportRemoveIndex
                  />
                  <div className="col-md-12 pt-2 pb-3">
                    {modeModifyErpExtractBatchConfig === 2 && (
                      <button
                        type="button"
                        onClick={() => onSave({ item: data[activeTabTable] })}
                        className="button button-border x-small float-right mr-4"
                      >
                        {t('label.saveConfig')}
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={onClearData}
                      className="button mr-2 mb-2 button-border black x-small float-right"
                    >
                      {t('label.clear')}
                    </button>
                  </div>
                </div>
              </CollapsibleTable>
            </div>
          </form>
        </div>
      </div>
      <ModalGLAccount
        isOpen={!!itemSelect}
        selectedParentId={itemSelect ? itemSelect.glAccount || null : null}
        onSelect={onSelectGLAccount}
        onCancel={onCancelGlAccount}
      />
    </div>
  );
};

ERPExtractBatch.propTypes = {
  getErpExtractBatchConfig: PropTypes.func.isRequired,
  createErpExtractBatchConfig: PropTypes.func.isRequired,
  modifyErpExtractBatchConfig: PropTypes.func.isRequired,
};

ERPExtractBatch.defaultProps = {};

export default connect(null, {
  getErpExtractBatchConfig,
  createErpExtractBatchConfig,
  modifyErpExtractBatchConfig,
})(ERPExtractBatch);
