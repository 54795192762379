import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { groupBy, map, isEmpty, cloneDeep, uniqBy } from 'lodash';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AccountTable from '../AccountTable';
import ChooseKindService from './ChooseKindService';
import ServiceForm from './ServiceForm';
import OrderForm from './OrderForm';
import OrderData from './OrderData';
import constants, { orderListRadioButtons, listFlexAttributes } from '../constants';
import { orderEnum } from '../../../constantsApp';
import {
  getBundleByBundleId,
  getCcpPropertiesConfig,
  getAccountDetail,
  getDiscountOfferById,
  searchServiceProvisioningData,
} from '../../App/actions';
import { createOrder, buildOrderProvisioningAttributes, getOrderDetail } from '../actions';
import { makeGetPermissionsOrderManagement, makeGetCcpPropertiesConfig } from '../../App/selectors';
import { makeGetOrderCustomAttributes } from '../../CustomerPage/selectors';
import {
  checkPermissionCreateOrder,
  checkPermissionNewOrderType,
  checkPermissionPackagePurchase,
  checkPermissionBundlePurchase,
  checkPermissionAlacartePurchase,
} from '../CheckPermission';
import RouteNames from '../../App/RouteNames';
import {
  StepProgressBar,
  FormCollapse,
  Breadcrumb,
  GenericInput,
  FormWithTableItem,
  ModalWithItem,
  DataTable,
} from '../../../components/common';
import { injectValueFromConfig, getLastIndex, validate } from '../../../utils/utils';
import { getUserId } from '../../../localStorages';
import ModalMultipleSelectDisscountOffer from '../../../components/common/ModalMultipleSelectDisscountOffer';
import ModalSelectCreditProfile from '../../../components/common/ModalSelectCreditProfile';
import OrderProvisioningAttributesForm from '../../../components/Orders/OrderProvisioningAttributesForm';
import SubscriptionsForm from '../../../components/Orders/SubscriptionsForm';
import {
  convertbuildOrderProvisioningAttributesNewOrder,
  convertbuildOrderProvisioningAttributesPayload,
  convertMultiSubscriptionData,
  convertbuildOrderProvisioningAttributesWithDefaultValue,
  convertLineFromDiscountOffers,
  findIndexService,
} from '../utilities';
import { AttributesForm } from '../../../components/Orders';
import { getCustomAttributesByObjectType } from '../../CustomerPage/actions';
import ModalSelectOrder from '../../../components/common/ModalSelectOrder';

const labelBillingProfile = {
  DAYS: 'DAYS',
  MONTHLY: 'MONTHS',
  QUARTERLY: 'QUARTERS',
};

let lastSubStep = 0;

const listMainSteps = [
  {
    step: 1,
    label: 'label.selectAccount',
  },
  {
    step: 2,
    label: 'label.orderData',
  },
  {
    step: 3,
    label: 'label.addSubscription',
  },
  {
    step: 4,
    label: 'label.subscriptionData',
  },
  {
    step: 5,
    label: 'label.createCancel',
  },
];

const listSteps = [
  {
    step: 1,
    label: 'label.subscriptionTerms',
  },
  {
    step: 2,
    label: 'label.purchaseOptions',
  },
  {
    step: 3,
    label: 'label.provisioningData',
  },
  {
    step: 4,
    label: 'label.overrideOptions',
  },
];

const subscriptionDefault = {
  subscriptionName: null,
  mode: constants.ModalMode.none,
  stage: 0,
  buildOrderProvisioningAttributesList: null,
};

class NewOrder extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      stage: 0,
      selectItem: {},
      orderDetail: this.initOrderDetail(props),
      groupServices: [],
      mode: constants.ModalMode.none,
      activeTab: {},
      subscriptionsData: [{ ...subscriptionDefault }],
      isMultiSubscriptionEnabled: false,
      wasValidatedSubscription: false,
      activeTabSubscriptionTab: { name: 0, isActive: true },
      provisioningEnabled: false,
      isSingleSubscription: true,
      oldStageSelected: 0,
      isMandatorySubscriptionCategory: false,
      indexCreditProfileSelect: -1,
      itemCreditProfileSelect: null,
      customAttributesByObjectType: [],
      isOpenModalOrder: false,
      isOpenMdalDetails: false,
      detailsData: [],
      itemProvisioningSelect: null,
      indexSelect: null,
      mappingList: null,
    };
    this.services = [];
  }

  componentDidMount() {
    const {
      location: { state },
    } = this.props;
    if (!isEmpty(state))
      this.onSelectNext({
        id: state.accountId,
        accountCategory: state.accountCategory || null,
        billingProfiles: state.billingProfiles || null,
      });
    this.doGetCcpPropertiesConfig();
  }

  doGetCustomAttributesByObjectType = () => {
    const { getCustomAttributesByObjectType, orderCustomAttributes } = this.props;
    if (orderCustomAttributes && orderCustomAttributes.attributes) {
      this.setState({
        customAttributesByObjectType: (orderCustomAttributes && orderCustomAttributes.attributes) || [],
      });
    } else {
      getCustomAttributesByObjectType(({ data }) => {
        if (data) {
          this.setState({
            customAttributesByObjectType: (data && data.attributes) || [],
          });
        }
      }, 'ORDER');
    }
  };

  doInitPropertiesConfig = ccpPropertyList => {
    const { orderDetail } = this.state;
    let { subscriptionsData } = this.state;
    const newOrderDetail = cloneDeep(orderDetail);
    const multiSubscriptionEnabled = ccpPropertyList.find(val => val.property === 'multiSubscriptionEnabled');
    const provisioningEnabled = ccpPropertyList.find(val => val.property === 'provisioningEnabled');
    const partialProvisioningEnabled = ccpPropertyList.find(val => val.property === 'partialProvisioningEnabled');

    const newInfo = injectValueFromConfig({
      dataConfig: ccpPropertyList,
      listFields: ['initialTermUnit', 'initialTerm', 'renewalTermUnit', 'renewalTerm'],
      defaultData: orderDetail,
    });
    newOrderDetail.initialTermUnit = newInfo.initialTermUnit;
    newOrderDetail.initialTerm = newInfo.initialTerm;
    newOrderDetail.renewalTermUnit = newInfo.renewalTermUnit;
    newOrderDetail.renewalTerm = newInfo.renewalTerm;
    newOrderDetail.isPartialFulfillmentAllowed =
      partialProvisioningEnabled && partialProvisioningEnabled?.value === 'true';
    subscriptionsData = subscriptionsData.map(val => {
      return {
        ...val,
        orderDetail: newOrderDetail,
        subscriptionName: provisioningEnabled && provisioningEnabled?.value === 'true' ? null : 'Default',
      };
    });
    this.setState({
      orderDetail: newOrderDetail,
      subscriptionsData: [...subscriptionsData],
      isMultiSubscriptionEnabled: multiSubscriptionEnabled && multiSubscriptionEnabled?.value === 'true',
      provisioningEnabled: provisioningEnabled && provisioningEnabled?.value === 'true',
    });
  };

  doGetCcpPropertiesConfig = () => {
    const { getCcpPropertiesConfig, ccpPropertyList } = this.props;
    if (!ccpPropertyList || !ccpPropertyList.length) {
      getCcpPropertiesConfig('', ({ success, data }) => {
        if (success) {
          this.doInitPropertiesConfig(data.ccpPropertyList);
        }
      });
    } else {
      this.doInitPropertiesConfig(ccpPropertyList);
    }
  };

  initOrderDetail = () => {
    return {
      type: orderEnum.type.trial,
      status: orderEnum.status.create,
      reason: null,
      accountId: null,
      userId: getUserId() || 'TestUserId',
      isPartialFulfillmentAllowed: false,
      effectiveDate: null,
      submittedDate: null,
      initialTerm: null,
      initialTermUnit: null,
      renewalTerm: null,
      renewalTermUnit: null,
      services: [],
    };
  };

  onClickRow = () => {};

  onSelectNext = rowItem => {
    const { orderDetail, subscriptionsData } = this.state;
    orderDetail.accountId = rowItem.id;
    const subscriptionCategory =
      rowItem.accountCategory && rowItem.accountCategory !== 'HYBRID' ? rowItem.accountCategory || null : null;
    orderDetail.subscriptionCategory = subscriptionCategory;
    const newSubscriptionsData = subscriptionsData.map(val => {
      return { ...val, subscriptionCategory: subscriptionCategory || 'POSTPAID' };
    });
    this.setState(
      {
        selectItem: rowItem,
        orderDetail,
        subscriptionsData: [...newSubscriptionsData],
        isMandatorySubscriptionCategory: rowItem?.accountCategory && rowItem.accountCategory === 'HYBRID',
      },
      () => {
        this.onPressNextButton();
        this.doGetCustomAttributesByObjectType();
      }
    );
  };

  onPressBackButton = () => {
    const { location, history } = this.props;
    const accountId = location && location.state && location.state.accountId ? location.state.accountId : null;

    if (accountId) {
      const redirectUrl = `/customers/${accountId}/services`;
      history.push(redirectUrl);
      return;
    }
    history.push(RouteNames.searchAndList.path);
  };

  onPressCancelButton = () => {
    const { location, history } = this.props;
    const accountId = location && location.state && location.state.accountId ? location.state.accountId : null;

    if (accountId) {
      const redirectUrl = `/customers/${accountId}/services`;
      history.push(redirectUrl);
      return;
    }
    this.setState({
      stage: 0,
      subscriptionsData: [{ ...subscriptionDefault }],
      oldStageSelected: 0,
    });
  };

  onPressCancelSubscriptionButton = index => {
    const { subscriptionsData } = this.state;
    subscriptionsData[index].stage = 0;
    this.setState({ subscriptionsData: [...subscriptionsData], stage: 3, oldStageSelected: 3 });
  };

  doInitDefaultOrderSubscriptionDetails = billingItem => {
    const { subscriptionsData } = this.state;
    const newSubscriptionsData = subscriptionsData.map(val => {
      const newVal = val;
      if (newVal.subscriptionCategory === 'PREPAID_AS_POSTPAID') {
        newVal.orderDetail.initialTerm = billingItem?.duration || null;
        newVal.orderDetail.renewalTerm = billingItem?.duration || null;
        newVal.orderDetail.initialTermUnit = billingItem?.billingFrequency
          ? labelBillingProfile[billingItem.billingFrequency] || null
          : null;
        newVal.orderDetail.renewalTermUnit = billingItem?.billingFrequency
          ? labelBillingProfile[billingItem.billingFrequency] || null
          : null;
      }

      return newVal;
    });
    this.setState({ subscriptionsData: newSubscriptionsData });
  };

  onPressNextButton = () => {
    const { stage, selectItem, subscriptionsData, orderDetail } = this.state;
    const {
      location,
      t,
      getAccountDetail,
      location: { state },
    } = this.props;
    const accountId = location && location.state && location.state.accountId ? location.state.accountId : null;
    if (selectItem.id || accountId) {
      if (stage === 2) {
        const newSub = uniqBy(subscriptionsData, 'subscriptionName');
        if (newSub.length !== subscriptionsData.length) {
          return toast.error(t('common:message.subscriptionNameNotDuplicate'));
        }
        const isPrepaidAsPostpaid = subscriptionsData.find(val => val.subscriptionCategory === 'PREPAID_AS_POSTPAID');
        if (isPrepaidAsPostpaid) {
          if (!state || !state.billingProfiles) {
            getAccountDetail(orderDetail.accountId, ({ success, data }) => {
              if (success && data.billingProfiles) {
                const billingItem = data?.billingProfiles[0] || {};
                this.doInitDefaultOrderSubscriptionDetails(billingItem);
              }
            });
          } else {
            const billingItem = state.billingProfiles || {};
            this.doInitDefaultOrderSubscriptionDetails(billingItem);
          }
        }
      }
      this.setState({ wasValidatedSubscription: true });
      if (!validate(true, this.formRef, t)) {
        return false;
      }
      return this.setState({
        stage: stage + 1,
        wasValidatedSubscription: false,
        oldStageSelected: stage + 1,
      });
    }
  };

  onPressCreateButton = () => {
    const { orderDetail, subscriptionsData, customAttributesByObjectType } = this.state;

    const { t } = this.props;

    const temp = [];
    if (customAttributesByObjectType && customAttributesByObjectType.length)
      customAttributesByObjectType.map(item => {
        if (item.value && item.isModify) {
          temp.push({ name: item.name, value: item.value });
        }
      });
    const newOrderDetail = {
      type: orderDetail.type || null,
      accountId: orderDetail.accountId || null,
      status: orderDetail.status || null,
      userId: orderDetail.userId || null,
      isPartialFulfillmentAllowed: orderDetail.isPartialFulfillmentAllowed || null,
      billingOnlyFlag: orderDetail.billingOnlyFlag || null,
      provisioningOnlyFlag: orderDetail.provisioningOnlyFlag || null,
      billOrderCharges: orderDetail.billOrderCharges || null,
      correlationId: orderDetail.correlationId || null,
      orderCustomAttributes: temp && temp.length ? temp : null,
    };
    let servicesList = [];
    let buildOrderProvisioningAttributesList = [];
    let indexService = 0;
    subscriptionsData.forEach(sb => {
      if (sb?.services && sb?.services.length) {
        const newGroupServicesItem = groupBy(sb?.services, item => {
          return item.serviceType;
        });
        map(newGroupServicesItem, serviceGroup => {
          servicesList = [
            ...servicesList,
            ...serviceGroup.map(val => {
              const { discountOfferId, ...rest } = val;
              indexService += 1;
              return { ...rest, subscriptionName: sb.subscriptionName || null, index: indexService };
            }),
          ];
        });
      }
      if (sb?.buildOrderProvisioningAttributesList && sb?.buildOrderProvisioningAttributesList.length) {
        buildOrderProvisioningAttributesList = [
          ...buildOrderProvisioningAttributesList,
          ...sb.buildOrderProvisioningAttributesList,
        ];
      }
    });
    const groupServices = groupBy(servicesList, item => {
      return item.serviceType;
    });
    newOrderDetail.services = [];
    newOrderDetail.provisioningAttributes = convertbuildOrderProvisioningAttributesNewOrder(
      buildOrderProvisioningAttributesList
    );
    newOrderDetail.subscriptions = convertMultiSubscriptionData(subscriptionsData);
    map(groupServices, serviceGroup => {
      return serviceGroup.map(service => {
        const {
          bundleName,
          packageName,
          isPackage,
          isBundle,
          isPriceOffer,
          isDiscount,
          correlationId,
          ...newService
        } = cloneDeep(service);
        if (newService && newService.lines && newService.lines.length) {
          newService.lines.forEach((val, idx) => {
            newService.lines.index = idx + 1;
            delete newService.lines[idx].discountOfferName;
            delete newService.lines[idx].priceOfferName;
            delete newService.lines[idx].id;
            if (newService.lines[idx] && newService.lines[idx].discountOfferId) {
              newService.lines[idx].discAmountOverride = newService.lines[idx].priceOverride;
              newService.lines[idx].discPercentOverride = newService.lines[idx].discountPercent;
              newService.lines[idx].startDate = newService.lines[idx].startDate || null;
              newService.lines[idx].endDate = newService.lines[idx].endDate || null;
              delete newService.lines[idx].priceOverride;
              delete newService.lines[idx].discountPercent;
            }
          });
        }
        newOrderDetail.services.push({ ...newService });
        return true;
      });
    });
    this.setState({ wasValidatedSubscription: true });
    if (!validate(true, this.formRef, t)) {
      return false;
    }
    const { docreateOrder } = this.props;
    docreateOrder(newOrderDetail, ({ success, id }) => {
      const { location, history } = this.props;
      if (success) {
        const accountId = location && location.state && location.state.accountId ? location.state.accountId : null;
        if (accountId) {
          history.push(`/customers/${accountId}/services`);
          return;
        }
        history.push(`/orders/${id}/detail`);
      }
    });
  };

  onPressNextSubscriptionButton = ({ index }) => {
    const { subscriptionsData } = this.state;
    const { t } = this.props;
    this.setState({ wasValidatedSubscription: true });
    if (!validate(true, this.formRef, t)) {
      return false;
    }

    if (subscriptionsData[index].stage === 1) {
      if (!subscriptionsData[index]?.services || subscriptionsData[index]?.services.length === 0) {
        toast(t('message.msgChoosePackageBundlePO'), { type: toast.TYPE.ERROR });
        return false;
      }
      this.doInitPackageItem(index);
    }

    subscriptionsData[index].stage += 1;
    if (subscriptionsData[index].stage === 2) {
      this.doBuildOrderProvisioningAttributes(index);
    }
    return this.setState({
      subscriptionsData: [...subscriptionsData],
      wasValidatedSubscription: false,
      // isOpenModalDisscountOffers: subscriptionsData[index].stage === 2,
    });
  };

  initServiceWithSelectPackage = ({
    index,
    bundleId,
    packageId,
    serviceType,
    packageName,
    bundleName,
    discountOfferId,
  }) => {
    return {
      index,
      serviceType,
      action: 'ADD',
      status: orderEnum.status.create,
      bundleId,
      packageId,
      packageName,
      bundleName,
      lines: [],
      discountOfferId,
      isPackage: true,
    };
  };

  initServiceWithSelectBundle = (bundle, index) => {
    return {
      index: index + 1,
      serviceType: bundle && bundle.components && bundle.components.length > 0 ? bundle.components[0].serviceType : '',
      action: 'ADD',
      reason: null,
      status: 'CREATED',
      bundleId: bundle.id,
      bundleName: bundle.name,
      packageId: null,
      discountOfferId: bundle.discountOfferId || null,
      discountOfferName: bundle.discountOfferName || null,
      isBundle: true,
      lines:
        bundle && bundle.components
          ? bundle.components.map(component => {
              return this.initLineFromBundle(bundle, component);
            })
          : [],
    };
  };

  initLineFromBundle = (bundle, component) => {
    return {
      index: component.index,
      action: 'ADD',
      status: 'CREATED',
      reason: null,
      quantity: null,
      priceOverride: null,
      priceOffset: null,
      discountPercent: null,
      startDate: bundle.startDate,
      endDate: bundle.endDate,
      relativeStart: null,
      relativeEnd: null,
      relativeStartUnit: null,
      relativeEndUnit: null,
      priceOfferId: component.priceOfferId,
      discountOfferId: component.discountOfferId || null,
      discountOfferName: component.discountOfferName,
      priceOfferName: component.priceOfferName,
    };
  };

  initServiceFromPriceOffers = (priceOffers, serviceIndex) => {
    return {
      index: serviceIndex,
      serviceType: priceOffers[0].serviceType || null,
      provisioningId: null,
      action: 'ADD',
      reason: null,
      status: 'CREATED',
      bundleId: null,
      packageId: null,
      isPriceOffer: true,
      lines: priceOffers.map((item, i) => {
        return this.initLineFromPriceOffer(item, i);
      }),
    };
  };

  initLineFromPriceOffer = (priceOffer, index) => {
    return {
      index,
      action: 'ADD',
      status: 'CREATED',
      reason: null,
      quantity: null,
      priceOverride: null,
      priceOffset: null,
      discountPercent: null,
      startDate: priceOffer.startDate,
      endDate: priceOffer.endDate,
      relativeStart: null,
      relativeEnd: null,
      relativeStartUnit: null,
      relativeEndUnit: null,
      priceOfferId: priceOffer.id,
      priceOfferName: priceOffer.name,
      discountOfferId: priceOffer.discountOfferId || null,
    };
  };

  addLine = (groupIndex, serviceIndex, bundle) => {
    const { orderDetail, groupServices } = this.state;
    try {
      groupServices[groupIndex][serviceIndex].lines = bundle
        ? bundle.components.map(component => {
            return this.initLineFromBundle(bundle, component);
          })
        : [];
      orderDetail.services = [];
      map(groupServices, serviceGroup => {
        return serviceGroup.map(service => {
          orderDetail.services.push(service);
          return service;
        });
      });
      this.setState({
        orderDetail,
        groupServices,
      });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeMode = (mode, index) => {
    const { orderDetail, subscriptionsData } = this.state;
    orderDetail.services = [];
    subscriptionsData[index].mode = mode;
    this.setState({
      subscriptionsData: [...subscriptionsData],
      orderDetail,
    });
  };

  onHandleChangeOrderForm = e => {
    const { orderDetail } = this.state;
    const newData = cloneDeep(orderDetail);
    if (e.fieldName === 'billingOnlyFlag' && e.value) {
      newData.provisioningOnlyFlag = null;
    }
    if (e.fieldName === 'provisioningOnlyFlag' && e.value) {
      newData.billingOnlyFlag = null;
    }

    newData[e.fieldName] = e.value;

    this.setState({
      orderDetail: newData,
    });
  };

  onHandleChangeSubscriptionForm = ({ name, value, index }) => {
    const { subscriptionsData } = this.state;
    const newData = cloneDeep(subscriptionsData);
    newData[index].orderDetail[name] = value;
    this.setState({
      subscriptionsData: newData,
    });
  };

  onChangeSwitch = ({ index, name }) => {
    const { subscriptionsData } = this.state;
    subscriptionsData[index][name] = !subscriptionsData[index][name];
    this.setState({ subscriptionsData: [...subscriptionsData] });
  };

  onRemoveItem = (evt, index) => {
    const { subscriptionsData } = this.state;
    const { services } = subscriptionsData[index];
    const indexItemRemove = services.findIndex(
      item => item.serviceType === evt.serviceType && item.index === evt.index
    );
    services.splice(indexItemRemove, 1);
    subscriptionsData[index].services = services;
    subscriptionsData[index].groupServices = groupBy(services, item => {
      return item.serviceType;
    });
    this.setState({ subscriptionsData: [...subscriptionsData] });
  };

  onRemoveLine = ({ itemService, itemLine, index }) => {
    try {
      const { subscriptionsData } = this.state;
      const { services } = subscriptionsData[index];
      const indexService = services.findIndex(item => item.index === itemService.index);
      const indexLine = services[indexService].lines.findIndex(item => item.index === itemLine.index);
      if (indexService > -1 && indexLine > -1) {
        services[indexService].lines.splice(indexLine, 1);
      }
      subscriptionsData[index].services = services;
      subscriptionsData[index].groupServices = groupBy(services, item => {
        return item.serviceType;
      });
      this.setState({ subscriptionsData: cloneDeep(subscriptionsData) });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeFormLine = (groupIndex, serviceIndex, e, index) => {
    const { subscriptionsData } = this.state;
    const { services, groupServices } = subscriptionsData[index];
    try {
      let indexServiceChange = -1;
      indexServiceChange = services.findIndex(
        item =>
          item.index === groupServices[groupIndex][serviceIndex].index &&
          item.serviceType === groupServices[groupIndex][serviceIndex].serviceType
      );
      if (indexServiceChange !== -1 && !Number.isNaN(indexServiceChange)) {
        const lineIndex = services[indexServiceChange].lines.findIndex(item => item.index === e.lineIndex);
        services[indexServiceChange].lines[lineIndex][e.fieldName] = e.value;
        subscriptionsData[index].groupServices = groupBy(services, item => {
          return item.serviceType;
        });
        subscriptionsData[index].services = services;
        this.setState({ subscriptionsData: [...subscriptionsData] });
      }
    } catch (error) {
      console.log(error);
    }
  };

  onChangeService = () => {};

  onChangeProvisioningId = ({ name, value, item, index }) => {
    const { subscriptionsData } = this.state;
    for (let i = 0; i < subscriptionsData[index].services.length; i++) {
      if (subscriptionsData[index].services[i].serviceType === item.serviceType) {
        subscriptionsData[index].services[i][name] = value;
      }
    }
    subscriptionsData[index].groupServices = groupBy(subscriptionsData[index].services, item => {
      return item.serviceType;
    });
    this.setState({ subscriptionsData: [...subscriptionsData] });
  };

  onChangeServiceData = ({ groupIndex, serviceIndex, e, index }) => {
    const { subscriptionsData } = this.state;
    const { services, groupServices } = subscriptionsData[index];
    try {
      let indexServiceChange = -1;
      indexServiceChange = services.findIndex(
        item =>
          item.index === groupServices[groupIndex][serviceIndex].index &&
          item.serviceType === groupServices[groupIndex][serviceIndex].serviceType
      );
      if (indexServiceChange !== -1 && !Number.isNaN(indexServiceChange)) {
        services[indexServiceChange][e.name] = e.value;
        subscriptionsData[index].groupServices = groupBy(services, item => {
          return item.serviceType;
        });
        this.setState({ subscriptionsData: [...subscriptionsData] });
      }
    } catch (error) {
      console.log(error);
    }
  };

  doInitPackageItem = index => {
    const { subscriptionsData } = this.state;
    const { getBundleByBundleId, getDiscountOfferById } = this.props;
    const { services } = subscriptionsData[index];
    const listBundleId = services.filter(val => !!val.bundleId && val.isPackage).map(item => item.bundleId) || [];
    const listDiscountId =
      services.filter(val => !!val.discountOfferId && val.isPackage).map(item => item.discountOfferId) || [];
    const newDataWithBundleId = [];
    const newDataWithDiscountId = [];
    if (!isEmpty(listBundleId)) {
      listBundleId.map(item =>
        getBundleByBundleId(item, ({ success, data }) => {
          if (success) newDataWithBundleId.push(data.components.map(cp => ({ ...cp, bundleId: item })));
          if (newDataWithBundleId.length === listBundleId.length && success) {
            newDataWithBundleId.forEach(lineFetchFromAPI => {
              if (services && services.length && lineFetchFromAPI && lineFetchFromAPI.length) {
                lineFetchFromAPI.forEach((val, j) => {
                  const indexService = services.findIndex(sr => sr.bundleId === val.bundleId);
                  services[indexService].lines[j] = {};
                  services[indexService].lines[j].index = j + 1;
                  services[indexService].lines[j].priceOfferId = val.priceOfferId || null;
                  services[indexService].lines[j].discountOfferId = val.discountOfferId || null;
                  services[indexService].lines[j].action = 'ADD';
                  services[indexService].lines[j].status = 'CREATED';
                  services[indexService].lines[j].priceOfferName = val.priceOfferName;
                  services[indexService].lines[j].discountOfferName = val.discountOfferName;
                });
              }
            });

            subscriptionsData[index].services = services;
            subscriptionsData[index].groupServices = groupBy(services, item => {
              return item.serviceType;
            });
            this.setState({
              subscriptionsData: [...subscriptionsData],
              mode: constants.ModalMode.purchasePackage,
            });
          }
        })
      );
    }
    if (!isEmpty(listDiscountId)) {
      listDiscountId.map(item =>
        getDiscountOfferById(item, ({ success, data }) => {
          if (success) newDataWithDiscountId.push([{ discountOfferName: data.name || null, discountOfferId: item }]);
          if (newDataWithDiscountId.length === listDiscountId.length && success) {
            newDataWithDiscountId.forEach(lineFetchFromAPI => {
              if (services && services.length && lineFetchFromAPI && lineFetchFromAPI.length) {
                lineFetchFromAPI.forEach((val, j) => {
                  const indexService = services.findIndex(sr => sr.discountOfferId === val.discountOfferId);
                  services[indexService].lines[j] = {};
                  services[indexService].lines[j].index = j + 1;
                  services[indexService].lines[j].priceOfferId = val.priceOfferId || null;
                  services[indexService].lines[j].discountOfferId = val.discountOfferId || null;
                  services[indexService].lines[j].action = 'ADD';
                  services[indexService].lines[j].status = 'CREATED';
                  services[indexService].lines[j].priceOfferName = val.priceOfferName;
                  services[indexService].lines[j].discountOfferName = val.discountOfferName;
                });
              }
            });

            subscriptionsData[index].services = services;
            subscriptionsData[index].groupServices = groupBy(services, item => {
              return item.serviceType;
            });
            this.setState({
              subscriptionsData: [...subscriptionsData],
              mode: constants.ModalMode.purchasePackage,
            });
          }
        })
      );
    }
  };

  onSelectService = ({ services, mode, ids, rows, index }) => {
    const { subscriptionsData } = this.state;
    const newData = cloneDeep(subscriptionsData);
    if (!services || (services && services.length === 0) || !ids || (ids && ids.length === 0)) {
      // newData[index].services = [];
      // newData[index].groupServices = [];

      // this.setState({
      //   subscriptionsData: newData,
      // });
      return;
    }
    let service = [];
    let serviceIndex = 0;
    // newData[index].services = [];
    switch (mode) {
      case constants.ModalMode.purchasePackage:
        if (!newData[index].services) newData[index].services = [];
        newData[index].services = newData[index].services.filter(val => !val.isPackage);
        newData[index].services = [
          ...newData[index].services,
          ...services[0].map((item, index) => {
            return this.initServiceWithSelectPackage({
              index: index + 1,
              packageId: item.packageId,
              bundleId: item.bundleId,
              bundleName: item.bundleName,
              serviceType: item.serviceType,
              discountOfferId: item.discountOfferId || null,
              // eslint-disable-next-line no-nested-ternary
              packageName: item.name ? item.name : rows && rows.length ? rows[0].name : '',
            });
          }),
        ];
        newData[index].groupServices = groupBy(newData[index].services, item => {
          return item.serviceType;
        });
        this.setState({
          subscriptionsData: newData,
          mode: constants.ModalMode.purchasePackage,
        });
        break;
      case constants.ModalMode.purchaseBundle:
        if (!newData[index].services) newData[index].services = [];
        newData[index].services = newData[index].services.filter(val => !val.isBundle);
        newData[index].services = [
          ...newData[index].services,
          ...services.map((item, index) => {
            return this.initServiceWithSelectBundle(item, index);
          }),
        ];
        newData[index].groupServices = groupBy(newData[index].services, item => {
          return item.serviceType;
        });
        this.setState({
          subscriptionsData: newData,
          mode: constants.ModalMode.purchaseBundle,
        });
        break;
      case constants.ModalMode.purchaseAlaCarte:
        if (!newData[index].services) newData[index].services = [];
        newData[index].services = newData[index].services.filter(val => !val.isPriceOffer);
        service = groupBy(services, item => {
          return item.serviceType;
        });

        serviceIndex = 0;
        newData[index].services = [
          ...newData[index].services,
          ...map(service, item => {
            serviceIndex += 1;
            return this.initServiceFromPriceOffers(item, serviceIndex);
          }),
        ];
        newData[index].groupServices = groupBy(newData[index].services, item => {
          return item.serviceType;
        });
        this.setState({
          subscriptionsData: newData,
          mode: constants.ModalMode.purchaseAlaCarte,
        });
        break;
      default:
        break;
    }
  };

  onChangeCheckBox = ({ fieldName, value }) => {
    const { orderDetail } = this.state;
    const newData = cloneDeep(orderDetail);
    newData[fieldName] = value;

    this.setState({
      orderDetail: newData,
    });
  };

  onSelectMultipleDiscountOffers = ({ id, row, index }) => {
    const { subscriptionsData } = this.state;
    let { services } = subscriptionsData[index];
    if (row && row.length) {
      if (!services || !services.length) services = [];
      let listDefaultLines = [];
      services = services.filter(val => !val.isDiscount);
      services.forEach(val => {
        const { lines } = val;
        if (lines && lines.length) {
          listDefaultLines = [...listDefaultLines, ...lines.filter(line => !!line.discountOfferId)];
        }
      });

      const newRows = row.filter(r => !listDefaultLines.find(ln => ln.discountOfferId === r.id));
      const newServices = [];
      if (newRows && newRows.length) {
        const newRowsGroupBy = groupBy(newRows, item => {
          return item.serviceType;
        });
        map(newRowsGroupBy, (value, key) => {
          const newService = {
            index: newServices.length + services.length + 1,
            serviceType: key,
            provisioningId: null,
            action: 'ADD',
            reason: null,
            status: 'CREATED',
            bundleId: null,
            packageId: null,
            lines: [],
            isDiscount: true,
          };
          newService.lines = convertLineFromDiscountOffers(value, getLastIndex({ data: value }));
          newServices.push(newService);
        });
      }
      if (newServices && newServices.length) {
        services = [...services, ...newServices];
      }

      subscriptionsData[index].groupServices = groupBy(services, item => {
        return item.serviceType;
      });
      subscriptionsData[index].services = services;
      // subscriptionsData[index].stage += subscriptionsData[index].stage;
      // this.doBuildOrderProvisioningAttributes(index);
      this.setState({
        subscriptionsData: cloneDeep([...subscriptionsData]),
        isOpenModalDisscountOffers: false,
      });
    }
  };

  doBuildOrderProvisioningAttributes = index => {
    const { buildOrderProvisioningAttributes } = this.props;
    const { subscriptionsData, provisioningEnabled } = this.state;
    if (!provisioningEnabled) return null;
    const { orderDetail, services } = subscriptionsData[index];
    return buildOrderProvisioningAttributes(
      convertbuildOrderProvisioningAttributesPayload({ ...orderDetail, services: services || [] }),
      ({ data }) => {
        subscriptionsData[index].buildOrderProvisioningAttributesList =
          convertbuildOrderProvisioningAttributesWithDefaultValue(data) || null;
        subscriptionsData[index].stage = data ? subscriptionsData[index].stage : subscriptionsData[index].stage + 1;
        this.setState({ subscriptionsData: [...subscriptionsData] });
      }
    );
  };

  onToggleTab = (activeTabName, serviceType) => {
    const { activeTab } = this.state;
    if (activeTab.name === activeTabName && serviceType === activeTab.serviceType) {
      this.setState({
        activeTab: { name: activeTabName, isActive: !activeTab.isActive, serviceType },
      });
    } else {
      this.setState({ activeTab: { name: activeTabName, isActive: true, serviceType } });
    }
  };

  onHandleChangeProvisioningAttributes = ({ value, indexService, indexAttribute, indexItem, index }) => {
    const { subscriptionsData } = this.state;
    try {
      const newAttributes = cloneDeep(subscriptionsData[index].buildOrderProvisioningAttributesList);
      newAttributes[indexService].configProvisioningAttributes[indexAttribute].attributesList[
        indexItem
      ].attributeValue = value;
      subscriptionsData[index].buildOrderProvisioningAttributesList = newAttributes;
      this.setState({ subscriptionsData: [...subscriptionsData] });
    } catch (error) {
      console.log(error);
    }
  };

  onAddSubscription = () => {
    let { subscriptionsData } = this.state;
    const { orderDetail } = this.state;
    if (!subscriptionsData) subscriptionsData = [];
    subscriptionsData.push({
      ...cloneDeep(subscriptionDefault),
      orderDetail: cloneDeep(orderDetail),
      subscriptionCategory: orderDetail.subscriptionCategory || null,
    });
    this.setState({ subscriptionsData: [...subscriptionsData] });
  };

  onRemoveSubscription = ({ index }) => {
    let { subscriptionsData } = this.state;
    if (!subscriptionsData) subscriptionsData = [];
    subscriptionsData.splice(index, 1);
    this.setState({ subscriptionsData: [...subscriptionsData] });
  };

  onChaneSubscription = ({ name, value, index }) => {
    const { subscriptionsData } = this.state;
    subscriptionsData[index][name] = value;
    this.setState({ subscriptionsData: [...subscriptionsData] });
  };

  onToggleSubscriptionTab = activeTabName => {
    const { activeTabSubscriptionTab } = this.state;
    if (activeTabSubscriptionTab.name === activeTabName) {
      this.setState({
        activeTabSubscriptionTab: { name: activeTabName, isActive: !activeTabSubscriptionTab.isActive },
      });
    } else {
      this.setState({ activeTabSubscriptionTab: { name: activeTabName, isActive: true } });
    }
  };

  onOpenCreditProfileModal = ({ index, item }) => {
    this.setState({ indexCreditProfileSelect: index, itemCreditProfileSelect: item });
  };

  onSelectCreditProfileName = name => {
    const { indexCreditProfileSelect, subscriptionsData } = this.state;
    subscriptionsData[indexCreditProfileSelect].creditProfileName = name;
    this.setState({
      subscriptionsData: [...subscriptionsData],
      itemCreditProfileSelect: null,
      indexCreditProfileSelect: -1,
    });
  };

  onSelectModalDiscounts = () => {
    this.setState({ isOpenModalDisscountOffers: true });
  };

  onHandleChangeOrderAttribute = (value, index) => {
    const { customAttributesByObjectType } = this.state;
    const newData = cloneDeep(customAttributesByObjectType);
    const indexChange = newData.findIndex(val => val.index === index);
    let newValue = value;
    if (value === 'TRUE') newValue = 'true';
    if (value === 'FALSE') newValue = 'false';
    newData[indexChange].value = newValue;
    newData[indexChange].isModify = true;
    if (!value) delete newData[indexChange].value;
    this.setState({
      customAttributesByObjectType: newData,
    });
  };

  doSearchServiceProvisioningData = ({ item, index, subIndex }) => {
    const { searchServiceProvisioningData } = this.props;
    const { orderDetail } = this.state;
    searchServiceProvisioningData(
      {
        page: 1,
        size: 200,
        filter: {
          provisioningId: item.provisioningId || null,
          serviceType: item.serviceType || null,
          accountId: orderDetail.accountId,
          type: item.type || null,
          status: 'ACTIVE',
        },
      },
      resp => {
        const newResp = resp;
        if (
          newResp.success &&
          newResp.data &&
          newResp.data.types &&
          newResp.data.types.length &&
          newResp.data.types[0].flexAttrMapping
        ) {
          const serviceTypeOptions =
            newResp.data?.types && newResp.data?.types?.length
              ? newResp.data.types.map(val => {
                  const { serviceType, type, flexAttrMapping } = val;
                  return {
                    label: type,
                    value: type,
                    serviceType,
                    type,
                    mapping: flexAttrMapping,
                  };
                })
              : [];

          const itemSelect = serviceTypeOptions.find(
            val => item?.provisioningList && val.value === item?.provisioningList[0].dataType
          );

          const dataResponse = {};
          let mappingList = null;
          if (itemSelect && itemSelect.mapping) {
            itemSelect.mapping.forEach(val => {
              dataResponse[val.flexAttribute] = val.tag;
            });
            mappingList = itemSelect.mapping;
          } else if (serviceTypeOptions && serviceTypeOptions.length) {
            mappingList = serviceTypeOptions[0].mapping;
            serviceTypeOptions[0].mapping.forEach(val => {
              dataResponse[val.flexAttribute] = val.tag;
            });
          }

          this.setState({
            serviceTypeOptions,
            indexSelect: subIndex,
            itemProvisioningSelect:
              item?.provisioningList && item?.provisioningList.length
                ? {
                    ...item,
                    type: item?.provisioningList[0].dataType,
                  }
                : { ...item, type: serviceTypeOptions ? serviceTypeOptions[0].value : null },
            isOpenMdalDetails: true,
            detailsData: item?.provisioningList || [],
            dataResponse,
            mappingList,
          });
        }
      }
    );
  };

  onToggleModalDetails = () => {
    this.setState({
      isOpenMdalDetails: false,
      dataResponse: null,
      detailsData: [],
      itemProvisioningSelect: null,
      indexSelect: null,
      wasValidatedSubscription: false,
      mappingList: null,
    });
  };

  renderSubscriptionForm = ({ modeCreateOrder, modePackagePurchase, modeBundlePurchase, modeAlacartePurchase }) => {
    const {
      selectItem,
      bundleName,
      packageName,
      mode,
      orderDetail,
      activeTab,
      subscriptionsData,
      isMultiSubscriptionEnabled,
      activeTabSubscriptionTab,
      isOpenModalDisscountOffers,
      provisioningEnabled,
    } = this.state;
    const { t } = this.props;
    let newListSteps = listSteps;

    if (!provisioningEnabled) {
      newListSteps = newListSteps.filter(val => val.step !== 3).map((item, index) => ({ ...item, step: index + 1 }));
    }
    return (
      <>
        {subscriptionsData.map((val, index) => {
          let serviceTypeOptions = t('selections:serviceType')();
          let addFirstParamsField = null;
          let defaultFilter = null;
          if (val?.services && val?.services?.length) {
            const serviceTypesList = val.services.map(val => val.serviceType);
            serviceTypeOptions = serviceTypeOptions.filter(sr => serviceTypesList.includes(sr.value));
            if (serviceTypeOptions && serviceTypeOptions.length) {
              addFirstParamsField = { name: 'serviceType', value: serviceTypeOptions[0].value };
              defaultFilter = { serviceType: serviceTypeOptions[0].value };
            } else {
              serviceTypeOptions = [];
            }
          }
          return (
            <FormCollapse
              key={index}
              isActive={index === activeTabSubscriptionTab.name && activeTabSubscriptionTab.isActive}
              title={`${t('label.subscription')}: ${val.subscriptionName || ''}`}
              state={index}
              onToggleTab={this.onToggleSubscriptionTab}
              statusBar={val.stage === lastSubStep ? 'blue' : 'yellow'}
              isNoT
            >
              <div className="col-md-12 row">
                <div className="col-md-6 mt-4 mb-5 group-step-subscription">
                  <StepProgressBar
                    listSteps={newListSteps}
                    stepSelected={val.stage}
                    onSelectStep={step => this.onSelectSubscriptionStep({ step, index })}
                  />
                </div>
                <div className="col-md-3 group-btn-subscription">
                  <button
                    type="button"
                    className="button button-border x-small gray box-shadow-btn"
                    onClick={() => this.onPressCancelSubscriptionButton(index)}
                  >
                    {t('label.cancel')}
                  </button>
                  <button
                    type="button"
                    className="button button-border x-small box-shadow-btn"
                    onClick={() => this.onPressNextSubscriptionButton({ index })}
                    disabled={modeCreateOrder === 1 || val.stage === lastSubStep}
                  >
                    {t('label.next')}
                  </button>
                </div>
              </div>
              {val.stage === 0 && (
                <OrderForm
                  id={selectItem.id}
                  orderDetail={val.orderDetail}
                  onChangeCheckBox={this.onChangeCheckBox}
                  onHandleChange={e => this.onHandleChangeSubscriptionForm({ ...e, index })}
                />
              )}
              {val.stage === 1 && (
                <>
                  <ChooseKindService
                    key={index}
                    id={selectItem.id}
                    onSelectService={e => this.onSelectService({ ...e, index })}
                    mode={val.mode}
                    onChangeMode={mode => this.onChangeMode(mode, index)}
                    modePackagePurchase={modePackagePurchase}
                    modeBundlePurchase={modeBundlePurchase}
                    modeAlacartePurchase={modeAlacartePurchase}
                    isMulti={isMultiSubscriptionEnabled}
                    data={val}
                    // onHandleChange={() => this.onChangeSwitch({ index, name: 'isAddDiscountOffer' })}
                    subscriptionIndex={index}
                    onSelectModalDiscounts={this.onSelectModalDiscounts}
                    services={val.services || []}
                  />
                  <ServiceForm
                    id={selectItem.id}
                    bundleName={bundleName}
                    packageName={packageName}
                    onChangeProvisioningId={e => this.onChangeProvisioningId({ ...e, index })}
                    orderDetail={orderDetail}
                    addLine={this.addLine}
                    onChangeFormLine={(groupIndex, serviceIndex, e) =>
                      this.onChangeFormLine(groupIndex, serviceIndex, e, index)
                    }
                    onChangeService={this.onChangeService}
                    groupServices={val.groupServices}
                    onRemoveLine={e => this.onRemoveLine({ ...e, index })}
                    onRemoveItem={evt => this.onRemoveItem(evt, index)}
                    mode={mode}
                    onChangeData={this.onChangeServiceData}
                    subscriptionsData={subscriptionsData}
                    isMultiSubscriptionEnabled={isMultiSubscriptionEnabled}
                    isReadMultiSubscriptionEnabled
                    subscriptionName={val.subscriptionName}
                    isViewOnly={val.stage === 1}
                  />
                </>
              )}
              {val.stage === 2 && provisioningEnabled && (
                <OrderProvisioningAttributesForm
                  data={val.buildOrderProvisioningAttributesList}
                  activeTab={activeTab}
                  onChange={e => this.onHandleChangeProvisioningAttributes({ ...e, index })}
                  onToggleTab={this.onToggleTab}
                />
              )}
              {((provisioningEnabled && val.stage === 3) || (!provisioningEnabled && val.stage === 2)) && (
                <ServiceForm
                  id={selectItem.id}
                  bundleName={bundleName}
                  packageName={packageName}
                  onChangeProvisioningId={e => this.onChangeProvisioningId({ ...e, index })}
                  orderDetail={orderDetail}
                  addLine={this.addLine}
                  onChangeFormLine={(groupIndex, serviceIndex, e) =>
                    this.onChangeFormLine(groupIndex, serviceIndex, e, index)
                  }
                  onChangeService={this.onChangeService}
                  groupServices={val.groupServices}
                  onRemoveLine={e => this.onRemoveLine({ ...e, index })}
                  onRemoveItem={evt => this.onRemoveItem(evt, index)}
                  mode={mode}
                  onChangeData={this.onChangeServiceData}
                  subscriptionsData={subscriptionsData}
                  isMultiSubscriptionEnabled={isMultiSubscriptionEnabled}
                  isReadMultiSubscriptionEnabled
                  subscriptionName={val.subscriptionName}
                  doGetItemDetails={e => this.doSearchServiceProvisioningData({ ...e, subIndex: index })}
                  isSupportViewProvisioning
                />
              )}
              <ModalMultipleSelectDisscountOffer
                isOpen={isOpenModalDisscountOffers && val.stage === 1}
                // isMultipleOptionInject
                // optionsInject={{
                //   serviceType: serviceTypeOptions,
                // }}
                addFirstParamsField={addFirstParamsField}
                onCancel={() => {
                  this.setState({ isOpenModalDisscountOffers: false });
                  // this.doBuildOrderProvisioningAttributes(index);
                }}
                onSelect={(id, row) => this.onSelectMultipleDiscountOffers({ id, row, index })}
                defaultFilter={defaultFilter}
              />
            </FormCollapse>
          );
        })}
      </>
    );
  };

  renderContent = ({ modeCreateOrder, modePackagePurchase, modeBundlePurchase, modeAlacartePurchase }) => {
    const {
      stage,
      selectItem,
      orderDetail,
      subscriptionsData,
      isMultiSubscriptionEnabled,
      isSingleSubscription,
      isMandatorySubscriptionCategory,
      customAttributesByObjectType,
    } = this.state;
    const { location, t } = this.props;
    switch (stage) {
      case 0:
        return (
          <AccountTable
            type="new-order"
            subscriptionStatus="CLOSED"
            statusRemove={['ACTIVE', 'SUSPENDED']}
            isSubscriptionStatusFilter
            // subscriptionStatus={null}
            onClickRow={this.onClickRow}
            selectItem={selectItem}
            modeCreateOrder={modeCreateOrder}
            onSelectNext={this.onSelectNext}
            linkTo={{
              pathname: RouteNames.searchAndList.path,
              state: location && location.state ? location.state : null,
            }}
            isNoPageTitle
          />
        );

      case 1:
        return (
          <>
            <OrderData
              orderDetail={orderDetail}
              accountId={selectItem.accountId}
              onHandleChange={this.onHandleChangeOrderForm}
              onClickField={this.onToggleModalOrder}
            />
            <FormWithTableItem title="label.createCustomerAttributes">
              <div className="col-md-12 p-0">
                <AttributesForm
                  customAttributesByObjectType={customAttributesByObjectType}
                  onChange={this.onHandleChangeOrderAttribute}
                />
              </div>
            </FormWithTableItem>
          </>
        );

      case 2:
        return (
          <SubscriptionsForm
            onAddSubscription={this.onAddSubscription}
            onChange={this.onChaneSubscription}
            onRemoveItem={this.onRemoveSubscription}
            data={subscriptionsData}
            isMultiSubscriptionEnabled={isMultiSubscriptionEnabled}
            isSingleSubscription={isSingleSubscription}
            onHandleChange={() => {
              this.setState({ isSingleSubscription: !isSingleSubscription });
            }}
            isMandatorySubscriptionCategory={isMandatorySubscriptionCategory}
            isCreditProfile
            onOpenCreditProfileModal={this.onOpenCreditProfileModal}
          />
        );
      case 3:
        return this.renderSubscriptionForm({
          modeCreateOrder,
          modePackagePurchase,
          modeBundlePurchase,
          modeAlacartePurchase,
        });

      case 4:
        return (
          <div className="col-md-12 row justify-content-center mt-2 mb-2">
            {orderListRadioButtons.map(item => (
              <GenericInput
                key={item.name}
                disabled={item.disabled}
                value={orderDetail[item.name] || null}
                onChange={({ name, value }) => this.onHandleChangeOrderForm({ fieldName: name, value })}
                wrapperClass={item.wrapperClass || 'col-md-4'}
                {...item}
              />
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  onSelectStep = step => {
    const { stage, oldStageSelected } = this.state;
    if (step < stage + 1 || step <= oldStageSelected + 1) {
      this.setState({ stage: step - 1 });
    }
  };

  onSelectSubscriptionStep = ({ step, index }) => {
    const { subscriptionsData, stage } = this.state;
    subscriptionsData[index].stage = step - 1;
    this.setState({ subscriptionsData: [...subscriptionsData], stage: step < lastSubStep + 1 ? 3 : stage });
  };

  onToggleModalOrder = () => {
    const { isOpenModalOrder } = this.state;
    this.setState({ isOpenModalOrder: !isOpenModalOrder });
  };

  onSelectOrder = id => {
    const { getOrderDetail, t } = this.props;
    const { orderDetail, provisioningEnabled } = this.state;
    if (id) {
      getOrderDetail(id, ({ success, data }) => {
        if (success && data?.id) {
          const { subscriptions, services } = data;
          if (!subscriptions || !subscriptions.length) return toast.error(t('message.noOrderSubscription'));
          orderDetail.correlationId = id;
          const newSubscriptionsData =
            subscriptions && subscriptions.length
              ? subscriptions.map(val => {
                  const { subscriptionName, ...rest } = val;
                  const servicesCorrelationId = services ? services.map(val => ({ ...val, correlationId: id })) : null;
                  const newServices = servicesCorrelationId
                    ? servicesCorrelationId.filter(sr => sr.subscriptionName === subscriptionName)
                    : null;
                  return {
                    subscriptionName,
                    ...rest,
                    stage: provisioningEnabled ? 3 : 2,
                    orderDetail: {
                      subscriptionName,
                      ...rest,
                    },
                    services: newServices,
                    groupServices: groupBy(servicesCorrelationId, item => {
                      return item.serviceType;
                    }),
                  };
                })
              : [];

          this.setState({
            subscriptionsData: newSubscriptionsData,
            isOpenModalOrder: false,
            stage: 3,
            oldStageSelected: 3,
            orderDetail,
          });
        }
      });
    } else {
      this.setState({ isOpenModalOrder: false });
    }
  };

  onHandleChangeProvisioningData = ({ name, value }) => {
    const { itemProvisioningSelect, serviceTypeOptions } = this.state;
    const newData = cloneDeep(itemProvisioningSelect);
    newData[name] = value;
    const itemSelect = serviceTypeOptions.find(val => val.value === value);
    const dataResponse = {};
    let mappingList = null;
    if (itemSelect && itemSelect.mapping) {
      itemSelect.mapping.forEach(val => {
        dataResponse[val.flexAttribute] = val.tag;
      });
      mappingList = itemSelect.mapping;
    }
    this.setState({ itemProvisioningSelect: newData, dataResponse, detailsData: [], mappingList });
  };

  onAddNewItem = () => {
    const { detailsData } = this.state;
    let newData = cloneDeep(detailsData);
    if (!newData) newData = [];
    newData.push({
      provisioningId: null,
    });
    this.setState({ detailsData: newData });
  };

  onHandleChangeTableItem = ({ name, value, index }) => {
    try {
      const { detailsData } = this.state;
      let newData = cloneDeep(detailsData);
      if (!newData) newData = [];
      newData[index][name] = value;
      this.setState({ detailsData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onRemoveTableItem = ({ index }) => {
    try {
      const { detailsData } = this.state;
      const newData = cloneDeep(detailsData);
      newData.splice(index, 1);
      this.setState({ detailsData: newData });
    } catch (error) {
      console.log(error);
    }
  };

  onSave = () => {
    const { indexSelect, subscriptionsData, detailsData, itemProvisioningSelect } = this.state;
    const { t } = this.props;
    this.setState({ wasValidatedSubscription: true });
    if (!validate(true, this.formRef, t)) {
      return false;
    }
    try {
      const newData = cloneDeep(subscriptionsData);
      const { services } = newData[indexSelect];
      const indexService = findIndexService({ itemProvisioningSelect, services });

      if (detailsData && detailsData.length && indexService !== -1) {
        services[indexService].provisioningList = detailsData.map(val => {
          return { ...val, dataType: itemProvisioningSelect.type };
        });
      } else {
        services[indexService].provisioningList = null;
      }
      newData[indexSelect].services = services;
      newData[indexSelect].groupServices = groupBy(services, item => {
        return item.serviceType;
      });
      return this.setState({
        isOpenMdalDetails: false,
        indexSelect: null,
        detailsData: null,
        wasValidatedSubscription: false,
        subscriptionsData: newData,
      });
    } catch (error) {
      this.setState({
        isOpenMdalDetails: false,
        indexSelect: null,
        detailsData: null,
        wasValidatedSubscription: false,
        subscriptionsData,
      });
    }
  };

  render() {
    const {
      stage,
      subscriptionsData,
      wasValidatedSubscription,
      provisioningEnabled,
      oldStageSelected,
      indexCreditProfileSelect,
      itemCreditProfileSelect,
      orderDetail,
      isOpenModalOrder,
      isOpenMdalDetails,
      detailsData,
      dataResponse,
      serviceTypeOptions,
      itemProvisioningSelect,
      mappingList,
    } = this.state;
    const { permissionOrder, t } = this.props;
    let modeCreateOrder = 0;
    let modeNewOrderType = 0;
    let modePackagePurchase = 0;
    let modeBundlePurchase = 0;
    let modeAlacartePurchase = 0;
    if (permissionOrder && permissionOrder.orderModulePermissions) {
      const listPermission = permissionOrder.orderModulePermissions;
      modeCreateOrder = checkPermissionCreateOrder({ listPermission });
      modeNewOrderType = checkPermissionNewOrderType({ listPermission });
      modePackagePurchase = checkPermissionPackagePurchase({ listPermission });
      modeBundlePurchase = checkPermissionBundlePurchase({ listPermission });
      modeAlacartePurchase = checkPermissionAlacartePurchase({ listPermission });
    }
    if (modeCreateOrder !== 2 || modeNewOrderType !== 2) return '';
    lastSubStep = provisioningEnabled ? 3 : 2;
    let isNextCreate = true;
    subscriptionsData.forEach(val => {
      if (val.stage < lastSubStep) {
        isNextCreate = false;
      }
    });

    const items = [
      {
        name: t('orderPage:sidebar.trialSubsription'),
        url: RouteNames.newOrder.path,
      },
    ];

    if (orderDetail.accountId) {
      items.push({
        name: orderDetail.accountId || null,
      });
    }

    const provTableColumns = [
      {
        name: 'remove',
        label: 'label.remove',
        render: (colName, item, index) => {
          return (
            <div className="form-group col-md-12">
              <button type="button" className="btn-phone" onClick={() => this.onRemoveTableItem({ index })}>
                <i className="fa fa-trash" />
              </button>
            </div>
          );
        },
      },
      {
        name: 'provisioningId',
        label: 'label.provisioningId',
        required: true,
        style: { minWidth: '200px' },
        render: (colName, item, index) => (
          <GenericInput
            value={item.provisioningId || null}
            name="provisioningId"
            onChange={({ name, value }) => this.onHandleChangeTableItem({ index, name, value })}
            wrapperClass="col-md-12"
            required
          />
        ),
      },
    ];

    if (dataResponse) {
      listFlexAttributes.forEach(att => {
        if (dataResponse[att.label]) {
          const itemMapping = mappingList ? mappingList.find(val => val.tag === dataResponse[att.label]) : null;
          if (!provisioningEnabled) {
            provTableColumns.push({
              name: att.name,
              label: dataResponse[att.label],
              style: { minWidth: '200px' },
              required: itemMapping && itemMapping.mandatoryFlag === 'Y',
              render: (colName, item, index) => (
                <GenericInput
                  value={item[att.name] || null}
                  name={att.name}
                  onChange={({ name, value }) => this.onHandleChangeTableItem({ index, name, value })}
                  wrapperClass="col-md-12"
                  required={itemMapping && itemMapping.mandatoryFlag === 'Y'}
                />
              ),
            });
          } else if (itemMapping && itemMapping.mandatoryFlag === 'Y') {
            provTableColumns.push({
              name: att.name,
              label: dataResponse[att.label],
              style: { minWidth: '200px' },
              required: itemMapping && itemMapping.mandatoryFlag === 'Y',
              render: (colName, item, index) => (
                <GenericInput
                  value={item[att.name] || null}
                  name={att.name}
                  onChange={({ name, value }) => this.onHandleChangeTableItem({ index, name, value })}
                  wrapperClass="col-md-12"
                  required={itemMapping && itemMapping.mandatoryFlag === 'Y'}
                />
              ),
            });
          }
        }
      });
    }

    return (
      <div>
        <div className="col-md-12 row p-0">
          <div className="col-sm-6">
            <button type="button" className="button x-small height-btn" onClick={() => this.onPressBackButton(true)}>
              {t('label.back')}
            </button>
          </div>
          <div className="col-sm-6 pr-0">
            <Breadcrumb items={items} />
          </div>
        </div>
        <div className="col-md-12 row text-center pb-3">
          <div className="col-md-6 pt-0 group-step-order">
            <StepProgressBar
              listSteps={listMainSteps}
              oldSelected={oldStageSelected}
              stepSelected={stage}
              onSelectStep={this.onSelectStep}
              isSuportOldSelected
            />
          </div>
          <div className="col-md-3">
            <button
              type="button"
              className="button button-border x-small gray box-shadow-btn"
              onClick={this.onPressCancelButton}
            >
              {t('label.cancel')}
            </button>
            {stage < 4 ? (
              <button
                type="button"
                className="button button-border x-small box-shadow-btn"
                onClick={this.onPressNextButton}
                disabled={modeCreateOrder === 1 || (!isNextCreate && stage === 3)}
              >
                {t('label.next')}
              </button>
            ) : (
              <button
                type="button"
                className="button button-border x-small box-shadow-btn"
                onClick={this.onPressCreateButton}
                disabled={modeCreateOrder === 1}
              >
                {t('label.create')}
              </button>
            )}
          </div>
        </div>
        <form
          noValidate
          ref={this.formRef}
          className={`needs-validation ${wasValidatedSubscription ? 'was-validated' : ''}`}
        >
          {this.renderContent({ modeCreateOrder, modePackagePurchase, modeBundlePurchase, modeAlacartePurchase })}
        </form>
        <ModalSelectCreditProfile
          isOpen={indexCreditProfileSelect !== -1}
          onCancel={() => {
            this.setState({ indexCreditProfileSelect: -1, itemCreditProfileSelect: null });
          }}
          selectedParentId={
            itemCreditProfileSelect && itemCreditProfileSelect.creditProfile
              ? itemCreditProfileSelect.creditProfile
              : null
          }
          onSelect={this.onSelectCreditProfileName}
        />
        <ModalSelectOrder
          isOpen={isOpenModalOrder}
          onSelect={this.onSelectOrder}
          onCancel={this.onToggleModalOrder}
          selectedParentId={orderDetail?.referenceOrder ? orderDetail.referenceOrder : null}
          accountId={orderDetail.accountId}
          defaultSearchParams={{ type: 'NEW', status: 'COMPLETED' }}
        />
        <ModalWithItem
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          isOpen={isOpenMdalDetails}
          onToggle={this.onToggleModalDetails}
          modalTitle={t('label.viewProvisioningData')}
        >
          <form
            noValidate
            ref={this.formRef}
            className={`needs-validation ${wasValidatedSubscription ? 'was-validated' : ''}`}
          >
            <div className="col-md-12 row mb-3">
              <GenericInput
                value={itemProvisioningSelect?.serviceType || null}
                name="serviceType"
                type="select"
                label="label.serviceType"
                tOptions="selections:serviceType"
                onChange={({ name, value }) => this.onHandleChangeProvisioningData({ name, value })}
                wrapperClass="col-md-4"
                menuPortalTarget
                required
                disabled
                readOnly
              />
              <GenericInput
                value={itemProvisioningSelect?.type || null}
                name="type"
                type="select"
                label="label.type"
                options={serviceTypeOptions}
                onChange={({ name, value }) => this.onHandleChangeProvisioningData({ name, value })}
                wrapperClass="col-md-4"
                menuPortalTarget
                required
                isNoClearable
              />
            </div>
            <DataTable columns={provTableColumns} data={detailsData || []} />
            <div className="col-md-12 pt-3 pb-3">
              <button type="button" onClick={this.onAddNewItem} className="button x-small">
                +
                {t('label.add')}
              </button>
              <button type="button" className="button button-border x-small float-right" onClick={this.onSave}>
                {t('label.submit')}
              </button>
              <button
                type="button"
                onClick={this.onToggleModalDetails}
                className="button button-border black x-small float-right"
              >
                {t('label.cancel')}
              </button>
            </div>
          </form>
        </ModalWithItem>
      </div>
    );
  }
}

NewOrder.propTypes = {
  docreateOrder: PropTypes.func.isRequired,
  getBundleByBundleId: PropTypes.func.isRequired,
  getCcpPropertiesConfig: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any),
  permissionOrder: PropTypes.objectOf(PropTypes.any),
  buildOrderProvisioningAttributes: PropTypes.func.isRequired,
  getOrderDetail: PropTypes.func.isRequired,
  getDiscountOfferById: PropTypes.func.isRequired,
  t: PropTypes.func,
  ccpPropertyList: PropTypes.arrayOf(PropTypes.any),
};

NewOrder.defaultProps = {
  location: {},
  history: {},
  permissionOrder: {},
  t: () => {},
  ccpPropertyList: [],
};

const mapStateToProps = createStructuredSelector({
  permissionOrder: makeGetPermissionsOrderManagement() || {},
  ccpPropertyList: makeGetCcpPropertiesConfig() || [],
  orderCustomAttributes: makeGetOrderCustomAttributes() || [],
});

const tNewOrder = connect(mapStateToProps, {
  getBundleByBundleId,
  docreateOrder: createOrder,
  getCcpPropertiesConfig,
  buildOrderProvisioningAttributes,
  getAccountDetail,
  getDiscountOfferById,
  getCustomAttributesByObjectType,
  getOrderDetail,
  searchServiceProvisioningData,
})(NewOrder);

export default withTranslation('common')(tNewOrder);
