import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { cloneDeep, orderBy } from 'lodash';
import { toast } from 'react-toastify';
import Tooltip from 'rc-tooltip';
import { withTranslation } from 'react-i18next';
import { countryList } from '../../../constantsApp/countryList';
import { GenericInput, ModalWithItem } from '../../../components/common';
import RouteNames from '../../App/RouteNames';
import PricingSection from './DiscountingForm/DiscountSection';
import ModalSelectItemId from './ModalSelectItemId';
import ModalSearchDiscountTrigger from '../../../components/common/ModalSearchDiscountTrigger';
import { convertIdPOAndDO, getLastIndex } from '../../../utils/utils';
import { AuditHistoryForm } from '../../../components/PricingHub';
import { getUserId } from '../../../localStorages';

const lisTransactionTypeSupportRateWhenInactive = ['FORWARD_RECURRING', 'ARREARS_RECURRING', 'VOLUME_DISCOUNT'];

const defaultFlatPricing = {
  discounts: [],
  accumulators: [],
};

const defaultRecurringPricing = {
  purchaseProration: 'PRORATE',
  cancelProration: 'PRORATE',
  upgradeProration: 'PRORATE',
  downgradeProration: 'PRORATE',
  discounts: [],
  // discounts: [{ index: 1, currencyId: 'USD', amount: null }],
  accumulators: [],
};

const defaultCustomerPricing = {
  index: 1,
  salesChannel: 'NONE',
  marketSegment: 'NONE',
  accountType: 'NONE',
  accountSubType: 'NONE',
  discounts: [],
  accumulators: [],
};

const defaultRecurringTiered = {
  purchaseProration: 'PRORATE',
  cancelProration: 'PRORATE',
  upgradeProration: 'PRORATE',
  downgradeProration: 'PRORATE',
  unit: 'MONTHS',
  frequency: 1,
  tierDiscounting: [
    {
      id: `TO-EMBRIX-${Math.floor(Math.random() * 100000000000)}`,
      name: '',
      description: '',
      tierType: 'PROSPECTIVE',
      tierReference: 'BALANCE',
      tiers: [
        {
          minimum: '0',
          maximum: '0',
          // discounts: [],
          discounts: [],
          accumulators: [],
        },
      ],
    },
  ],
};

const defaultAttributeBased = {
  index: 1,
  rateUnit: 'NONE',
  timeUnit: 'NONE',
  zoneUnit: 'NONE',
  impactUnit: 'NONE',
  holidayName: 'NONE',
  code: '',
  discounts: [],
  accumulators: [],
};

const defaultTiered = {
  id: `TO-EMBRIX-${Math.floor(Math.random() * 100000000000)}`,
  name: '',
  description: '',
  discountOfferId: '',
  tierType: null,
  tierReference: null,
  accumulatorId: null,
  volumeTreatment: 'ACTUAL',
  evaluationStart: 0,
  evaluationEnd: 0,
  offsetMonths: 0,
  nextDiscountingTerm: 0,
  tiers: [
    {
      maximum: '0',
      minimum: '0',
      // discounts: [],
      discounts: [],
      accumulators: [],
      // accumulators: [{ accumulatorId: '', amount: '0', duration: 0, unit: null }],
    },
  ],
};

const defaultUsageAttributeBase = {
  index: 1,
  name: 'usage',
  description: 'usage',
  usageAttributeDiscountingData: [
    {
      attribute: '',
      value: 'NONE',
    },
  ],
  discounts: [],
  accumulators: [],
};

const defaultComplex = {
  id: `TO-EMBRIX-${Math.floor(Math.random() * 10000000000000)}`,
  index: 1,
  name: '',
  description: '',
  purchaseProration: null,
  cancelProration: null,
  upgradeProration: null,
  downgradeProration: null,
  unit: null,
  frequency: 1,
};

const mapPricingFromItem = ({
  discountingModel,
  flatDiscounting,
  recurringDiscounting,
  customerDiscounting,
  tierDiscounting,
  txnDiscounting,
  transactionType,
  complexDiscounting,
  pricings,
  isChangeModel,
  usageAttributeDiscounting,
}) => {
  if (discountingModel === 'FLAT')
    return !flatDiscounting || flatDiscounting.length === 0 ? [{ ...defaultFlatPricing }] : flatDiscounting;
  if (discountingModel === 'RECURRING')
    return !recurringDiscounting || recurringDiscounting.length === 0 || isChangeModel
      ? [{ ...defaultRecurringPricing }]
      : recurringDiscounting;
  if (discountingModel === 'CUSTOMER_ATTRIBUTE_BASED')
    return !customerDiscounting || customerDiscounting.length === 0
      ? [{ ...defaultCustomerPricing }]
      : customerDiscounting;
  if (discountingModel === 'RECURRING_TIERED')
    return !recurringDiscounting || recurringDiscounting.length === 0 || isChangeModel
      ? [{ ...defaultRecurringTiered }]
      : recurringDiscounting;
  if (discountingModel === 'TRANSACTION_ATTRIBUTE_BASED')
    return !txnDiscounting || txnDiscounting.length === 0
      ? [{ ...defaultAttributeBased }]
      : txnDiscounting[0].txnDiscounts;
  if (discountingModel === 'TIERED') {
    return !tierDiscounting || !tierDiscounting.length ? [{ ...defaultTiered }] : tierDiscounting;
  }
  if (
    discountingModel === 'COMPLEX' &&
    (transactionType === 'USAGE' || transactionType === 'FORWARD_RECURRING' || transactionType === 'ARREARS_RECURRING')
  ) {
    return !complexDiscounting || !complexDiscounting.length
      ? [{ ...defaultComplex }]
      : orderBy(complexDiscounting, ['index'], ['asc']);
  }

  if (discountingModel === 'USAGE_ATTRIBUTE_BASED') {
    return !usageAttributeDiscounting || usageAttributeDiscounting.length === 0
      ? [{ ...defaultUsageAttributeBase }]
      : usageAttributeDiscounting;
  }

  return pricings;
};

class DiscountForm extends Component {
  state = {
    wasValidated: false,
    formValid: true,
    isSelectingItemId: false,
    itemId: null,
    discountingModel: '',
    transactionType: '',
    isOpenModalAudit: false,
  };

  formRef = React.createRef();

  static getDerivedStateFromProps(props, state) {
    if (state.oldItem === props.item) return null;
    return {
      ...props.item,
      pricings: mapPricingFromItem(props.item || {}) || [],
      item: props.item,
      oldItem: props.item,
    };
  }

  validate = (out = false) => {
    const { t } = this.props;
    const formValid = this.formRef && this.formRef.current.checkValidity();
    this.setState({ formValid });
    const { elements } = this.formRef.current;
    for (let i = 0; i < elements.length; i++) {
      if (!elements[i].validity.valid) {
        console.log(elements[i].name, 'invalid');
      }
    }
    if (!formValid && out) {
      toast.error(t('message.mandatory'));
    }
    return formValid;
  };

  onOpenParentSelector = e => {
    this.setState({ isSelectingItemId: true });
  };

  onCancelSelector = () => {
    this.setState({ isSelectingItemId: false });
  };

  onSelectItemId = (itemId, row) => {
    const { creatingMode } = this.props;

    if (creatingMode) {
      return this.setState({
        itemId,
        isSelectingItemId: false,
        taxCode: row?.taxCode || null,
        ratingTimeTaxation: true,
      });
    }
    this.setState({ itemId, isSelectingItemId: false, taxCode: row?.taxCode || null });
  };

  handleInputChange = ({ name, value }) => {
    if (name === 'transactionType') this.setState({ complexDiscounting: null });
    const { transactionType, discountingModel } = this.state;
    this.setState({ [name]: value }, () => {
      if (
        (name === 'transactionType' && transactionType !== value) ||
        (name === 'discountingModel' && discountingModel !== value)
      ) {
        this.handleModelChange(name, value);
      }
    });
  };

  handleModelChange = () => {
    const {
      flatDiscounting,
      recurringDiscounting,
      customerDiscounting,
      tierDiscounting,
      pricings,
      transactionType,
      usageAttributeDiscounting,
      discountingModel,
      complexDiscounting,
    } = this.state;
    const newPricings = mapPricingFromItem({
      discountingModel,
      flatDiscounting,
      recurringDiscounting,
      customerDiscounting,
      tierDiscounting,
      pricings,
      isChangeModel: true,
      usageAttributeDiscounting,
      transactionType,
      complexDiscounting,
    });
    this.setState({ pricings: newPricings });
  };

  onToggleModalDiscountTrigger = () => {
    const { isOpenModalDiscountTrigger } = this.state;
    this.setState({ isOpenModalDiscountTrigger: !isOpenModalDiscountTrigger });
  };

  onSelectDiscountTriggerName = discountTriggerName => {
    this.setState({ discountTriggerName, isOpenModalDiscountTrigger: false });
  };

  handleSubmit = e => {
    this.setState({ wasValidated: true });
    e.preventDefault();
    if (!this.validate(true)) {
      return false;
    }
    const { onSubmit, creatingMode } = this.props;
    const {
      id,
      name,
      description,
      itemId,
      startDate,
      endDate,
      startDuration,
      startUnit,
      endDuration,
      endUnit,
      discountingModel,
      transactionType,
      minimumQuantity,
      maximumQuantity,
      salesChannel,
      marketSegment,
      accountType,
      accountSubType,
      serviceType,
      serviceAddOn,
      status,
      country,
      pricings,
      item,
      discountingType,
      useAccumulatorBalance,
      accumulatorId,
      discountTriggerName,
      effectiveDate,
      accountingCode,
      ratingTimeTaxation,
      offerNameAsTxnDescr,
      rateWhenInactive,
    } = this.state;
    let tierDiscounting = null;
    let txnDiscounting = null;
    let complexDiscounting = null;
    let flatDiscounting = null;
    let customerDiscounting = null;
    let recurringDiscounting = null;
    let usageAttributeDiscounting = null;
    if (discountingModel === 'TIERED') {
      tierDiscounting = cloneDeep(pricings);
      if (creatingMode && tierDiscounting && tierDiscounting.length) {
        tierDiscounting = tierDiscounting.map((pr, index) => {
          const { id, ...rest } = pr;
          return { ...rest, id: convertIdPOAndDO(name, 'tiers', index) };
        });
      }
    }

    if (discountingModel === 'FLAT') {
      flatDiscounting = cloneDeep(pricings);
    }

    if (discountingModel === 'CUSTOMER_ATTRIBUTE_BASED') {
      if (pricings && pricings.length) {
        customerDiscounting = cloneDeep(pricings).map((val, idx) => {
          const { index, ...rest } = val;
          return { ...rest, index: creatingMode ? idx + 1 : index };
        });
      }
    }

    if (discountingModel === 'RECURRING' || discountingModel === 'RECURRING_TIERED') {
      recurringDiscounting = cloneDeep(pricings);
      if (creatingMode && recurringDiscounting && recurringDiscounting.length) {
        recurringDiscounting = recurringDiscounting.map((pr, index) => {
          const { tierDiscounting, ...rest } = pr;
          let newTierDiscounting = [];
          if (tierDiscounting && tierDiscounting.length) {
            newTierDiscounting = tierDiscounting.map((tier, idx) => {
              const { id, ...tr } = tier;
              return { ...tr, id: convertIdPOAndDO(name, `recurring${index + 1}_tiers`, idx) };
            });
          }
          return { ...rest, tierDiscounting: newTierDiscounting };
        });
      }
    }

    if (discountingModel === 'TRANSACTION_ATTRIBUTE_BASED') {
      txnDiscounting = {};
      if (creatingMode) {
        // txnDiscounting.id = `ATT-EMBRIX-${Math.floor(Math.random() * 100000000000)}`;
        txnDiscounting.id = convertIdPOAndDO(name, 'transaction');
        txnDiscounting.name = 'USAGE1';
        txnDiscounting.description = 'USAGE1';
      } else {
        txnDiscounting.id = item.txnDiscounting && item.txnDiscounting[0] ? item.txnDiscounting[0].id : '';
      }
      txnDiscounting.txnDiscounts = pricings;
    }

    if (
      (transactionType === 'USAGE' ||
        transactionType === 'FORWARD_RECURRING' ||
        transactionType === 'ARREARS_RECURRING') &&
      discountingModel === 'COMPLEX'
    ) {
      complexDiscounting = pricings;
      if (creatingMode && complexDiscounting && complexDiscounting.length) {
        complexDiscounting = complexDiscounting.map((pr, idx) => {
          const { id, index, ...rest } = pr;
          return { ...rest, index: creatingMode ? idx + 1 : index, id: convertIdPOAndDO(name, 'complex', idx) };
        });
      }
    }
    if (discountingModel === 'USAGE_ATTRIBUTE_BASED') {
      if (pricings && pricings.length) {
        usageAttributeDiscounting = cloneDeep(pricings).map((val, idx) => {
          const { index, ...rest } = val;
          return { ...rest, index: creatingMode ? idx + 1 : index };
        });
      }
    }

    let isUseAccumulatorBalance = null;
    if ((useAccumulatorBalance === 'TRUE' || useAccumulatorBalance === true) && transactionType === 'VOLUME_DISCOUNT') {
      isUseAccumulatorBalance = 'true';
    }

    if (
      (useAccumulatorBalance === 'FALSE' || useAccumulatorBalance === false) &&
      transactionType === 'VOLUME_DISCOUNT'
    ) {
      isUseAccumulatorBalance = 'false';
    }

    const data = {
      id,
      name: name || null,
      description: description || null,
      itemId: itemId || null,
      startDate: startDate || null,
      endDate: endDate || null,
      startDuration: startDuration || startDuration === 0 ? startDuration : null,
      startUnit: startUnit || null,
      endDuration: endDuration || endDuration === 0 ? endDuration : null,
      endUnit: endUnit || null,
      discountingModel: discountingModel || null,
      transactionType: transactionType || null,
      minimumQuantity: minimumQuantity || minimumQuantity === 0 ? minimumQuantity : null,
      maximumQuantity: maximumQuantity || maximumQuantity === 0 ? maximumQuantity : null,
      salesChannel: salesChannel || null,
      marketSegment: marketSegment || null,
      accountType: accountType || null,
      accountSubType: accountSubType || null,
      serviceType: serviceType || null,
      serviceAddOn: serviceAddOn || null,
      status: status || null,
      customerDiscounting: customerDiscounting || null,
      recurringDiscounting: recurringDiscounting || null,
      flatDiscounting: flatDiscounting || null,
      tierDiscounting: tierDiscounting || null,
      country: country || null,
      txnDiscounting: txnDiscounting || null,
      complexDiscounting: complexDiscounting || null,
      usageAttributeDiscounting: usageAttributeDiscounting || null,
      discountingType: discountingType || null,
      useAccumulatorBalance: isUseAccumulatorBalance,
      accumulatorId: isUseAccumulatorBalance ? accumulatorId || null : null,
      discountTriggerName: discountTriggerName || null,
      effectiveDate: effectiveDate || null,
      accountingCode: accountingCode || null,
      ratingTimeTaxation: typeof ratingTimeTaxation === 'boolean' ? `${ratingTimeTaxation}` : null,
      offerNameAsTxnDescr: typeof offerNameAsTxnDescr === 'boolean' ? `${offerNameAsTxnDescr}` : null,
      rateWhenInactive: typeof rateWhenInactive === 'boolean' ? `${rateWhenInactive}` : null,
    };
    if (onSubmit) {
      onSubmit(data, success => {
        this.setState({ wasValidated: !success });
      });
    }
  };

  onChangePricings = pricings => {
    const { discountingModel, transactionType } = this.state;
    const newStates = cloneDeep({ pricings });
    newStates.recurringDiscounting = null;
    newStates.customerDiscounting = null;
    newStates.flatDiscounting = null;
    newStates.txnDiscounts = null;
    if (discountingModel === 'FLAT') {
      newStates.flatDiscounting = pricings;
    } else if (discountingModel === 'RECURRING' || transactionType === 'RECURRING_TIERED') {
      newStates.recurringDiscounting = pricings;
    } else if (discountingModel === 'CUSTOMER_ATTRIBUTE_BASED') {
      newStates.customerDiscounting = pricings;
    } else if (discountingModel === 'TIERED') {
      newStates.tierDiscounting = pricings;
    } else if (discountingModel === 'TRANSACTION_ATTRIBUTE_BASED') {
      newStates.txnDiscounts = pricings;
    } else if (
      (transactionType === 'USAGE' || transactionType === 'FORWARD_RECURRING') &&
      discountingModel === 'COMPLEX'
    ) {
      newStates.complexDiscounting = pricings;
    }

    this.setState(newStates);
  };

  addNewCustomerPricing = () => {
    // e.preventDefault();
    const { pricings } = this.state;
    const defaultPricing = mapPricingFromItem(this.state);
    const newPricing = defaultPricing && defaultPricing.length > 0 ? defaultPricing[0] : null;
    const discounts = newPricing && newPricing.discounts ? [...newPricing.discounts] : [];
    this.setState({ pricings: [...pricings, { ...newPricing, discounts, index: getLastIndex({ data: pricings }) }] });
  };

  addNewUsageAttributeBase = () => {
    const { pricings } = this.state;
    const newPricing = pricings && pricings.length > 0 ? cloneDeep(pricings[0]) : null;
    const discounts =
      newPricing && newPricing.discounts
        ? [...newPricing.discounts].map(val => ({ currencyId: val.currencyId || null, amount: null }))
        : [];
    this.setState({ pricings: [...pricings, { ...newPricing, discounts, index: getLastIndex({ data: pricings }) }] });
  };

  onAddCurrency = ({ idx, index }) => {
    try {
      const { pricings, discountingModel } = this.state;
      const { creatingMode, defaultCurrency } = this.props;
      let newPricing = cloneDeep(pricings);
      let indexNewItem = 0;
      const data = { currencyId: defaultCurrency || null, amount: null };
      if (
        discountingModel === 'FLAT' ||
        discountingModel === 'TRANSACTION_ATTRIBUTE_BASED' ||
        discountingModel === 'CUSTOMER_ATTRIBUTE_BASED'
      ) {
        if (creatingMode) newPricing[index || 0].discounts.push(data);
        if (!creatingMode) {
          newPricing[index || 0].discounts.forEach(item => {
            if (item.index > indexNewItem) {
              indexNewItem = item.index;
            }
          });
          data.index = indexNewItem;
          newPricing[index || 0].discounts.push(data);
        }
      }

      if (discountingModel === 'USAGE_ATTRIBUTE_BASED') {
        if (creatingMode) {
          newPricing = newPricing.map(val => {
            const { discounts, ...rest } = val;
            return { ...rest, discounts: [...discounts, data] };
          });
        }
        if (!creatingMode) {
          newPricing[index || 0].discounts.forEach(item => {
            if (item.index > indexNewItem) {
              indexNewItem = item.index;
            }
          });
          data.index = indexNewItem;
          newPricing = newPricing.map(val => {
            const { discounts, ...rest } = val;
            return { ...rest, discounts: [...discounts, data] };
          });
        }
      }

      if (discountingModel === 'TIERED' || discountingModel === 'COMPLEX') {
        if (creatingMode) {
          if (newPricing[index || 0].tiers) {
            newPricing[index || 0].tiers[idx].discounts.push(data);
          } else {
            newPricing[index || 0].discounts.push(data);
          }
        }
        if (!creatingMode) {
          if (newPricing[index || 0].tiers) {
            newPricing[index || 0].tiers[idx].discounts.forEach(item => {
              if (item.index > indexNewItem) {
                indexNewItem = item.index;
              }
            });
            data.index = indexNewItem;
            newPricing[index || 0].tiers[idx].discounts.push(data);
          } else {
            newPricing[index || 0].discounts.forEach(item => {
              if (item.index > indexNewItem) {
                indexNewItem = item.index;
              }
            });
            data.index = indexNewItem;
            newPricing[index || 0].discounts.push(data);
          }
        }
      }

      if (discountingModel === 'RECURRING') {
        if (creatingMode) newPricing[index || 0].discounts.push(data);
        if (!creatingMode) {
          newPricing[index || 0].discounts.forEach(item => {
            if (item.index > indexNewItem) {
              indexNewItem = item.index;
            }
          });
          data.index = indexNewItem;
          newPricing[index || 0].discounts.push(data);
        }
      }
      if (discountingModel === 'RECURRING_TIERED') {
        if (creatingMode) newPricing[0].tierDiscounting[0].tiers[idx].discounts.push(data);
        if (!creatingMode) {
          newPricing[0].tierDiscounting[0].tiers[idx].discounts.forEach(item => {
            if (item.index > indexNewItem) {
              indexNewItem = item.index;
            }
          });
          data.index = indexNewItem;
          newPricing[0].tierDiscounting[0].tiers[idx].discounts.push(data);
        }
      }

      this.setState({ pricings: newPricing });
    } catch (error) {
      console.log('error', error);
    }
  };

  handleRemoveCurrency = ({ idx, idxPrice, isPricingField, indexPricing, index }) => {
    const { pricings, discountingModel } = this.state;
    const { creatingMode } = this.props;
    const newPricing = cloneDeep(pricings);
    if (discountingModel === 'RECURRING_TIERED') {
      if (creatingMode) {
        newPricing[index || 0].tierDiscounting[0].tiers[idx].discounts.splice(idxPrice, 1);
      } else {
        newPricing[index || 0].tierDiscounting[0].tiers[idx].discounts[idxPrice] = {
          index: newPricing[index || 0].tierDiscounting[0].tiers[idx].discounts[idxPrice].index,
        };
      }
    } else if (!isPricingField) {
      if (creatingMode) {
        newPricing[index || 0].tiers[idx].discounts.splice(idxPrice, 1);
      } else {
        newPricing[index || 0].tiers[idx].discounts[idxPrice] = {
          index: newPricing[index || 0].tiers[idx].discounts[idxPrice].index,
        };
      }
    }
    if (isPricingField) {
      if (creatingMode) {
        newPricing[indexPricing].discounts.splice(idxPrice, 1);
      } else {
        newPricing[indexPricing].discounts[idxPrice] = {
          index: newPricing[indexPricing].discounts[idxPrice].index,
        };
      }
    }

    this.setState({ pricings: newPricing });
  };

  onAddAccumulators = ({ idx, isPricingField, index, indexPricing }) => {
    const { pricings } = this.state;
    const { creatingMode } = this.props;
    const newPricing = cloneDeep(pricings);
    let indexNewItem = 0;
    const newData = {
      accumulatorId: '',
      amount: null,
      duration: 0,
      unit: null,
    };
    if (!isPricingField) {
      if (newPricing[index || 0].tiers[idx].accumulators.length) {
        newPricing[index || 0].tiers[idx].accumulators.forEach(item => {
          if (item.index > indexNewItem) {
            indexNewItem = item.index;
          }
        });
      }
      if (creatingMode) {
        newPricing[index || 0].tiers[idx].accumulators.push(newData);
      } else {
        newPricing[index || 0].tiers[idx].accumulators.push({
          index: indexNewItem + 1,
          ...newData,
        });
      }
    }

    if (isPricingField) {
      if (newPricing[indexPricing].accumulators.length) {
        newPricing[indexPricing].accumulators.forEach(item => {
          if (item.index > indexNewItem) {
            indexNewItem = item.index;
          }
        });
      }
      if (creatingMode) {
        newPricing[indexPricing].accumulators.push(newData);
      } else {
        newPricing[indexPricing].accumulators.push({
          index: indexNewItem + 1,
          ...newData,
        });
      }
    }

    this.setState({ pricings: newPricing });
  };

  handleRemoveAccumulators = ({ idxAccumulator, idx, isPricingField, indexPricing }) => {
    const { pricings } = this.state;
    const { creatingMode } = this.props;
    const newPricing = cloneDeep(pricings);
    if (!isPricingField) {
      if (creatingMode) {
        newPricing[0].tiers[idx].accumulators.splice(idxAccumulator, 1);
      } else {
        newPricing[0].tiers[idx].accumulators[idxAccumulator] = {
          index: newPricing[0].tiers[idx].accumulators[idxAccumulator].index,
          accumulatorId: newPricing[0].tiers[idx].accumulators[idxAccumulator].accumulatorId,
        };
      }
    }

    if (isPricingField) {
      if (creatingMode) {
        newPricing[indexPricing].accumulators.splice(idxAccumulator, 1);
      } else {
        newPricing[indexPricing].accumulators[idxAccumulator] = {
          index: newPricing[indexPricing].accumulators[idxAccumulator].index,
          accumulatorId: newPricing[indexPricing].accumulators[idxAccumulator].accumulatorId,
        };
      }
    }

    this.setState({ pricings: newPricing });
  };

  handleAddTier = ({ isTier, pricingIdx, isComplexUsage }) => {
    const { pricings, discountingModel } = this.state;
    const { creatingMode, defaultCurrency } = this.props;
    const newPricing = cloneDeep(pricings);
    let indexNewItem = 0;
    const newData = {
      maximum: '0',
      minimum: '0',
      // discounts: [],
      discounts: [],
      // accumulators: [{ accumulatorId: '', amount: '0', duration: 0, unit: null }],
      accumulators: [],
    };

    if (!creatingMode) {
      newData.discounts[0] = { ...newData.discounts[0], index: 1, currencyId: defaultCurrency || null };
    }
    const defaultTier =
      newPricing[pricingIdx] && newPricing[pricingIdx].tiers[0] ? newPricing[pricingIdx].tiers[0] : {};

    if (creatingMode) {
      if (isComplexUsage) {
        if (isTier) {
          if (creatingMode) {
            newPricing[pricingIdx].tiers.push(defaultTier);
          } else {
            newPricing[pricingIdx].tiers.forEach(item => {
              if (item.index > indexNewItem) {
                indexNewItem = item.index;
              }
            });
            defaultTier.index = indexNewItem + 1;
            newPricing[pricingIdx].tiers.push(defaultTier);
          }
        } else {
          newPricing[pricingIdx].tiers.push({
            maximum: '0',
            minimum: '0',
            discounts: [],
          });
        }
      } else {
        newPricing[0].tiers.push(newData);
      }
    } else {
      if (newPricing.length) {
        if (discountingModel === 'TIERED' && newPricing[0].tiers) {
          newPricing[0].tiers.forEach(item => {
            if (item.index > indexNewItem) {
              indexNewItem = item.index;
            }
          });
        } else {
          newPricing.forEach(item => {
            if (item.index > indexNewItem) {
              indexNewItem = item.index;
            }
          });
        }
      }
      if (isComplexUsage) {
        newPricing[pricingIdx].tiers.forEach(item => {
          if (item.index > indexNewItem) {
            indexNewItem = item.index;
          }
        });
        defaultTier.index = indexNewItem + 1;
        newPricing[pricingIdx].tiers.push(defaultTier);
      } else {
        newPricing[0].tiers.push({
          index: indexNewItem + 1,
          ...newData,
        });
      }
    }

    this.setState({ pricings: newPricing });
  };

  onRemoveTier = ({ idx, index, indexTierPricing }) => {
    try {
      const { pricings, discountingModel } = this.state;
      const { creatingMode } = this.props;
      const newPricing = cloneDeep(pricings);
      if (discountingModel === 'RECURRING_TIERED') {
        if (creatingMode) {
          newPricing[index || 0].tierDiscounting[indexTierPricing].tiers.splice(idx, 1);
        } else {
          newPricing[index || 0].tierDiscounting[indexTierPricing].tiers[idx] = {
            index: newPricing[index || 0].tierDiscounting[indexTierPricing].tiers[idx].index,
          };
        }
      } else if (creatingMode) {
        newPricing[index || 0].tiers.splice(idx, 1);
      } else {
        newPricing[index || 0].tiers[idx] = {
          index: newPricing[index || 0].tiers[idx].index,
        };
      }

      this.setState({ pricings: newPricing });
    } catch (error) {
      console.log(error);
    }
  };

  onUpdateTierItem = (data, idx, index) => {
    const { pricings } = this.state;
    const newPricing = cloneDeep(pricings);
    newPricing[index].tiers[idx] = data;
    this.setState({ pricings: newPricing });
  };

  onUpdateDataPricing = ({ data, index }) => {
    const { pricings } = this.state;
    const newPricing = cloneDeep(pricings);
    newPricing[index] = data;
    this.setState({ pricings: newPricing });
  };

  onAddSelections = selectField => {
    const { pricings } = this.state;
    const newPricing = cloneDeep(pricings);
    if (selectField) {
      const lastItem = newPricing.length ? newPricing.length - 1 : 0;

      if (selectField.indexOf(1) > -1) {
        newPricing[lastItem] = {
          ...newPricing[lastItem],
          salesChannel: 'NONE',
          marketSegment: 'NONE',
          accountType: 'NONE',
          accountSubType: 'NONE',
          index: 1,
        };
      }
      if (selectField.indexOf(2) > -1) {
        newPricing[lastItem] = {
          ...newPricing[lastItem],
          index: 1,
          usageAttributeDiscountingData: [
            {
              index: 1,
              attribute: '',
              value: 'NONE',
            },
          ],
        };
      }
      if (selectField.indexOf(3) > -1) {
        newPricing[lastItem] = {
          ...newPricing[lastItem],
          rateUnit: 'NONE',
          timeUnit: 'NONE',
          zoneUnit: 'NONE',
          impactUnit: 'NONE',
          holidayName: 'NONE',
          index: 1,
        };
      }
      if (selectField.indexOf(4) > -1) {
        newPricing[lastItem] = {
          ...newPricing[lastItem],
          tierType: 'PROSPECTIVE',
          tierReference: 'BALANCE',
          accumulatorId: null,
          volumeTreatment: 'ACTUAL',
          evaluationStart: '0',
          evaluationEnd: '0',
          offsetMonths: '0',
          nextDiscountingTerm: '0',
          index: 1,
          // purchaseProration: 'FULL',
          // cancelProration: 'FULL',
          // upgradeProration: 'FULL',
          // downgradeProration: 'FULL',
          tiers: [
            {
              maximum: '0',
              minimum: '0',
              discounts: [],
              accumulators: [],
            },
          ],
        };
      } else {
        newPricing[lastItem] = {
          ...newPricing[lastItem],
          tierType: null,
          tierReference: null,
          accumulatorId: null,
          volumeTreatment: null,
          evaluationStart: '0',
          evaluationEnd: '0',
          offsetMonths: '0',
          nextDiscountingTerm: '0',
          // purchaseProration: null,
          // cancelProration: null,
          // upgradeProration: null,
          // downgradeProration: null,
          discounts: [],
          accumulators: [],
          tiers: null,
          index: 1,
        };
      }
    }
    return this.setState({ pricings: newPricing, complexDiscounting: newPricing });
  };

  onCopyOldComplexUsagePricing = index => {
    try {
      const { pricings } = this.state;
      const newPricing = cloneDeep(pricings);
      const newData = cloneDeep(newPricing[index]);
      let indexNewItem = 0;
      newData.id = `TO-EMBRIX-${Math.floor(Math.random() * 100000000000)}`;
      newPricing.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
      newData.index = indexNewItem + 1;
      newPricing.push(newData);
      this.setState({ pricings: newPricing });
    } catch (error) {
      console.log(error);
    }
  };

  onAddAccumulatorsWithOtherType = ({ index }) => {
    const { pricings } = this.state;
    const { creatingMode } = this.props;
    const newPricing = cloneDeep(pricings);
    let indexNewItem = 0;
    const newData = {
      accumulatorId: '',
      amount: null,
      duration: 0,
      unit: null,
    };
    if (newPricing[index].accumulators.length) {
      newPricing[index].accumulators.forEach(item => {
        if (item.index > indexNewItem) {
          indexNewItem = item.index;
        }
      });
    }
    if (creatingMode) {
      newPricing[index].accumulators.push(newData);
    } else {
      newPricing[index].accumulators.push({
        index: indexNewItem + 1,
        ...newData,
      });
    }
    this.setState({ pricings: newPricing });
  };

  onChangeAccumulatorsWithOtherType = ({ name, value, idx, index }) => {
    const { pricings } = this.state;
    const newPricing = cloneDeep(pricings);
    if (newPricing && newPricing[index || 0].accumulators && newPricing[index || 0].accumulators[idx])
      newPricing[index || 0].accumulators[idx][name] = value;
    this.setState({ pricings: newPricing });
  };

  handleRemoveAccumulatorsWithOtherType = ({ index, idx }) => {
    const { creatingMode } = this.props;
    const { pricings } = this.state;
    const newPricing = cloneDeep(pricings);
    if (newPricing && newPricing[index || 0].accumulators && newPricing[0].accumulators[idx])
      if (creatingMode) {
        newPricing[index || 0].accumulators.splice(idx, 1);
      } else {
        newPricing[index || 0].accumulators[idx] = {
          index: newPricing[index || 0].accumulators[idx].index,
        };
      }

    this.setState({ pricings: newPricing });
  };

  onAddAccumulatorsRecurring = ({ idx, indexTierPricing }) => {
    const { creatingMode } = this.props;
    const { pricings } = this.state;
    const newPricing = cloneDeep(pricings);
    let indexNewItem = 0;

    try {
      if (
        newPricing[0].tierDiscounting &&
        newPricing[0].tierDiscounting[indexTierPricing] &&
        newPricing[0].tierDiscounting[indexTierPricing].tiers &&
        newPricing[0].tierDiscounting[indexTierPricing].tiers.length &&
        newPricing[0].tierDiscounting[indexTierPricing].tiers[idx].accumulators
      ) {
        newPricing[0].tierDiscounting[indexTierPricing].tiers[idx].accumulators.forEach(item => {
          if (item.index > indexNewItem) {
            indexNewItem = item.index;
          }
        });
      }
      if (creatingMode) {
        newPricing[0].tierDiscounting[indexTierPricing].tiers[idx].accumulators.push({
          accumulatorId: '',
          amount: null,
          duration: 0,
          unit: null,
        });
      } else {
        newPricing[0].tierDiscounting[indexTierPricing].tiers[idx].accumulators.push({
          index: indexNewItem + 1,
          accumulatorId: '',
          amount: null,
          duration: 0,
          unit: null,
        });
      }
      this.setState({ pricings: newPricing });
    } catch (error) {
      console.log('error', error);
    }
  };

  handleRemoveAccumulatorsRecurring = ({ idxAccumulator, idx, indexTierPricing }) => {
    try {
      const { pricings } = this.state;
      const { creatingMode } = this.props;
      const newPricing = cloneDeep(pricings);
      if (creatingMode) {
        newPricing[0].tierDiscounting[indexTierPricing].tiers[idx].accumulators.splice(idxAccumulator, 1);
      } else {
        newPricing[0].tierDiscounting[indexTierPricing].tiers[idx].accumulators[idxAccumulator] = {
          index: newPricing[0].tierDiscounting[indexTierPricing].tiers[idx].accumulators[idxAccumulator].index,
        };
      }
      this.setState({ pricings: newPricing });
    } catch (error) {
      console.log(error);
    }
  };

  handleAddTierRecurring = ({ indexTierPricing }) => {
    try {
      const { pricings } = this.state;
      const { creatingMode } = this.props;
      const newPricing = cloneDeep(pricings);
      let indexNewItem = 0;
      const newData = {
        maximum: '0',
        minimum: '0',
        discounts: [],
        accumulators: [],
      };

      if (creatingMode) {
        newPricing[0].tierDiscounting[indexTierPricing].tiers.push(newData);
      } else {
        newPricing[0].tierDiscounting[indexTierPricing].tiers.forEach(item => {
          if (item.index > indexNewItem) {
            indexNewItem = item.index;
          }
        });
        const newDataWithIndexPrices = cloneDeep(newData);
        // newDataWithIndexPrices.discounts[0].index = 1;
        newPricing[0].tierDiscounting[indexTierPricing].tiers.push({
          index: indexNewItem + 1,
          ...newDataWithIndexPrices,
        });
      }
      this.setState({ pricings: newPricing });
    } catch (error) {
      console.log(error);
    }
  };

  handleRemovCurrencyWithOtherType = ({ idx, index }) => {
    try {
      const { pricings, discountingModel } = this.state;
      const { creatingMode } = this.props;
      const newPricing = cloneDeep(pricings);
      if (
        discountingModel === 'FLAT' ||
        discountingModel === 'CUSTOMER_ATTRIBUTE_BASED' ||
        discountingModel === 'USAGE_ATTRIBUTE_BASED'
      ) {
        if (creatingMode) {
          newPricing[index].discounts.splice(idx, 1);
        } else {
          newPricing[index].discounts.splice(idx, 1);
          newPricing[index].discounts[idx] = {
            index: newPricing[index].discounts[idx].index,
          };
        }
      }

      if (discountingModel === 'RECURRING_TIERED' || discountingModel === 'RECURRING') {
        if (creatingMode) {
          newPricing[index].discounts.splice(idx, 1);
        } else {
          newPricing[index].discounts.splice(idx, 1);
          newPricing[index].discounts[idx] = {
            index: newPricing[index].discounts[idx].index,
          };
        }
      }
      this.setState({ pricings: newPricing });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeRemoveSelection = value => {
    const { pricings } = this.state;
    const newPricing = cloneDeep(pricings);
    const lastItem = newPricing.length - 1;
    if (value === 1) {
      delete newPricing[lastItem].salesChannel;
      delete newPricing[lastItem].marketSegment;
      delete newPricing[lastItem].accountType;
      delete newPricing[lastItem].accountSubType;
    }
    if (value === 2) {
      delete newPricing[lastItem].usageAttributeDiscountingData;
    }
    if (value === 3) {
      delete newPricing[lastItem].rateUnit;
      delete newPricing[lastItem].timeUnit;
      delete newPricing[lastItem].zoneUnit;
      delete newPricing[lastItem].impactUnit;
      delete newPricing[lastItem].holidayName;
    }
    if (value === 4) {
      delete newPricing[lastItem].tierType;
      delete newPricing[lastItem].tierReference;
      delete newPricing[lastItem].accumulatorId;
      delete newPricing[lastItem].volumeTreatment;
      delete newPricing[lastItem].evaluationStart;
      delete newPricing[lastItem].evaluationEnd;
      delete newPricing[lastItem].offsetMonths;
      delete newPricing[lastItem].nextDiscountingTerm;
      delete newPricing[lastItem].purchaseProration;
      delete newPricing[lastItem].cancelProration;
      delete newPricing[lastItem].upgradeProration;
      delete newPricing[lastItem].downgradeProration;
      newPricing[lastItem].tierType = null;
      newPricing[lastItem].tierReference = null;
      newPricing[lastItem].accumulatorId = null;
      newPricing[lastItem].volumeTreatment = null;
      newPricing[lastItem].evaluationStart = '0';
      newPricing[lastItem].evaluationEnd = '0';
      newPricing[lastItem].offsetMonths = '0';
      newPricing[lastItem].nextDiscountingTerm = '0';
      newPricing[lastItem].purchaseProration = null;
      newPricing[lastItem].cancelProration = null;
      newPricing[lastItem].upgradeProration = null;
      newPricing[lastItem].downgradeProration = null;
      newPricing[lastItem].discounts = [];
      newPricing[lastItem].accumulators = [];
      newPricing[lastItem].tiers = null;
    }

    return this.setState({ pricings: newPricing, complexDiscounting: newPricing });
  };

  onChangeAddSelection = (value, selectField) => {
    const { pricings } = this.state;
    const newPricing = cloneDeep(pricings);
    const lastItem = newPricing.length - 1;
    if (value === 1) {
      newPricing[lastItem] = {
        ...newPricing[lastItem],
        salesChannel: 'NONE',
        marketSegment: 'NONE',
        accountType: 'NONE',
        accountSubType: 'NONE',
      };
    }
    if (value === 2) {
      newPricing[lastItem] = {
        ...newPricing[lastItem],
        usageAttributeDiscountingData: [
          {
            index: 1,
            attribute: '',
            value: 'NONE',
          },
        ],
      };
    }
    if (value === 3) {
      newPricing[lastItem] = {
        ...newPricing[lastItem],
        rateUnit: 'NONE',
        timeUnit: 'NONE',
        zoneUnit: 'NONE',
        impactUnit: 'NONE',
        holidayName: 'NONE',
      };
    }

    if (selectField.indexOf(4) > -1) {
      newPricing[lastItem].tierType = 'PROSPECTIVE';
      newPricing[lastItem].tierReference = 'BALANCE';
      newPricing[lastItem].accumulatorId = null;
      newPricing[lastItem].volumeTreatment = 'ACTUAL';
      newPricing[lastItem].evaluationStart = '0';
      newPricing[lastItem].evaluationEnd = '0';
      newPricing[lastItem].offsetMonths = '0';
      newPricing[lastItem].nextDiscountingTerm = '0';
      newPricing[lastItem].discounts = null;
      newPricing[lastItem].accumulators = null;
      // newPricing[lastItem].purchaseProration = 'FULL';
      // newPricing[lastItem].cancelProration = 'FULL';
      // newPricing[lastItem].upgradeProration = 'FULL';
      // newPricing[lastItem].downgradeProration = 'FULL';
      newPricing[lastItem].tiers = [
        {
          maximum: '0',
          minimum: '0',
          discounts: [],
          accumulators: [],
        },
      ];
    } else {
      newPricing[lastItem].tierType = null;
      newPricing[lastItem].tierReference = null;
      newPricing[lastItem].accumulatorId = null;
      newPricing[lastItem].volumeTreatment = null;
      newPricing[lastItem].evaluationStart = '0';
      newPricing[lastItem].evaluationEnd = '0';
      newPricing[lastItem].offsetMonths = '0';
      newPricing[lastItem].nextDiscountingTerm = '0';
      // newPricing[lastItem].purchaseProration = null;
      // newPricing[lastItem].cancelProration = null;
      // newPricing[lastItem].upgradeProration = null;
      // newPricing[lastItem].downgradeProration = null;
      newPricing[lastItem].discounts = [];
      newPricing[lastItem].accumulators = [];
      newPricing[lastItem].tiers = null;
    }

    return this.setState({ pricings: newPricing, complexDiscounting: newPricing });
  };

  onChangeRecurringComplex = ({ name, value }) => {
    try {
      const { pricings } = this.state;
      const newPricing = cloneDeep(pricings);
      newPricing.forEach((val, idx) => {
        const newItem = cloneDeep(val);
        newItem[name] = value;
        newPricing[idx] = newItem;
        this.setState({ pricings: newPricing, complexDiscounting: newPricing });
      });
    } catch (error) {
      console.log(error);
    }
  };

  onCancelData = () => {
    this.setState({
      id: null,
      name: null,
      description: null,
      itemId: null,
      startDate: null,
      endDate: null,
      startDuration: null,
      startUnit: null,
      endDuration: null,
      endUnit: null,
      discountingModel: null,
      transactionType: null,
      minimumQuantity: null,
      maximumQuantity: null,
      salesChannel: null,
      marketSegment: null,
      accountType: null,
      accountSubType: null,
      serviceType: null,
      serviceAddOn: null,
      status: null,
      country: null,
      pricings: null,
      discountingType: null,
      useAccumulatorBalance: null,
      accumulatorId: null,
      discountTriggerName: null,
      effectiveDate: null,
    });
  };

  onToggleModalAudit = () => {
    this.setState({ isOpenModalAudit: false });
  };

  onOpenModalConfigName = () => {
    this.setState({ isOpenModalAudit: true });
  };

  render() {
    const {
      creatingMode,
      isSubmitting,
      modeModifyDiscountOffer,
      modeApproveDiscountOffer,
      modeSellableDiscountOffer,
      modeNonSellableDiscountOffer,
      modeSunsetDiscountOffer,
      optionsAccumulatorId,
      optionsGrantId,
      t,
      usageContainerRecordList,
      onCancel,
      getAuditData,
      convertAuditJsonData,
      id,
      title,
      getConfigUsageTypeConfig,
      getItemDetail,
    } = this.props;
    const {
      wasValidated,
      pricings,
      discountingModel,
      isSelectingItemId,
      parentItemId,
      itemId,
      item,
      transactionType,
      useAccumulatorBalance,
      isOpenModalDiscountTrigger,
      discountTriggerName,
      isOpenModalAudit,
      serviceType,
    } = this.state;
    const dataCopy2Create = cloneDeep(item);
    if (dataCopy2Create.id) delete dataCopy2Create.id;
    if (dataCopy2Create.name) delete dataCopy2Create.name;
    if (dataCopy2Create.description) delete dataCopy2Create.description;

    let newStatusOptionsWithPermission = cloneDeep(t('selections:statusPricing')());
    if (modeApproveDiscountOffer === 0)
      newStatusOptionsWithPermission = newStatusOptionsWithPermission.filter(item => item.value !== 'APPROVED');
    if (modeSellableDiscountOffer === 0)
      newStatusOptionsWithPermission = newStatusOptionsWithPermission.filter(item => item.value !== 'SELLABLE');
    if (modeNonSellableDiscountOffer === 0)
      newStatusOptionsWithPermission = newStatusOptionsWithPermission.filter(item => item.value !== 'NON_SELLABLE');
    if (modeSunsetDiscountOffer === 0)
      newStatusOptionsWithPermission = newStatusOptionsWithPermission.filter(item => item.value !== 'SUNSET');

    if (modeApproveDiscountOffer === 1)
      newStatusOptionsWithPermission[
        newStatusOptionsWithPermission.findIndex(item => item.value === 'APPROVED')
      ].isDisabled = true;
    if (modeSellableDiscountOffer === 1)
      newStatusOptionsWithPermission[
        newStatusOptionsWithPermission.findIndex(item => item.value === 'SELLABLE')
      ].isDisabled = true;
    if (modeNonSellableDiscountOffer === 1)
      newStatusOptionsWithPermission[
        newStatusOptionsWithPermission.findIndex(item => item.value === 'NON_SELLABLE')
      ].isDisabled = true;
    if (modeSunsetDiscountOffer === 1)
      newStatusOptionsWithPermission[
        newStatusOptionsWithPermission.findIndex(item => item.value === 'SUNSET')
      ].isDisabled = true;

    let listTaxCodeFields = [];

    if (itemId) {
      listTaxCodeFields = [
        {
          name: 'taxCode',
          label: 'common:label.taxCode',
          wrapperClass: 'col-md-4',
          readOnly: true,
        },
        {
          label: 'common:label.realTimeTaxation',
          wrapperClass: 'col-md-4 mt-4 pt-1',
          name: 'ratingTimeTaxation',
          type: 'checkbox',
        },
      ];
    }

    const listInputs = [
      {
        label: 'common:label.id',
        wrapperClass: 'col-md-4',
        name: 'id',
      },
      {
        label: 'common:label.name',
        wrapperClass: 'col-md-4',
        name: 'name',
        required: true,
      },
      {
        label: 'common:label.useNameAsInvoiceDescription',
        wrapperClass: 'col-md-4 mt-4 pt-1',
        name: 'offerNameAsTxnDescr',
        type: 'checkbox',
        tooltipDetails: t('message.useNameAsInvoiceDescriptionTooltip'),
      },
      {
        label: 'common:label.description',
        wrapperClass: 'col-md-4',
        name: 'description',
        type: 'textarea',
        required: true,
      },
      {
        name: 'discountingType',
        label: 'common:label.discountingType',
        wrapperClass: 'col-md-4',
        tOptions: 'selections:pricingType',
        isClearable: true,
        type: 'select',
        disabled: !creatingMode,
      },
      {
        label: 'common:label.itemId',
        wrapperClass: 'col-md-4',
        name: 'itemId',
        required: true,
        type: 'popup',
        onClick: this.onOpenParentSelector,
        fa: 'fa-external-link',
      },
      ...listTaxCodeFields,
      {
        name: 'serviceType',
        label: 'common:label.serviceType',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:serviceType',
        isClearable: true,
        required: true,
      },
      {
        name: 'transactionType',
        label: 'common:label.transactionType',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:transactionTypeDiscount',
        required: true,
        isClearable: true,
        disabled: !creatingMode,
      },
      {
        name: 'discountingModel',
        label: 'common:label.discountingModel',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:discountingModel',
        isClearable: true,
        required: true,
      },
      {
        name: 'status',
        label: 'common:label.status',
        wrapperClass: 'col-md-4',
        type: 'select',
        options: newStatusOptionsWithPermission,
        isClearable: true,
        required: true,
      },
      {
        label: 'common:label.effectiveDate',
        wrapperClass: 'col-md-4',
        name: 'effectiveDate',
        type: 'date',
        required: true,
      },
      {
        name: 'startDate',
        label: 'common:label.startDate',
        wrapperClass: 'col-md-4',
        type: 'date',
        isClearable: true,
      },
      {
        label: 'common:label.startUnit',
        wrapperClass: 'col-md-4',
        type: 'select',
        name: 'startUnit',
        tOptions: 'selections:timeUnit',
      },

      {
        label: 'common:label.startDuration',
        wrapperClass: 'col-md-4',
        type: 'number',
        name: 'startDuration',
      },
      {
        name: 'endDate',
        label: 'common:label.endDate',
        wrapperClass: 'col-md-4',
        type: 'date',
        isClearable: true,
      },
      {
        label: 'common:label.endUnit',
        wrapperClass: 'col-md-4',
        type: 'select',
        name: 'endUnit',
        tOptions: 'selections:timeUnit',
      },
      {
        label: 'common:label.endDuration',
        wrapperClass: 'col-md-4',
        type: 'number',
        name: 'endDuration',
      },
      {
        label: 'common:label.minimumQuantity',
        wrapperClass: 'col-md-4',
        type: 'number',
        name: 'minimumQuantity',
      },
      {
        label: 'common:label.maximumQuantity',
        wrapperClass: 'col-md-4',
        type: 'number',
        name: 'maximumQuantity',
      },
      {
        name: 'salesChannel',
        label: 'common:label.salesChannel',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:salesChannel',
        isClearable: true,
      },
      {
        name: 'marketSegment',
        label: 'common:label.marketSegment',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:marketSegment',
        isClearable: true,
      },
      {
        name: 'accountType',
        label: 'common:label.accountType',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:accountType',
        isClearable: true,
      },
      {
        name: 'accountSubType',
        label: 'common:label.accountSubType',
        wrapperClass: 'col-md-4',
        type: 'select',
        tOptions: 'selections:accountSubType',
        isClearable: true,
      },
      {
        name: 'country',
        label: 'common:label.country',
        wrapperClass: 'col-md-4',
        options: countryList,
        isClearable: true,
        type: 'select',
      },
      {
        label: 'common:label.accountingCode',
        wrapperClass: 'col-md-4',
        name: 'accountingCode',
      },
      {
        label: 'common:label.serviceAddOn',
        wrapperClass: 'col-md-4',
        name: 'serviceAddOn',
      },
      {
        label: 'common:label.externalId',
        wrapperClass: 'col-md-4',
        name: 'externalId',
      },
      {
        label: 'common:label.externalName',
        wrapperClass: 'col-md-4',
        name: 'externalName',
      },
      {
        name: 'useAccumulatorBalance',
        label: 'common:label.useAccumulatorBalance',
        wrapperClass: 'col-md-4',
        tOptions: 'selections:selectBool',
        isClearable: true,
        type: 'select',
        disabled: transactionType !== 'VOLUME_DISCOUNT',
        typeValue: 'boolean',
        tooltipDetails: t('common:message.useAccumulatorBalanceInfo'),
      },
      {
        label: 'common:label.discountTriggerName',
        wrapperClass: 'col-md-4',
        name: 'discountTriggerName',
        type: 'popup',
        onClick: this.onToggleModalDiscountTrigger,
        fa: 'fa-external-link',
      },
    ];

    if (transactionType === 'VOLUME_DISCOUNT' && (useAccumulatorBalance === 'TRUE' || useAccumulatorBalance === true)) {
      listInputs.push({
        label: 'common:label.accumulatorId',
        wrapperClass: 'col-md-4',
        name: 'accumulatorId',
        isClearable: true,
        type: 'select',
        options: optionsAccumulatorId || [],
      });
    }

    if (lisTransactionTypeSupportRateWhenInactive.includes(transactionType)) {
      listInputs.push({
        label: 'common:label.rateWhenInactive',
        wrapperClass: 'mt-4 pt-2 col-md-4',
        name: 'rateWhenInactive',
        type: 'checkbox',
        tooltipDetails: 'common:message.rateWhenInactiveInfoDO',
      });
    }

    listInputs.push({
      label: 'common:label.userId',
      wrapperClass: 'col-md-4',
      name: 'userId',
      disabled: true,
      readOnly: true,
    });

    return (
      <div className="col-md-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block w-100">
              <h5 className="card-title">
                {t(title)}
                {!creatingMode && (
                  <Tooltip
                    trigger={['hover']}
                    placement="bottom"
                    overlay={t('label.auditHistory')}
                    arrowContent={<div className="rc-tooltip-arrow-inner" />}
                  >
                    <button
                      type="button"
                      onClick={this.onOpenModalConfigName}
                      className="button x-small mr-2 btn-config-item ml-5"
                    >
                      A
                    </button>
                  </Tooltip>
                )}
              </h5>
            </div>
            <div>
              <form
                onSubmit={this.handleSubmit}
                className={`needs-validation ${wasValidated ? 'was-validated' : ''}`}
                noValidate
                ref={this.formRef}
              >
                <div className="form-row">
                  {listInputs.map(input => {
                    if (input.name === 'ratingTimeTaxation') {
                      return (
                        <GenericInput
                          {...input}
                          key={input.name}
                          value={this.state[input.name] !== false}
                          onChange={this.handleInputChange}
                        />
                      );
                    }
                    if (input.name === 'userId') {
                      return (
                        <GenericInput
                          {...input}
                          key={input.name}
                          value={creatingMode ? this.state[input.name] || getUserId() || null : this.state[input.name] || null}
                          onChange={this.handleInputChange}
                        />
                      );
                    }
                    if (input.typeValue === 'boolean') {
                      return (
                        <GenericInput
                          {...input}
                          key={input.name}
                          value={
                            this.state[input.name] === true || this.state[input.name] === 'TRUE' ? 'TRUE' : 'FALSE'
                          }
                          onChange={this.handleInputChange}
                          disabled={(!creatingMode && input.name === 'discountingModel') || input.disabled}
                          readOnly={input.fa}
                          // fa={input.name === 'itemId' ? 'fa-external-link' : ''}
                          // onClick={input.name === 'itemId' ? this.onOpenParentSelector : e => {}}
                        />
                      );
                    }
                    return (
                      <GenericInput
                        {...input}
                        key={input.name}
                        value={this.state[input.name]}
                        onChange={this.handleInputChange}
                        disabled={(!creatingMode && input.name === 'discountingModel') || input.disabled}
                        readOnly={input.fa}
                        // fa={input.name === 'itemId' ? 'fa-external-link' : ''}
                        // onClick={input.name === 'itemId' ? this.onOpenParentSelector : e => {}}
                      />
                    );
                  })}

                  <div className="col-md-12">
                    <PricingSection
                      pricings={cloneDeep(pricings)}
                      discountingModel={discountingModel}
                      onChangePricings={this.onChangePricings}
                      creatingMode={creatingMode}
                      onAddCurrency={this.onAddCurrency}
                      onAddAccumulators={this.onAddAccumulators}
                      handleRemovePrice={this.handleRemoveCurrency}
                      handleRemoveAccumulators={this.handleRemoveAccumulators}
                      handleAddTier={this.handleAddTier}
                      optionsAccumulatorId={optionsAccumulatorId}
                      onRemoveTier={this.onRemoveTier}
                      onUpdateTierItem={this.onUpdateTierItem}
                      optionsGrantId={optionsGrantId}
                      onUpdateDataPricing={this.onUpdateDataPricing}
                      transactionType={transactionType}
                      onAddSelections={this.onAddSelections}
                      onCopyOldComplexUsagePricing={this.onCopyOldComplexUsagePricing}
                      onAddAccumulatorsWithOtherType={this.onAddAccumulatorsWithOtherType}
                      onAddGrantWithOtherType={this.onAddGrantWithOtherType}
                      onChangeGrantWithOtherType={this.onChangeGrantWithOtherType}
                      onChangeAccumulatorsWithOtherType={this.onChangeAccumulatorsWithOtherType}
                      handleRemoveAccumulatorsWithOtherType={this.handleRemoveAccumulatorsWithOtherType}
                      onAddAccumulatorsRecurring={this.onAddAccumulatorsRecurring}
                      handleRemoveAccumulatorsRecurring={this.handleRemoveAccumulatorsRecurring}
                      handleAddTierRecurring={this.handleAddTierRecurring}
                      handleRemovCurrencyWithOtherType={this.handleRemovCurrencyWithOtherType}
                      usageContainerRecordList={usageContainerRecordList}
                      addNewCustomerPricing={this.addNewCustomerPricing}
                      addNewUsageAttributeBase={this.addNewUsageAttributeBase}
                      onChangeRemoveSelection={this.onChangeRemoveSelection}
                      onChangeAddSelection={this.onChangeAddSelection}
                      onChangeRecurringComplex={this.onChangeRecurringComplex}
                      getConfigUsageTypeConfig={getConfigUsageTypeConfig}
                      serviceType={serviceType}
                    />
                  </div>

                  <div className="form-group col-md-12 action-buttons">
                    <label htmlFor="validationCustom01" />
                    <br />
                    {isSubmitting && (
                      <button type="button" className="button button-border x-small float-right">
                        {t('label.saving')}
                      </button>
                    )}
                    {!creatingMode && !isSubmitting && modeModifyDiscountOffer === 2 && (
                      <Link
                        to={{ pathname: RouteNames.pricingDiscountAdd.path, state: dataCopy2Create }}
                        className="button button-border gray x-small float-right"
                      >
                        {t('label.copyToCreate')}
                      </Link>
                    )}
                    {!creatingMode && !isSubmitting && modeModifyDiscountOffer === 2 && (
                      <button type="submit" className="button button-border x-small float-right">
                        {t('label.modify')}
                      </button>
                    )}
                    {creatingMode && !isSubmitting && (
                      <button type="submit" className="button button-border x-small float-right">
                        {t('label.create')}
                      </button>
                    )}
                    {onCancel ? (
                      <button
                        onClick={onCancel}
                        type="button"
                        className="button button-border black x-small  float-right"
                      >
                        {t('label.cancel')}
                      </button>
                    ) : (
                      <button
                        onClick={this.onCancelData}
                        type="button"
                        className="button button-border black x-small  float-right"
                      >
                        {t('label.cancel')}
                      </button>
                    )}
                  </div>
                </div>
              </form>
              <ModalSelectItemId
                isOpen={isSelectingItemId}
                selectedParentId={itemId}
                onSelect={this.onSelectItemId}
                onCancel={this.onCancelSelector}
                toggleModal={this.onCancelSelector}
                defaultSearchParams={{ type: 'REVENUE' }}
              />
              <ModalSearchDiscountTrigger
                isOpen={isOpenModalDiscountTrigger}
                selectedParentId={discountTriggerName}
                onSelect={this.onSelectDiscountTriggerName}
                onCancel={this.onToggleModalDiscountTrigger}
                toggleModal={this.onToggleModalDiscountTrigger}
              />
            </div>
          </div>
        </div>
        <ModalWithItem
          isOpen={isOpenModalAudit}
          onToggle={this.onToggleModalAudit}
          modalTitle={t('label.auditHistory')}
          wrapperClass="modal-custom modal-70"
        >
          <button
            onClick={this.onToggleModalAudit}
            type="button"
            className="button button-border black x-small mr-2 mb-2"
          >
            {t('label.cancel')}
          </button>
          <AuditHistoryForm
            getAuditData={getAuditData}
            id={id}
            convertAuditJsonData={convertAuditJsonData}
            optionsAccumulatorId={optionsAccumulatorId}
            optionsGrantId={optionsGrantId}
            usageContainerRecordList={usageContainerRecordList}
            isDisccountOffer
            isOpenItem={isOpenModalAudit}
            getItemDetail={getItemDetail}
          />
        </ModalWithItem>
      </div>
    );
  }
}

DiscountForm.propTypes = {
  onSubmit: PropTypes.func,
  item: PropTypes.objectOf(PropTypes.any),
  isSubmitting: PropTypes.bool,
  creatingMode: PropTypes.bool,
  modeModifyDiscountOffer: PropTypes.number,
  modeApproveDiscountOffer: PropTypes.number,
  modeSellableDiscountOffer: PropTypes.number,
  modeNonSellableDiscountOffer: PropTypes.number,
  modeSunsetDiscountOffer: PropTypes.number,
  defaultCurrency: PropTypes.string,
};

DiscountForm.defaultProps = {
  onSubmit: () => {},
  item: {},
  isSubmitting: false,
  creatingMode: true,
  modeModifyDiscountOffer: 0,
  modeApproveDiscountOffer: 2,
  modeSellableDiscountOffer: 2,
  modeNonSellableDiscountOffer: 2,
  modeSunsetDiscountOffer: 2,
  defaultCurrency: null,
};

export default withTranslation(['common'])(DiscountForm);
