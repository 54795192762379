import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';
import jobNS from './constants/jobNS';
import { getUserId } from '../../localStorages';

export default data => {
  const newData = data;
  newData.userId = getUserId() || newData.userId || null;
  const dataCreate = parseToMutationRequest(newData, jobNS);
  const createTenantSchedule = `createTenantSchedule(input: ${dataCreate})`;
  return gql`
  mutation {
    ${createTenantSchedule} {
      id
      status
      scheduleDate
      userId
      resetAllowed
      scheduleFrequency
      scheduleName
      jobScheduleList {
        index
        jobId
        predJobId
        nextJobId
        name
        notes
        userId
        scheduleTime
        type
        scheduleType
        status
        count
        countSuccess
        countError
      }
    }
  }
`;
};
