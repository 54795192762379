import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { Breadcrumb, AreaCharts, PieCharts } from '../../../components/common';
import { makeGetUserDashboards, makeGetDashboardTileConfig } from '../selectors';
import { getUserDashboards, getUserPreferences } from '../actions';
import RouteNames from '../../App/RouteNames';
import { listNameTitleUserDashboard } from '../constants';
import defineBackground from '../../../utils/defineBackgroundChart';
import { checkPermissionForAllPages } from '../CheckPermission';
import { makeGetPermissionsDashboard, makeGetPermissionsReports } from '../../App/selectors';
import { checkPermissionGetObjectFiles } from '../../ReportManagement/CheckPermission';
import FolioCount from '../FolioCount';
import AssetSummary from '../AssetSummary';
import FolioSummary from '../FolioSummary';

class MyDashboard extends Component {
  componentDidMount() {
    const { getUserDashboards, getUserPreferences } = this.props;
    getUserDashboards();
    getUserPreferences('');
  }

  render() {
    const { userDashboards, t, listConfig, permissionsDashboard, permissionsReports } = this.props;
    const listKey = listConfig && listConfig.length ? listConfig.map(item => item.dashboardType) : [];
    let listKeyShowing = [];
    if (permissionsDashboard && permissionsDashboard.dashboardModulePermissions) {
      const listPermission = permissionsDashboard.dashboardModulePermissions;
      listKeyShowing = checkPermissionForAllPages({ listPermission });
    }

    let modeFolioCountSummary = 0;
    if (permissionsReports && permissionsReports.reportModulePermissions) {
      const listPermission = permissionsReports.reportModulePermissions;
      modeFolioCountSummary = checkPermissionGetObjectFiles({ listPermission });
    }
    // if (modeFolioCountSummary) return '';

    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link to={RouteNames.myDashboard.path} className="button x-small">
                {t('label.back')}
              </Link>
            </div>
            <div className="col-sm-6">
              <Breadcrumb items={[{ name: t('label.myDashboard') }]} />
            </div>
          </div>
        </div>
        <br />
        <div className="col-md-12 mb-30 p-0">
          {/* <div className="card card-statistics h-100"> */}
          <div className="d-block w-100">
            <h5 className="card-title">{t('label.myDashboard')}</h5>
          </div>
          <div className="chart-group mb-5 mr-0">
            {listKey.map(val => {
              let dataChart = [];
              let dataPieChart = [];
              const item = userDashboards.find(item => item.name === val);
              if (userDashboards && userDashboards.length > 0 && item) {
                dataChart = item.data;
                dataPieChart = item.pieChartData;
              }
              if (modeFolioCountSummary && val === 'FOLIO_COUNT') {
                return <FolioCount isComponent />;
              }
              if (val === 'FOLIO_SUMMARY') {
                return <FolioSummary isComponent />;
              }
              if (val === 'ASSET_SUMMARY') {
                return <AssetSummary isComponent />;
              }
              if (listKeyShowing.indexOf(val) === -1) return '';
              if (dataChart && dataChart.length) {
                return (
                  <AreaCharts
                    key={val}
                    title={t(listNameTitleUserDashboard[val])}
                    data={dataChart}
                    wrapperClass="chart-item-dashboard mt-2 mb-5"
                    subClass="animate__animated animate__zoomIn"
                    keySeries="value"
                    keyCategories="mon"
                    dataType={item.dataType}
                    isRenderTitleYaxis
                    isNeedSupportYear={val === 'REVENUE_FORECAST' || val === 'MONTHLY_RECURRING_REVENUE'}
                    background={defineBackground[val] ? defineBackground[val] : null}
                  />
                );
              }
              if (dataPieChart && dataPieChart.length) {
                return (
                  <PieCharts
                    key={val}
                    data={dataPieChart}
                    wrapperClass="col-md-4 chart-pie-dashboard"
                    subClass="animate__animated animate__zoomIn"
                    keySeries="value"
                    keyCategories="mon"
                    style={{ height: 300, width: 450 }}
                    title={t(listNameTitleUserDashboard[val])}
                    height="250px"
                    // background={defineBackground[val] ? defineBackground[val] : null}
                  />
                );
              }
            })}
          </div>
        </div>
        {/* </div> */}
      </div>
    );
  }
}

MyDashboard.propTypes = {
  permissionsDashboard: PropTypes.objectOf(PropTypes.any),
};

MyDashboard.defaultProps = {
  permissionsDashboard: {},
};

const mapStateToProps = createStructuredSelector({
  userDashboards: makeGetUserDashboards() || [],
  listConfig: makeGetDashboardTileConfig() || [],
  permissionsDashboard: makeGetPermissionsDashboard() || {},
  permissionsReports: makeGetPermissionsReports() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, { getUserDashboards, getUserPreferences })(MyDashboard)
);
