import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { selectPackageId, selectBundleId, selectPriceOfferId } from '../../App/actions';
import { PriceOffersSearchForm, BundelSearchForm, PackageSearchForm } from '../../../components/Orders';
import { ModalWithItem } from '../../../components/common';
import constants from '../constants';
import { updateOrderStatus, modifyOrder } from '../actions';
import { makeErrorMessage } from '../selectors';
import SelectTable from './SelectTable';

class ChooseService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableColumns: constants.tableSelectPackageColumns,
      selectedItemIds: [],
      isOpenModal: false,
      rowsSelected: [],
    };
  }

  getTypePurchase = mode => {
    if (mode === constants.ModalMode.purchasePackage) {
      this.setState({
        modalTitle: 'label.choosePackage',
        tableColumns: constants.tableSelectPackageColumns,
        selectedItemIds: [],
        isOpenModal: true,
      });
    } else if (mode === constants.ModalMode.purchaseBundle) {
      this.setState({
        // modalTitle: 'Select Bundles :',
        modalTitle: 'label.chooseBundle',
        tableColumns: constants.tableSelectBundleColumns,
        selectedItemIds: [],
        isOpenModal: true,
      });
    } else {
      this.setState({
        // modalTitle: 'Select Price Offers :',
        modalTitle: 'label.choosePriceOffers',
        tableColumns: constants.tableSelectPriceOfferColumns,
        selectedItemIds: [],
        isOpenModal: true,
      });
    }
    const { onChangeMode } = this.props;
    onChangeMode(mode);
  };

  searchItemsOfSelectModal = (postData, cb) => {
    const { mode, doSelectPackageId, doSelectBundleId, doSelectPriceOfferId } = this.props;
    switch (mode) {
      case constants.ModalMode.purchasePackage:
        doSelectPackageId(postData, cb);
        break;
      case constants.ModalMode.purchaseBundle:
        doSelectBundleId(postData, cb);
        break;
      case constants.ModalMode.purchaseAlaCarte:
        doSelectPriceOfferId(postData, cb);
        break;
      default:
        break;
    }
  };

  onClickRow = (ids, list) => {
    const rowsSelected = list.filter(val => ids.includes(val.id));
    this.setState({ selectedItemIds: ids, rowsSelected });
  };

  onSelectItems = () => {
    const { mode, onSelectService, isMulti } = this.props;
    const { selectedItemIds, rowsSelected } = this.state;
    if (mode === constants.ModalMode.purchasePackage) {
      if (isMulti && selectedItemIds.length > 0) {
        let rows = [];
        const tempServices = selectedItemIds.map(id => {
          const rowsItem = rowsSelected.filter(item => item.id === id);
          rows = [...rows, ...rowsItem];
          return rowsItem[0] || {};
        });

        let listComponents = [];
        if (tempServices && tempServices.length) {
          tempServices.forEach(item => {
            if (item?.components?.length) {
              listComponents = [
                ...listComponents,
                ...item.components.map(val => ({ ...val, name: item.name || null, packageId: item.id })),
              ];
            }
          });
        }
        onSelectService({
          services: [listComponents],
          rows,
          ids: selectedItemIds,
          mode: constants.ModalMode.purchasePackage,
        });
        return this.setState({ selectedItemIds: [], isOpenModal: false, rowsSelected: [] });
      }
      if (selectedItemIds.length > 0) {
        const rows = rowsSelected.filter(item => item.id === selectedItemIds[0]);
        onSelectService({
          services: rows.map(item => {
            return item.components ? item.components.map(i => ({ ...i, packageId: selectedItemIds[0] })) : null;
          }),
          rows,
          ids: selectedItemIds,
          mode: constants.ModalMode.purchasePackage,
        });
        return this.setState({ selectedItemIds: [], isOpenModal: false, rowsSelected: [] });
      }
      onSelectService({
        services: [],
        ids: selectedItemIds,
        mode: constants.ModalMode.purchasePackage,
      });
    }
    if (mode === constants.ModalMode.purchaseBundle) {
      if (selectedItemIds.length > 0) {
        const tempServices = selectedItemIds.map(id => {
          const rows = rowsSelected.filter(item => item.id === id);
          if (rows && rows.length > 0) {
            const { startDate, endDate, ...rest } = rows[0] || {};
            return { ...rest };
          }
          return {};
        });

        onSelectService({
          services: tempServices,
          ids: selectedItemIds,
          mode: constants.ModalMode.purchaseBundle,
        });
        return this.setState({ selectedItemIds: [], isOpenModal: false, rowsSelected: [] });
      }
      onSelectService({
        services: [],
        ids: selectedItemIds,
        mode: constants.ModalMode.purchaseBundle,
      });
    }
    if (mode === constants.ModalMode.purchaseAlaCarte) {
      if (selectedItemIds.length > 0) {
        const tempServices = selectedItemIds.map(id => {
          const rows = rowsSelected.filter(item => item.id === id);
          if (rows && rows.length > 0) {
            const { startDate, endDate, ...rest } = rows[0] || {};
            return { ...rest };
          }
          return {};
        });
        onSelectService({
          services: tempServices,
          ids: selectedItemIds,
          mode: constants.ModalMode.purchaseAlaCarte,
        });
        return this.setState({ selectedItemIds: [], isOpenModal: false, rowsSelected: [] });
      }
      onSelectService({
        services: [],
        ids: selectedItemIds,
        mode: constants.ModalMode.purchaseAlaCarte,
      });
      return this.setState({ selectedItemIds: [], isOpenModal: false, rowsSelected: [] });
    }
  };

  onToggleModal = () => {
    this.setState({ isOpenModal: false });
  };

  render() {
    const {
      id,
      mode,
      modePackagePurchase,
      modeBundlePurchase,
      modeAlacartePurchase,
      t,
      isMulti,
      onHandleChange,
      data,
      subscriptionIndex,
      onSelectModalDiscounts,
      services,
      onChangeMode,
    } = this.props;
    const { isOpenPriceOfferModal, tableColumns, modalTitle, selectedItemIds, isOpenModal } = this.state;
    let SearchForm = PackageSearchForm;
    if (constants.ModalMode.purchaseBundle === mode) {
      SearchForm = BundelSearchForm;
    }
    if (constants.ModalMode.purchaseAlaCarte === mode) {
      SearchForm = PriceOffersSearchForm;
    }
    return (
      <div>
        {/* <div className="col-md-12 mb-30">
          <div className="card card-statistics"> */}
        {/* <CardHeaderForm title={t('label.order')} label={t('label.accountNumber')} value={id} /> */}
        <div className="form-group col-md-12 justify-content-center row pb-3">
          {modePackagePurchase !== 0 && (
            <button
              type="button"
              className="button button-border x-small"
              // className={`button button-border x-small ${
              //   constants.ModalMode.purchasePackage === mode ? 'active' : ''
              // }`}
              disabled={modePackagePurchase === 1}
              active={constants.ModalMode.purchasePackage === mode}
              onClick={() => {
                this.getTypePurchase(constants.ModalMode.purchasePackage);
              }}
            >
              {t('label.addPackage')}
            </button>
          )}
          {modeBundlePurchase !== 0 && (
            <button
              type="button"
              className={`button button-border x-small ${constants.ModalMode.purchaseBundle === mode ? 'active' : ''}`}
              disabled={modeBundlePurchase === 1}
              onClick={() => {
                this.getTypePurchase(constants.ModalMode.purchaseBundle);
              }}
            >
              {t('label.addBundle')}
            </button>
          )}
          {modeAlacartePurchase !== 0 && (
            <button
              type="button"
              className="button button-border x-small"
              // className={`button button-border x-small ${
              //   constants.ModalMode.purchaseAlaCarte === mode ? 'active' : ''
              // }`}
              disabled={modeAlacartePurchase === 1}
              onClick={() => {
                this.getTypePurchase(constants.ModalMode.purchaseAlaCarte);
              }}
            >
              {t('label.addAlaCarte')}
            </button>
          )}
          <button
            type="button"
            className="button button-border x-small"
            // className={`button button-border x-small ${
            //   constants.ModalMode.addDiscounts === mode ? 'active' : ''
            // }`}
            onClick={() => {
              onSelectModalDiscounts();
              onChangeMode(constants.ModalMode.addDiscounts);
            }}
          >
            {t('label.addDiscounts')}
          </button>
          {/* <GenericInput
                label="common:label.addDiscounts"
                value={data.isAddDiscountOffer}
                name={`isAddDiscountOffer-${subscriptionIndex}`}
                onChange={({ value }) => onHandleChange({ value, name: 'isAddDiscountOffer' })}
                wrapperClass="ml-3 mt-1"
                type="switch"
              /> */}
          {/* </div>
          </div> */}
        </div>
        <ModalWithItem
          isOpen={isOpenModal}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          onToggle={this.onToggleModal}
        >
          <div>
            <SelectTable
              modalTitle={modalTitle}
              defaultFilter={{ status: 'SELLABLE' }}
              isSingleSelect={!isMulti && mode === constants.ModalMode.purchasePackage}
              isOpen={isOpenPriceOfferModal}
              SearchForm={SearchForm}
              searchItems={this.searchItemsOfSelectModal}
              tableColumns={tableColumns}
              onSelect={this.onSelectTable}
              onClickRow={this.onClickRow}
              selectedItemIds={selectedItemIds}
              mode={mode}
              isNeedSupportRefetch
            />
          </div>
          <div className="text-right modal-footer">
            <button type="button" className="btn btn-secondary" onClick={this.onToggleModal}>
              {t('label.cancel')}
            </button>
            <button type="button" className="btn btn-success" onClick={this.onSelectItems}>
              {t('label.select')}
            </button>
          </div>
        </ModalWithItem>
      </div>
    );
  }
}

ChooseService.propTypes = {
  onChangeMode: PropTypes.func,
  doSelectPriceOfferId: PropTypes.func.isRequired,
  doSelectBundleId: PropTypes.func.isRequired,
  doSelectPackageId: PropTypes.func.isRequired,
  mode: PropTypes.string,
  id: PropTypes.string,
  onSelectService: PropTypes.func,
  modePackagePurchase: PropTypes.number,
  modeBundlePurchase: PropTypes.number,
  modeAlacartePurchase: PropTypes.number,
};

ChooseService.defaultProps = {
  mode: '',
  onChangeMode: () => {},
  id: '',
  onSelectService: () => {},
  modePackagePurchase: 2,
  modeBundlePurchase: 2,
  modeAlacartePurchase: 2,
};

const mapStateToProps = createStructuredSelector({
  errorMessage: makeErrorMessage() || {},
});

const newChooseService = connect(mapStateToProps, {
  updateOrderStatus,
  modifyOrder,
  doSelectPackageId: selectPackageId,
  doSelectBundleId: selectBundleId,
  doSelectPriceOfferId: selectPriceOfferId,
})(ChooseService);

export default withTranslation('common')(newChooseService);
