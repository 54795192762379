import gql from 'graphql-tag';
import { parseToMutationRequest } from '../../utils/utils';
import orderNS from './constants/orderNS';
import { getUserId } from '../../localStorages';

export default data => {
  const newData = data;
  newData.userId = getUserId() || newData.userId || null;
  const dataCreate = parseToMutationRequest(newData, orderNS);
  const indexDataType = dataCreate.indexOf('dataType');
  const newDataCreate = indexDataType !== -1 ? `${dataCreate.replace(/dataType/gi, 'type')}` : dataCreate;
  const createOrder = `createOrder(input: ${newDataCreate})`;

  const qqlStr = gql`
  mutation {
    ${createOrder} {
      id
      type
      status
      reason
      accountId
      userId
      clientId
      isPartialFulfillmentAllowed
      effectiveDate
      submittedDate
      initialTerm
      initialTermUnit
      renewalTerm
      renewalTermUnit
      trialTerm
      trialTermUnit
      crmId
      provisioningId
      trialType
      accountType
      salesAgentId
      provisioningOnlyFlag
      services {
        index
        serviceType
        provisioningId
        action
        reason
        status
        bundleId
        bundleName
        packageId
        packageName
        subscriptionName
        provisioningList {
          provisioningId
          type
          action
          effectiveDate
          endDate
        }
        lines {
          index
          action
          status
          reason
          quantity
          priceOverride
          priceOffset
          discountPercent
          startDate
          endDate
          relativeStart
          relativeEnd
          relativeStartUnit
          relativeEndUnit
          priceOfferId
          discountOfferId
          priceOfferName
          discountOfferName
          discAmountOverride
          discPercentOverride
        }
      }
      billables {
        index
        action
        omsInternalId
        serviceId
        serviceName
        parentServiceId
        parentServiceName
        status
        quantity
        servicePrice
        totalPrice
        uom
        serviceType
        itemId
        loadType
        productDescription
        serviceDiscount
        clientCircuitId
        externalServiceId
        commodityPrice
        cost
        externalServiceIdOp
        includedLicenses
        initialTermUnit
        initialTerm
        priceWithTax
        taxCode
        notes
        operationalState
        administrativeState
        dischargeDate
        cancelledDate
        releaseDate
        serviceMovement
        reason
        invoiceExhibits
        recurringType
        capacity
        validity
        trialTerm
        trialTermUnit
        cutOffDate
        internationalDIDCountry
        commitmentTerm
        commitmentDollarValue
        locations{
          pointId
          pointName
          index
        }
      }
      nonBillables {
        omsInternalId
        action
        status
        assetTag
        description
        comments
        cost
        deliveryDate
        inventoryItemId
        primaryCode
        quantity
        exchangeRate
        statusId
        transactionType
        externalId
      }
      locations {
        omsInternalId
        action
        status
        userId
        pointId
        accountId
        cpId
        floor
        colony
        street
        city
        state
        zipCode
        plusCode
        country
        creationDate
        fullName
        company
        latitude
        longitude
        commercialClasification
        ring
        pointName
        ownerName
        ownerId
        validationStatus
        statusId
        deliveryType
      }
      infraNonBillables {
        action
        omsInternalId
        status
        assetTag
        comments
        description
        cost
        deliveryDate
        inventoryItemId
        primaryCode
        quantity
        exchangeRate
        statusId
        transactionType
        externalId
      }
      customAttributes {
        name
        value
      }
      provisioningAttributes {
        serviceType
        priceOfferId
        provisioningAttributesList {
          name
          value
        }
      }
      subscriptions {
        index
        subscriptionName
        trialToPaidNow
        initialTerm
        initialTermUnit
        renewalTerm
        renewalTermUnit
        trialTerm
        trialTermUnit
        trialType
        subscriptionCategory
      }
    }
  }
`;
  return qqlStr;
};
