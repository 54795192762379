import * as types from './types';

export function getUserPreferences(payload, cb) {
  return {
    type: types.GET_USER_PREFERENCES,
    payload,
    cb,
  };
}

export function getUserPreferencesSuccess(payload, cb) {
  return {
    type: types.GET_USER_PREFERENCES_SUCCESS,
    payload,
    cb,
  };
}

export function modifyUserPreferences(payload, cb) {
  return {
    type: types.MODIFY_USER_PREFERENCES,
    payload,
    cb,
  };
}

export function createUserPreferences(payload, cb) {
  return {
    type: types.CREATE_USER_PREFERENCES,
    payload,
    cb,
  };
}

export function getUserDashboards(payload, cb) {
  return {
    type: types.GET_USER_DASHBOARDS,
    payload,
    cb,
  };
}

export function getUserDashboardsSuccess(payload, cb) {
  return {
    type: types.GET_USER_DASHBOARDS_SUCCESS,
    payload,
    cb,
  };
}

export function getDashboardsByType(payload, cb) {
  return {
    type: types.GET_DASHBOARD_BY_TYPE,
    payload,
    cb,
  };
}

export function getPieChartDashBoardByType(payload, cb) {
  return {
    type: types.GET_PIE_CHART_DASHBOARD_BY_TYPE,
    payload,
    cb,
  };
}

export function getBarchartDashboardByType(payload, cb) {
  return {
    type: types.GET_BAR_CHART_DASHBOARD_BY_TYPE,
    payload,
    cb,
  };
}

export function getFolioSummaryDashboard(payload, cb) {
  return {
    type: types.GET_FOLIO_SUMMARY_DASHBOARD,
    payload,
    cb,
  };
}

export function getAssetSummaryDashboard(payload, cb) {
  return {
    type: types.GET_ASSET_SUMMARY_DASHBOARD,
    payload,
    cb,
  };
}
