import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { SidebarSupportSubItem } from '../../components/common';
import RouteNames from '../App/RouteNames';
import { checkPermissionForAllPages } from './CheckPermission';
import { isHasPermissionWithMenu } from '../../utils/utils';
import { permissionList } from '../../utils/constants';

class Sidebar extends Component {
  state = {};

  render() {
    const { isExpand = true, t, permissionsReports } = this.props;
    let listKeyMode = {};
    if (!isHasPermissionWithMenu({ permissions: permissionsReports, listKeys: permissionList.reports })) return null;

    if (permissionsReports && permissionsReports.reportModulePermissions) {
      const listPermission = permissionsReports.reportModulePermissions;
      listKeyMode = checkPermissionForAllPages({ listPermission });
    }
    const {
      modeGetCustomerBillingReport,
      modeGetSubscriptionReport,
      modeGetBillingInvoicingReconReport,
      modeGetFailedPaymentCustomerReport,
      modeCollection030days,
      modeCollection3060days,
      modeCollection6090days,
      modeGetPaymentReport,
      modeGetAgeingReport,
      modeGetTrialSubscriptionReport,
      modeGetFailedJobsReport,
      modeGetPaymentSuspenseReport,
      modeGetInvoicingPaymentReconReport,
      modeGetNewCustomerReport,
      modeGetAccountClosureReport,
      modeGetOrderReport,
      modeGetTrialToPaidSubscriptionReport,
      modeGetUnbilledTransactionReport,
      modeGetUsageSummaryReport,
      modeGetUsageDetailReport,
      modeGetUsageReconciliationReport,
      modeGetBilledTransactionSummaryReport,
      modeGetBillProductSummaryReport,
      modeGetInvoiceReport,
      modeGetWriteOffReport,
      modeGetDisputeReport,
      modeGetTaxSummaryReport,
      modeGetTaxDetailReport,
      modeGetRevenueRecognitionReport,
      modeGetRevenueSummaryReport,
      modeGetArOPSReport,
      modeCollectionGT90days,
      modeGetJobDetailReport,
      modeGetJobScheduleReport,
      modeGetActiveSubscriptionReport,
      modeGetSubscriptionContractExpiryReport,
      modeGetRevenueForecastReport,
      modeGetMRRForecastReport,
      modeGetVoiceCustomerCallTypeSummaryReport,
      modeGetVoiceOperatorCallTypeSummaryReport,
      modeGetExcessVoiceTrafficReport,
      modeGetVoiceTrafficByLDDestinationReport,
      modeGetDataServiceReport,
      modeGetNewSMSReport,
      modeGetRawInterconnectVoiceReport,
      modeGetRawInterconnectSMSReport,
      modeGetCreditNoteReport,
      modeGetVoiceCallTypeSummaryReport,
      modeGetInternetOperatorDataCost,
      modeGetTelcoSummary,
      modeSearchAccountReport,
      modeGetRawFileReport,
      modeGetBillableService,
      modeGetAccountingReconciliationReport,
      modeGetRawCdrReport,
      modeGetRawSmsReport,
      modeGetBillableServicesForecast,
      modeGetNonBroadsoftMapReport,
      modeGetServicesForecastReport,
      modeGetEmployeeCostingPnl,
      modeGetObjectFiles,
      modeGetProvisioningData,
      modeGetbillingTracker,
      modeGetCommitmentReport,
      modeGetAgeingPaymentReport,
      modeGetCancellationExtract,
      modeGetSearchKPIExtract,
      modeGetBroadsoftZeroDurationCdrs,
      modeGetNonBroadsoftZeroDurationCdrs,
      modeGetDetailRecords,
      modeGetExtractBI,
      modeGetRegR00105Report,
      modeGetRegR00106Report,
      modeGetRegR00401Report,
      modeGetRegR00405Report,
      modeGetRegR00501Report,
      modeGetRegR00502Report,
      modeGetRegR00503Report,
      modeGetRegR00504Report,
      modeGetRegR00505Report,
      modeGetRegR00506Report,
      modeGetCreditBureauReport,
      modeExemtionReport,
      modeGetFonatelReport,
      modeGetPhcAssetReport,
      modeGetServiceReconReport,
      modeGetPrepaidConsumptionReport,
      modeGetUsageAverageConsumptionReport,
      modeGetEnergyTopupReport,
      modeGetSubscriptionTopupReport,
      modeGetOrderDetailReport,
      modeGetServiceInvoiceSummaryReport,
      modeGetItemSummaryReport,
      modeGetAssetReport,
      modeGetCollectionNextActionReport,
    } = listKeyMode;

    const listConfigExtracts = [];
    if (modeGetCancellationExtract) {
      listConfigExtracts.push({
        icon: 'ti-server',
        label: 'reportPage:sidebar.commissionsCancellations',
        href: RouteNames.commissionsCancellations.path,
      });
    }

    if (modeGetSearchKPIExtract) {
      listConfigExtracts.push({
        icon: 'ti-bar-chart',
        label: 'reportPage:sidebar.KPI',
        href: RouteNames.kpi.path,
      });
    }

    if (modeGetExtractBI) {
      listConfigExtracts.push({
        icon: 'ti-palette',
        label: 'reportPage:sidebar.extractBI',
        href: RouteNames.extractBI.path,
      });
    }

    const sideProps = {
      isExpand,
      items: [
        {
          isHaveExpand: true,
          mainTilte: 'navbar:operationsHub.subMain.reports',
          href: '',
          childs: [],
        },
        {
          isHaveExpand: true,
          mainTilte: 'reportPage:sidebar.config',
          isHiddenChild: true,
          childs: [
            {
              icon: 'ti-file',
              disable: true,
              label: `${t('reportPage:sidebar.styleSheet')}-1`,
              href: '/report/config/sheet-1',
            },
            {
              icon: 'ti-file',
              disable: true,
              label: `${t('reportPage:sidebar.styleSheet')}-2`,
              href: '/report/config/sheet-2',
            },
            {
              icon: 'ti-file',
              disable: true,
              label: `${t('reportPage:sidebar.styleSheet')}-3`,
              href: '/report/config/sheet-3',
            },
            {
              icon: 'ti-file',
              disable: true,
              label: `${t('reportPage:sidebar.styleSheet')}-4`,
              href: '/report/config/sheet-4',
            },
            {
              icon: 'ti-file',
              disable: true,
              label: `${t('reportPage:sidebar.styleSheet')}-5`,
              href: '/report/config/sheet-5',
            },
          ],
        },
        {
          isHaveExpand: true,
          mainTilte: 'reportPage:sidebar.templates',
          isHiddenChild: true,
          childs: [
            {
              icon: 'ti-folder',
              disable: true,
              label: `${t('reportPage:sidebar.template')}-1`,
              href: '/report/config/template-1',
            },
            {
              icon: 'ti-folder',
              disable: true,
              label: `${t('reportPage:sidebar.template')}-2`,
              href: '/report/config/template-2',
            },
            {
              icon: 'ti-folder',
              disable: true,
              label: `${t('reportPage:sidebar.template')}-3`,
              href: '/report/config/template-3',
            },
            {
              icon: 'ti-folder',
              disable: true,
              label: `${t('reportPage:sidebar.template')}-4`,
              href: '/report/config/template-4',
            },
            {
              icon: 'ti-folder',
              disable: true,
              label: `${t('reportPage:sidebar.template')}-5`,
              href: '/report/config/template-5',
            },
          ],
        },
        {
          isHaveExpand: true,
          mainTilte: 'reportPage:sidebar.report',
          isHiddenItems: false,
          subMain: [
            (modeGetNewCustomerReport || modeGetAccountClosureReport || modeSearchAccountReport) && {
              subMainTitle: 'reportPage:sidebar.customerReports',
              key: 'customerReports',
              childs: [
                modeSearchAccountReport && {
                  icon: 'ti-panel',
                  label: 'reportPage:sidebar.accountReport',
                  href: RouteNames.searchAccount.path,
                },
                modeGetNewCustomerReport && {
                  icon: 'ti-user',
                  label: 'reportPage:sidebar.newCustomer',
                  href: RouteNames.newCustomerReport.path,
                },
                modeGetAccountClosureReport && {
                  icon: 'ti-lock',
                  label: 'reportPage:sidebar.accountClosure',
                  href: RouteNames.accountClosure.path,
                },
                modeExemtionReport && {
                  icon: 'ti-envelope',
                  label: 'reportPage:sidebar.taxExemption',
                  href: RouteNames.taxExemptionReport.path,
                },
              ],
            },
            (modeGetSubscriptionReport ||
              modeGetTrialSubscriptionReport ||
              modeGetActiveSubscriptionReport ||
              modeGetTrialToPaidSubscriptionReport ||
              modeGetSubscriptionContractExpiryReport ||
              modeGetBillableService ||
              modeGetServicesForecastReport ||
              modeGetProvisioningData) && {
              subMainTitle: 'reportPage:sidebar.subscriptionReports',
              key: 'subscriptionReports',
              childs: [
                modeGetSubscriptionReport && {
                  icon: 'ti-calendar',
                  label: 'reportPage:sidebar.subscriptionExpiry',
                  href: RouteNames.subscriptionExpiry.path,
                },
                modeGetTrialSubscriptionReport && {
                  icon: 'ti-timer',
                  label: 'reportPage:sidebar.trialSubscription',
                  href: RouteNames.trialExpiry.path,
                },
                modeGetTrialToPaidSubscriptionReport && {
                  icon: 'ti-reload',
                  label: 'reportPage:sidebar.trialToPaidSubscription',
                  href: RouteNames.trialToPaidSubscription.path,
                },
                modeGetActiveSubscriptionReport && {
                  icon: 'ti-server',
                  label: 'reportPage:sidebar.activeSubscriptions',
                  href: RouteNames.activeSubscriptions.path,
                },
                modeGetSubscriptionContractExpiryReport && {
                  icon: 'ti-package',
                  label: 'reportPage:sidebar.subscriptionContractExpiry',
                  href: RouteNames.subscriptionContractExpiry.path,
                },
                modeGetBillableService && {
                  icon: 'ti-server',
                  label: 'reportPage:sidebar.subscriptionBillableServices',
                  href: RouteNames.subscriptionBillableServices.path,
                },
                modeGetServicesForecastReport && {
                  icon: 'ti-layout-grid3',
                  label: 'reportPage:sidebar.servicesForcecast',
                  href: RouteNames.servicesForcecast.path,
                },
                modeGetProvisioningData && {
                  icon: 'ti-archive',
                  label: 'reportPage:sidebar.provisioningData',
                  href: RouteNames.provisioningData.path,
                },
                modeGetServiceReconReport && {
                  icon: 'ti-book',
                  label: 'reportPage:sidebar.contractServiceReconciliation',
                  href: RouteNames.contractServiceReconciliation.path,
                },
                modeGetEnergyTopupReport && {
                  icon: 'fa fa-battery-three-quarters',
                  label: 'reportPage:sidebar.energyTopup',
                  href: RouteNames.energyTopup.path,
                },
                modeGetAssetReport && {
                  icon: 'ti-bag',
                  label: 'reportPage:sidebar.asset',
                  href: RouteNames.asset.path,
                },
              ],
            },
            modeGetOrderReport && {
              subMainTitle: 'reportPage:sidebar.orderReports',
              key: 'orderReports',
              childs: [
                modeGetOrderReport && {
                  icon: 'ti-shopping-cart',
                  label: 'reportPage:sidebar.order',
                  href: RouteNames.orderReport.path,
                },
                modeGetOrderDetailReport && {
                  icon: 'ti-layers-alt',
                  label: 'reportPage:sidebar.orderDetail',
                  href: RouteNames.orderDetailReport.path,
                },
              ],
            },
            (modeGetRawFileReport ||
              modeGetRawCdrReport ||
              modeGetBroadsoftZeroDurationCdrs ||
              modeGetNonBroadsoftMapReport ||
              modeGetNonBroadsoftZeroDurationCdrs ||
              modeGetRawSmsReport ||
              modeGetUsageReconciliationReport) && {
              subMainTitle: 'reportPage:sidebar.CDRReports',
              key: 'CDRReports',
              childs: [
                modeGetRawFileReport && {
                  icon: 'ti-bag',
                  label: 'reportPage:sidebar.rawFiles',
                  href: RouteNames.rawUsageFile.path,
                },
                modeGetRawCdrReport && {
                  icon: 'ti-cloud-down',
                  label: 'reportPage:sidebar.rawBroadsoft',
                  href: RouteNames.rawCdr.path,
                },
                modeGetBroadsoftZeroDurationCdrs && {
                  icon: 'ti-rss',
                  label: 'reportPage:sidebar.rawBroadsoftZeroDurationCDR',
                  href: RouteNames.broadsoftZeroDurationCdrs.path,
                },
                modeGetNonBroadsoftMapReport && {
                  icon: 'ti-crown',
                  label: 'reportPage:sidebar.metaswitch',
                  href: RouteNames.nonBroadSoftRawCdrData.path,
                },
                modeGetNonBroadsoftZeroDurationCdrs && {
                  icon: 'ti-control-stop',
                  label: 'reportPage:sidebar.metaswitchZero',
                  href: RouteNames.nonBroadsoftZeroDurationCdrs.path,
                },
                modeGetRawSmsReport && {
                  icon: 'ti-cloud-down',
                  label: 'reportPage:sidebar.rawSMSFiles',
                  href: RouteNames.rawSMSFiles.path,
                },
                modeGetUsageReconciliationReport && {
                  icon: 'ti-medall',
                  label: 'reportPage:sidebar.CDRReconciliationSummary',
                  href: RouteNames.CDRReconciliationSummary.path,
                },
              ],
            },
            (modeGetUsageSummaryReport ||
              modeGetUsageReconciliationReport ||
              modeGetUsageDetailReport ||
              modeGetVoiceCallTypeSummaryReport ||
              modeGetVoiceCustomerCallTypeSummaryReport ||
              modeGetVoiceOperatorCallTypeSummaryReport ||
              modeGetVoiceTrafficByLDDestinationReport ||
              modeGetExcessVoiceTrafficReport ||
              modeGetRawInterconnectVoiceReport ||
              modeGetNewSMSReport ||
              modeGetRawInterconnectSMSReport ||
              modeGetDataServiceReport ||
              modeGetInternetOperatorDataCost ||
              modeGetTelcoSummary ||
              modeGetUsageReconciliationReport ||
              modeGetDetailRecords ||
              modeGetPrepaidConsumptionReport) && {
              subMainTitle: 'reportPage:sidebar.usage',
              key: 'usageReports',
              childs: [
                modeGetUsageSummaryReport && {
                  icon: 'ti-bag',
                  label: 'reportPage:sidebar.usageSummary',
                  href: RouteNames.usageSummary.path,
                },
                modeGetUsageDetailReport && {
                  icon: 'ti-layers-alt',
                  label: 'reportPage:sidebar.usageDetail',
                  href: RouteNames.usageDetail.path,
                },
                modeGetVoiceCallTypeSummaryReport && {
                  icon: 'ti-import',
                  label: 'reportPage:sidebar.voiceCallTypeSummaryReport',
                  href: RouteNames.voiceCallTypeSummaryReport.path,
                },
                modeGetVoiceCustomerCallTypeSummaryReport && {
                  icon: 'ti-zoom-in',
                  label: 'reportPage:sidebar.voiceCustomerCallTypeSummary',
                  href: RouteNames.voiceCustomerCallTypeSummary.path,
                },
                modeGetVoiceOperatorCallTypeSummaryReport && {
                  icon: 'ti-comments',
                  label: 'reportPage:sidebar.voiceOperatorCallTypeSummary',
                  href: RouteNames.voiceOperatorCallTypeSummary.path,
                },
                modeGetVoiceTrafficByLDDestinationReport && {
                  icon: 'ti-flag',
                  label: 'reportPage:sidebar.voiceTrafficByLdDistination',
                  href: RouteNames.voiceTrafficByLdDistination.path,
                },
                modeGetExcessVoiceTrafficReport && {
                  icon: 'ti-layout',
                  label: 'reportPage:sidebar.voiceExcessTrafficReport',
                  href: RouteNames.voiceExcessTrafficReport.path,
                },
                modeGetRawInterconnectVoiceReport && {
                  icon: 'ti-mouse-alt',
                  label: 'reportPage:sidebar.interconnectVoiceReport',
                  href: RouteNames.interconnectVoiceReport.path,
                },
                modeGetNewSMSReport && {
                  icon: 'ti-truck',
                  label: 'reportPage:sidebar.newSmsReportData',
                  href: RouteNames.newSmsReportData.path,
                },
                modeGetRawInterconnectSMSReport && {
                  icon: 'ti-package',
                  label: 'reportPage:sidebar.interconnectSmsReport',
                  href: RouteNames.interconnectSmsReport.path,
                },
                modeGetDataServiceReport && {
                  icon: 'ti-briefcase',
                  label: 'reportPage:sidebar.onDemand',
                  href: RouteNames.onDemand.path,
                },
                modeGetInternetOperatorDataCost && {
                  icon: 'ti-server',
                  label: 'reportPage:sidebar.internetOperatorDataCost',
                  href: RouteNames.internetOperatorDataCost.path,
                },
                modeGetTelcoSummary && {
                  icon: 'ti-filter',
                  label: 'reportPage:sidebar.telcoSummary',
                  href: RouteNames.telcoSummary.path,
                },
                modeGetDetailRecords && {
                  icon: 'ti-layout-tab',
                  label: 'reportPage:sidebar.suspendedFailedRecords',
                  href: RouteNames.suspendedFailedRecords.path,
                },
                modeGetPrepaidConsumptionReport && {
                  icon: 'fa fa-money',
                  label: 'reportPage:sidebar.prepaidConsumption',
                  href: RouteNames.prepaidConsumption.path,
                },
                modeGetUsageAverageConsumptionReport && {
                  icon: 'ti-bar-chart',
                  label: 'reportPage:sidebar.prepaidAverageConsumption',
                  href: RouteNames.prepaidAverageConsumption.path,
                },
              ],
            },
            (modeGetCustomerBillingReport ||
              modeGetBillProductSummaryReport ||
              modeGetInvoiceReport ||
              modeGetBilledTransactionSummaryReport ||
              modeGetTaxSummaryReport ||
              modeGetTaxDetailReport ||
              modeGetBillableServicesForecast ||
              modeGetObjectFiles ||
              modeGetbillingTracker ||
              modeGetCommitmentReport ||
              modeGetServiceInvoiceSummaryReport ||
              modeGetItemSummaryReport) && {
              subMainTitle: 'reportPage:sidebar.billingReports',
              key: 'billingReports',
              childs: [
                modeGetCustomerBillingReport && {
                  icon: 'ti-credit-card',
                  label: 'reportPage:sidebar.billing',
                  href: RouteNames.billingReport.path,
                },
                modeGetBillProductSummaryReport && {
                  icon: 'ti-layers-alt',
                  label: 'reportPage:sidebar.productSummary',
                  href: RouteNames.productSummary.path,
                },
                modeGetInvoiceReport && {
                  icon: 'ti-notepad',
                  label: 'reportPage:sidebar.invoicing',
                  href: RouteNames.invoicingReport.path,
                },
                modeGetBilledTransactionSummaryReport && {
                  icon: 'ti-printer',
                  label: 'reportPage:sidebar.transactionSummary',
                  href: RouteNames.transactionSummary.path,
                },
                modeGetTaxSummaryReport && {
                  icon: 'ti-ruler-pencil',
                  label: 'reportPage:sidebar.taxSummary',
                  href: RouteNames.taxSummary.path,
                },
                modeGetTaxDetailReport && {
                  icon: 'ti-bookmark-alt',
                  label: 'reportPage:sidebar.taxDetail',
                  href: RouteNames.taxDetail.path,
                },
                modeGetBillableServicesForecast && {
                  icon: 'ti-menu-alt',
                  label: 'reportPage:sidebar.billableServicesForecast',
                  href: RouteNames.billableServicesForecast.path,
                },
                modeGetObjectFiles && {
                  icon: 'ti-files',
                  label: 'reportPage:sidebar.folioCount',
                  href: RouteNames.folioCount.path,
                },
                modeGetbillingTracker && {
                  icon: 'ti-list',
                  label: 'reportPage:sidebar.billingTracker',
                  href: RouteNames.billingTracker.path,
                },
                modeGetCommitmentReport && {
                  icon: 'ti-receipt',
                  label: 'reportPage:sidebar.commitmentData',
                  href: RouteNames.commitmentData.path,
                },
                modeGetFonatelReport && {
                  icon: 'ti-package',
                  label: 'reportPage:sidebar.fonatelReport',
                  href: RouteNames.fonatelReport.path,
                },
                modeGetPhcAssetReport && {
                  icon: 'ti-share',
                  label: 'reportPage:sidebar.PHCReport',
                  href: RouteNames.phcReport.path,
                },
                modeGetServiceInvoiceSummaryReport && {
                  icon: 'ti-gallery',
                  label: 'reportPage:sidebar.serviceInvoiceSummary',
                  href: RouteNames.serviceInvoiceSummary.path,
                },
                modeGetItemSummaryReport && {
                  icon: 'ti-harddrives',
                  label: 'reportPage:sidebar.itemSummary',
                  href: RouteNames.itemSummary.path,
                },
              ],
            },
            (modeGetPaymentReport || modeGetFailedPaymentCustomerReport || modeGetPaymentSuspenseReport) && {
              subMainTitle: 'reportPage:sidebar.paymentReports',
              key: 'paymentReports',
              childs: [
                modeGetPaymentReport && {
                  icon: 'ti-agenda',
                  label: 'reportPage:sidebar.payment',
                  href: RouteNames.paymentReport.path,
                },
                modeGetFailedPaymentCustomerReport && {
                  icon: 'ti-archive',
                  label: 'reportPage:sidebar.failedPayments',
                  href: RouteNames.failPayment.path,
                },
                modeGetPaymentSuspenseReport && {
                  icon: 'ti-receipt',
                  label: 'reportPage:sidebar.paymentSuspense',
                  href: RouteNames.paymentSuspenseReport.path,
                },
                modeGetSubscriptionTopupReport && {
                  icon: 'ti-credit-card',
                  label: 'reportPage:sidebar.topUp',
                  href: RouteNames.topUpReport.path,
                },
              ],
            },
            (modeCollection030days ||
              modeCollection3060days ||
              modeCollection6090days ||
              modeCollectionGT90days ||
              modeGetAgeingReport ||
              modeGetAgeingPaymentReport) && {
              subMainTitle: 'reportPage:sidebar.collectionReports',
              key: 'collectionReports',
              childs: [
                modeCollection030days && {
                  icon: 'ti-calendar',
                  label: 'reportPage:sidebar.030days',
                  href: RouteNames.collection030DaysReport.path,
                },
                modeCollection3060days && {
                  icon: 'ti-wallet',
                  label: 'reportPage:sidebar.3060days',
                  href: RouteNames.collection3060DaysReport.path,
                },
                modeCollection6090days && {
                  icon: 'ti-files',
                  label: 'reportPage:sidebar.6090days',
                  href: RouteNames.collection6090DaysReport.path,
                },
                modeCollectionGT90days && {
                  icon: 'ti-panel',
                  label: 'reportPage:sidebar.over90days',
                  href: RouteNames.collectionOver90DaysReport.path,
                },
                modeGetAgeingReport && {
                  icon: 'ti-notepad',
                  label: 'reportPage:sidebar.ageingInvoice',
                  href: RouteNames.collectionAgeingSummaryReport.path,
                },
                modeGetAgeingPaymentReport && {
                  icon: 'ti-image',
                  label: 'reportPage:sidebar.ageingPayment',
                  href: RouteNames.ageingPayment.path,
                },
                modeGetCollectionNextActionReport && {
                  icon: 'ti-bar-chart',
                  label: 'reportPage:sidebar.collectionActions',
                  href: RouteNames.collectionActions.path,
                },
              ],
            },
            (modeGetArOPSReport || modeGetDisputeReport || modeGetWriteOffReport) && {
              subMainTitle: 'reportPage:sidebar.AROpsReports',
              key: 'arOpsReports',
              childs: [
                modeGetCreditNoteReport && {
                  icon: 'ti-comments',
                  label: 'reportPage:sidebar.creditNotes',
                  href: RouteNames.creditNotesReport.path,
                },
                modeGetArOPSReport && {
                  icon: 'ti-comment-alt',
                  label: 'reportPage:sidebar.AROpsDetails',
                  href: RouteNames.arOpsDetails.path,
                },
                modeGetDisputeReport && {
                  icon: 'ti-layers',
                  label: 'reportPage:sidebar.openDispute',
                  href: RouteNames.openDispute.path,
                },
                modeGetWriteOffReport && {
                  icon: 'ti-layers-alt',
                  label: 'reportPage:sidebar.openWriteOff',
                  href: RouteNames.openWriteOff.path,
                },
              ],
            },
            (modeGetRevenueSummaryReport ||
              modeGetRevenueRecognitionReport ||
              modeGetRevenueForecastReport ||
              modeGetMRRForecastReport ||
              modeGetEmployeeCostingPnl) && {
              subMainTitle: 'reportPage:sidebar.revenueReports',
              key: 'revenueReports',
              childs: [
                modeGetRevenueSummaryReport && {
                  icon: 'ti-ruler-pencil',
                  label: 'reportPage:sidebar.revenueSummary',
                  href: RouteNames.revenueSummary.path,
                },
                modeGetRevenueRecognitionReport && {
                  icon: 'ti-save',
                  label: 'reportPage:sidebar.revenueRecognition',
                  href: RouteNames.revenueRecognition.path,
                },
                modeGetRevenueForecastReport && {
                  icon: 'ti-magnet',
                  label: 'reportPage:sidebar.revenueForecast',
                  href: RouteNames.revenueForecast.path,
                },
                modeGetMRRForecastReport && {
                  icon: 'ti-eraser',
                  label: 'reportPage:sidebar.MRRForecast',
                  href: RouteNames.revenueMMRForecast.path,
                },
                modeGetEmployeeCostingPnl && {
                  icon: 'ti-money',
                  label: 'reportPage:sidebar.timeAndExpensePL',
                  href: RouteNames.employeeCostingPNL.path,
                },
                modeGetEmployeeCostingPnl && {
                  icon: 'ti-world',
                  label: 'reportPage:sidebar.timeAndExpenseYearlyPL',
                  href: RouteNames.employeeCostingYearlyPL.path,
                },
              ],
            },
            (modeGetJobScheduleReport || modeGetFailedJobsReport || modeGetJobDetailReport) && {
              subMainTitle: 'reportPage:sidebar.jobReports',
              key: 'jobReports',
              childs: [
                modeGetJobScheduleReport && {
                  icon: 'ti-receipt',
                  label: 'reportPage:sidebar.scheduleSummary',
                  href: RouteNames.jobScheduleSummary.path,
                },
                modeGetFailedJobsReport && {
                  icon: 'ti-palette',
                  label: 'reportPage:sidebar.jobFailureActivity',
                  href: RouteNames.jobFailureActivity.path,
                },
                modeGetJobDetailReport && {
                  icon: 'ti-wallet',
                  label: 'reportPage:sidebar.jobsDetailReport',
                  href: RouteNames.jobDetail.path,
                },
              ],
            },
            (modeGetUnbilledTransactionReport ||
              modeGetBillingInvoicingReconReport ||
              modeGetInvoicingPaymentReconReport ||
              modeGetAccountingReconciliationReport) && {
              subMainTitle: 'reportPage:sidebar.reconciliationReports',
              key: 'reconciliationReports',
              childs: [
                modeGetUnbilledTransactionReport && {
                  icon: 'ti-server',
                  label: 'reportPage:sidebar.unbilledTransactions',
                  href: RouteNames.unbilledTransactions.path,
                },
                modeGetBillingInvoicingReconReport && {
                  icon: 'ti-money',
                  label: 'reportPage:sidebar.billingInvoicing',
                  href: RouteNames.billingInvoicing.path,
                },
                modeGetInvoicingPaymentReconReport && {
                  icon: 'ti-harddrives',
                  label: 'reportPage:sidebar.billingAR',
                  href: RouteNames.billingAR.path,
                },
                modeGetAccountingReconciliationReport && {
                  icon: 'ti-harddrives',
                  label: 'reportPage:sidebar.accountingReconciliationReport',
                  href: RouteNames.accountingReconciliation.path,
                },
              ],
            },
            (modeGetRegR00105Report ||
              modeGetRegR00106Report ||
              modeGetRegR00401Report ||
              modeGetRegR00405Report ||
              modeGetRegR00501Report ||
              modeGetRegR00502Report ||
              modeGetRegR00503Report ||
              modeGetRegR00504Report ||
              modeGetRegR00505Report ||
              modeGetRegR00506Report ||
              modeGetCreditBureauReport) && {
              subMainTitle: 'reportPage:sidebar.regulatoryReports',
              key: 'regulatoryReports',
              childs: [
                modeGetRegR00105Report && {
                  icon: 'ti-list-ol',
                  label: 'reportPage:sidebar.R00105',
                  href: RouteNames.r00105.path,
                },
                modeGetRegR00106Report && {
                  icon: 'ti-list-ol',
                  label: 'reportPage:sidebar.R00106',
                  href: RouteNames.r00106.path,
                },
                modeGetRegR00401Report && {
                  icon: 'ti-list-ol',
                  label: 'reportPage:sidebar.R00401',
                  href: RouteNames.r00401.path,
                },
                modeGetRegR00405Report && {
                  icon: 'ti-list-ol',
                  label: 'reportPage:sidebar.R00405',
                  href: RouteNames.r00405.path,
                },
                modeGetRegR00501Report && {
                  icon: 'ti-list-ol',
                  label: 'reportPage:sidebar.R00501',
                  href: RouteNames.r00501.path,
                },
                modeGetRegR00502Report && {
                  icon: 'ti-list-ol',
                  label: 'reportPage:sidebar.R00502',
                  href: RouteNames.r00502.path,
                },
                modeGetRegR00503Report && {
                  icon: 'ti-list-ol',
                  label: 'reportPage:sidebar.R00503',
                  href: RouteNames.r00503.path,
                },
                modeGetRegR00504Report && {
                  icon: 'ti-list-ol',
                  label: 'reportPage:sidebar.R00504',
                  href: RouteNames.r00504.path,
                },
                modeGetRegR00505Report && {
                  icon: 'ti-list-ol',
                  label: 'reportPage:sidebar.R00505',
                  href: RouteNames.r00505.path,
                },
                modeGetRegR00506Report && {
                  icon: 'ti-list-ol',
                  label: 'reportPage:sidebar.R00506',
                  href: RouteNames.r00506.path,
                },
                modeGetCreditBureauReport && {
                  icon: 'ti-credit-card',
                  label: 'reportPage:sidebar.creditBureau',
                  href: RouteNames.creditBureau.path,
                },
              ],
            },
          ],
        },
        {
          isHaveExpand: true,
          mainTilte: 'reportPage:sidebar.extracts',
          isHiddenChild: true,
          childs: [...listConfigExtracts],
        },
      ],
    };

    return <SidebarSupportSubItem {...sideProps} />;
  }
}

Sidebar.propTypes = {
  permissionsBundleManagement: PropTypes.objectOf(PropTypes.any),
  location: PropTypes.objectOf(PropTypes.any),
  isExpand: PropTypes.bool,
};

Sidebar.defaultProps = {
  permissionsBundleManagement: {},
  location: {},
  isExpand: true,
};

const mapStateToProps = store => {
  const layoutReducer = store.get('layout');
  const { sidebarExpanded } = layoutReducer ? layoutReducer.toJS() : { sidebarExpanded: true };
  const permissionsReports = store.get('auth').toJS().permissionsApp.opsHubModules;

  return {
    isExpand: sidebarExpanded,
    permissionsReports: permissionsReports.length ? permissionsReports.filter(a => a.moduleName === 'REPORTS')[0] : {},
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default withTranslation('reportPage')(connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar)));
