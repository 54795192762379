import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { Breadcrumb, BarChartsGroup, GenericInput } from '../../../components/common';
import { getFolioSummaryDashboard } from '../actions';
import RouteNames from '../../App/RouteNames';
import { listNameTitleUserDashboard } from '../constants';
import defineBackground from '../../../utils/defineBackgroundChart';
import { checkPermissionFolioSummary } from '../CheckPermission';
import { makeGetPermissionsDashboard } from '../../App/selectors';

class FolioSummary extends Component {
  state = { data: [], folioType: null };

  componentDidMount() {
    this.doGetFolioSummaryDashboard();
  }

  doGetFolioSummaryDashboard = () => {
    const { getFolioSummaryDashboard } = this.props;
    const { folioType } = this.state;
    getFolioSummaryDashboard({ folioType }, ({ success, data }) => {
      if (success) this.setState({ data });
    });
  };

  render() {
    const { data, folioType } = this.state;
    const { t, permissionsDashboard, isComponent } = this.props;
    let modeFolioSummary = 0;
    if (permissionsDashboard && permissionsDashboard.dashboardModulePermissions) {
      const listPermission = permissionsDashboard.dashboardModulePermissions;
      modeFolioSummary = checkPermissionFolioSummary({ listPermission });
    }
    if (modeFolioSummary === 0) return '';
    if (isComponent)
    return (
      <BarChartsGroup
        key={data.name}
        title={t(listNameTitleUserDashboard.FOLIO_SUMMARY)}
        data={data || []}
        wrapperClass="chart-item-dashboard mt-2 mb-5"
        subClass="animate__animated animate__zoomIn"
        keySeries="value"
        keyCategories="mon"
        background={defineBackground[data.name] ? defineBackground[data.name] : null}
        isToolbar
        dataType="AMOUNT"
        columnWidth="5px"
      />
    );
    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link to={RouteNames.userPreferences.path} className="button x-small">
                {t('label.back')}
              </Link>
            </div>
            <div className="col-sm-6">
              <Breadcrumb items={[{ name: t(listNameTitleUserDashboard.FOLIO_SUMMARY) }]} />
            </div>
          </div>
        </div>
        <br />
        <div className="mb-4">
          <GenericInput
            value={folioType || null}
            wrapperClass="col-md-2 m-auto"
            label="label.folioType"
            name="folioType"
            type="select"
            tOptions="selections:folioType"
            onChange={({ value }) => {
              if (value === folioType) return null;
              return this.setState({ folioType: value || null }, () => this.doGetFolioSummaryDashboard());
            }}
          />
        </div>
        <div className="row mb-5">
          <BarChartsGroup
            key={data.name}
            title={t(listNameTitleUserDashboard.FOLIO_SUMMARY)}
            data={data || []}
            wrapperClass="chart-item embrix-line-barchart-type m-auto"
            subClass="animate__animated animate__zoomIn"
            keySeries="value"
            keyCategories="mon"
            background={defineBackground[data.name] ? defineBackground[data.name] : null}
            isToolbar
            dataType="AMOUNT"
            columnWidth="15px"
          />
        </div>
      </div>
    );
  }
}

FolioSummary.propTypes = {
  permissionsDashboard: PropTypes.objectOf(PropTypes.any),
};

FolioSummary.defaultProps = {
  permissionsDashboard: {},
};

const mapStateToProps = createStructuredSelector({
  permissionsDashboard: makeGetPermissionsDashboard() || {},
});

export default withTranslation('common')(connect(mapStateToProps, { getFolioSummaryDashboard })(FolioSummary));
