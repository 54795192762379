import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { queryRequest, mutationRequest } from 'utils/request';
import { toast } from 'react-toastify';
import {
  getArOpsConfigByType,
  updateArOpsConfigByType,
  searchAdjustments,
  processAdjustment,
  searchDisputes,
  processDispute,
  processSettlement,
  searchWriteOffs,
  getDisputeById,
  getWriteoffById,
  processWriteoffReversal,
  processWriteoff,
  getAdjustmentById,
  getARItemConfig,
  modifyARItemConfig,
  createARItemConfig,
  getARReasonCodeConfig,
  modifyARReasonCodeConfig,
  createARReasonCodeConfig,
  getGroupReasonCodes,
  modifyGroupReasonCode,
  createGroupReasonCode,
  getConfigArOpsThresholds,
  createConfigArOpsThresholds,
  modifyConfigArOpsThresholds,
  getInvoiceLinesById,
  searchBulkAdjustmentUploadStats,
  searchCloudFileUploadData,
  searchBulkActionStats,
  getUnbilledTransactionSummary,
  searchArOpsRequests,
  getArOpsRequestById,
} from 'api';
import { isConnecting, isEndConnected } from '../Loader/actions';
import * as types from './types';
import * as actions from './actions';
import i18n from '../../i18n';

export function* getArOpsConfigSaga({ cb }) {
  yield put(isConnecting());

  try {
    const response = yield call(queryRequest, getArOpsConfigByType());
    if (cb) cb(response && response.getArOpsConfigByType);
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb([]);
  }
}

export function* updateArOpsConfigByTypeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataProcess = payload;
    const response = yield call(mutationRequest, updateArOpsConfigByType(dataProcess));
    if (cb) cb(response ? response.updateArOpsConfigByType : null);
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.updateArOpsConfigSuccessfully'));
  } catch (err) {
    yield put(isEndConnected());
  }
}
// ------ adjustments
export function* searchListAdjustmentsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchAdjustments({ page, size, filter, sort }));
    if (response.searchAdjustments) {
      if (cb) cb({ success: true, data: response.searchAdjustments });

      yield put(actions.searchAdjustmentsSuccess(response.searchAdjustments));
    } else if (cb) cb({ success: true, data: null });

    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: true, data: null });
  }
}

export function* processAdjustmentSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataProcess = payload;
    const response = yield call(mutationRequest, processAdjustment(dataProcess));
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.applyAdjustmentSuccessfully'));
    let id = '';
    if (
      response.processAdjustment &&
      response.processAdjustment.invoiceLines &&
      response.processAdjustment.invoiceLines[0].id
    )
      id = response.processAdjustment.invoiceLines[0].id;
    if (response.processAdjustment && response.processAdjustment.id) id = response.processAdjustment.id;
    yield put(push(`/ar-operations/adjustments/${id}/detail`));
    if (cb) cb();
  } catch (err) {
    yield put(isEndConnected());
    // toast.error('Apply Adjustment failed!');
    if (cb) cb();
  }
}

/**
 * getAdjustmentDetailSaga
 * @param {*} payload: Adjustment's id
 */
export function* getAdjustmentDetailSaga({ payload }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getAdjustmentById(payload));
    if (response.getAdjustmentById) {
      yield put(actions.getAdjustmentDetailSuccess(response.getAdjustmentById));
    }
    yield put(isEndConnected());
  } catch (err) {
    // yield put(actions.getAdjustmentDetailFailed(handleError(err, `Can not get Adjustment Info for ${payload}`)));
    yield put(isEndConnected());
  }
}

// ------ disputes
export function* searchListDisputesSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchDisputes({ page, size, filter, sort }));
    if (response.searchDisputes) {
      yield put(actions.searchDisputesSuccess(response.searchDisputes));
      if (cb) cb({ success: true, data: response.searchDisputes });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    if (cb) cb({ success: false, data: null });
  }
}

/**
 * getDisputeSaga
 * @param {*} payload: dispute's id
 */
export function* getDisputeSaga({ payload }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getDisputeById(payload));
    if (response.getDisputeById) {
      yield put(actions.getDisputeDetailSuccess(response.getDisputeById));
    }
    yield put(isEndConnected());
  } catch (err) {
    // yield put(actions.getDisputeDetailFailed(handleError(err, `Can not get Dispute Info for ${payload}`)));
    yield put(isEndConnected());
  }
}

export function* processDisputeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataProcess = payload;
    const response = yield call(mutationRequest, processDispute(dataProcess));
    if (cb) yield cb();
    yield put(isEndConnected());
    toast.success(i18n.t('common:apiMessage.applyDisputeSuccessfully'));
    yield put(push(`/ar-operations/disputes/${response.processDispute.id}/detail`));
  } catch (err) {
    yield put(isEndConnected());
    // toast('Apply Dispute failed!', { type: toast.TYPE.ERROR });
    if (cb) cb();
  }
}

export function* processSettlementSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataProcess = payload;
    const response = yield call(mutationRequest, processSettlement(dataProcess));
    if (response.processSettlement) {
      yield put(isEndConnected());
      yield put(push(`/ar-operations/disputes/${response.processSettlement.id}/detail`));
      toast.success(i18n.t('common:apiMessage.settleADisputeSuccessfully'));
    }
    if (cb) yield cb();
  } catch (err) {
    yield put(isEndConnected());
    // setNotification({
    //   type: NotificationTypes.error,
    //   message: `Settle a Dispute failed! <br> ${err}`,
    // })
    if (cb) cb();
  }
}

// ------ write-offs
export function* searchListWriteOffsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchWriteOffs({ page, size, filter, sort }));
    if (response.searchWriteoffs) {
      yield put(actions.searchWriteOffsSuccess(response.searchWriteoffs));
      if (cb) cb({ success: true, data: response.searchWriteoffs });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    // yield put(actions.searchWriteOffsFailure('Failed to fetch disputes. Please try again or check your network!'));
    if (cb) cb({ success: false, data: null });
  }
}

export function* getWriteOffDetailSaga({ payload }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getWriteoffById(payload));
    if (response.getWriteoffById) {
      yield put(actions.getWriteOffDetailSuccess(response.getWriteoffById));
    }
    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
  }
}

export function* processWriteoffReversalOffSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataProcess = payload;
    const response = yield call(mutationRequest, processWriteoffReversal(dataProcess));
    if (cb) yield cb();
    yield put(isEndConnected());
    yield put(push(`/ar-operations/write-offs/${response.processWriteoffReversal.id}/detail`));
    toast.success(i18n.t('common:apiMessage.reverseWriteOffSuccessfully'));
  } catch (err) {
    yield put(isEndConnected());
    // yield put(
    //   setNotification({
    //     type: NotificationTypes.error,
    //     message: `reverse Write-off failed! <br> ${err}`,
    //   })
    // );
    if (cb) yield cb();
  }
}

export function* processWriteoffSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const dataProcess = payload;
    const response = yield call(mutationRequest, processWriteoff(dataProcess));
    if (cb) yield cb();
    yield put(isEndConnected());
    yield put(push(`/ar-operations/write-offs/${response.processWriteoff.id}/detail`));
    toast.success(i18n.t('common:apiMessage.applyWriteOffSuccessfully'));
  } catch (err) {
    yield put(isEndConnected());
    // yield put(
    //   setNotification({
    //     type: NotificationTypes.error,
    //     message: `Apply Write off failed! <br> ${err}`,
    //   })
    // );
    if (cb) cb();
  }
}

export function* getARItemConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getARItemConfig(payload));
    if (response.getARItemConfig) {
      if (cb) cb({ success: true, data: response.getARItemConfig });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* modifyARItemConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, modifyARItemConfig(payload));
    if (response.modifyARItemConfig) {
      toast.success(i18n.t('common:apiMessage.modifyArOpsItemMappingSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyARItemConfig });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createARItemConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, createARItemConfig(payload));
    if (response.createARItemConfig) {
      if (cb) cb({ success: true, data: response.createARItemConfig });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* getARReasonCodeConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getARReasonCodeConfig(payload));
    if (response.getARReasonCodeConfig) {
      if (cb) cb({ success: true, data: response.getARReasonCodeConfig });
    } else if (cb) cb({ success: true, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* modifyARReasonCodeConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, modifyARReasonCodeConfig(payload));
    if (response.modifyARReasonCodeConfig) {
      toast.success(i18n.t('common:apiMessage.modifyReasonCodeSuccessfully'));
      if (cb) cb({ success: true, data: response.modifyARReasonCodeConfig });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

export function* createARReasonCodeConfigSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, createARReasonCodeConfig(payload));
    if (response.createARReasonCodeConfig) {
      toast.success(i18n.t('common:apiMessage.createReasonCodeSuccessfully'));
      if (cb) cb({ success: true, data: response.createARReasonCodeConfig });
    } else if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: null });
    yield put(isEndConnected());
  }
}

// Group Reason
export function* getGroupReasonCodeSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getGroupReasonCodes());
    yield put(isEndConnected());
    if (response.getGroupReasonCodes && response.getGroupReasonCodes) {
      if (cb) yield cb({ success: true, data: response.getGroupReasonCodes });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* modifyGroupReasonCodeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyGroupReasonCode(payload));
    if (response.modifyGroupReasonCode) {
      if (cb) cb({ success: true, data: response.modifyGroupReasonCode });
      toast('Modify Groups Reason successfully!', { type: toast.TYPE.SUCCESS });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createGroupReasonCodeSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createGroupReasonCode(payload));
    if (response.createGroupReasonCode) {
      toast.success(i18n.t('common:apiMessage.createGroupsReasonSuccessfully'));
      if (cb) cb({ success: true, data: response.createGroupReasonCode });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getARThresholdsSaga({ cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getConfigArOpsThresholds());
    yield put(isEndConnected());
    if (response.getConfigArOpsThresholds && response.getConfigArOpsThresholds) {
      if (cb) yield cb({ success: true, data: response.getConfigArOpsThresholds });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* modifyARThresholdsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, modifyConfigArOpsThresholds(payload));
    if (response.modifyConfigArOpsThresholds) {
      if (cb) cb({ success: true, data: response.modifyConfigArOpsThresholds });
      toast.success(i18n.t('common:apiMessage.modifyARThresholdsSuccessfully'));
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* createARThresholdsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(mutationRequest, createConfigArOpsThresholds(payload));
    if (response.createConfigArOpsThresholds) {
      toast.success(i18n.t('common:apiMessage.createARThresholdsSuccessfully'));
      if (cb) cb({ success: true, data: response.createConfigArOpsThresholds });
    }
    yield put(isEndConnected());
  } catch (err) {
    if (cb) cb({ success: false, data: [] });
    yield put(isEndConnected());
  }
}

export function* getInvoiceLinesByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getInvoiceLinesById(payload));
    yield put(isEndConnected());
    if (response.getInvoiceLinesById && response.getInvoiceLinesById) {
      if (cb) yield cb({ success: true, data: response.getInvoiceLinesById });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* searchBulkAdjustmentUploadStatsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchBulkAdjustmentUploadStats(payload));
    yield put(isEndConnected());
    if (response.searchBulkAdjustmentUploadStats && response.searchBulkAdjustmentUploadStats) {
      if (cb) yield cb({ success: true, data: response.searchBulkAdjustmentUploadStats });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* searchCloudFileUploadDataSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchCloudFileUploadData(payload));
    yield put(isEndConnected());
    if (response.searchCloudFileUploadData && response.searchCloudFileUploadData) {
      if (cb) yield cb({ success: true, data: response.searchCloudFileUploadData });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* searchBulkActionStatsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchBulkActionStats(payload));
    yield put(isEndConnected());
    if (response.searchBulkActionStats && response.searchBulkActionStats) {
      if (cb) yield cb({ success: true, data: response.searchBulkActionStats });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* getUnbilledTransactionSummarySaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getUnbilledTransactionSummary(payload));
    yield put(isEndConnected());
    if (response.getUnbilledTransactionSummary && response.getUnbilledTransactionSummary) {
      if (cb) yield cb({ success: true, data: response.getUnbilledTransactionSummary });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* searchArOpsRequestsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const { page, size, filter, sort } = payload;
    const response = yield call(queryRequest, searchArOpsRequests({ page, size, filter, sort }));
    if (response.searchArOpsRequests) {
      // yield put(actions.searchAdjustmentsSuccess(response.searchArOpsRequests));
      if (cb) cb({ success: true, data: response.searchArOpsRequests });
    } else if (cb) cb({ success: false, data: null });

    yield put(isEndConnected());
  } catch (err) {
    yield put(isEndConnected());
    // yield put(actions.searchAdjustmentsSuccess([]));
    if (cb) cb({ success: false, data: null });
  }
}

export function* getArOpsRequestByIdSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, getArOpsRequestById(payload));
    yield put(isEndConnected());
    if (response.getArOpsRequestById && response.getArOpsRequestById) {
      if (cb) yield cb({ success: true, data: response.getArOpsRequestById });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

export function* searchAllBulkActionStatsSaga({ payload, cb }) {
  yield put(isConnecting());
  try {
    const response = yield call(queryRequest, searchBulkActionStats(payload));
    yield put(isEndConnected());
    if (response.searchBulkActionStats && response.searchBulkActionStats) {
      if (cb) yield cb({ success: true, data: response.searchBulkActionStats });
    } else if (cb) yield cb({ success: false, data: null });
  } catch (err) {
    yield put(isEndConnected());
    if (cb) yield cb({ success: false, data: {} });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* arOperationsSaga() {
  yield takeLatest(types.UPDATE_AROPS_CONFIG_BY_TYPE, updateArOpsConfigByTypeSaga);
  yield takeLatest(types.GET_AROPS_CONFIG_BY_TYPE, getArOpsConfigSaga);
  yield takeLatest(types.SEARCH_ADJUSTMENTS, searchListAdjustmentsSaga);
  yield takeLatest(types.SEARCH_DISPUTES, searchListDisputesSaga);
  yield takeLatest(types.SEARCH_WRITE_OFFS, searchListWriteOffsSaga);
  yield takeLatest(types.PROCESS_ADJUSTMENT, processAdjustmentSaga);
  yield takeLatest(types.PROCESS_DISPUTE, processDisputeSaga);
  yield takeLatest(types.PROCESS_SETTLEMENT, processSettlementSaga);
  yield takeLatest(types.GET_DISPUTE_DETAIL, getDisputeSaga);
  yield takeLatest(types.GET_WRITE_OFFS_DETAIL, getWriteOffDetailSaga);
  yield takeLatest(types.PROCESS_REVERSE_WRITE_OFF, processWriteoffReversalOffSaga);
  yield takeLatest(types.PROCESS_WRITE_OFF, processWriteoffSaga);
  yield takeLatest(types.GET_ADJUSTMENT_DETAIL, getAdjustmentDetailSaga);
  yield takeLatest(types.GET_AR_ITEM_CONFIG, getARItemConfigSaga);
  yield takeLatest(types.MODIFY_AR_ITEM_CONFIG, modifyARItemConfigSaga);
  yield takeLatest(types.CREATE_AR_ITEM_CONFIG, createARItemConfigSaga);
  yield takeLatest(types.GET_AR_REASON_CODE, getARReasonCodeConfigSaga);
  yield takeLatest(types.MODIFY_AR_REASON_CODE, modifyARReasonCodeConfigSaga);
  yield takeLatest(types.CREATE_AR_REASON_CODE, createARReasonCodeConfigSaga);
  yield takeLatest(types.GET_GROUPS_REASON, getGroupReasonCodeSaga);
  yield takeLatest(types.MODIFY_GROUPS_REASON, modifyGroupReasonCodeSaga);
  yield takeLatest(types.CREATE_GROUPS_REASON, createGroupReasonCodeSaga);
  yield takeLatest(types.GET_AR_THRESHOLDS, getARThresholdsSaga);
  yield takeLatest(types.MODIFY_AR_THRESHOLDS, modifyARThresholdsSaga);
  yield takeLatest(types.CREATE_AR_THRESHOLDS, createARThresholdsSaga);
  yield takeLatest(types.GET_INVOICE_LINES_BY_ID, getInvoiceLinesByIdSaga);
  yield takeLatest(types.SEARCH_BULK_ADJUSTMENT_UPLOAD_STATS, searchBulkAdjustmentUploadStatsSaga);
  yield takeLatest(types.SEARCH_CLOUD_FILE_UPLOAD_DATA, searchCloudFileUploadDataSaga);
  yield takeLatest(types.SEARCH_BULK_ACTION_STATS, searchBulkActionStatsSaga);
  yield takeLatest(types.GET_UNBILLED_TRANSACTION_SUMMARY, getUnbilledTransactionSummarySaga);
  yield takeLatest(types.SEARCH_AR_OPS_REQUESTS, searchArOpsRequestsSaga);
  yield takeLatest(types.GET_AR_OPS_REQUEST_BY_ID, getArOpsRequestByIdSaga);
  yield takeLatest(types.SEARCH_ALL_BULK_ACTION_STATS, searchAllBulkActionStatsSaga);
}
