export const GET_USER_PREFERENCES = 'GET_USER_PREFERENCES';
export const GET_USER_PREFERENCES_SUCCESS = 'GET_USER_PREFERENCES_SUCCESS';
export const MODIFY_USER_PREFERENCES = 'MODIFY_USER_PREFERENCES';
export const CREATE_USER_PREFERENCES = 'CREATE_USER_PREFERENCES';
export const GET_USER_DASHBOARDS = 'GET_USER_DASHBOARDS';
export const GET_USER_DASHBOARDS_SUCCESS = 'GET_USER_DASHBOARDS_SUCCESS';

export const GET_DASHBOARD_BY_TYPE = 'GET_DASHBOARD_BY_TYPE';
export const GET_PIE_CHART_DASHBOARD_BY_TYPE = 'GET_PIE_CHART_DASHBOARD_BY_TYPE';

export const GET_BAR_CHART_DASHBOARD_BY_TYPE = 'GET_BAR_CHART_DASHBOARD_BY_TYPE';
export const GET_FOLIO_SUMMARY_DASHBOARD = 'GET_FOLIO_SUMMARY_DASHBOARD';
export const GET_ASSET_SUMMARY_DASHBOARD = 'GET_ASSET_SUMMARY_DASHBOARD';
