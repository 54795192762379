import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';

export default data => {
  const dataCreate = parseToMutationRequest(data, []);
  const convertJsonToConfigCollectionSchedule = `convertJsonToConfigCollectionSchedule(input: ${dataCreate})`;
  return gql`
  query {
    ${convertJsonToConfigCollectionSchedule} {
      id
      type
      collectionProfile
      userId
      collectionSchedule {
        index
        offsetdays
        description
        action
        actionWorkingDay
      }
    }
  }
`;
};
