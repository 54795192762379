import gql from 'graphql-tag';

export default ({ type }) => {
  const getAssetSummaryDashboard = `getAssetSummaryDashboard(type: ${type})`;
  return gql`
    query {
      ${getAssetSummaryDashboard} {
        mon
        assettypeData {
          value
          type
        }
      }
    }
  `;
};
