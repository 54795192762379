import gql from 'graphql-tag';

export default () => gql`
  query {
    getErpExtractBatchConfig(dummy: "") {
      id
      description
      type
      userId
      data {
        index
        glAccount
        revenueActivityType
        exclude
        glAccountPattern
      }
    }
  }
`;
