import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { parseToMutationRequestPostMethod, getPageTotalCount } from '../../../utils/utils';
import PageTitle from '../../../components/PageTitle';
import {
  TitleFrom,
  ModalUploadFile,
  ModalWithItem,
  DataTable,
  GenericInput,
  ButtonExport,
  ExcelExport,
  SwitchExport,
  TablePagination,
} from '../../../components/common';
import RouteNames from '../../App/RouteNames';
import Footer from '../../../components/Footer';
import { makeGetPermissionsArOpsManagement, makeGetS3Config } from '../../App/selectors';
import { uploadMultiPartFiles, viewBulkAdjustmentUploadStats, readFileFromS3 } from '../../App/actions';
import {
  searchBulkAdjustmentUploadStats,
  searchBulkActionStats,
  searchCloudFileUploadData,
  searchAllBulkActionStats,
} from '../actions';
import { checkPermissionBulkAdjustments } from '../CheckPermission';
import { BulkAdjustmentsSearchForm } from '../../../components/ArOperationsHub';
import convertJson2Sheet from '../../../utils/ExcelHelper/exportExcelFile';
import convertJson2Pdf from '../../../utils/PdfHelper/exportPdfFile';
import { PAGE_SIZE_DOWNLOAD } from '../../../utils/constants';

let fileNameExport = '';

const bulkAdjustmentUploadStatsSort = {
  fileUploadDate: {
    asc: 'fileUploadDate_ASC',
    desc: 'fileUploadDate_DESC',
  },
  fileName: {
    asc: 'fileName_ASC',
    desc: 'fileName_DESC',
  },
};

const statsFileRecordColumns = [
  {
    name: 'createdDate',
    label: 'common:label.createdDate',
  },
  {
    name: 'batchId',
    label: 'common:label.batchId',
  },
  {
    name: 'apiName',
    label: 'common:label.apiName',
  },
  {
    name: 'status',
    label: 'common:label.status',
  },
  {
    name: 'errorMessage',
    label: 'common:label.errorMessage',
  },
  {
    name: 'payload',
    label: 'common:label.payload',
    style: { minWidth: '300px' },
    render: (colName, item) => (
      <GenericInput
        value={item.payload}
        wrapperClass="col-md-12"
        onChange={() => {}}
        name="payload"
        type="textarea"
        readOnly
      />
    ),
  },
];

class BulkAdjustments extends React.PureComponent {
  buttonRef = React.createRef();

  constructor() {
    super();
    this.state = {
      isOpenUploadFile: false,
      page: 0,
      size: 20,
      filter: {},
      sort: '',
      sorted: {},
      isLoading: false,
      adjustmentUploadStatData: [],
      isOpenModalFileDetails: false,
      totalCount: null,
      pageViewFile: 0,
      sizeViewFile: 20,
      filterViewFile: {},
      sortViewFile: '',
      sortedViewFile: {},
      listAllStatsFileData: [],
      isActivePdf: false,
      fileNameSelect: null,
      itemSelectDetails: null,
      allStatsFileData: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      totalCount: getPageTotalCount({ ...state, items: state.adjustmentUploadStatData }),
    };
  }

  componentDidMount() {
    this.doSearchBulkAdjustmentUploadStats();
  }

  onChangeSwitch = () => {
    const { isActivePdf } = this.state;
    this.setState({ isActivePdf: !isActivePdf });
  };

  onHandleUpload = ({ files }) => {
    const { isOpenUploadFile } = this.state;
    const { uploadMultiPartFiles } = this.props;
    if (files && files[0]) {
      const formData = new FormData();
      let query = '';
      query = `{"query": "mutation{uploadMultipartFiles(input: ${parseToMutationRequestPostMethod(
        { category: 'AR_OPS', arSubType: 'BULK_ADJUSTMENT', name: files[0].name },
        ['category', 'arSubType']
      )}){absoluteFile}}"}`;
      formData.append('graphql', query);
      formData.append('file', files[0]);
      uploadMultiPartFiles(formData, res => {
        if (res && res.success) {
          this.setState({
            isOpenUploadFile: !isOpenUploadFile,
            itemSelect: null,
          });
        }
      });
    } else {
      this.setState({ isOpenUploadFile: !isOpenUploadFile, itemSelect: null });
    }
  };

  onToggleModal = () => {
    const { isOpenUploadFile } = this.state;
    this.setState({ isOpenUploadFile: !isOpenUploadFile });
  };

  onCancelUploadFile = () => {
    this.setState({ isOpenUploadFile: false, itemSelect: null });
  };

  onToggleModalViewStatusFile = ({ item }) => {
    const { isOpenModalStatsFile } = this.state;
    if (!isOpenModalStatsFile) {
      this.doSearchBulkActionStats({ item });
    }
    this.setState({
      isOpenModalStatsFile: !isOpenModalStatsFile,
      itemSelectDetails: item || null,
      pageViewFile: 0,
      sizeViewFile: 20,
      allStatsFileData: [],
    });
  };

  doSearchBulkActionStats = ({ item }) => {
    const { searchBulkActionStats } = this.props;
    const { filterViewFile, pageViewFile, sizeViewFile, sortedViewFile } = this.state;
    const payload = {
      page: pageViewFile + 1,
      size: sizeViewFile,
      filter: {
        ...filterViewFile,
        fileName: item?.fileName || null,
        apiName: 'PROCESS_ADJUSTMENT',
      },
      sort: !isEmpty(sortedViewFile)
        ? bulkAdjustmentUploadStatsSort[sortedViewFile.sortCol][sortedViewFile.sortDir]
        : null,
    };
    searchBulkActionStats(payload, ({ data }) => {
      this.setState({ statsFileData: data || [], fileNameSelect: item.fileName || null });
    });
  };

  doSearchBulkAdjustmentUploadStats = () => {
    const { filter, page, size, sorted } = this.state;
    const { searchBulkAdjustmentUploadStats } = this.props;
    const payload = {
      page: page + 1,
      size,
      filter,
      sort: !isEmpty(sorted) ? bulkAdjustmentUploadStatsSort[sorted.sortCol][sorted.sortDir] : null,
    };
    this.setState({ isLoading: true });
    searchBulkAdjustmentUploadStats(payload, ({ data }) => {
      this.setState({ isLoading: false, adjustmentUploadStatData: data || [] });
    });
  };

  onHandleSubmitSearch = filter => {
    this.setState({ filter: { ...filter }, page: 0 }, () => {
      this.doSearchBulkAdjustmentUploadStats();
    });
  };

  onPageChange = page => {
    this.setState({ page }, () => this.doSearchBulkAdjustmentUploadStats());
  };

  onSizeChange = size => {
    this.setState({ size, page: 0 }, () => this.doSearchBulkAdjustmentUploadStats());
  };

  onPageChangeViewFile = page => {
    const { pageViewFile, itemSelectDetails } = this.state;
    if (page !== pageViewFile)
      this.setState({ pageViewFile: page }, () => this.doSearchBulkActionStats({ item: itemSelectDetails }));
  };

  onSizeChangeViewFile = size => {
    const { sizeViewFile, itemSelectDetails } = this.state;
    if (size !== sizeViewFile)
      this.setState({ sizeViewFile: size, page: 0 }, () => this.doSearchBulkActionStats({ item: itemSelectDetails }));
  };

  onSortColumn = (sortCol, sortDir) => {
    this.setState({ sorted: { sortCol, sortDir } }, () => this.doSearchBulkAdjustmentUploadStats());
  };

  doSearchAllBulkActionStats = cb => {
    const { searchAllBulkActionStats } = this.props;
    const { filterViewFile, sortedViewFile, itemSelectDetails } = this.state;
    const payload = {
      page: 1,
      size: PAGE_SIZE_DOWNLOAD,
      filter: {
        ...filterViewFile,
        fileName: itemSelectDetails?.fileName || null,
        apiName: 'PROCESS_ADJUSTMENT',
      },
      sort: !isEmpty(sortedViewFile)
        ? bulkAdjustmentUploadStatsSort[sortedViewFile.sortCol][sortedViewFile.sortDir]
        : null,
    };
    searchAllBulkActionStats(payload, ({ data }) => {
      this.setState({ allStatsFileData: data || [] }, () => {
        if (cb) cb();
      });
    });
  };

  onExport = () => {
    this.doSearchAllBulkActionStats(() => {
      const { isActivePdf, allStatsFileData } = this.state;
      if (isActivePdf) {
        const { t } = this.props;
        convertJson2Pdf({
          data: allStatsFileData,
          t,
          title: fileNameExport.toLocaleUpperCase(),
          fileName: `${fileNameExport.toLocaleLowerCase().replace(/ /g, '_')}_${moment(new Date()).format(
            'YYYY_MM_DD'
          )}`,
          columnsTable: statsFileRecordColumns,
        });
      } else {
        this.buttonRef.current.click();
      }
    });
  };

  doReadFileFromS3 = ({ config, path, fileName }) => {
    const { readFileFromS3 } = this.props;
    readFileFromS3({ ...config.data, url: path }, ({ success, data }) => {
      if (success) {
        saveAs(data, fileName);
      }
    });
  };

  doSearchCloudFileUploadData = () => {
    const { searchCloudFileUploadData, s3Config } = this.props;
    const payload = {
      page: 1,
      size: 1,
      filter: { fileType: 'BULK_ADJUSTMENT_TEMPLATE' },
    };
    searchCloudFileUploadData(payload, ({ success, data }) => {
      if (success && data) {
        this.doReadFileFromS3({
          path: `${data[0].filePath}/${data[0].fileName}`,
          fileName: data[0].fileName,
          config: { data: s3Config },
        });
      }
    });
  };

  render() {
    const { t, permissionsArOps } = this.props;
    const {
      itemSelect,
      isOpenUploadFile,
      isOpenModalStatsFile,
      statsFileData,
      isLoading,
      adjustmentUploadStatData,
      page,
      size,
      totalCount,
      isActivePdf,
      fileNameSelect,
      sorted,
      pageViewFile,
      sizeViewFile,
      allStatsFileData,
    } = this.state;

    let modeBulkAdjustments = 0;

    if (permissionsArOps && permissionsArOps.arOpsModulePermissions) {
      const listPermission = permissionsArOps.arOpsModulePermissions;
      modeBulkAdjustments = checkPermissionBulkAdjustments({ listPermission });
    }

    if (!modeBulkAdjustments) return '';
    const columns = [
      {
        name: 'view',
        label: 'common:label.view',
        render: (name, item) => (
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={() => {
              this.onToggleModalViewStatusFile({ item });
            }}
          >
            {t('label.view')}
          </button>
        ),
      },
      {
        name: 'fileName',
        label: 'common:label.fileName',
        sortable: true,
      },
      {
        name: 'fileUploadDate',
        label: 'common:label.uploadDate',
        sortable: true,
      },
      {
        name: 'successCount',
        label: 'common:label.successCount',
      },
      {
        name: 'failureCount',
        label: 'common:label.failureCount',
      },
      {
        name: 'pendingCount',
        label: 'common:label.pendingCount',
      },
      {
        name: 'totalCount',
        label: 'common:label.totalCount',
      },
    ];

    fileNameExport = fileNameSelect || '';
    if (fileNameExport) {
      if (fileNameExport.lastIndexOf('.') !== -1) {
        fileNameExport = fileNameExport.substring(0, fileNameExport.lastIndexOf('.'));
      }
    }

    return (
      <div className="content-wrapper">
        <PageTitle
          linkTo={RouteNames.arOperationsAdjustmentApply.path}
          titleBtn={t('label.back')}
          items={[{ name: t('arOperationPage:sidebar.adjustments'), url: RouteNames.arOperationsAdjustments.path }]}
        />
        <br />
        <div>
          <div className="col-sm-12 mb-30">
            <div className="card card-statistics h-100">
              <TitleFrom title={t('arOperationPage:sidebar.bulkAdjustments')} />
              <div className="card-body mb-3">
                <div className="col-md-12 mt-3">
                  <button type="button" className="button x-small float-right mr-2" onClick={this.onToggleModal}>
                    {t('label.upload')}
                  </button>
                  <button
                    type="button"
                    className="button button-border x-small float-right mr-2"
                    onClick={this.doSearchCloudFileUploadData}
                  >
                    {t('label.downloadTemplate')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="card card-statistics h-100">
              <TitleFrom title={t('label.fileUploadStats')} />
              <div className="card-body">
                <BulkAdjustmentsSearchForm onSubmit={this.onHandleSubmitSearch} />
              </div>
            </div>
            <div className="col-md-12 pr-0 mt-3">
              <div className="card card-statistics h-100">
                <div className="card-body">
                  <DataTable
                    columns={columns}
                    data={adjustmentUploadStatData || []}
                    onSort={this.onSortColumn}
                    isLoading={isLoading}
                    sorted={sorted}
                  />
                </div>
              </div>
            </div>
            <br />
            <div className="mb-30">
              <TablePagination
                pageNumber={page}
                pageSize={size}
                totalCount={totalCount}
                onPageChange={this.onPageChange}
                onSizeChange={this.onSizeChange}
              />
            </div>
          </div>
          <Footer className="footer-bottom" />
        </div>
        <ModalUploadFile
          title={t('label.uploadBulkAdjustmentFile')}
          isOpen={isOpenUploadFile}
          onCancel={this.onCancelUploadFile}
          onUpload={this.onHandleUpload}
          itemSelect={itemSelect}
          // accept=".csv"
        />
        <ModalWithItem
          modalTitle={`${t('label.fileName')}: ${fileNameSelect}`}
          wrapperClass="modal-custom modal-70 bd-example-modal-lg modal-selector"
          isOpen={isOpenModalStatsFile}
          onToggle={this.onToggleModalViewStatusFile}
        >
          <div className="d-flex mb-4 float-right">
            <div className="ml-auto mt-2 mb-auto mr-3">
              <SwitchExport
                onChange={this.onChangeSwitch}
                wrapperClass={isActivePdf ? 'switch-active' : 'switch-non-active'}
                title={t('label.excel')}
                checked={isActivePdf}
                rightTitle={t('label.pdf')}
              />
            </div>
            <>
              <ButtonExport onExport={() => this.onExport(false)} />
              <ExcelExport
                element={<button type="button" className="display-none" ref={this.buttonRef} />}
                nameSheet={fileNameExport.toLocaleUpperCase()}
                multiDataSet={convertJson2Sheet({
                  data: allStatsFileData,
                  t,
                  title: fileNameExport.toLocaleUpperCase(),
                  columnsTable: statsFileRecordColumns,
                })}
                fileName={`${fileNameExport.toLocaleLowerCase().replace(/ /g, '_')}_${moment(new Date()).format(
                  'YYYY_MM_DD'
                )}`}
              />
            </>
          </div>
          <DataTable columns={statsFileRecordColumns} data={statsFileData || []} />
          <div className="mb-30">
            <TablePagination
              pageNumber={pageViewFile}
              pageSize={sizeViewFile}
              totalCount={totalCount}
              onPageChange={this.onPageChangeViewFile}
              onSizeChange={this.onSizeChangeViewFile}
            />
          </div>
          <button type="button" onClick={this.onToggleModalViewStatusFile} className="button x-small float-right">
            {t('label.back')}
          </button>
        </ModalWithItem>
      </div>
    );
  }
}

BulkAdjustments.propTypes = {
  uploadMultiPartFiles: PropTypes.func.isRequired,
  searchBulkActionStats: PropTypes.func.isRequired,
  searchBulkAdjustmentUploadStats: PropTypes.func.isRequired,
  searchCloudFileUploadData: PropTypes.func.isRequired,
  permissionsArOps: PropTypes.objectOf(PropTypes.any),
  t: PropTypes.func,
  readFileFromS3: PropTypes.func.isRequired,
  searchAllBulkActionStats: PropTypes.func.isRequired,
};

BulkAdjustments.defaultProps = {
  permissionsArOps: {},
  t: () => {},
};

const mapStateToProps = createStructuredSelector({
  permissionsArOps: makeGetPermissionsArOpsManagement() || {},
  s3Config: makeGetS3Config() || {},
});

export default withTranslation('common')(
  connect(mapStateToProps, {
    uploadMultiPartFiles,
    viewBulkAdjustmentUploadStats,
    searchBulkAdjustmentUploadStats,
    searchBulkActionStats,
    searchCloudFileUploadData,
    readFileFromS3,
    searchAllBulkActionStats,
  })(BulkAdjustments)
);
