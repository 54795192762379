import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withTranslation } from 'react-i18next';
import { Breadcrumb, LineChartsGroup, GenericInput } from '../../../components/common';
import { getAssetSummaryDashboard } from '../actions';
import RouteNames from '../../App/RouteNames';
import { listNameTitleUserDashboard } from '../constants';
import defineBackground from '../../../utils/defineBackgroundChart';
import { checkPermissionAssetSummary } from '../CheckPermission';
import { makeGetPermissionsDashboard } from '../../App/selectors';

const assetType = ['SUBSCRIPTION_TYPE', 'PAID_VS_TRIAL_SUBSCRIPTION', 'SERVICE_TYPE'];

class AssetSummary extends Component {
  state = { data: [], type: null, top: null };

  componentDidMount() {
    this.doGetAssetSummaryDashboard();
  }

  doGetAssetSummaryDashboard = () => {
    const { getAssetSummaryDashboard } = this.props;
    const { type } = this.state;
    getAssetSummaryDashboard({ type }, ({ success, data }) => {
      if (success) this.setState({ data: data || [] });
    });
  };

  render() {
    const { data, type, top } = this.state;
    const { t, permissionsDashboard, isComponent, isOffer } = this.props;
    let modeAssetSummary = 0;
    if (permissionsDashboard && permissionsDashboard.dashboardModulePermissions) {
      const listPermission = permissionsDashboard.dashboardModulePermissions;
      modeAssetSummary = checkPermissionAssetSummary({ listPermission });
    }
    if (modeAssetSummary === 0) return '';

    let optionSelect = t('selections:assetSummaryType')();

    if (isOffer) {
      optionSelect = optionSelect.filter(val => !assetType.includes(val.value));
    }
    if (!isOffer) {
      optionSelect = optionSelect.filter(val => assetType.includes(val.value));
    }
    const titleForm = type ? optionSelect.find(e => e.value === type) : 0;
    if (isComponent)
      return (
        <LineChartsGroup
          key={data.name}
          title={t(
            isOffer ? listNameTitleUserDashboard.ASSET_SUMMARY_OFFER : listNameTitleUserDashboard.ASSET_SUMMARY_SUBS
          )}
          data={data || []}
          wrapperClass="chart-item-dashboard mt-2 mb-5"
          subClass="animate__animated animate__zoomIn"
          keySeries="value"
          keyCategories="mon"
          background={defineBackground[data.name] ? defineBackground[data.name] : null}
          isToolbar
          dataType="AMOUNT"
          keyData="assettypeData"
          keyType="type"
          columnWidth="5px"
          topN={top}
          isSupportZero
        />
      );
    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-sm-6">
              <Link to={RouteNames.userPreferences.path} className="button x-small">
                {t('label.back')}
              </Link>
            </div>
            <div className="col-sm-6">
              <Breadcrumb
                items={[
                  {
                    name: t(
                      isOffer
                        ? listNameTitleUserDashboard.ASSET_SUMMARY_OFFER
                        : listNameTitleUserDashboard.ASSET_SUMMARY_SUBS
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <br />
        <div className="mb-4 col-md-12 row">
          <GenericInput
            value={type || null}
            wrapperClass="col-md-3 ml-auto"
            label={isOffer ? 'label.offerType' : 'label.assetType'}
            name="type"
            type="select"
            options={optionSelect}
            onChange={({ value }) => {
              if (value === type) return null;
              return this.setState({ type: value || null, top: null }, () => this.doGetAssetSummaryDashboard());
            }}
          />
          <GenericInput
            value={top || null}
            wrapperClass="col-md-3 mr-auto"
            label="label.showTopN"
            name="top"
            type="number"
            onChange={({ value }) => {
              if (value === top) return null;
              return this.setState({ top: value || null });
            }}
          />
        </div>
        <div className="row mb-5">
          <LineChartsGroup
            key={data.name}
            title={
              titleForm && titleForm.label
                ? `${titleForm.label.replace(/_/g, ' ').toLowerCase()} ${t('label.summary')}`
                : t(
                    isOffer
                      ? listNameTitleUserDashboard.ASSET_SUMMARY_OFFER
                      : listNameTitleUserDashboard.ASSET_SUMMARY_SUBS
                  )
            }
            data={data || []}
            wrapperClass="chart-item embrix-line-barchart-type m-auto"
            subClass="animate__animated animate__zoomIn capitalize-text"
            keySeries="value"
            keyCategories="mon"
            background={defineBackground[data.name] ? defineBackground[data.name] : null}
            isToolbar
            dataType="AMOUNT"
            columnWidth="15px"
            keyData="assettypeData"
            keyType="type"
            topN={top}
            isSupportZero
          />
        </div>
      </div>
    );
  }
}

AssetSummary.propTypes = {
  permissionsDashboard: PropTypes.objectOf(PropTypes.any),
};

AssetSummary.defaultProps = {
  permissionsDashboard: {},
};

const mapStateToProps = createStructuredSelector({
  permissionsDashboard: makeGetPermissionsDashboard() || {},
});

export default withTranslation('common')(connect(mapStateToProps, { getAssetSummaryDashboard })(AssetSummary));
