import gql from 'graphql-tag';
import { parseToMutationRequest } from 'utils/utils';
import priceOfferNS from './constants/priceOfferNS';
import { getUserId } from '../../localStorages';

export default data => {
  let newPriceOfferNS = priceOfferNS;
  if (data && data.pricingModel === 'COMPLEX' && data.transactionType === 'USAGE') {
    newPriceOfferNS = newPriceOfferNS.filter(item => item !== 'timeUnit');
  }
  const newData = data;
  newData.userId = getUserId() || newData.userId || null;
  const dataCreate = parseToMutationRequest(newData, newPriceOfferNS);
  const modifyPriceOffer = `modifyPriceOffer(input: ${dataCreate})`;
  return gql`
  mutation {
    ${modifyPriceOffer} {
      id
      name
      description
      transactionType
      pricingModel
      serviceType
      serviceAddOn
      itemId
      startUnit
      startDuration
      endUnit
      endDuration
      minimumQuantity
      maximumQuantity
      salesChannel
      marketSegment
      accountType
      accountSubType
      startDate
      endDate
      status
      flatPricing{
        prices {
          index
          currencyId
          amount
        }
      }
      recurringPricing{
        purchaseProration
        cancelProration
        upgradeProration
        downgradeProration
        frequency
        unit
        prices{
          index
          currencyId
          amount
        }
      }
      customerPricing {
        index
        salesChannel
        marketSegment
        accountType
        accountSubType
        prices{
          index
          currencyId
          amount
        }
      }
    }
  }
`;
};
